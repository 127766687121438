import React from "react";
import {Button, Drawer, Table} from "antd";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {getNotificationListApi, updateNotificationListApi} from "../../api/notification.api";
import NotificationDetailComponent from "./notification-detail.component";

class NotificationListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Title', dataIndex: 'title', key: 'title'},
                {title: 'Description', dataIndex: 'description', key: 'description'},
                {title: 'Specified For', dataIndex: 'specifiedFor', key: 'specifiedFor'},
                {
                    title: 'Tanggal',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (createdAt) => formatDateToReadable(createdAt)
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => <Button onClick={() => this.setState({
                        notificationDetail: record,
                        notificationDrawerVisibility: true
                    })}>Detail</Button>
                }
            ],

            notificationList: [],
            notificationDetail: {},
            notificationDrawerVisibility: false,
            totalItems: 0,
            pageSize: 25,
            currentPage: 1,
            selectedRowKeys: []
        }
    }

    componentDidMount(): void {
        this.getNotificationData(25, 1)
    }

    getNotificationData = (pageSize, currentPage) => {
        let params = {
            size: pageSize,
            page: currentPage - 1
        }
        getNotificationListApi(params)
            .then(response => {
                this.setState({
                    notificationList: response.responseData.datas,
                    totalItems: response.responseData.totalItems
                })
            })
            .catch(err => console.log(err))
    }

    onChangePagination = (current) => {
        let {pageSize} = this.state
        this.setState({currentPage: current})
        this.getNotificationData(pageSize, current)
    };

    onSizeChange = (current, pageSize) => {
        this.setState({pageSize})
        this.getNotificationData(pageSize, current)
    }

    onSelectRow = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    }

    softDeleteNotification = () => {
        const {selectedRowKeys} = this.state
        updateNotificationListApi(selectedRowKeys)
            .then(response => {
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {selectedRowKeys, notificationList, totalItems, columns, pageSize, currentPage, notificationDetail, notificationDrawerVisibility} = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };
        return (
            <>
                <div align="right" style={{marginBottom: 20, marginRight: 75}}>
                    <Button disabled={selectedRowKeys.length > 0 ? false : true} onClick={() => this.softDeleteNotification()}>Delete</Button>
                </div>
                <Table
                    rowSelection={rowSelection}
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={notificationList}
                    rowKey={'id'}
                    pagination={{
                        pageSize: pageSize,
                        pageSizeOptions: ['25', '50', '100'],
                        total: totalItems,
                        onChange: this.onChangePagination,
                        current: currentPage,
                        onShowSizeChange: this.onSizeChange,
                    }}
                />
                <Drawer
                    title={`Notification Detail`}
                    width={520}
                    visible={notificationDrawerVisibility}
                    closable={true}
                    onClose={() => this.setState({notificationDrawerVisibility: false})}
                >
                    <NotificationDetailComponent notificationDetail={notificationDetail}/>
                </Drawer>
            </>
        )
    }
}

export default NotificationListComponent
