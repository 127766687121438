import React from "react";
import {Button, Drawer, Table} from "antd";
import UserDetailComponent from "./user-detail.component";
import {getAllUserApi, getUserDetailApi} from "../../api/user.api";
import {formatDateToReadable} from "../../ui-util/general-variable";
import PointTrackerComponent from "./point-tracker.component";
import {getPointListByIdApi, getTotalUserPointApi} from "../../api/point.api";
import FilterUserComponent from "./filter-user.component";
import {setUserData} from "../../ui-util/user-export-util";

class UserListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Name', dataIndex: 'full_name', key: 'name'},
                {title: 'Email', dataIndex: 'email', key: 'email'},
                {
                    title: 'Birthday',
                    dataIndex: 'birth_date',
                    key: 'birthday',
                    render: (birth_date) => !!birth_date && formatDateToReadable(birth_date)
                },
                {
                    title: 'Gender',
                    dataIndex: 'gender',
                    key: 'gender',
                    render: (gender) => gender === 'P' ? 'Perempuan' : 'Laki Laki'
                },
                {title: 'Level', dataIndex: 'level', key: 'level', render: (level) => level?.name},
                {
                    title: 'Action',
                    key: 'action',
                    fixed: 'right',
                    width: 100,
                    render: (record) => <Button onClick={() => this.showUserDetailDrawer(record)}>Detail</Button>
                }
            ],
            userDetailDrawerVisibility: false,
            userDetail: {},
            userList: [],
            currentPage: 1,
            pageSize: 25,
            userPointList: [],
            pageSizePointTracker: 10,
            currentPagePointTracker: 0,
            totalItemsPointTracker: 0,
            tableSearch: '',
            searchKey: '',
            selectedRowKeys: [],
            selectedRow: [],
            totalPoint: 0,
            loading: false,
            excelData: {}
        }
    }

    componentDidMount(): void {
        this.getAllUserData(1, 25, {})
    }

    getAllUserData = (currentPage, pageSize, bodyRequest) => {
        this.setState({loading: true})
        let params = {
            page: currentPage - 1,
            size: pageSize
        }
        getAllUserApi(params, bodyRequest)
            .then(result => {
                this.setState({
                    userList: result.responseData.datas,
                    totalItems: result.responseData.totalItems,
                    loading: false
                })
            }).catch(err => {
            console.log(err)
        })
    }

    showUserDetailDrawer = (record) => {
        const {pageSizePointTracker, currentPagePointTracker} = this.state
        let params = {
            page: currentPagePointTracker,
            size: pageSizePointTracker
        }
        Promise.all([
            getPointListByIdApi(record.id, params),
            getTotalUserPointApi(record.id),
            getUserDetailApi(record.id)
        ])
            .then(result => {
                let selectRow = setUserData([result[2]])
                this.setState({
                    userPointList: result[0].fixData.data,
                    totalItemsPointTracker: result[0].fixData.totalItems,
                    userDetailDrawerVisibility: true,
                    userDetail: result[2],
                    totalPoint: result[1].tempPoint,
                    excelData: selectRow
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    onChangePagination = (current) => {
        let {pageSize, searchKey, tableSearch, startDate, endDate} = this.state
        this.setState({currentPage: current})
        let bodyRequest = {
            table: tableSearch,
            search: searchKey,
            startDate,
            endDate
        }
        this.getAllUserData(current, pageSize, bodyRequest)
    };

    onSizeChange = (current, pageSize) => {
        this.setState({pageSize})
        this.getAllUserData(current, pageSize, {})
    }

    loadMorePoint = () => {
        const {pageSizePointTracker, currentPagePointTracker, userPointList, userDetail} = this.state
        let params = {
            page: currentPagePointTracker + 1,
            size: pageSizePointTracker
        }
        getPointListByIdApi(userDetail.id, params)
            .then(result => {
                let pointTracker = userPointList.concat(result.fixData.data)
                this.setState({userPointList: pointTracker, currentPagePointTracker: result.fixData.currentPage})
            })
            .catch(err => {
                console.log(err)
            })
    }

    searchAndFilterHandler = (searchKey, tableSearch, startDate, endDate) => {
        let bodyRequest = {
            table: tableSearch,
            search: searchKey,
            startDate,
            endDate
        }
        this.setState({searchKey, tableSearch, startDate, endDate})
        this.getAllUserData(1, 25, bodyRequest)
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns, userList, userDetailDrawerVisibility, userDetail, currentPage, totalItems, userPointList, totalItemsPointTracker, pageSize, totalPoint, loading, excelData} = this.state
        return (
            <>
                <FilterUserComponent searchAndFilter={this.searchAndFilterHandler}/>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={userList}
                    rowKey={'id'}
                    scroll={{x: 1500}}
                    loading={loading}
                    pagination={{
                        pageSize: pageSize,
                        pageSizeOptions: ['25', '50', '100'],
                        total: totalItems,
                        onChange: this.onChangePagination,
                        current: currentPage,
                        onShowSizeChange: this.onSizeChange,
                    }}
                />
                <Drawer
                    title={`User Detail`}
                    width={520}
                    visible={userDetailDrawerVisibility}
                    closable={true}
                    forceRender={true}
                    onClose={() => this.setState({userDetailDrawerVisibility: false, currentPagePointTracker: 0})}
                >
                    <UserDetailComponent userDetail={userDetail} userPointList={userPointList} totalPoint={totalPoint}
                                         excelData={excelData}/>
                    <PointTrackerComponent
                        userPointList={userPointList}
                        totalItems={totalItemsPointTracker}
                        loadMore={this.loadMorePoint}
                    />
                </Drawer>
            </>
        )
    }
}

export default UserListComponent
