import React from "react";
import {Button, Drawer, Table} from "antd";
import SkinConditionFormComponent from "./skin-condition-form.component";
import {createSkinConditionApi} from "../../api/skinCondition.api";

class SkinConditionComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {
                    title: 'Action', key: 'action',
                    render: (record) => <Button htmlType="submit"
                                                onClick={() => this.addSkinConditionButtonHandler(record)}>Add</Button>
                }
            ],
            skinConditionList: [
                {
                    key: 'skin-type',
                    name: 'Skin Type'
                }, {
                    key: 'skin-tone',
                    name: 'Skin Tone'
                }, {
                    key: 'skin-undertone',
                    name: 'Skin Undertone'
                }, {
                    key: 'skin-concern',
                    name: 'Skin Concern'
                }
            ],
            skinConditionFormVisibility: false,
            skinConditionKey: null,
            skinConditionName: null,
            loading: false
        }
    }

    addSkinConditionButtonHandler = (skinCondition) => {
        this.setState({
            skinConditionFormVisibility: true,
            skinConditionKey: skinCondition.key,
            skinConditionName: skinCondition.name
        })
    }

    onCloseDrawer = (value) => {
        this.setState({skinConditionFormVisibility: value})
    }

    onFinishAddSkinCondition = (values, fileImage) => {
        if (fileImage.length < 1) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const {skinConditionKey} = this.state
            const form = new FormData()
            form.append('name', values.name)
            form.append('image', values.image.file)
            createSkinConditionApi(skinConditionKey, form)
                .then(result => {
                    alert("Banner berhasil di upload")
                    this.setState({skinConditionFormVisibility: false, loading: false})
                    window.location.href = `/recommendation`
                }).catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns, skinConditionList, skinConditionFormVisibility, skinConditionName, loading} = this.state
        return (
            <>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={skinConditionList}
                    pagination={false}
                />
                <Drawer
                    title={`Create ${skinConditionName}`}
                    width={520}
                    visible={skinConditionFormVisibility}
                    closable={true}
                    onClose={() => this.onCloseDrawer(false)}
                >
                    <SkinConditionFormComponent onFinish={this.onFinishAddSkinCondition} skinConditionName={skinConditionName} loading={loading}/>
                </Drawer>
            </>
        )
    }
}

export default SkinConditionComponent
