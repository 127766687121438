import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import RewardFormComponent from "./reward-form.component";
import {createRewardApi} from "../../api/reward.api";
import moment from "moment";

class CreateRewardPage extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            loading: false,
            switchValue: false,
        }
    }

    onFinishCreateRewardHandler = (values, fileImage) => {
        if (fileImage.length < 1) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData();
            form.append('image', values.image.file)
            form.append('point_cost', values.point_cost)
            form.append('name', values.name)
            form.append('description', values.description)
            form.append('group_name', values.group_name)
            form.append('valid_until', moment(values.valid_until).format("YYYY-MM-DD"))
            form.append('is_publish', !!values.is_publish)
            if (!!values.level_id) {
                form.append('level_id', values.level_id)
            }
            if (!!values.email) {
                values.email.forEach((item) => {
                    form.append('email', item)
                })
            }
            createRewardApi(form)
                .then(result => {
                    this.setState({loading: false})
                    alert("Banner berhasil di upload")
                    window.location.href = '/reward'
                }).catch(err => {
                console.log(err)
            })
        }
    }

    onChangeSwitchValue = (value) => {
        this.setState({switchValue: value})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {loading, switchValue} = this.state
        return (
            <>
                <PageHeaderComponent title={'Create Reward'}/>
                <RewardFormComponent switchValue={switchValue} onChange={this.onChangeSwitchValue}
                                     onFinish={this.onFinishCreateRewardHandler} loading={loading} labelCol={2}/>
            </>
        )
    }
}
export default CreateRewardPage
