import React from "react";
import {Button, Col, Image, Row} from "antd";
import {formatDateToReadable} from "../../ui-util/general-variable";
import ExportUserListToExcel from "./export-user.component";

class UserDetailComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            userDetailList: [
                {key: 'full_name', name: 'Nama'},
                {key: 'birth_date', name: 'Tanggal Lahir'},
                {key: 'email', name: 'Email'},
                {key: 'address', name: 'Address'},
                {key: 'gender', name: 'Gender'},
                {key: 'phone', name: 'Phone Number'},
                {key: 'level', name: 'Level'},
                {key: 'created_at', name: 'Join Date'},
                {key: 'skin_tone', name: 'Skin Tone'},
                {key: 'skin_type', name: 'Skin Type'},
                {key: 'skin_undertone', name: 'Skin Undertone'},
                {key: 'skin_concerns', name: 'Skin Concern'},
                {key: 'referal_id', name: 'Referal Id'},
                {key: 'referal_to', name: 'Referal to'},
                {key: 'interests', name: 'Interest'},
                {key: 'social_medias', name: 'Social Media'},
                {key: 'point', name: 'Point'},
                {key: 'point_tracker', name: 'Point Tracker'}
            ]
        }
    }

    renderItemValue = (item) => {
        const {userDetail, userPointList, totalPoint} = this.props
        switch (item.key) {
            case 'created_at':
            case 'birth_date':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{!!userDetail[item.key] && formatDateToReadable(userDetail[item.key])}</p>
                        </Col>
                    </Row>
                )
            case 'point_tracker':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{userPointList.length === 0 && 'History point tidak ada'}</p>
                        </Col>
                    </Row>
                )
            case 'gender':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{userDetail[item.key] === 'P' ? 'Perempuan' : 'Laki Laki'}</p>
                        </Col>
                    </Row>
                )
            case 'skin_tone':
            case 'skin_type':
            case 'skin_undertone':
            case 'level':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{!!userDetail[item.key] && userDetail[item.key].name}</p>
                        </Col>
                    </Row>
                )
            case 'social_medias':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            {
                                !!userDetail[item.key] && userDetail[item.key].map((item, index) => (
                                    <p key={index.toString()}>{item.name} : {item.user_social_medias.value}</p>
                                ))
                            }
                        </Col>
                    </Row>
                )
            case 'address':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>
                                {!!userDetail[item.key] && userDetail[item.key] + ',' }
                                {!!userDetail['address_city'] && userDetail['address_city'].name + ',' + userDetail['address_city'].province.name}
                            </p>
                        </Col>
                    </Row>
                )
            case 'skin_concerns':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            {
                                !!userDetail[item.key] && userDetail[item.key].map((item, index) => (
                                    <p key={index.toString()}>{item.name}</p>
                                ))
                            }
                        </Col>
                    </Row>
                )
            case 'interests':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            {
                                !!userDetail[item.key] && userDetail[item.key].map((item, index) => (
                                    !!item.interest_category && <p key={index.toString()}>{item.interest_category.name} : {item.name}</p>
                                ))
                            }
                        </Col>
                    </Row>
                )
            case 'point':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            {totalPoint}
                        </Col>
                    </Row>
                )
            default:
                if (!!userDetail[item.key]) {
                    return (
                        <Row key={item.key} gutter={16}>
                            <Col className={'gutter-row'} span={10}>
                                <p>{item.name}</p>
                            </Col>
                            <Col className={'gutter-row'} span={14}>
                                <p>{userDetail[item.key]}</p>
                            </Col>
                        </Row>
                    )
                } else {
                    return null
                }
        }
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {userDetailList} = this.state
        const {userDetail, excelData} = this.props

        return (
            <>
                <Image
                    src={userDetail.photo}
                    width={200}
                    style={{marginBottom: 10}}
                />
                <Row gutter={16}>
                    <ExportUserListToExcel userList={excelData} element={<Button style={{ marginTop: 10, marginBottom: 10}}>Export</Button>}/>
                    <Col span={24}>
                        {userDetailList.map(item => this.renderItemValue(item))}
                    </Col>
                </Row>
            </>
        )
    }
}

export default UserDetailComponent
