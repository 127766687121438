import React from "react";
import {getInterestCategoryApi} from "../../api/interest-category.api";
import {CaretRightOutlined} from "@ant-design/icons";
import {Button, Collapse, Image, Table} from "antd";
import EditFormModal from "../../component/edit-form.modal";
import InterestFormComponent from "../create-interest/interest-form.component";
import {deleteInterestApi, updateInterestApi} from "../../api/interest.api";
import StandardAlertModal from "../../component/standard-alert.modal";

const {Panel} = Collapse;

class InterestListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Image', dataIndex: 'image', key: 'image', render: (image) => <Image src={image} width={100} height={100} />},
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {
                    key: 'edit', render: (record) =>
                        <>
                            <Button style={{marginRight: 10}}
                                onClick={() => this.changeVisibility('editInterestModalVisibility', true, record)}>Edit</Button>
                            <Button
                                onClick={() => this.changeVisibility('deleteInterestModalVisibility', true, record)}>Delete</Button>
                        </>
                }
            ],
            interestList: [],
            editInterestModalVisibility: false,
            deleteInterestModalVisibility: false,
            loading: false,
            loadingDelete: false
        }
    }

    componentDidMount(): void {
        this.getInterestListData()
    }

    getInterestListData = () => {
        getInterestCategoryApi()
            .then(response => {
                this.setState({interestList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    changeVisibility = (key, value, interestDetail) => {
        this.setState({[key]: value, interestDetail})
    }

    onFinishEditInterest = (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData();
            const {id} = this.state.interestDetail
            form.append('name', values.name)
            form.append('image', fileImage[0])

            updateInterestApi(id, form)
                .then(result => {
                    this.getInterestListData()
                    this.setState({loading: false, editInterestModalVisibility: false})
                    alert("Interest berhasil di upload")
                }).catch(err => {
                console.log(err)
            })
        }
    }

    onFinishDeleteInterest = () => {
        this.setState({loadingDelete: true})
        const {id} = this.state.interestDetail
        deleteInterestApi(id)
            .then(result => {
                this.getInterestListData()
                this.setState({loadingDelete: false, deleteInterestModalVisibility: false})
                alert('Interest berhasil dihapus!')
            }).catch(err => {
            this.setState({loadingDelete: false})
            this.changeVisibility('deleteInterestModalVisibility', false)
            console.log(err)
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {interestList, columns, editInterestModalVisibility, interestDetail, deleteInterestModalVisibility, loadingDelete, loading} = this.state
        return (
            <>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    ghost
                    className="site-collapse-custom-collapse">
                    {
                        interestList.map(item => (
                            <Panel header={item.name} key={item.id}>
                                <div style={{marginLeft: 50}}>
                                    <Table
                                        showHeader={false}
                                        className="components-table-demo-nested"
                                        columns={columns}
                                        dataSource={item.interests}
                                        pagination={false}
                                        rowKey={'id'}
                                    />
                                </div>
                            </Panel>
                        ))
                    }
                </Collapse>
                <EditFormModal
                    modalVisibility={editInterestModalVisibility}
                    modalVisibilityKey={"editInterestModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    title={`Edit Interest`}
                    content={<InterestFormComponent onFinish={this.onFinishEditInterest}
                                                    interestDetail={interestDetail} labelCol={4} loading={loading}/>}
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onFinishDeleteInterest}
                    changeVisibility={this.changeVisibility}
                    skinConditionKey={"deleteInterestModalVisibility"}
                    modalVisibility={deleteInterestModalVisibility}
                    title={"Delete interest"}
                    bodyMessage={"Apakah anda yakin ingin menghapus interest ini?"}
                />
            </>

        )
    }
}

export default InterestListComponent
