import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import BeautyProfileComponent from "./beauty-profile.component";

class BeautyProfilePage extends React.PureComponent {
    render() {
        return (
            <>
                <PageHeaderComponent title={'Beauty Profile List'}/>
                <BeautyProfileComponent/>
            </>
        )
    }
}

export default BeautyProfilePage