import moment from 'moment';

const isEqual = require("react-fast-compare")

const cloudFunctionBaseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const formatDateToReadable = (date) => {
    return moment(date).format("DD-MM-YYYY")
}

export {
    isEqual,
    cloudFunctionBaseUrl,
    formatDateToReadable
}
