import React from "react";
import {deleteRewardApi, getRewardListApi, updateRewardApi} from "../../api/reward.api";
import EditFormModal from "../../component/edit-form.modal";
import RewardFormComponent from "../create-reward/reward-form.component";
import StandardAlertModal from "../../component/standard-alert.modal";
import {Button, Image, Table, Tabs} from "antd";
import {formatDateToReadable} from "../../ui-util/general-variable";
import moment from "moment";
import {CheckCircleOutlined} from "@ant-design/icons";

const {TabPane} = Tabs;

class RewardSettingComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            columns: [
                {
                    title: 'Publish',
                    dataIndex: 'is_publish',
                    key: 'is_publish',
                    render: (is_publish) => is_publish && <CheckCircleOutlined key="check"/>
                },
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => <Image src={image} width={100} height={100}/>
                },
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {title: 'Description', dataIndex: 'description', key: 'description', width: 400},
                {
                    title: 'Level',
                    dataIndex: 'level',
                    key: 'level',
                    render: (level) => <div>{!!level && level.name}</div>
                },
                {
                    title: 'Valid',
                    dataIndex: 'valid_until',
                    key: 'valid',
                    render: (valid) => formatDateToReadable(valid),
                    width: 150
                },
                {title: 'Jumlah Point', dataIndex: 'point_cost', key: 'point', width: 120},
                {
                    title: 'Action', key: 'action', width: 250, render: (record) =>
                        <>
                            <Button style={{marginRight: 10}}
                                    onClick={() => {
                                        this.changeVisibility('editRewardModalVisibility', true, record)
                                        this.setState({switchValue: record.is_publish})
                                    }}>Edit</Button>
                            <Button
                                onClick={() => this.changeVisibility('deleteRewardModalVisibility', true, record)}>Delete</Button>
                        </>
                }
            ],
            tabList: [
                {tab: 'All', id: ''},
                {tab: 'Published', id: true},
                {tab: 'Unpublished', id: false}
            ],
            rewardList: [],
            rewardDetail: {},
            editRewardModalVisibility: false,
            deleteRewardModalVisibility: false,
            loading: false,
            loadingDelete: false,
            switchValue: true,
            activeTab: ''
        }
    }

    componentDidMount(): void {
        this.getRewardListData()
    }

    getRewardListData = () => {
        const {activeTab} = this.state
        let requestBody = activeTab === '' ? {} : {is_publish: activeTab === "true"}
        getRewardListApi(requestBody)
            .then(result => {
                this.setState({rewardList: result})
            })
            .catch(err => console.log(err))
    }

    changeVisibility = (key, value, rewardDetail) => {
        this.setState({[key]: value, rewardDetail})
    }

    onFinishEditReward = (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const {id} = this.state.rewardDetail
            const form = new FormData();
            form.append('image', fileImage[0])
            form.append('point_cost', values.point_cost)
            form.append('name', values.name)
            form.append('description', values.description)
            form.append('group_name', values.group_name)
            form.append('valid_until', moment(values.valid_until).format("YYYY-MM-DD"))
            form.append('is_publish', !!values.is_publish)
            if (!!values.level_id) {
                form.append('level_id', values.level_id)
            }
            if (!!values.email) {
                values.email.forEach((item) => {
                    form.append('email', item)
                })
            }

            updateRewardApi(id, form)
                .then(result => {
                    this.getRewardListData()
                    setTimeout(() => {
                        this.setState({loading: false, editRewardModalVisibility: false})
                    }, 1000);
                    alert('Reward berhasil di update')
                }).catch(err => {
                this.setState({loading: false})
                alert('Reward gagal di update')
                console.log(err)
            })
        }
    }

    onOkButtonHandler = () => {
        this.setState({loadingDelete: true})
        const {id} = this.state.rewardDetail
        deleteRewardApi(id)
            .then(response => {
                this.setState({loading: false})
                this.changeVisibility('deleteRewardModalVisibility', false)
                alert('Reward berhasil di hapus')
                window.location.reload()
            }).catch(err => {
            console.log(err)
        })
    }

    onChangeSwitchValue = (value) => {

        this.setState({switchValue: value})
    }

    onTabChange = (activeTab) => {
        this.setState({activeTab})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevState.activeTab !== this.state.activeTab) {
            this.getRewardListData()
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {rewardList, editRewardModalVisibility, rewardDetail, loading, loadingDelete, deleteRewardModalVisibility, columns, switchValue, activeTab, tabList} = this.state

        return (
            <div>
                <Tabs
                    activeKey={activeTab}
                    onChange={this.onTabChange}
                >
                    {
                        tabList.map((item, index) =>
                            <TabPane tab={item.tab} key={item.id}>
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={rewardList}
                                    scroll={{x: 1200}}
                                    rowKey={'id'}
                                />
                            </TabPane>
                        )
                    }
                </Tabs>
                <EditFormModal
                    modalVisibility={editRewardModalVisibility}
                    changeVisibility={this.changeVisibility}
                    modalVisibilityKey={"editRewardModalVisibility"}
                    title={"Edit Reward"}
                    content={<RewardFormComponent
                        rewardDetail={rewardDetail}
                        onFinish={this.onFinishEditReward}
                        switchValue={switchValue}
                        onChange={this.onChangeSwitchValue}
                        loading={loading}
                        labelCol={5}/>
                    }
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onOkButtonHandler}
                    changeVisibility={this.changeVisibility}
                    skinConditionKey={"deleteRewardModalVisibility"}
                    modalVisibility={deleteRewardModalVisibility}
                    title={"Anda yakin ingin menghapus reward?"}
                    bodyMessage={"Judul reward pada “History Poin” dan “List Reedem Reward” akan hilang  apabila  reward dihapus."}
                />
            </div>
        )
    }
}

export default RewardSettingComponent
