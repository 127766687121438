import React from "react";
import SkinConditionComponent from "./skin-condition.component";
import PageHeaderComponent from "../../component/page-header.component";

class SkinConditionPage extends React.PureComponent {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <PageHeaderComponent title={'Create Skin Condition'}/>
                <SkinConditionComponent/>
            </>
        )
    }
}

export default SkinConditionPage
