import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import { Col, Row, Spin } from 'antd';
import { ArrowRepeat, CheckCircle, XCircle } from 'react-bootstrap-icons';

function CustomPillSelect(props) {
  const {title, icon, data, onClick, width, onReset, selected} = props
  const [showOption, setShowOption]  = useState('')
  const node = useRef()

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  if (data===null || typeof(data)==="undefined") {
    return (<div className="filterSpinner"><Spin /></div>)
  }

  function generateList(){
    let content = []
    for (let item of data) {
      let dataIsSelected = selected.indexOf(item.value)>-1 ? "selected" : "";
      content.push(
        <li className={`pill-select ${dataIsSelected}`} onClick={() => itemSelected(item.value)} key={item.value}><span>{item.text}</span>
          {dataIsSelected==="selected" ? <XCircle /> : <CheckCircle /> }
        </li>
      )
    }
    return content
  }

  function itemSelected(value){
    setShowOption('')
    onClick(value)
  }

  function handleReset(){
    setShowOption('')
    onReset()
  }

  function handleClick(e) {
    if (node.current.contains(e.target)) {
      return;
    }
    
    setShowOption('')
    return;
  }

  function disabledEvent(e){
    setShowOption('showed')
    e.preventDefault()
    return false
  }
  
  return (
    <div className="toolicon-wrapper" ref={node}>
      <div onClick={(e)=>disabledEvent(e)} className={`toolicon filter` }></div>
      <div className={`tool-inner ${icon} left ${showOption}`} style={{"maxWidth": width}}>
          <div className="pill-wrapper">
          <Row className="pill-head">
            <Col className="pill-title" span={12}>{title}</Col>
            <Col onClick={(e)=>handleReset()} className="pill-refresh" span={12}><ArrowRepeat/> Reset</Col>
          </Row>
            <ul className="pill-container">
              {generateList()}
            </ul>
          </div>
      </div>
    </div>
  )
}

CustomPillSelect.defaultProps = {
  icon: "",
  width: "200px",
  data: [],
  selected: [],
  onClick: (value) => {console.log(`element value ${value} clicked`)},
  onReset: () => {console.log(`element reset`)}
}

CustomPillSelect.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  width: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.array,
  onClick: PropTypes.func,
  onReset: PropTypes.func,
};
  
export default CustomPillSelect;