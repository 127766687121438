import React from "react";
import {
    deleteInterestCategoryApi,
    getInterestCategoryApi,
    updateInterestCategoryApi
} from "../../api/interest-category.api";
import EditFormModal from "../../component/edit-form.modal";
import InterestCategoryFormComponent from "../create-interest-category/interest-category-form.component";
import StandardAlertModal from "../../component/standard-alert.modal";
import {Button, Image, Table} from "antd";

class InterestCategoryComponent extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {title: 'Image', dataIndex: 'image', key: 'image', render: (image) => <Image src={image} width={100} height={100} />},
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {
                    key: 'edit', render: (record) =>
                        <>
                            <Button style={{marginRight: 10}}
                                    onClick={() => this.changeVisibility('editInterestCategoryModalVisibility', true, record)}>Edit</Button>
                            <Button
                                onClick={() => this.changeVisibility('deleteInterestCategoryModalVisibility', true, record)}>Delete</Button>
                        </>
                }
            ],
            interestCategoryList: [],
            interestCategoryDetail: {},
            editInterestCategoryModalVisibility: false,
            deleteInterestCategoryModalVisibility: false,
            loading: false,
            loadingDelete: false
        }
    }


    componentDidMount(): void {
        this.getInterestCategoryData()
    }

    getInterestCategoryData = () => {
        getInterestCategoryApi()
            .then(response => {
                this.setState({interestCategoryList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    onFinishEditInterestCategory = (values, fileImage) => {
        if(fileImage.length < 1 && !!values.image.file){
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData();
            const {id} = this.state.interestCategoryDetail

            form.append('name', values.name)
            form.append('image', fileImage[0])

            updateInterestCategoryApi(id,form)
                .then(result => {
                    this.getInterestCategoryData()
                    alert("Interest category berhasil di ubah")
                    this.setState({loading: false})
                    this.changeVisibility('editInterestCategoryModalVisibility', false)
                }).catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
        }
    }

    changeVisibility = (key, value, interestCategoryDetail) => {
        this.setState({[key]: value, interestCategoryDetail})
    }

    onOkButtonHandler = () => {
        this.setState({loadingDelete: true})
        const {id} = this.state.interestCategoryDetail
        deleteInterestCategoryApi(id)
            .then(result => {
                this.getInterestCategoryData()
                alert('Interest category berhasil dihapus!')
                this.setState({loadingDelete: false})
                this.changeVisibility('deleteInterestCategoryModalVisibility', false)

            }).catch(err => {
            this.setState({loadingDelete: false})
            this.changeVisibility('deleteInterestCategoryModalVisibility', false)
            console.log(err)
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {interestCategoryList, editInterestCategoryModalVisibility, interestCategoryDetail, deleteInterestCategoryModalVisibility, loadingDelete, columns, loading} = this.state
        return (
            <>
                <Table
                    showHeader={false}
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={interestCategoryList}
                    pagination={false}
                    rowKey={'id'}
                />
                <EditFormModal
                    modalVisibility={editInterestCategoryModalVisibility}
                    changeVisibility={this.changeVisibility}
                    modalVisibilityKey={"editInterestCategoryModalVisibility"}
                    title={"Edit Category"}
                    content={
                        <InterestCategoryFormComponent onFinish={this.onFinishEditInterestCategory} interestCategoryDetail={interestCategoryDetail} labelCol={4} wrapperCol={20} loading={loading}/>
                    }
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onOkButtonHandler}
                    changeVisibility={this.changeVisibility}
                    skinConditionKey={"deleteInterestCategoryModalVisibility"}
                    modalVisibility={deleteInterestCategoryModalVisibility}
                    title={"Delete Category"}
                    bodyMessage={"Apakah anda yakin ingin menghapus kategori ini?"}
                />
            </>
        )
    }
}

export default InterestCategoryComponent
