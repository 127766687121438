import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getUserCampaignApi = (campaignId,params) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/${campaignId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    }))
}

const getUserCampaignDetailApi = (campaignId,userId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/${campaignId}/${userId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setUserCampaignApi = (campaignId,data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/approval/${campaignId}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setSendProductApi = (campaignId,data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/send-product/${campaignId}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setApproveDraftApi = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-content/publish`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setApproveDraftKontenApi = (campaignId,data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-content/approve-content/${campaignId}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setPublishKonten = (campaignId,data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/done/${campaignId}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getContentCampaignApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-content/detail`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setContentCampaignApi = (contentId,data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-content/approval/${contentId}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const deleteUserCampaignApi = (campaign_id,user_id) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/${campaign_id}/${user_id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

export {
    getUserCampaignApi,
    getUserCampaignDetailApi,
    setUserCampaignApi,
    setSendProductApi,
    setApproveDraftApi,
    setPublishKonten,
    getContentCampaignApi,
    setContentCampaignApi,
    setApproveDraftKontenApi,
    deleteUserCampaignApi
}
