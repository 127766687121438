import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportUserListToExcel extends React.Component {

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {userList, element} = this.props

        return (
            <ExcelFile element={element}>
                <ExcelSheet data={userList} name="Avostore List">
                    <ExcelColumn label="Id" value="id"/>
                    <ExcelColumn label="Nama" value="name"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Tanggal Bergabung" value="joinDate"/>
                    <ExcelColumn label="Tanggal Lahir" value="birthDate"/>
                    <ExcelColumn label="Jenis Kelamin" value="gender"/>
                    <ExcelColumn label="No. Handphone" value="phone"/>
                    <ExcelColumn label="Referal Id" value="referal_id"/>
                    <ExcelColumn label="Level" value="level"/>
                    <ExcelColumn label="Alamat" value="address"/>
                    <ExcelColumn label="Skin Type" value="skinType"/>
                    <ExcelColumn label="Skin Tone" value="skinTone"/>
                    <ExcelColumn label="Skin Undertone" value="skinUndertone"/>
                    <ExcelColumn label="Skin Concern" value="skinConcern"/>
                    <ExcelColumn label="Instagram" value="Instagram"/>
                    <ExcelColumn label="Facebook" value="Facebook"/>
                    <ExcelColumn label="Blog" value="Blog"/>
                    <ExcelColumn label="Twitter" value="Twitter"/>
                    <ExcelColumn label="Youtube" value="Youtube"/>
                    <ExcelColumn label="TikTok" value="TikTok"/>
                    <ExcelColumn label="Makeup Accesories" value="Makeup Accesories"/>
                    <ExcelColumn label="Favourite Look" value="Favourite Look"/>
                    <ExcelColumn label="Favorite Topics" value="Favorite Topics"/>
                    <ExcelColumn label="Makeup Product" value="Makeup Product"/>
                    <ExcelColumn label="Makeup Tools" value="Makeup Tools"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
