import {PageHeader} from "antd";
import React from "react";

class PageHeaderComponent extends React.PureComponent {
    render() {
        const {title} = this.props
        return <PageHeader
            className="site-page-header"
            title={title}
        />
    }
}

export default PageHeaderComponent