import React from "react";
import {Drawer, Input} from 'antd';
import PageHeaderComponent from "../../component/page-header.component";
import UserListTrackingComponent from "./user-list-tracking.component";
import {getUserDetailApi, getUserListReferralIdByEmailApi} from "../../api/user.api";
import UserDetailTrackingComponent from "./user-detail-tracking.component";
import UserDetailComponent from "../user/user-detail.component";
import PointTrackerComponent from "../user/point-tracker.component";
import {setUserData} from "../../ui-util/user-export-util";
import {getPointListByIdApi, getTotalUserPointApi} from "../../api/point.api";

const {Search} = Input;

class TrackingPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            loading: false,
            userDetail: {},
            userList: [],
            userDetailDrawerVisibility: false,
            userDetailToShow: {},
            userPointList: [],
            pageSizePointTracker: 10,
            currentPagePointTracker: 0,
            totalItemsPointTracker: 0,
            totalPoint: 0,
            excelData: {}
        }
    }

    onSearch = (value) => {
        this.setState({loading: true})
        getUserListReferralIdByEmailApi({email: value})
            .then(result => {
                this.setState({loading: false, userDetail: result.userDetail, userList: result.userList})
            })
            .catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
    }

    showUserDetailDrawer = (record) => {
        const {pageSizePointTracker, currentPagePointTracker} = this.state
        let params = {
            page: currentPagePointTracker,
            size: pageSizePointTracker
        }
        Promise.all([
            getPointListByIdApi(record.id, params),
            getTotalUserPointApi(record.id),
            getUserDetailApi(record.id)
        ])
            .then(result => {
                let selectRow = setUserData([result[2]])
                this.setState({
                    userPointList: result[0].fixData.data,
                    totalItemsPointTracker: result[0].fixData.totalItems,
                    userDetailDrawerVisibility: true,
                    userDetailToShow: result[2],
                    totalPoint: result[1].tempPoint,
                    excelData: selectRow
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    loadMorePoint = () => {
        const {pageSizePointTracker, currentPagePointTracker, userPointList, userDetail} = this.state
        let params = {
            page: currentPagePointTracker + 1,
            size: pageSizePointTracker
        }
        getPointListByIdApi(userDetail.id, params)
            .then(result => {
                let pointTracker = userPointList.concat(result.fixData.data)
                this.setState({userPointList: pointTracker, currentPagePointTracker: result.fixData.currentPage})
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const {loading, userDetail, userList, userDetailDrawerVisibility, userDetailToShow, userPointList,totalItemsPointTracker, excelData, totalPoint } = this.state
        return (
            <>
                <PageHeaderComponent title={'Tracking User'}/>
                <div style={{padding: 20}}>
                    <Search
                        placeholder="input email user"
                        enterButton="Search"
                        size="large"
                        loading={loading}
                        onSearch={this.onSearch}
                    />
                    {
                        !!userDetail.id &&
                        <>
                            <UserDetailTrackingComponent userDetail={userDetail} showUserDetail={this.showUserDetailDrawer}/>
                            <UserListTrackingComponent userList={userList} showUserDetail={this.showUserDetailDrawer}/>
                        </>
                    }
                </div>
                <Drawer
                    title={`User Detail`}
                    width={520}
                    visible={userDetailDrawerVisibility}
                    closable={true}
                    forceRender={true}
                    onClose={() => this.setState({userDetailDrawerVisibility: false})}
                >
                    <UserDetailComponent
                        userDetail={userDetailToShow} userPointList={userPointList} totalPoint={totalPoint}
                        excelData={excelData}
                    />
                    <PointTrackerComponent
                        userPointList={userPointList}
                        totalItems={totalItemsPointTracker}
                        loadMore={this.loadMorePoint}
                    />
                </Drawer>
            </>
        )
    }
}

export default TrackingPage
