import React from "react";
import {Button, Checkbox, Form, Input, Row, Select, Spin, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {DebounceSelect} from "../../component/debounce-select.component";
import {ExcelRenderer} from "react-excel-renderer";

const {TextArea} = Input;
const {Option} = Select;

class NotificationFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            fileImageList: [],
            showImage: false,
            disabledReceiverId: false,
            selectedEmail: [],
            excelFile: [],
            excelFileExtract: [],
            sendAllChecked: false,
            receiverIdList: []
        }
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    onChangeCheckbox = (value) => {
        const {selectedEmail} = this.state
        if (value && selectedEmail.length > 0) {
            this.setState({selectedEmail: [], excelFile: []})
        }
        this.setState({disabledReceiverId: value.target.checked, sendAllChecked: value.target.checked})
    }

    dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    beforeUpload = (file) => {
        const {selectedEmail} = this.state
        const isExcel =
            file.type === "application/vnd.ms-excel" ||
            file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        if (!!isExcel) {
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    console.log(err);
                } else {
                    let newRows = [];
                    resp.rows.slice(1).map((row, index) => {
                        if (row && row !== "undefined") {
                            newRows.push(row[0]);
                        }
                    });
                    if (newRows.length === 0) {
                        alert('Data tidak ditemukan di dalam file')
                    } else {
                        this.setState({
                            excelFileExtract: newRows,
                            excelFile: [file],
                            disabledReceiverId: true
                        });
                        if (selectedEmail.length > 0) {
                            this.setState({selectedEmail: []})
                        }
                    }
                }
            });
        } else {
            alert('Mohon masukkan file excel')
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {fileImageList, disabledReceiverId, selectedEmail, excelFile, excelFileExtract, sendAllChecked, receiverIdList} = this.state
        const {loading, onFinish} = this.props

        return (
            <Form ref={this.formRef} labelCol={{span: 3}}
                  wrapperCol={{span: 10}} onFinish={(value) => onFinish(value, fileImageList, excelFileExtract, receiverIdList)}
                  initialValues={{receiverId: []}}>
                <Form.Item
                    key={'1'}
                    label={'Title'}
                    name={'title'}
                    rules={[{
                        required: true,
                        message: 'Please input title',
                    }]}
                >
                    <Input placeholder={'Cth: Produk Baru'}/>
                </Form.Item>
                <Form.Item
                    key={'2'}
                    label={'Description'}
                    name={'description'}
                    rules={[{
                        required: true,
                        message: 'Please input description',
                    }]}
                >
                    <TextArea placeholder={'Masukkan deskripsi notifikasi'}/>
                </Form.Item>
                <Form.Item
                    key={'3'}
                    label={'Image'}
                    name={'image'}
                >
                    <Upload
                        fileList={fileImageList}
                        onRemove={this.removeButtonHandler}
                        beforeUpload={this.beforeUploadImage}
                    >
                        <Row
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 10
                            }}>
                            <Button icon={<UploadOutlined/>}>
                                Upload
                            </Button>
                            <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                        </Row>
                    </Upload>
                </Form.Item>
                <Form.Item
                    key={'4'}
                    label={'Specified For'}
                    name={'specifiedFor'}
                    rules={[{
                        required: true,
                        message: 'Please select specified for!',
                    }]}
                >
                    <Select
                        placeholder={'Pilih jenis notifikasi yang akan dikirim'}
                        style={{width: '100%'}}
                    >
                        <Option key={'akun'}>Account</Option>
                        <Option key={'product'}>Product</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    key={'5'}
                    label={'Send All'}
                    name={'sendAll'}
                    valuePropName="checked"
                >
                    <Checkbox disabled={selectedEmail.length > 0 || excelFileExtract.length > 0}
                              onChange={this.onChangeCheckbox}>Notifikasi akan dikirimkan ke seluruh customer</Checkbox>
                </Form.Item>
                <Form.Item
                    key={'6'}
                    label={'Receiver Id'}
                    name={'receiverId'}
                >
                    <DebounceSelect
                        disabled={sendAllChecked || disabledReceiverId}
                        value={selectedEmail}
                        onChange={(email, value) => {
                            this.setState({selectedEmail: email, receiverIdList: value})
                        }}
                    />
                </Form.Item>
                <Form.Item
                    key={'7'}
                    label={'Receiver Id Bulk'}
                    name={'receiverEmailBulk'}
                >
                    <Upload
                        name="file"
                        fileList={excelFile}
                        disabled={selectedEmail.length > 0 || sendAllChecked}
                        customRequest={this.dummyRequest}
                        beforeUpload={this.beforeUpload}
                        onRemove={() => this.setState({excelFile: [], sendAllChecked: false, disabledReceiverId: false})}>
                        <Button disabled={selectedEmail.length > 0 || sendAllChecked} icon={<UploadOutlined/>}>Select File</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default NotificationFormComponent
