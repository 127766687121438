import React from "react";
import InterestCategoryComponent from "./interest-category.component";
import PageHeaderComponent from "../../component/page-header.component";

class InterestCategoryPage extends React.PureComponent {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <PageHeaderComponent title={'Interest Category List'}/>
                <InterestCategoryComponent/>
            </>
        )
    }
}

export default InterestCategoryPage
