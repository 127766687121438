import React from "react";
import {Button, Table} from "antd";
import EditFormModal from "../../component/edit-form.modal";
import StandardAlertModal from "../../component/standard-alert.modal";
import PointFormComponent from "../create-point/point-form.component";
import {deletePointApi, getPointApi, updatePointApi} from "../../api/point.api";

class PointSettingComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Point Name', dataIndex: 'name', key: 'name'},
                {title: 'Description', dataIndex: 'description', key: 'description'},
                {title: 'Point', dataIndex: 'point', key: 'point'},
                {title: 'Code', dataIndex: 'code', key: 'code'},
                {title: 'Level', dataIndex: 'level', key: 'level_id', render: (level) => level.name},
                {
                    title: 'Action', key: 'action',
                    render: (record) =>
                        <Button htmlType="submit"
                                onClick={() => this.changeVisibility('editPointModalVisibility', true, record)}>Edit</Button>
                }
            ],
            editPointModalVisibility: false,
            deletePointModalVisibility: false,
            pointDetail: {},
            pointList: [],
            loading: false,
            loadingDelete: false
        }
    }

    componentDidMount(): void {
        this.getPointListData()
    }

    getPointListData = () => {
        getPointApi()
            .then(response => {
                this.setState({pointList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    deletePointButtonHandler = (pointDetail) => {
        this.setState({deletePointModalVisibility: true, pointDetail})
    }

    changeVisibility = (key, value, pointDetail) => {
        this.setState({[key]: value, pointDetail})
    }

    onFinishEditPoint = (values) => {
        const {id} = this.state.pointDetail
        this.setState({loading: true})
        updatePointApi(id, values)
            .then(result => {
                this.getPointListData()
                this.setState({loading: false, editPointModalVisibility: false})
                alert('Point berhasil diubah')
            })
            .catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
    }

    onOkButtonHandler = () => {
        const {id} = this.state.pointDetail
        deletePointApi(id)
            .then(result => {
                this.getPointListData()
                this.setState({loading: false, deletePointModalVisibility: false})
                alert('Point berhasil dihapus')
            })
            .catch(err => {
                console.log(err)
            })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns, editPointModalVisibility, deletePointModalVisibility, pointDetail, loading, loadingDelete, pointList} = this.state
        return (
            <>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={pointList}
                    pagination={false}
                    rowKey={"id"}
                />
                <EditFormModal
                    modalVisibility={editPointModalVisibility}
                    modalVisibilityKey={"editPointModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    title={`Edit Point`}
                    content={<PointFormComponent pointDetail={pointDetail} onFinish={this.onFinishEditPoint}
                                                 loading={loading} labelCol={6} slug={'edit'}/>}
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onOkButtonHandler}
                    skinConditionKey={"deletePointModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    modalVisibility={deletePointModalVisibility}
                    title={`Delete Point`}
                    bodyMessage={`Apakah anda yakin ingin menghapus poin ini?`}
                />
            </>
        )
    }
}

export default PointSettingComponent
