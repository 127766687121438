import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import {useHistory, useLocation} from 'react-router';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {cloudFunctionBaseUrl} from "../../../ui-util/general-variable";
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import SortFilter from '../../../component/Common/sort-filter';
import { getUserAnalyticApi } from "../../../api/goddess-gang.api";

ReactFC.fcRoot(FusionCharts, Maps, World, FusionTheme);

const token = localStorage.getItem('TOKEN_AUTH');

function AnalyticsGoddessGang({ match }) {
    const location = useLocation()
    const history = useHistory()
    const [search, setSearch]         = useState('');
    const [limit_similliar_influencer, setLimit]   = useState('5');
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('id');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        brandedContentStats: null,
        influencer: null,
        locations: null,
        mediaItems: null,
        priceRange: null,
        recommendedInfluencers: null,
        stats: null,
        category: null,
        disabled: false,
        viewAll : false
    });
    const pathRaw = window.location.pathname.split('/')
    const id_user = pathRaw[2]
    const id_socmed = pathRaw[3]
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const {
        register,
        formState: { errors },
        handleSubmit,
        control
      } = useForm({
        mode: "all"// "onChange"
    });
    const onSubmit = data => console.log(data);
    function viewAll () {
        setAppState({ viewAll: true});
        const fetchData = async () => {
            const respDetailUser= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id_user}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respUserAnalytics= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id_user}/${id_socmed}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            if (respUserAnalytics.data.data != null) {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, brandedContentStats: respUserAnalytics.data.data.detail.brandedContentStats, influencer: respUserAnalytics.data.data.detail.influencer, locations: respUserAnalytics.data.data.detail.locations, mediaItems: respUserAnalytics.data.data.detail.mediaItems, priceRange: respUserAnalytics.data.data.detail.priceRange, recommendedInfluencers: respUserAnalytics.data.data.detail.recommendedInfluencers, stats: respUserAnalytics.data.data.detail.stats, category: respUserAnalytics.data?.data?.detail?.influencer?.category, viewAll: true });
            } else {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, viewAll: true })
            }
        };
        fetchData();
    }
    const updateSorting = (value) => {
        setSort(value)
        const fetchData = async () => {
            let params = {
                influencers_brand_name_sort: value,
                influencers_brand_name_search: search
            }
            const respDetailUser= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id_user}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respUserAnalytics= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id_user}/${id_socmed}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            })
            if (respUserAnalytics.data.data != null) {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, brandedContentStats: respUserAnalytics.data.data.detail.brandedContentStats, influencer: respUserAnalytics.data.data.detail.influencer, locations: respUserAnalytics.data.data.detail.locations, mediaItems: respUserAnalytics.data.data.detail.mediaItems, priceRange: respUserAnalytics.data.data.detail.priceRange, recommendedInfluencers: respUserAnalytics.data.data.detail.recommendedInfluencers, stats: respUserAnalytics.data.data.detail.stats, category: respUserAnalytics.data?.data?.detail?.influencer?.category, viewAll: true });
            } else {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, viewAll: true })
            }
        };
        fetchData();
    }
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        const fetchData = async () => {
            let params = {
                influencers_brand_name_sort: sort,
                influencers_brand_name_search: search
            }
            const respDetailUser= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id_user}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respUserAnalytics= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id_user}/${id_socmed}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            })
            if (respUserAnalytics.data.data != null) {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, brandedContentStats: respUserAnalytics.data.data.detail.brandedContentStats, influencer: respUserAnalytics.data.data.detail.influencer, locations: respUserAnalytics.data.data.detail.locations, mediaItems: respUserAnalytics.data.data.detail.mediaItems, priceRange: respUserAnalytics.data.data.detail.priceRange, recommendedInfluencers: respUserAnalytics.data.data.detail.recommendedInfluencers, stats: respUserAnalytics.data.data.detail.stats, category: respUserAnalytics.data?.data?.detail?.influencer?.category, viewAll: true });
            } else {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, viewAll: true })
            }
        };
        fetchData();
    }
    useEffect(() => {
        setAppState({ loading: true, viewAll: appState.viewAll });
        const fetchData = async () => {
            const respDetailUser= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id_user}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respUserAnalytics= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id_user}/${id_socmed}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            if (respUserAnalytics.data.data != null) {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, brandedContentStats: respUserAnalytics.data.data.detail.brandedContentStats, influencer: respUserAnalytics.data.data.detail.influencer, locations: respUserAnalytics.data.data.detail.locations, mediaItems: respUserAnalytics.data.data.detail.mediaItems, priceRange: respUserAnalytics.data.data.detail.priceRange, recommendedInfluencers: respUserAnalytics.data.data.detail.recommendedInfluencers, stats: respUserAnalytics.data.data.detail.stats, category: respUserAnalytics.data?.data?.detail?.influencer?.category, viewAll: appState.viewAll });
            } else {
                setAppState({ loading: false, detail: respDetailUser.data.user, data: respUserAnalytics.data.data, viewAll: appState.viewAll })
            }
        };
        fetchData();
        }, []);
        console.log(appState.viewAll)
        // Configuration    
        const COUNT_FORMATS =
        [
            { // 0 - 999
                letter: '',
                limit: 1e3
            },
            { // 1,000 - 999,999
                letter: 'K',
                limit: 1e6
            },
            { // 1,000,000 - 999,999,999
                letter: 'M',
                limit: 1e9
            },
            { // 1,000,000,000 - 999,999,999,999
                letter: 'B',
                limit: 1e12
            },
            { // 1,000,000,000,000 - 999,999,999,999,999
                letter: 'T',
                limit: 1e18
            }
        ];
            
        // Format Method:
        function formatFollower(value)
        {
            const format = COUNT_FORMATS.find(format => (value < format.limit));

            value = (1000 * value / format.limit);
            value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

            return (value + format.letter);
        }

        function getArraySum(a){
            var total=0;
            for(var i in a) { 
                total += a[i];
            }
            return total;
        }
        
        const largestAgeGroup = (appState?.stats?.age?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? appState?.stats?.audienceStatistics?.age?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? appState?.data?.detail?.audienceStatistics?.ageDistribution?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? appState?.data?.detail?.channel?.channelStatistics?.age?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? 0)

        const largestAudienceGroup = (appState?.stats?.audienceBrandAffinity?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? 0)

        const largestAuditionLocation = (appState?.stats?.audienceLocation?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? appState?.stats?.audienceStatistics?.locations?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        )  ?? appState?.data?.detail?.audienceLocation?.reduce(
        (max, item) => item.count > max ? item.count : max, 0
        ) ?? 0)

        const largestAudienceInterestGroup = (appState?.stats?.audienceInterests?.buckets?.reduce(
            (max, item) => item.count > max ? item.count : max, 0
        ) ?? 0)

        const genderFemale = appState?.stats?.gender?.buckets[1]?.count ?? appState?.stats?.audienceStatistics?.gender?.buckets[1]?.count ?? appState?.data?.detail?.audienceStatistics?.genderDistribution[1]?.count ?? appState?.data?.detail?.channel?.channelStatistics?.gender?.buckets[1]?.count ?? 0;
        const genderMale = appState?.stats?.gender?.buckets[0]?.count ?? appState?.stats?.audienceStatistics?.gender?.buckets[0]?.count ?? appState?.data?.detail?.audienceStatistics?.genderDistribution[0]?.count ?? appState?.data?.detail?.channel?.channelStatistics?.gender?.buckets[0]?.count ?? 0;
        const totalGender = genderFemale + genderMale;

        const branded = appState?.data?.detail?.brandedContentStats?.branded ?? 0;
        const nonBranded = appState?.data?.detail?.brandedContentStats?.nonBranded ?? 0;
        const totalBrand = branded + nonBranded;
        
        const engagementLike = appState?.stats?.engagement?.avgLikesRatio ?? appState?.data?.detail?.influencer?.engagement?.avgReactionsRatio ?? appState?.data?.detail?.engagement?.avgLikesRatio ?? appState?.data?.detail?.channel?.engagement?.avgLikesRatio ?? 0;
        const engagementComment = appState?.stats?.engagement?.avgCommentsRatio ?? appState?.data?.detail?.influencer?.engagement?.avgCommentsRatio ?? appState?.data?.detail?.engagement?.avgCommentsRatio ?? appState?.data?.detail?.channel?.engagement?.avgCommentsRatio ?? 0;
        const engagementDiggs = appState?.data?.detail?.engagement?.avgDiggsRatio ?? 0;
        const engagementShare = appState?.data?.detail?.engagement?.avgSharesRatio ?? appState?.data?.detail?.influencer?.engagement?.avgSharesRatio ?? 0;
        const engagementPlay = appState?.data?.detail?.engagement?.avgPlaysRatio ?? 0;
        const engagementDislike = appState?.data?.detail?.channel?.engagement?.avgDislikesRatio ?? 0;
        const engagementView = appState?.data?.detail?.channel?.engagement?.avgViewsRatio ?? 0;
        const estReach = appState?.data?.detail?.influencer?.estimatedReach ?? appState?.data?.detail?.channel?.estimatedReach ?? 0;
        const estImpres = appState?.data?.detail?.influencer?.estimatedImpressions ?? appState?.data?.detail?.channel?.estimatedImpressions ?? 0; 
        const estMedia = appState?.data?.detail?.influencer?.estimatedMediaValue ?? appState?.data?.detail?.channel?.estimatedMediaValue ?? 0;
        const totalLike = appState?.data?.detail?.totalLike ?? getArraySum(appState?.data?.detail?.engagementTimeline?.map(item=> item.reactionCount) ?? appState?.data?.detail?.engagementTimelineData?.map(item=> item.likeCount)) ?? 0;
        const totalComment = appState?.data?.detail?.totalComment ?? getArraySum(appState?.data?.detail?.engagementTimeline?.map(item=> item.commentCount) ?? appState?.data?.detail?.engagementTimelineData?.map(item=> item.commentCount)) ?? 0;
        const totalShare =  getArraySum(appState?.data?.detail?.engagementTimelineData?.map(item=> item.shareCount)) ?? 0;
        const totalView = getArraySum(appState?.data?.detail?.videos?.map(item=> item?.statistics?.viewCount)) ?? 0;
        const dataSource = {
            chart: {
                "theme": "fusion",
                "markerBgColor": "#B68884",
                "entityFillColor": "#E2E2E2",
                "entityFillHoverColor": "#E5E5E9",
                "showLabels": "1",
                "numbersuffix": "M",
                "includevalueinlabels": "1"
            },
            data: appState?.stats?.audienceLocation?.buckets ?? null
        };
        
        const chartConfigs = {
            type: 'world',
            width: 350,
            height: 250,
            dataFormat: 'json',
            dataSource: dataSource
        };
        const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
        ];
        let genderColor = []
        if (genderMale < genderFemale){
            genderColor = ['#893930','#E2E2E2']
        } else if (genderMale > genderFemale) {
            genderColor = ['#E2E2E2','#893930']
        }
        let genderLabelColor = []
        if (genderMale < genderFemale){
            genderLabelColor = ['#E2E2E2','#893930']
        } else if (genderMale > genderFemale) {
            genderLabelColor = ['#893930','#E2E2E2']
        }
        let brandColor = []
        if (branded > nonBranded){
            brandColor = ['#893930','#E2E2E2']
        } else if (branded < nonBranded) {
            brandColor = ['#E2E2E2','#893930']
        }
        let brandLabelColor = []
        if (branded > nonBranded){
            brandLabelColor = ['#E2E2E2','#893930']
        } else if (branded < nonBranded) {
            brandLabelColor = ['#893930','#E2E2E2']
        }
    return ( 
        <div id="main-content">
            {(() => {
            if (appState.loading== false && appState.data != null){
                let labelChartFollowers = appState?.stats?.followersTimeseries?.map(item => new Date(item.date).getDate()+ " " + monthNames[new Date(item.date).getMonth()]) ?? appState?.data?.detail?.followerTimelineData?.map(item => new Date(item.date).getDate()+ " " + monthNames[new Date(item.date).getMonth()]) ?? appState?.stats?.followerTimeline?.map(item => new Date(item.date).getDate()+ " " + monthNames[new Date(item.date).getMonth()]) ?? appState?.data?.detail?.subscriberTimelineData?.map(item => new Date(item.date).getDate()+ " " + monthNames[new Date(item.date).getMonth()])
            return <div className="main-dash">
                <div className="toolbar">
                    <div className="toolbar-left inner">
                    </div>
                    <div className="toolbar-right inner">
                        <div className="toolbar-inner-right">
                            <div className="toolicon-wrapper status export mr-0">
                                <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="toolicon status export"
                                table="table-to-xls"
                                filename={id_socmed==1?"INSTAGRAM REPORT FOR " + appState.data.username:id_socmed==2?"FACEBOOK REPORT FOR " + appState.data.username:id_socmed==4?"TIKTOK REPORT FOR " + appState.data.username:"YOUTUBE REPORT FOR " + appState.data.username}
                                sheet={id_socmed==1?"Instagram Report Per User":id_socmed==2?"Facebbok Report Per User":id_socmed==4?"Tiktok Report Per User":"Youtube Report Per User"}
                                buttonText="Export"/>
                            </div>
                            {(() => {
                                if (id_socmed == 1){
                                return <table id="table-to-xls" className="hide-table">
                                        <tr className="txt-capital">INSTAGRAM REPORT FOR {appState.data.username}</tr>
                                        <table><thead></thead></table>
                                        <table>
                                            <tr>INFLUENCER BASIC INFORMATION</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.fullName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Username</td>
                                                    <td>{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Follower Count</td>
                                                    <td>{formatFollower(appState?.data?.follower)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Suspicious Followers count</td>
                                                    <td>{formatFollower(appState?.data?.detail?.influencer?.profile?.pk)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Instagram URL</td>
                                                    <td>https://www.instagram.com/{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{id_socmed== 1 ? appState.locations : id_socmed== 2 ? appState.data.detail.influencer.locations : id_socmed == 4 ? appState.data.detail.influencer.countries : appState.data.detail.channel.countries}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicEmail}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ph No</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicPhoneNumber}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>INTERESTS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.interests && appState?.stats?.interests.length> 0 ? appState?.stats?.interests.map((item,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>SIMILLAR INFLUENCER</tr>
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState.recommendedInfluencers && appState.recommendedInfluencers.length> 0 ? appState.recommendedInfluencers.map((repo,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.username}</td>
                                                        <td>{repo.fullName ?? repo.name}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE AGE STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Age Range</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.age?.buckets?.length>0 ? appState?.stats?.age?.buckets?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{Math.round(repo.count*100)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE GENDER STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Gender</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Laki-laki</td>
                                                    <td>{Math.round(genderMale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                                <tr>
                                                    <td>Perempuan</td>
                                                    <td>{Math.round(genderFemale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE LOCATION STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Country</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.audienceLocation?.buckets?.length>0 ? appState?.stats?.audienceLocation?.buckets?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{Math.round(repo.count*100)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>INFLUENCER BRAND AFFINITY</tr>
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Name</th>
                                                    <th>Posts</th>
                                                    <th>Sponsored Posts</th>
                                                    <th>Last On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.influencerBrandAffinity?.length>0 ? appState?.stats?.influencerBrandAffinity?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.username}</td>
                                                        <td>{repo.fullName}</td>
                                                        <td>{repo.mediaItems.length}</td>
                                                        <td>{repo.mediaItems[0].sponsored}</td>
                                                        <td>{repo.mediaItems[0].takenAt}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>INFLUENCER BRANDED CONTENT PERCENTAGE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Past Number Of Days</th>
                                                    <th>Total Posts</th>
                                                    <th>Branded</th>
                                                    <th>Non Branded</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{appState?.brandedContentStats?.days}</td>
                                                    <td>{appState?.brandedContentStats?.total}</td>
                                                    <td>{appState?.brandedContentStats?.branded}</td>
                                                    <td>{appState?.brandedContentStats?.nonBranded}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Percentage</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Average Comment Ratio</td>
                                                    <td>{(engagementComment*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Like Ratio</td>
                                                    <td>{(engagementLike*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Total Comment</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalComment)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Like</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalLike)))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE INTERESTS DISTRIBUTION</tr>
                                            <thead>
                                                <tr>
                                                    <th>Interest</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.audienceInterests?.buckets?.length>0 ? appState?.stats?.audienceInterests?.buckets?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{repo.count}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>FOLLOWERS TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Followes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.followersTimeseries?.length>0 ? appState?.stats?.followersTimeseries?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.date}</td>
                                                        <td>{repo.followers}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Like</th>
                                                    <th>Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.engagementTimeseries?.length>0 ? appState?.stats?.engagementTimeseries?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.takenAt}</td>
                                                        <td>{repo.likeCount}</td>
                                                        <td>{repo.commentCount}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                    </table>
                                }
                            })()}
                            {(() => {
                                if (id_socmed == 2){
                                return <table id="table-to-xls" className="hide-table">
                                        <tr className="txt-capital">FACEBOOK REPORT FOR {appState.data.username}</tr>
                                        <table><thead></thead></table>
                                        <table>
                                            <tr>INFLUENCER BASIC INFORMATION</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>{appState?.data?.detail?.influencer?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Username</td>
                                                    <td>{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Follower Count</td>
                                                    <td>{formatFollower(appState?.data?.follower)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Like Count</td>
                                                    <td>{formatFollower(appState?.data?.detail?.influencer?.likes)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Facebook URL</td>
                                                    <td>https://www.facebook.com/{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{id_socmed== 1 ? appState.locations : id_socmed== 2 ? appState.data.detail.influencer.locations : id_socmed == 4 ? appState.data.detail.influencer.countries : appState.data.detail.channel.countries}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicEmail ?? appState?.detail?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ph No</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicPhoneNumber ?? appState?.detail?.phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>INTERESTS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.category && appState?.category.length > 0 ? appState?.category.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE AGE STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Age Range</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.audienceStatistics?.age?.buckets?.length>0 ? appState?.stats?.audienceStatistics?.age?.buckets?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{Math.round(repo.count)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE GENDER STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Gender</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Laki-laki</td>
                                                    <td>{Math.ceil(genderMale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                                <tr>
                                                    <td>Perempuan</td>
                                                    <td>{Math.ceil(genderFemale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE LOCATION STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Country</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.audienceStatistics?.locations?.buckets?.length>0 ? appState?.stats?.audienceStatistics?.locations?.buckets?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{Math.round(repo.count*100)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Percentage</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Average Comment Ratio</td>
                                                    <td>{(engagementComment*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Like Ratio</td>
                                                    <td>{(engagementLike*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Shares Ratio</td>
                                                    <td>{(engagementShare*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Total Comment</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalComment)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Like</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalLike)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Reach</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estReach)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Media Value</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estMedia)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Impression</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estImpres)))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>FOLLOWERS TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Followes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.stats?.followerTimeline?.length>0 ? appState?.stats?.followerTimeline?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.date}</td>
                                                        <td>{repo.followers}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Reaction</th>
                                                    <th>Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.engagementTimeline?.length>0 ? appState?.data?.detail?.engagementTimeline?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.takenAt}</td>
                                                        <td>{repo.reactionCount}</td>
                                                        <td>{repo.commentCount}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                    </table>
                                }
                            })()}
                            {(() => {
                                if (id_socmed == 4){
                                return <table id="table-to-xls" className="hide-table">
                                        <tr className="txt-capital">TIKTOK REPORT FOR {appState.data.username}</tr>
                                        <table><thead></thead></table>
                                        <table>
                                            <tr>INFLUENCER BASIC INFORMATION</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>{appState?.data?.detail?.influencer?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Username</td>
                                                    <td>{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Follower Count</td>
                                                    <td>{formatFollower(appState?.data?.detail?.influencer?.profileStatistics?.followerCount)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tiktok URL</td>
                                                    <td>https://www.tiktok.com/{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{id_socmed== 1 ? appState.locations : id_socmed== 2 ? appState.data.detail.influencer.locations : id_socmed == 4 ? appState.data.detail.influencer.countries : appState.data.detail.channel.countries}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicEmail ?? appState?.detail?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ph No</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicPhoneNumber ?? appState?.detail?.phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>SIMILLAR INFLUENCER</tr>
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState.recommendedInfluencers && appState.recommendedInfluencers.length> 0 ? appState.recommendedInfluencers.map((repo,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.username}</td>
                                                        <td>{repo.fullName ?? repo.name}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE AGE STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Age Range</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.audienceStatistics?.ageDistribution?.length>0 ? appState?.data?.detail?.audienceStatistics?.ageDistribution?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{Math.round(repo.count*100)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE GENDER STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Gender</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Laki-laki</td>
                                                    <td>{Math.ceil(genderMale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                                <tr>
                                                    <td>Perempuan</td>
                                                    <td>{Math.ceil(genderFemale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE LOCATION STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Country</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.audienceLocation?.length>0 ? appState?.data?.detail?.audienceLocation?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.code}</td>
                                                        <td>{Math.round(repo.count*100)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Percentage</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Average Comment Ratio</td>
                                                    <td>{(engagementComment*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Shares Ratio</td>
                                                    <td>{(engagementLike*100).toFixed(2)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Total Comment</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalComment)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Like</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalLike)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Share</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalShare)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Reach</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estReach)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Media Value</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estMedia)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Impression</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estImpres)))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>FOLLOWERS TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Followes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.followerTimelineData?.length>0 ? appState?.data?.detail?.followerTimelineData?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.date}</td>
                                                        <td>{repo.followers}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Like</th>
                                                    <th>Comment</th>
                                                    <th>Share</th>
                                                    <th>Play</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.engagementTimelineData?.length>0 ? appState?.data?.detail?.engagementTimelineData?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.takenAt}</td>
                                                        <td>{repo.likeCount}</td>
                                                        <td>{repo.commentCount}</td>
                                                        <td>{repo.shareCount}</td>
                                                        <td>{repo.playCount}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                    </table>
                                }
                            })()}
                            {(() => {
                                if (id_socmed == 5){
                                return <table id="table-to-xls" className="hide-table">
                                        <tr className="txt-capital">YOUTUBE REPORT FOR {appState.data.username}</tr>
                                        <table><thead></thead></table>
                                        <table>
                                            <tr>INFLUENCER BASIC INFORMATION</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.fullName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Username</td>
                                                    <td>{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Follower Count</td>
                                                    <td>{formatFollower(appState?.data?.follower)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Instagram URL</td>
                                                    <td>https://www.youtube.com/{appState?.data?.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{id_socmed== 1 ? appState.locations : id_socmed== 2 ? appState.data.detail.influencer.locations : id_socmed == 4 ? appState.data.detail.influencer.countries : appState.data.detail.channel.countries}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicEmail ?? appState?.detail?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ph No</td>
                                                    <td>{appState?.data?.detail?.influencer?.profile?.contact?.publicPhoneNumber ?? appState?.detail?.phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>INTERESTS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.interests && appState?.data?.detail?.interests.length> 0 ? appState?.data?.detail?.interests.map((item,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE AGE STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Age Range</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.channel?.channelStatistics?.age?.buckets?.length>0 ? appState?.data?.detail?.channel?.channelStatistics?.age?.buckets?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.label}</td>
                                                        <td>{Math.round(repo.count)}%</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>AUDIENCE GENDER STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Gender</th>
                                                    <th>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Laki-laki</td>
                                                    <td>{Math.ceil(genderMale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                                <tr>
                                                    <td>Perempuan</td>
                                                    <td>{Math.ceil(genderFemale/totalGender*100).toFixed(0)}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT STATS</tr>
                                            <thead>
                                                <tr>
                                                    <th>Influencer Information</th>
                                                    <th>Percentage</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Average Comment Ratio</td>
                                                    <td>{(engagementComment).toFixed(0)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Like Ratio</td>
                                                    <td>{(engagementLike).toFixed(0)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Dislike Ratio</td>
                                                    <td>{(engagementDislike).toFixed(0)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Average Views Ratio</td>
                                                    <td>{(engagementView).toFixed(0)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Total Comment</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalComment)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Like</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalLike)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total View</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(totalView)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Reach</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estReach)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Media Value</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estMedia)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Est. Impression</td>
                                                    <td></td>
                                                    <td>{(formatFollower(Math.round(estImpres)))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>SUBSCRIBERS TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Subscribers</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.subscriberTimelineData?.length>0 ? appState?.data?.detail?.subscriberTimelineData?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.date}</td>
                                                        <td>{repo.subscribers}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                        <table><thead></thead><thead></thead></table>
                                        <table>
                                            <tr>ENGAGEMENT TIMELINE</tr>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Like</th>
                                                    <th>Comment</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {appState?.data?.detail?.engagementTimelineData?.length>0 ? appState?.data?.detail?.engagementTimelineData?.map((repo,index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td>{repo.takenAt}</td>
                                                        <td>{repo.likeCount}</td>
                                                        <td>{repo.commentCount}</td>
                                                        <td>{repo.viewCount}</td>
                                                    </tr>
                                                );
                                                }): <td>-</td>
                                            }
                                            </tbody>
                                        </table>
                                    </table>
                                }
                            })()}
                        </div>
                    </div>
                </div>{/* end of toolbar*/}
                <div className="accordions-01">
                    <div className="accordion-01">
                        <input type="checkbox" id="chck1" defaultChecked />
                        <label className="analytic-accordion" htmlFor="chck1">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="font-medium red">{id_socmed==1 ? "Instagram Profile" : id_socmed==2 ? "Facebook Profile": id_socmed==4 ? "Tiktok Profile" : id_socmed==5 ? "Youtube Profile" : ""}</h6>
                                </div>
                                <div>
                                    <div className="font-medium">Hide <img src="/img/arrow-up.svg" className="gg-icon-arrow black" /></div>
                                </div>
                            </div>
                        </label>
                        <div className="tab-content big">
                            <div className="row-of-info">
                                <div className="row">
                                    <div className="col-5">
                                        <div className="box-white">
                                            <div className="row align-items-center">
                                            <div className="col-4">
                                                <div className="for-img hide">
                                                    <img src={id_socmed==4? appState?.detail?.photo : appState?.data?.detail?.influencer?.profile?.picture ?? appState?.data?.detail?.channel?.profilePictureUrl ?? appState?.data?.detail?.influencer?.profilePictureUrl} className="img-circle img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <h3 className="font-medium mb-3">{appState.data.username}</h3>
                                                <div className="row mb-3">
                                                <div className="col-6">
                                                    <h6 className="font-medium">Followers</h6>
                                                    <div className="ft-count">{formatFollower(appState.data.follower)}</div>
                                                </div>
                                                <div className="col-6">
                                                    <h6 className="font-medium">Location</h6>
                                                    <div className="ft-count">{id_socmed== 1 ? appState.locations : id_socmed== 2 ? appState.data.detail.influencer.locations : id_socmed == 4 ? appState.data.detail.influencer.countries : appState.data.detail.channel.countries}</div>
                                                </div>
                                                </div>
                                                <h6 className="font-medium">Email</h6>
                                                <div className="ft-count">{appState?.data?.detail?.influencer?.profile?.contact?.publicEmail ?? appState.detail.email}</div>
                                            </div>
                                            </div>
                                            <hr />
                                            <p>{appState?.data?.biography || appState?.data?.detail?.channel?.description}</p>
                                            <hr />
                                            {(() => {
                                            if (id_socmed==2) {
                                                return <div className="row">
                                                     <div className="col-4">
                                                        {appState?.category && appState?.category.length > 0 ? appState?.category.map((item, index) => {
                                                            return (
                                                                <div className="tag-box" key={index}>{item.name}</div>
                                                                );
                                                            }): <span>No data category</span>
                                                        }
                                                        </div>
                                                    </div>
                                            } else if (id_socmed==1) {
                                                return <div className="row">
                                                    {appState?.stats?.interests && appState?.stats?.interests.length> 0 ? appState?.stats?.interests.map((item,index) => {
                                                        return (
                                                            <div className="col-4" key={index}>
                                                                <div className="tag-box">{item}</div>
                                                            </div>
                                                            );
                                                        }): <div className="hidden"></div>
                                                    }
                                                </div>
                                            } else if (id_socmed==5) {
                                                return <div className="row">
                                                    {appState?.data?.detail?.interests && appState?.data?.detail?.interests.length> 0 ? appState?.data?.detail?.interests.map((item,index) => {
                                                        return (
                                                            <div className="col-4" key={index}>
                                                                <div className="tag-box">{item}</div>
                                                            </div>
                                                            );
                                                        }): <div className="hidden"></div>
                                                    }
                                                </div>
                                            }
                                            })()}
                                            {appState?.stats?.interests || appState?.data?.detail?.influencer?.category || appState?.data?.detail?.interests ? <hr /> : "" }
                                            <div className="row">
                                                {appState.detail.social_medias.map((item, index) => {
                                                    return (
                                                        <div key={index} className={item.name=="TikTok" || item.name=="Facebook"  || item.name=="Youtube" || item.name=="Instagram" ?"col-3":"hidden"}>
                                                        {(() => {
                                                            if (item.user_social_medias.value != "" && item.name=="Instagram") {
                                                                return <a href={item.user_social_medias.value} target="_blank" className="text-center btn-block" key={index}><img src="/img/instagram-solid.svg" className="img-fluid" /></a>
                                                            } else if (item.user_social_medias.value != "" && item.name=="Facebook") {
                                                                return <a href={item.user_social_medias.value} target="_blank" className="text-center btn-block" key={index}><img src="/img/fb-solid.svg" className="img-fluid" /></a>
                                                            } else if (item.user_social_medias.value != "" && item.name=="Youtube") {
                                                                return <a href={item.user_social_medias.value} target="_blank" className="text-center btn-block" key={index}><img src="/img/youtube-solid.svg" className="img-fluid" /></a>
                                                            } else if (item.user_social_medias.value != "" && item.name=="TikTok") {
                                                                return <a href={item.user_social_medias.value} target="_blank" className="text-center btn-block" key={index}><img src="/img/tiktok-solid.svg" className="img-fluid" /></a>
                                                            } else {
                                                                return <span></span>
                                                            }
                                                        })()}
                                                    </div>
                                                    );
                                                })}
                                            </div>
                                        </div>{/* end Personal info*/}
                                        <div className={appState?.recommendedInfluencers && appState?.recommendedInfluencers?.length> 0?"box-white":"hidden"}>
                                            <div className="bw-head">
                                                <h6 className="font-medium">Simillar Influencer</h6>
                                                <div className="help">
                                                    <span className="tool-tip">
                                                    <button className="btn btn-help">?</button>
                                                    <div className="tool-tip-content big">
                                                        Simillar influencer engaged followers
                                                    </div>
                                                    </span>
                                                </div>
                                            </div>
                                            {(() => {
                                            if (appState.viewAll==false) {
                                                return <div className="bx-influencer">
                                                {appState.recommendedInfluencers && appState.recommendedInfluencers.length> 0 ? (appState.recommendedInfluencers.slice(0, 5).map((item,index) => {
                                                    return (
                                                        <div className="bx-items" key={index}>
                                                            <div className="bi-link">
                                                                <img src={item.picture} className="img-fluid img-circle" />
                                                                {item.fullName ?? item.name}
                                                            </div>
                                                        </div>
                                                    );
                                                    })): (<div className="post-box"></div>
                                                    )}
                                                </div>
                                            } else if (appState.viewAll==true) {
                                                return <div className="bx-influencer">
                                                {appState.recommendedInfluencers && appState.recommendedInfluencers.length> 0 ? appState.recommendedInfluencers.map((item,index) => {
                                                    return (
                                                        <div className="bx-items" key={index}>
                                                            <div className="bi-link">
                                                                <img src={item.picture} className="img-fluid img-circle" />
                                                                {item.fullName ?? item.name}
                                                            </div>
                                                        </div>
                                                    );
                                                    }): <div className="post-box"></div>
                                                }
                                                </div>
                                            }
                                            })()}
                                            <a onClick={viewAll} className={appState?.recommendedInfluencers?.length>5 && appState?.viewAll==false?"gg-link dark text-right":"hidden"}>View All <img src="/img/arrow-right.svg" className="gg-icon-arrow black" /></a>
                                        </div>
                                    </div>
                                    <div className={id_socmed==2?"hidden":"col-7"}>
                                        <div className="box-white for-media upload-media">
                                            <div className="bw-head">
                                                <h4 className="font-medium ml-3">Recent Uploaded Media</h4>
                                                <div className="help">
                                                    <span className="tool-tip">
                                                        <button className="btn btn-help big">?</button>
                                                        <div className="tool-tip-content big bottom">
                                                            Recent uploaded media of influencer's engaged followers
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="bfi">
                                            {(() => {
                                                if (id_socmed==1) {
                                                return <div className="row-post">   
                                                    {appState.mediaItems && appState.mediaItems.length> 0 ? appState.mediaItems.map((mediaItems, index) => {
                                                        return (
                                                            <div className="col-4 mb-2" key={index}>
                                                                <div className="link-to-post"><iframe src={mediaItems.media.urlThumbnail.replace(`media/?size=t`, 'embed')} width="224" height="500" frameBorder="0" /></div>
                                                            </div>
                                                        );
                                                        }):<div className="col-4 mb-2">No data Image</div>
                                                    }
                                                    </div>
                                                } else if (id_socmed==4) {
                                                return <div className="row-post">   
                                                    {appState?.data?.detail?.videos && appState?.data?.detail?.videos.length> 0 ? appState?.data?.detail?.videos.map((mediaItems, index) => {
                                                        return (
                                                            <div className="col-4 mb-2" key={index}>
                                                                <div className="link-to-post"><img src={mediaItems.video.cover.replace(`media/?size=t`, 'embed')} className="img-fluid" frameBorder="0" /></div>
                                                            </div>
                                                        );
                                                        }):<div className="col-4 mb-2">No data Image</div>
                                                    }
                                                    </div>
                                                } else if (id_socmed==5) {
                                                    return <div className="row-post">   
                                                        {appState?.data?.detail?.videos && appState?.data?.detail?.videos.length> 0 ? appState?.data?.detail?.videos.map((mediaItems, index) => {
                                                            return (
                                                                <div className="col-4 mb-2" key={index}>
                                                                    <div className="link-to-post"><img src={mediaItems.thumbnailUrl.replace(`media/?size=t`, 'embed')} className="img-fluid" frameBorder="0" /></div>
                                                                </div>
                                                            );
                                                            }):<div className="col-4 mb-2">No data Image</div>
                                                        }
                                                    </div>
                                                }
                                            })()}
                                            </div>
                                        </div>{/* end uploaded Media*/}
                                    </div>
                                </div>
                            </div>{/* end of info*/}
                        </div>
                    </div>
                </div>
                <div className="accordions-01">
                    <div className="accordion-01">
                    <input type="checkbox" id="chck2" defaultChecked />
                    <label className="analytic-accordion" htmlFor="chck2">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h6 className="font-medium red">Distribution</h6>
                            </div>
                            <div>
                                <div className="font-medium">Hide <img src="/img/arrow-up.svg" className="gg-icon-arrow black" /></div>
                            </div>
                        </div>
                    </label>
                    <div className="tab-content big">
                        <div className="row-of-info">
                        <div className="row">
                            <div className="col-4">
                                <div className="box-white for-chart">
                                    <div className="bw-head">
                                        <h6 className="font-medium">Age Distribution</h6>
                                        <div className="help">
                                            <span className="tool-tip">
                                                <button className="btn btn-help">?</button>
                                                <div className="tool-tip-content big">
                                                    Age distribution of influencer's engaged followers
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <Chart
                                        type="bar"
                                        options={{
                                            chart: {
                                                toolbar: {
                                                    export: {
                                                        csv: {
                                                            filename: 'Age Distribution - '+appState.data.username,
                                                        },
                                                        svg: {
                                                            filename: 'Age Distribution - '+appState.data.username,
                                                        },
                                                        png: {
                                                            filename: 'Age Distribution - '+appState.data.username,
                                                        }
                                                    }
                                                }
                                            },
                                            labels: appState?.stats?.age?.buckets.map(item => item.label.split(' ')) ?? appState?.stats?.audienceStatistics?.age?.buckets.map(item => item.label.split(' ')) ?? appState?.data?.detail?.audienceStatistics?.ageDistribution.map(item => item.label.split(' ')) ?? appState?.data?.detail?.channel?.channelStatistics?.age?.buckets.map(item => item.label.split(' ')) ?? [],
                                            yaxis: {
                                                min: 0,
                                                max: id_socmed==1 || id_socmed==4 ? Math.ceil(Math.round(largestAgeGroup*100) / 1) * 1 : Math.ceil(largestAgeGroup / 1) * 1,
                                                tickAmount: 5,
                                                labels: {
                                                    formatter: function (value) {
                                                        return (Math.ceil(value)).toFixed(0) + "%";
                                                    },
                                                    offsetX: -12,
                                                    minWidth: 42,
                                                    style: {
                                                        colors: '#626262',
                                                        fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                        fontSize: 12,
                                                    }
                                                }
                                            },
                                            xaxis: {
                                                labels: {
                                                    style: {
                                                        colors: '#626262',
                                                        fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                        fontSize: 12,
                                                    }
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    columnWidth: '40%',
                                                    distributed: true
                                                }
                                            },
                                            stroke: {
                                                width: 1,
                                                colors: appState?.stats?.age?.buckets.map(item => Math.round(item.count*100) === Math.round(largestAgeGroup*100) ? '#893930' : '#E2E2E2') 
                                                ?? appState?.stats?.audienceStatistics?.age?.buckets.map(item => item.count === largestAgeGroup ? '#893930' : '#E2E2E2') 
                                                ?? appState?.data?.detail?.audienceStatistics?.ageDistribution.map(item => Math.round(item.count*100) === Math.round(largestAgeGroup*100) ? '#893930' : '#E2E2E2') 
                                                ?? appState?.data?.detail?.channel?.channelStatistics?.age?.buckets.map(item => item === largestAgeGroup ? '#893930' : '#E2E2E2'),
                                            },
                                            tooltip: {
                                                x: {show: false}
                                            },
                                            colors: appState?.stats?.age?.buckets.map(item => Math.round(item.count*100) === Math.round(largestAgeGroup*100) ? '#893930' : '#E2E2E2') 
                                            ?? appState?.stats?.audienceStatistics?.age?.buckets.map(item => item.count === largestAgeGroup ? '#893930' : '#E2E2E2')
                                            ?? appState?.data?.detail?.audienceStatistics?.ageDistribution.map(item => Math.round(item.count*100) === Math.round(largestAgeGroup*100) ? '#893930' : '#E2E2E2') 
                                            ?? appState?.data?.detail?.channel?.channelStatistics?.age?.buckets.map(item => Math.round(item.count) === largestAgeGroup ? '#893930' : '#E2E2E2'),
                                            dataLabels: {
                                                enabled: false
                                            },
                                            legend: {
                                                show: false,
                                            },
                                        }}
                                        series={[{ name: 'Jumlah', data: appState?.stats?.age?.buckets.map(item => Math.round(item.count*100))
                                        ?? appState?.stats?.audienceStatistics?.age?.buckets.map(item => item.count) 
                                        ?? appState?.data?.detail?.audienceStatistics?.ageDistribution.map(item => Math.round(item.count*100)) 
                                        ?? appState?.data?.detail?.channel?.channelStatistics?.age?.buckets.map(item => item.count) ?? [] }]}
                                    />
                                </div>
                            </div>{/* end Age Distribution*/}
                            <div className="col-4">
                                <div className="box-white for-chart">
                                    <div className="bw-head">
                                        <h6 className="font-medium">Gender Distribution</h6>
                                        <div className="help">
                                            <span className="tool-tip">
                                            <button className="btn btn-help">?</button>
                                            <div className="tool-tip-content big">
                                                Gender Distribution of influencer's engaged followers
                                            </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="chart-center-1">
                                        <Chart
                                            options={{
                                                chart: {
                                                    type: 'pie',
                                                },
                                                colors: genderColor,
                                                legend: {
                                                    show: false,
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        dataLabels: {
                                                            offset: -30,
                                                        },
                                                    }
                                                },
                                                stroke: {
                                                    width: 0,
                                                },
                                                dataLabels: {
                                                    formatter: (val) => `${Math.round(val)}%`,
                                                    style: {
                                                        fontFamily: "'Nunito Sans', sans-serif",
                                                        fontSize: '14px',
                                                        colors: genderLabelColor,
                                                    },
                                                    dropShadow: {
                                                        enabled: false,
                                                    }
                                                },
                                                tooltip: {
                                                    enabled: false,
                                                },
                                                labels: ['Perempuan', 'Laki-laki']
                                            }}
                                            type="pie"
                                            height={250}
                                            width={220}
                                            series={[
                                                genderFemale,
                                                genderMale
                                            ]}
                                        />
                                    </div>
                                    <div className="legend-wrapper">
                                    {(() => {
                                        if (genderFemale > genderMale) {
                                            return <div className="legend red"><span className="legend-data">Perempuan ({genderFemale? Math.round(genderFemale/totalGender*100)+'%' : '...'} User)</span></div>
                                        } else if (genderFemale < genderMale) {
                                            return <div className="legend gray"><span className="legend-data">Perempuan ({genderFemale? Math.round(genderFemale/totalGender*100)+'%' : '...'} User)</span></div>
                                        }
                                    })()}
                                    {(() => {
                                        if (genderMale < genderFemale) {
                                            return <div className="legend gray"><span className="legend-data">Laki-laki ({genderMale?  Math.round(genderMale/totalGender*100)+'%'  : '...'} User)</span></div>
                                        } else if (genderMale > genderFemale) {
                                            return <div className="legend red"><span className="legend-data">Laki-laki ({genderMale?  Math.round(genderMale/totalGender*100)+'%'  : '...'} User)</span></div>
                                        }
                                    })()}
                                    </div>
                                </div>
                            </div>{/* end Gender*/}
                            <div className="col-4">
                                <div className="box-white for-chart bg-fc">
                                    <div className="bw-head">
                                        <h6 className="font-medium">Engagement</h6>
                                        <div className="help">
                                            <span className="tool-tip">
                                                <button className="btn btn-help">?</button>
                                                <div className="tool-tip-content big">
                                                    Metric about influencer's reach on {id_socmed==1?"instagram":id_socmed==2?"facebook":id_socmed==4?"tiktok":"youtube"}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={id_socmed==2 || id_socmed==4 || id_socmed==5 ? "row engagment-box":"engagment-box"}>
                                        <div className={engagementLike != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                            <h5>{id_socmed==5?(engagementLike).toFixed(0) :(engagementLike*100).toFixed(2)+"%"}</h5>
                                            <p>Avg Like Ratio</p>
                                        </div>
                                        <div className={engagementDislike != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                            <h5>{id_socmed==5?(engagementDislike).toFixed(0):(engagementDislike*100).toFixed(2)+"%"}</h5>
                                            <p>Avg. Dislike Ratio</p>
                                        </div>
                                        <div className={engagementComment != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                            <h5>{id_socmed==5?(engagementComment).toFixed(0) : (engagementComment*100).toFixed(2)+"%"}</h5>
                                            <p>Avg. Comment Ratio</p>
                                        </div>
                                        <div className={engagementShare != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                            <h5>{(engagementShare*100).toFixed(2)+"%"}</h5>
                                            <p>Avg. Shares Ratio</p>
                                        </div>
                                        <div className={engagementView != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                            <h5>{id_socmed==5?(engagementView).toFixed(0):(engagementView*100).toFixed(2)+"%"}</h5>
                                            <p>Avg. Views Ratio</p>
                                        </div>
                                        <div className={totalLike != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                            <h5>{(formatFollower(Math.round(totalLike)))}</h5>
                                            <p>Total Like</p>
                                        </div>
                                        <div className={id_socmed == 5 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                            <h5>{id_socmed==5?(formatFollower(Math.round(totalView))):""}</h5>
                                            <p>Total View</p>
                                        </div>
                                        <div className={totalComment != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                            <h5>{(formatFollower(Math.round(totalComment)))}</h5>
                                            <p>Total Comment</p>
                                        </div>
                                        <div className={id_socmed == 4 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                            {(() => {
                                            if (id_socmed == 4) {
                                                return <h5>{(formatFollower(Math.round(totalShare)))}</h5>
                                                }
                                            })()}
                                            <p>Total Share</p>
                                        </div>
                                        <div className={estReach != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-bookmark.svg" className="img-fluid mb-3" />
                                            <h5>{(formatFollower(Math.round(estReach)))}</h5>
                                            <p>Est. Reach</p>
                                        </div>
                                        <div className={estMedia != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-play.svg" className="img-fluid mb-3" />
                                            <h5>{(formatFollower(Math.round(estMedia)))}</h5>
                                            <p>Est. Media Value</p>
                                        </div>
                                        <div className={estImpres != 0 ? "e-items" : "hidden"}>
                                            <img src="/img/icon-bookmark.svg" className="img-fluid mb-3" />
                                            <h5>{(formatFollower(Math.round(estImpres)))}</h5>
                                            <p>Est. Impression</p>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end Engagment*/}
                            <div className={appState?.stats?.influencerBrandAffinity?"col-4":"hidden"}>
                                <div className="box-white for-chart">
                                    <div className="bw-head mb-3">
                                        <h6 className="font-medium">Influencer Brand Affinity</h6>
                                        <div className="help">
                                            <span className="tool-tip">
                                            <button className="btn btn-help">?</button>
                                                <div className="tool-tip-content big">
                                                    Brands that influencer has mentioned & tagged in their posts 
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-filter-iba">
                                        <div className="for-form">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group inside search-input">
                                                    <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                    <input type="text" className="form-control" id="inputSearch" placeholder="Filter Keywords" value={search} onChange={changeSearch} />
                                                    <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                </div>
                                            </form>
                                        </div>
                                        <SortFilter value={sort} onClick={(e) => updateSorting(e)} />

                                    </div>
                                    <div className="box-brand">
                                    {appState?.stats?.influencerBrandAffinity && appState?.stats?.influencerBrandAffinity.length> 0 ? appState?.stats?.influencerBrandAffinity.map((item,index) => {
                                        return (
                                            <div className="brand-items" key={index}>
                                                <div className="logontext">
                                                    <div className="d-flex align-items-center">
                                                        <div className="logo">
                                                            <embed src={item.profilePicUrl.replace(`media/?size=t`, 'embed')} className="img-circle img-fluid avatar-influencer" />
                                                        </div>
                                                        <div className="desc">
                                                            <h6 className="font-medium">{item.fullName}</h6>
                                                                {item.username}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        }): <div className="brand-items">No data</div>
                                    }
                                    </div>
                                </div>
                            </div>{/* end Influencer Brand Afinity*/}
                            <div className="col-4">
                            <div className={appState?.stats?.audienceBrandAffinity?.buckets ? "box-white for-chart" : "hidden"}>
                                <div className="bw-head mb-4">
                                <h6 className="font-medium">Audience Brand Affinity</h6>
                                <div className="help">
                                    <span className="tool-tip">
                                    <button className="btn btn-help">?</button>
                                    <div className="tool-tip-content big">
                                        Top brands that influencer's followers follow on {id_socmed==1?"instagram":id_socmed==2?"facebook":id_socmed==4?"tiktok":"youtube"} 
                                    </div>
                                    </span>
                                </div>
                                </div>
                                <Chart
                                    type="bar"
                                    options={{
                                        chart: {
                                            toolbar: {
                                                export: {
                                                    csv: {
                                                        filename: 'Audience Brand Affinity - '+appState.data.username,
                                                    },
                                                    svg: {
                                                        filename: 'Audience Brand Affinity - '+appState.data.username,
                                                    },
                                                    png: {
                                                        filename: 'Audience Brand Affinity - '+appState.data.username,
                                                    }
                                                }
                                            }
                                        },
                                        labels: appState?.stats?.audienceBrandAffinity?.buckets.map(item => item.label.split(' ')) ?? [],
                                        yaxis: {
                                            min: 0,
                                            max: Math.ceil(Math.round(largestAudienceGroup*1) / 1) * 1,
                                            tickAmount: 5,
                                            labels: {
                                                formatter: function (value) {
                                                    return (Math.ceil(value)).toFixed(0) + "%";
                                                },
                                                offsetX: -12,
                                                minWidth: 42,
                                                style: {
                                                    colors: '#626262',
                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                    fontSize: 12,
                                                }
                                            }
                                        },
                                        xaxis: {
                                            labels: {
                                                style: {
                                                    colors: '#626262',
                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                    fontSize: 12,
                                                }
                                            }
                                        },
                                        plotOptions: {
                                            bar: {
                                                columnWidth: '40%',
                                                distributed: true
                                            }
                                        },
                                        stroke: {
                                            width: 1,
                                            colors: appState?.stats?.audienceBrandAffinity?.buckets.map(item => Math.round(item.count*1) === Math.round(largestAudienceGroup*1) ? '#893930' : '#E2E2E2'),
                                        },
                                        tooltip: {
                                            x: {show: false}
                                        },
                                        colors: appState?.stats?.audienceBrandAffinity?.buckets.map(item => Math.round(item.count*1) === Math.round(largestAudienceGroup*1) ? '#893930' : '#E2E2E2'),
                                        dataLabels: {
                                            enabled: false
                                        },
                                        legend: {
                                            show: false,
                                        },
                                    }}
                                    series={[{ name: 'Jumlah', data: appState?.stats?.audienceBrandAffinity?.buckets.map(item => Math.round(item.count*1)) ?? [] }]}
                                />
                            </div>
                            </div>{/* end Brand Afinity*/}
                                <div className={id_socmed==5?"hidden":"col-4"}>
                                    <div className="box-white for-chart">
                                        <div className="bw-head">
                                            <h6 className="font-medium">Audience Location Distribution</h6>
                                            <div className="help">
                                                <span className="tool-tip">
                                                <button className="btn btn-help">?</button>
                                                <div className="tool-tip-content big">
                                                    Location Distribution of influencer's engagaed followers
                                                </div>
                                                </span>
                                            </div>
                                        </div>
                                        <Chart
                                            type="bar"
                                            options={{
                                                chart: {
                                                    toolbar: {
                                                        export: {
                                                            csv: {
                                                                filename: 'Audience Location Distribution '+appState.data.username,
                                                            },
                                                            svg: {
                                                                filename: 'Audience Location Distribution '+appState.data.username,
                                                            },
                                                            png: {
                                                                filename: 'Audience Location Distribution '+appState.data.username,
                                                            }
                                                        }
                                                    }
                                                },
                                                labels: appState?.stats?.audienceLocation?.buckets.map(item => item.label.split(' ')) ?? appState?.stats?.audienceStatistics?.locations?.buckets.map(item => item.label.split(' ')) ?? appState?.data?.detail?.audienceLocation?.map(item => item.code.split(' ')) ?? [],
                                                yaxis: {
                                                    min: 0,
                                                    max: Math.round(Math.round(largestAuditionLocation*100) / 1) * 1,
                                                    tickAmount: 5,
                                                    labels: {
                                                        formatter: function (value) {
                                                            return (value).toFixed(0) + "%";
                                                        },
                                                        offsetX: -12,
                                                        minWidth: 42,
                                                        style: {
                                                            colors: '#626262',
                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                            fontSize: 12,
                                                        }
                                                    }
                                                },
                                                xaxis: {
                                                    labels: {
                                                        style: {
                                                            colors: '#626262',
                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                            fontSize: 12,
                                                        }
                                                    }
                                                },
                                                plotOptions: {
                                                    bar: {
                                                        columnWidth: '40%',
                                                        distributed: true
                                                    }
                                                },
                                                stroke: {
                                                    width: 1,
                                                    colors: appState?.stats?.audienceLocation?.buckets?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocation*100) ? '#893930' : '#E2E2E2') ?? appState?.stats?.audienceStatistics?.locations?.buckets?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocation*100) ? '#893930' : '#E2E2E2') ?? appState?.data?.detail?.audienceLocation?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocation*100) ? '#893930' : '#E2E2E2'),
                                                },
                                                tooltip: {
                                                    x: {show: false}
                                                },
                                                colors: appState?.stats?.audienceLocation?.buckets?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocation*100) ? '#893930' : '#E2E2E2') ?? appState?.stats?.audienceStatistics?.locations?.buckets?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocation*100) ? '#893930' : '#E2E2E2') ?? appState?.data?.detail?.audienceLocation?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocation*100) ? '#893930' : '#E2E2E2'),
                                                dataLabels: {
                                                    enabled: false
                                                },
                                                legend: {
                                                    show: false,
                                                },
                                            }}
                                            series={[{ name: 'Jumlah', data: appState?.stats?.audienceLocation?.buckets.map(item =>Math.round(item.count*100)) ?? appState?.stats?.audienceStatistics?.locations?.buckets.map(item => Math.round(item.count*100)) ?? appState?.data?.detail?.audienceLocation?.map(item => Math.round(item.count*100)) ?? [] }]}
                                        />
                                        {/* <ReactFC {...chartConfigs} /> */}
                                        {/* <div id="indicator" className="d-flex">
                                            <div className="col-3">
                                                <div className="d-flex align-items-baseline">
                                                <div className="indicator red" />
                                                <div className="text red">&gt; 1.0M Users</div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex align-items-baseline">
                                                <div className="indicator green" />
                                                <div className="text red">100K-999K Users</div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex align-items-baseline">
                                                <div className="indicator blue" />
                                                <div className="text red">51K-99K Users</div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex align-items-baseline">
                                                <div className="indicator yellow" />
                                                <div className="text red">
                                                    50K Users
                                                </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>{/* end location*/}
                                {(() => {
                                if (id_socmed==1){
                                    return <div className={id_socmed==1?"col-6":"hidden"}>
                                        <div className="box-white for-chart">
                                            <div className="bw-head">
                                                <h6 className="font-medium">Branded Content Distribution</h6>
                                                <div className="help">
                                                    <span className="tool-tip">
                                                    <button className="btn btn-help">?</button>
                                                    <div className="tool-tip-content big">
                                                        Branded content distribution of influencer's engaged followers
                                                    </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="box-pc">
                                                <div className="post-count">
                                                    <h1 className="font-medium black">{appState?.data?.detail?.brandedContentStats?.total ?? 0}</h1>
                                                    Total Post
                                                </div>
                                            </div>
                                            <div className="chart-center">
                                                <Chart
                                                    options={{
                                                        chart: {
                                                            type: 'donut',
                                                        },
                                                        colors: brandColor,
                                                        legend: {
                                                            show: false,
                                                        },
                                                        plotOptions: {
                                                            donut: {
                                                                dataLabels: {
                                                                    offset: -30
                                                                }
                                                            }
                                                        },
                                                        stroke: {
                                                            width: 0,
                                                        },
                                                        dataLabels: {
                                                            formatter: (val) => `${Math.round(val)}%`,
                                                            style: {
                                                                fontFamily: "'Nunito Sans', sans-serif",
                                                                fontSize: '14px',
                                                                colors: brandLabelColor,
                                                            },
                                                            dropShadow: {
                                                                enabled: false,
                                                            }
                                                        },
                                                        tooltip: {
                                                            enabled: false,
                                                        }
                                                    }}
                                                    type="donut"
                                                    height={250}
                                                    width={220}
                                                    series={[
                                                        branded,
                                                        nonBranded
                                                    ]
                                                    }
                                                />
                                            </div>
                                            <div className="total-indicator">
                                                {(() => {
                                                    if (branded > nonBranded) {
                                                        return <div className="indicator-info red">
                                                            <div className="d-flex align-items-center">
                                                                <div className="indicator red" />
                                                                <h2 className="font-medium mb-0 mr-3 ml-2">{branded? Math.round(branded/totalBrand*100)+'%' : '...'}</h2>
                                                                <div>Branded Content</div>
                                                            </div>
                                                        </div>
                                                    } else if (branded < nonBranded) {
                                                        return <div className="indicator-info grey">
                                                        <div className="d-flex align-items-center">
                                                            <div className="indicator grey" />
                                                            <h2 className="font-medium mb-0 mr-3 ml-2">{branded? Math.round(branded/totalBrand*100)+'%' : '...'}</h2>
                                                            <div>Branded Content</div>
                                                        </div>
                                                    </div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (nonBranded < branded) {
                                                        return <div className="indicator-info grey">
                                                        <div className="d-flex align-items-center">
                                                            <div className="indicator grey" />
                                                            <h2 className="font-medium mb-0 mr-3 ml-2">{nonBranded? Math.round(nonBranded/totalBrand*100)+'%' : '...'}</h2>
                                                            <div>Non Branded Content</div>
                                                        </div>
                                                    </div>
                                                    } else if (nonBranded > branded) {
                                                        return <div className="indicator-info red">
                                                        <div className="d-flex align-items-center">
                                                            <div className="indicator red" />
                                                            <h2 className="font-medium mb-0 mr-3 ml-2">{nonBranded? Math.round(nonBranded/totalBrand*100)+'%' : '...'}</h2>
                                                            <div>Non Branded Content</div>
                                                        </div>
                                                    </div>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                } 
                                })()}
                                {(() => {
                                if (id_socmed==1){
                                    return <div className={id_socmed==1?"col-6":"hidden"}>
                                        <div className="box-white for-chart">
                                            <div className="bw-head mb-2">
                                                <h6 className="font-medium">Audience Interest Distribution</h6>
                                                <div className="help">
                                                    <span className="tool-tip">
                                                        <button className="btn btn-help">?</button>
                                                        <div className="tool-tip-content big">
                                                            Top Interests of influencer's followers
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <p>Primary Influencer Related Interests (%)</p>
                                            <div className="row mb-2">
                                            {appState?.stats?.interests && appState?.stats?.interests.length> 0 ? appState?.stats?.interests.map((item,index) => {
                                                return (
                                                    <div className="col-4" key={index}>
                                                        <div className="tag-box">{item}</div>
                                                    </div>
                                                    );
                                                }): <div className="hidden"></div>
                                                }
                                            </div>
                                            <p>Primary Influencer Related Interests (%)</p>
                                            <Chart
                                                type="bar"
                                                options={{
                                                    chart: {
                                                        toolbar: {
                                                            export: {
                                                                csv: {
                                                                    filename: 'Audience Interest Distribution - '+appState.data.username,
                                                                },
                                                                svg: {
                                                                    filename: 'Audience Interest Distribution - '+appState.data.username,
                                                                },
                                                                png: {
                                                                    filename: 'Audience Interest Distribution - '+appState.data.username,
                                                                }
                                                            }
                                                        }
                                                    },
                                                    height: 350,
                                                    labels: appState?.stats?.audienceInterests?.buckets.map(item => item.label.split(' ')) ?? [],
                                                    yaxis: {
                                                        min: 0,
                                                        max: Math.ceil(Math.round(largestAudienceInterestGroup*100) / 1) * 1,
                                                        tickAmount:5,
                                                        labels: {
                                                            offsetX: -12,
                                                            minWidth: 20,
                                                            style: {
                                                                colors: '#626262',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    xaxis: {
                                                        labels: {
                                                            formatter: function (value) {
                                                                return (Math.ceil(value)).toFixed(0) + "%";
                                                            },
                                                            style: {
                                                                colors: '#626262',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 1,
                                                            horizontal: true,
                                                        }
                                                    },
                                                    stroke: {
                                                        width: 1,
                                                        colors: appState?.stats?.audienceInterests?.buckets.map(item => Math.round(item.count*100) === Math.round(largestAudienceInterestGroup*100) ? '#893930' : '#E2E2E2'),
                                                    },
                                                    tooltip: {
                                                        x: {show: false}
                                                    },
                                                    colors: appState?.stats?.audienceInterests?.buckets.map(item => Math.round(item.count*100) === Math.round(largestAudienceInterestGroup*100) ? '#893930' : '#E2E2E2'),
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    legend: {
                                                        show: false,
                                                    },
                                                }}
                                                series={[{ name: 'Jumlah', data: appState?.stats?.audienceInterests?.buckets.map(item => Math.round(item.count*100)) ?? [] }]}
                                            />
                                    </div>
                                    </div>
                                }
                                })()}
                            </div>
                        </div>{/* end of info*/}
                    </div>
                    </div>
                </div>
                <div className="accordions-01">
                    <div className="accordion-01">
                        <input type="checkbox" id="chck3" defaultChecked />
                        <label className="analytic-accordion" htmlFor="chck3">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="font-medium red">Timeline</h6>
                                </div>
                                <div>
                                    <div className="font-medium">Hide <img src="/img/arrow-up.svg" className="gg-icon-arrow black" /></div>
                                </div>
                            </div>
                        </label>
                        <div className="tab-content big">
                            <div className="row-of-info">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box-white for-chart">
                                            <div className="bw-head mb-4">
                                                <h6 className="font-medium">{id_socmed==5?"Subscribers Timeline":"Followers Timeline"}</h6>
                                                <div className="help">
                                                    <span className="tool-tip">
                                                    <button className="btn btn-help">?</button>
                                                    <div className="tool-tip-content big">
                                                        {id_socmed==5?"Followers timeline of influencer's engaged followers" : "Subscriber timeline of influencer's engaged followers"}
                                                    </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <Chart
                                                type="line"
                                                height={320}
                                                options={{
                                                    chart: {
                                                        zoom: {
                                                            enabled: false,
                                                        },
                                                        toolbar: {
                                                            export: {
                                                                csv: {
                                                                    filename: 'Followers Timeline '+appState.data.username,
                                                                },
                                                                svg: {
                                                                    filename: 'Followers Timeline '+appState.data.username,
                                                                },
                                                                png: {
                                                                    filename: 'Followers Timeline '+appState.data.username,
                                                                }
                                                            }
                                                        }
                                                    },
                                                    yaxis: {
                                                        min: 0,
                                                        tickAmount: 5,
                                                        labels: {
                                                            offsetX: -10,
                                                            minWidth: 42,
                                                            style: {
                                                                colors: '#953232',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 10,
                                                            }
                                                        }
                                                    },
                                                    xaxis: {
                                                        labels: {
                                                            style: {
                                                                colors: '#953232',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 10,
                                                            }
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    stroke: {
                                                        curve: 'straight',
                                                        width: 2
                                                    },
                                                    markers: {
                                                        size: 5,
                                                    },
                                                    xaxis: {
                                                        categories: labelChartFollowers,
                                                    },
                                                    colors: ['#953232'],
                                                }}
                                                series={[
                                                    { name: 'Followers Timeline', data: appState?.stats?.followersTimeseries?.map(item => item.followers) 
                                                    ?? appState?.stats?.followerTimeline?.map(item => item.followers) 
                                                    ?? appState?.data?.detail?.followerTimelineData?.map(item => item.followers)
                                                    ?? appState?.data?.detail?.subscriberTimelineData?.map(item => item.subscribers) }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box-white for-chart">
                                            <div className="bw-head mb-4">
                                                <h6 className="font-medium">Engagement Timeline</h6>
                                                <div className="help">
                                                    <span className="tool-tip">
                                                        <button className="btn btn-help">?</button>
                                                        <div className="tool-tip-content big">
                                                            Engagement timeline of influencer's engaged followers
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            {(() => {
                                            if (id_socmed==1){
                                                let labelChart = appState?.stats?.engagementTimeseries?.map(item => new Date(item.takenAt).getDate()+ " " + monthNames[new Date(item.takenAt).getMonth()])
                                                return <Chart
                                                    type="line"
                                                    height={320}
                                                    options={{
                                                        chart: {
                                                            zoom: {
                                                                enabled: false,
                                                            },
                                                            toolbar: {
                                                                export: {
                                                                    csv: {
                                                                        filename: 'Engagement Timeline '+appState.data.username,
                                                                    },
                                                                    svg: {
                                                                        filename: 'Engagement Timeline '+appState.data.username,
                                                                    },
                                                                    png: {
                                                                        filename: 'Engagement Timeline '+appState.data.username,
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        yaxis: {
                                                            min: 0,
                                                            tickAmount: 5,
                                                            labels: {
                                                                offsetX: -12,
                                                                minWidth: 42,
                                                                style: {
                                                                    colors: '#953232',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        xaxis: {
                                                            labels: {
                                                                style: {
                                                                    colors: '#953232',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: false
                                                        },
                                                        stroke: {
                                                            curve: 'straight',
                                                            width: 2
                                                        },
                                                        markers: {
                                                            size: 5,
                                                        },
                                                        xaxis: {
                                                            categories: labelChart,
                                                        },
                                                        colors: ['#953232'],
                                                    }}
                                                    series={[
                                                        { name: 'Engagement Like', data: appState?.stats?.engagementTimeseries?.map(item => item.likeCount) },
                                                        { name: 'Engagement Comment', data: appState?.stats?.engagementTimeseries?.map(item => item.commentCount) }
                                                    ]}
                                                />
                                            } else if (id_socmed==2){
                                                let labelChart = appState?.data?.detail?.engagementTimeline?.map(item => new Date(item.takenAt).getDate()+ " " + monthNames[new Date(item.takenAt).getMonth()])
                                                return <Chart
                                                type="line"
                                                height={320}
                                                options={{
                                                    chart: {
                                                        zoom: {
                                                            enabled: false,
                                                        },
                                                        toolbar: {
                                                            export: {
                                                                csv: {
                                                                    filename: 'Engagement Timeline '+appState.data.username,
                                                                },
                                                                svg: {
                                                                    filename: 'Engagement Timeline '+appState.data.username,
                                                                },
                                                                png: {
                                                                    filename: 'Engagement Timeline '+appState.data.username,
                                                                }
                                                            }
                                                        }
                                                    },
                                                    yaxis: {
                                                        min: 0,
                                                        tickAmount: 5,
                                                        labels: {
                                                            offsetX: -12,
                                                            minWidth: 42,
                                                            style: {
                                                                colors: '#953232',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    xaxis: {
                                                        labels: {
                                                            style: {
                                                                colors: '#953232',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    stroke: {
                                                        curve: 'straight',
                                                        width: 2
                                                    },
                                                    markers: {
                                                        size: 5,
                                                    },
                                                    xaxis: {
                                                        categories: labelChart,
                                                    },
                                                    colors: ['#953232'],
                                                }}
                                                series={[
                                                    { name: 'Engagement Reaction', data: appState?.data?.detail?.engagementTimeline?.map(item => item.reactionCount) },
                                                    { name: 'Engagement Comment', data: appState?.data?.detail?.engagementTimeline?.map(item => item.commentCount) },
                                                ]}
                                            />
                                            } else if (id_socmed==4){
                                                let labelChart = appState?.data?.detail?.engagementTimelineData?.map(item => new Date(item.takenAt).getDate()+ " " + monthNames[new Date(item.takenAt).getMonth()])
                                                return <Chart
                                                    type="line"
                                                    height={320}
                                                    options={{
                                                        chart: {
                                                            zoom: {
                                                                enabled: false,
                                                            },
                                                            toolbar: {
                                                                export: {
                                                                    csv: {
                                                                        filename: 'Engagement Timeline '+appState.data.username,
                                                                    },
                                                                    svg: {
                                                                        filename: 'Engagement Timeline '+appState.data.username,
                                                                    },
                                                                    png: {
                                                                        filename: 'Engagement Timeline '+appState.data.username,
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        yaxis: {
                                                            min: 0,
                                                            tickAmount: 5,
                                                            labels: {
                                                                offsetX: -12,
                                                                minWidth: 42,
                                                                style: {
                                                                    colors: '#953232',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        xaxis: {
                                                            labels: {
                                                                style: {
                                                                    colors: '#953232',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: false
                                                        },
                                                        stroke: {
                                                            curve: 'straight',
                                                            width: 2
                                                        },
                                                        markers: {
                                                            size: 5,
                                                        },
                                                        xaxis: {
                                                            categories: labelChart,
                                                        },
                                                        colors: ['#953232'],
                                                    }}
                                                    series={[
                                                        { name: 'Engagement Like', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.likeCount) },
                                                        { name: 'Engagement Comment', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.commentCount) },
                                                        { name: 'Engagement Play', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.playCount) },
                                                        { name: 'Engagement Share', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.shareCount) },
                                                    ]}
                                                />
                                            } else if (id_socmed==5){
                                                let labelChart = appState?.data?.detail?.engagementTimelineData?.map(item => new Date(item.takenAt).getDate()+ " " + monthNames[new Date(item.takenAt).getMonth()])
                                                return <Chart
                                                type="line"
                                                height={320}
                                                options={{
                                                    chart: {
                                                        zoom: {
                                                            enabled: false,
                                                        },
                                                        toolbar: {
                                                            export: {
                                                                csv: {
                                                                    filename: 'Engagement Timeline '+appState.data.username,
                                                                },
                                                                svg: {
                                                                    filename: 'Engagement Timeline '+appState.data.username,
                                                                },
                                                                png: {
                                                                    filename: 'Engagement Timeline '+appState.data.username,
                                                                }
                                                            }
                                                        }
                                                    },
                                                    yaxis: {
                                                        min: 0,
                                                        tickAmount: 5,
                                                        labels: {
                                                            offsetX: -12,
                                                            minWidth: 42,
                                                            style: {
                                                                colors: '#953232',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    xaxis: {
                                                        labels: {
                                                            style: {
                                                                colors: '#953232',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    stroke: {
                                                        curve: 'straight',
                                                        width: 2
                                                    },
                                                    markers: {
                                                        size: 5,
                                                    },
                                                    xaxis: {
                                                        categories: labelChart,
                                                    },
                                                    colors: ['#953232'],
                                                }}
                                                series={[
                                                    { name: 'Engagement Like', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.likeCount) },
                                                    { name: 'Engagement Comment', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.commentCount) },
                                                    { name: 'Engagement View', data: appState?.data?.detail?.engagementTimelineData?.map(item => item.viewCount) },
                                                ]}
                                            />
                                            }
                                        })()}
                                        </div>
                                    </div>{/* end Audience*/}
                                    <div className="col-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            } else if (appState.loading== false && appState.data == null){
                return <div className="main-dash">
                    <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data user analytics, sorry</p>;
                </div>
            } else if (appState.loading== true && appState.data == undefined){
                return <div className="main-dash">
                    <div className="credential pt-5">
                        <div className="credential-box-noborder">
                        <div className="load-2">
                            <p>Loading... Please wait</p>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        </div>
                    </div>
                </div>
            }
            })()}
        </div>
    );
}


export default AnalyticsGoddessGang;
