import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getUserRegisteredApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=waiting verification`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getUserRejectedApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=reject`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getUserApprovedApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=approve`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getDetailUserApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setStatusUserApi = (id, bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/set-status/${id}`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setAddCampaignApi = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/user/add-to-campaign`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setSendEmailApi = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/user/invite-by-email`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const setSendNotifApi = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/user/send-notif`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getUserAnalyticApi = (userId,socmedId,params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${userId}/${socmedId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getQuestionerResultApi = async (userId) => {
    const response = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/questioner-result/${userId}`,
        {
            headers: {
                Authorization: token
            }
        }
    )

    return response.data
}

const getUpdateUsersApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/affable/update-user`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

export {
    getUserRegisteredApi,
    getUserRejectedApi,
    getUserApprovedApi,
    getDetailUserApi,
    setStatusUserApi,
    setAddCampaignApi,
    setSendEmailApi,
    setSendNotifApi,
    getUserAnalyticApi,
    getQuestionerResultApi,
    getUpdateUsersApi
}
