import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getGroupApi = (params) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    }))
}

const getGroupDetailApi = (groupId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/${groupId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const getGroupUserApi = (groupId, params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/user/${groupId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const createGroupApi = (data) => {
    return new Promise((resolve) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/create`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const updateGroupApi = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/update/${id}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const deleteGroupApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/delete/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const deleteUserGroupApi = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/delete/user`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const setSendEmailApi = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/group/invite-by-email`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const addUserGroupApi = (data) => {
    return new Promise((resolve) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/add/user`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            resolve(err.response.data)
        })
    })
}

const getGroupListApi = () => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/list`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err.response.data)
        })
    }))
}

export {
    getGroupApi,
    getGroupDetailApi,
    getGroupUserApi,
    createGroupApi,
    updateGroupApi,
    deleteGroupApi,
    deleteUserGroupApi,
    setSendEmailApi,
    addUserGroupApi,
    getGroupListApi
}
