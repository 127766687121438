import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { setGoddesGangBannerApi, deleteBannerApi, setActiveBanner } from "../../api/banner-goddess-gang.api";
import {cloudFunctionBaseUrl} from "../../ui-util/general-variable";
import SwiperCore, {
    Zoom,Navigation
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Zoom,Navigation]);
  

const token = localStorage.getItem('TOKEN_AUTH');

function SettingBanner() {
    const [BannerCover, setBannerCover]                 = useState('');
    const [BannerCampaign, setBannerCampaign]           = useState('');
    const [BannerDescription, setBannerDescription]     = useState('');
    const [BannerRegistration, setBannerRegistration]   = useState('');
    const [BannerSendProduct, setBannerSendProduct]     = useState('');
    const [appState, setAppState]     = useState({
        loading: false,
        cover: null,
        campaign: null,
        description: null,
        registration: null,
        send_product: null,
        disabled: false
    });
    function deleteBanner(e){
        setAppState({disabled: true, cover:appState.cover, campaign:appState.campaign, description:appState.description, registration:appState.registration, send_product:appState.send_product});
        swal({
            title: "Confirmation",
            text: "Are you sure delete banner ?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                },
            },
            dangerMode: true,
        }).then((isConfirm) => {
            if (!isConfirm){ return false;}
            setAppState({disabled: true, cover:appState.cover, campaign:appState.campaign, description:appState.description, registration:appState.registration, send_product:appState.send_product});
            const banner_id = e
            deleteBannerApi(banner_id)
            .then((result) => {
                const data = result.message;
                if(result.code == 201) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        button: true
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                }
            });
            
        }) 
    }
    function activeBanner(e){
        setAppState({disabled: true, cover:appState.cover, campaign:appState.campaign, description:appState.description, registration:appState.registration, send_product:appState.send_product});
        swal({
            title: "Confirmation",
            text: "Are you sure active banner ?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                },
            },
            dangerMode: true,
        }).then((isConfirm) => {
            if (!isConfirm){ return false;}
            setAppState({disabled: true, cover:appState.cover, campaign:appState.campaign, description:appState.description, registration:appState.registration, send_product:appState.send_product});
            let bodyRequest = []
            let values = {
                banner_id: e,
                is_active: true
            }
            bodyRequest.push(values)
            setActiveBanner(values)
            .then((result) => {
                const data = result.message;
                if(result.code == 201) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        button: true
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                }
            });
            
        }) 
    }
    function nonactiveBanner(e){
        setAppState({disabled: true, cover:appState.cover, campaign:appState.campaign, description:appState.description, registration:appState.registration, send_product:appState.send_product});
        swal({
            title: "Confirmation",
            text: "Are you sure non active banner ?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                },
            },
            dangerMode: true,
        }).then((isConfirm) => {
            if (!isConfirm){ return false;}
            setAppState({disabled: true, cover:appState.cover, campaign:appState.campaign, description:appState.description, registration:appState.registration, send_product:appState.send_product});
            let bodyRequest = []
            let values = {
                banner_id: e,
                is_active: false
            }
            bodyRequest.push(values)
            setActiveBanner(values)
            .then((result) => {
                const data = result.message;
                if(result.code == 201) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        button: true
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                }
            });
            
        }) 
    }
    const onBannerCoverChange = (event) => {
        const BannerCover = event.target.files[0]
        setBannerCover(BannerCover)
        const form = new FormData();
        form.append('title', 'goddest gang cover')
        form.append('image', BannerCover)
        setGoddesGangBannerApi(form)
        .then((result) => {
            const data = result.message;
            if(result.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    const onBannerCampaignChange = (event) => {
        const BannerCampaign = event.target.files[0]
        setBannerCampaign(BannerCampaign)
        const form = new FormData();
        form.append('title', 'goddest gang campaign')
        form.append('image', BannerCampaign)
        setGoddesGangBannerApi(form)
        .then((result) => {
            const data = result.message;
            if(result.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    const onBannerDescriptionChange = (event) => {
        const BannerDescription = event.target.files[0]
        setBannerDescription(BannerDescription)
        const form = new FormData();
        form.append('title', 'goddest gang description')
        form.append('image', BannerDescription)
        setGoddesGangBannerApi(form)
        .then((result) => {
            const data = result.message;
            if(result.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    const onBannerRegistrationChange = (event) => {
        const BannerRegistration = event.target.files[0]
        setBannerRegistration(BannerRegistration)
        const form = new FormData();
        form.append('title', 'registration success banner')
        form.append('image', BannerRegistration)
        setGoddesGangBannerApi(form)
        .then((result) => {
            const data = result.message;
            if(result.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    const onBannerSendProductChange = (event) => {
        const BannerSendProduct = event.target.files[0]
        setBannerSendProduct(BannerSendProduct)
        const form = new FormData();
        form.append('title', 'send product')
        form.append('image', BannerSendProduct)
        setGoddesGangBannerApi(form)
        .then((result) => {
            const data = result.message;
            if(result.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
           const respBannerCover = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banner-goddest?title=goddest gang cover`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respBannerCampaign = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banner-goddest?title=goddest gang campaign`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respBannerDescription = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banner-goddest?title=goddest gang description`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respBannerRegistration = await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/banner-goddest?title=registration success banner`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            const respBannerSendProduct = await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/banner-goddest?title=send product`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            setAppState({ loading: false, cover: respBannerCover.data.data, campaign: respBannerCampaign.data.data, description: respBannerDescription.data.data, registration: respBannerRegistration.data.data, send_product: respBannerSendProduct.data.data });
        };
        fetchData();
    }, []);
    return ( 
        <div id="main-content">
        {(() => {
            if (appState.cover || appState.campaign || appState.description){
            return <div className="content-wrapper">
                <h5 className="grey font-medium mb-4">Banner Goddess Gang</h5>
                <div className="box-file-upload mb-5">
                    <div className="d-flex justify-content-center">
                        <div className="upload-center">
                            {(() => {
                                if (appState.cover != '') {
                                return <Swiper zoom={true} navigation={true}
                                slidesPerView={'auto'} className="mySwiper swiper-banner"
                              >
                                    {appState.cover.map((repo,index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <button onClick={(e)=>activeBanner(repo.id)} className={repo.is_active==false?"btn btn-xs btn-toggle":"hidden"} data-toggle="button" aria-pressed={repo.is_active==false?"false":"true"} autoComplete="on">
                                                    <div className="handle"></div>
                                                </button>
                                                <button onClick={(e)=>nonactiveBanner(repo.id)} className={repo.is_active==true?"btn btn-xs btn-toggle active":"hidden"} data-toggle="button" aria-pressed={repo.is_active==true?"true":"false"} autoComplete="on">
                                                    <div className="handle"></div>
                                                </button>
                                                <button onClick={(e)=>deleteBanner(repo.id)} className="btn btn-delete"><img src="/img/ico-delete.svg" className="img-fluid red" /></button>
                                                <div className="swiper-zoom-container">
                                                    <img src={repo.image} className="img-fluid" />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                    </Swiper> 
                                } else {
                                return  <img src="/img/ico-upload.svg" width={100} className="img-fluid" />
                                }
                            })()}
                            <div className="grey mb-1">Upload Gambar</div>
                            <div className="grey mb-2">Ukuran 382x x 115px</div>
                            <div className="upload-btn-banner mb-2">
                                <button className="btn btn-secondary">
                                    <img src="/img/ico-folder.svg" className="img-fluid red mr-2" /> Choose File
                                </button>
                                <input type="file" name="image" onChange={(event) =>onBannerCoverChange(event)} />
                            </div>
                            <div className="font-medium">or Drop JPG here</div>
                        </div>
                    </div>
                </div> 
                <div className="row mb-5">
                    <div className="col-6">
                        <h5 className="grey font-medium mb-4">Banner Campaign Goddess Gang</h5>
                        <div className="row">
                            <div className="col-8">
                                <div className="box-file-upload">
                                    <div className="d-flex justify-content-center">
                                        <div className="upload-center">
                                            {(() => {
                                                if (appState.campaign != '') {
                                                return <img src={appState.campaign[0].image} className="img-fluid" />
                                                } else {
                                                return  <img src="/img/ico-upload.svg" width={100} className="img-fluid" />
                                                }
                                            })()}
                                            <div className="grey mb-1">Upload Gambar</div>
                                            <div className="grey mb-2">Ukuran 382x x 115px</div>
                                            <div className="upload-btn-banner mb-2">
                                                <button className="btn btn-secondary"><img src="/img/ico-folder.svg" className="img-fluid red mr-2" />
                                                Choose
                                                File</button>
                                                <input type="file" name="image" onChange={(event) =>onBannerCampaignChange(event)} />
                                            </div>
                                            <div className="font-medium">or Drop JPG here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <h5 className="grey font-medium mb-4">Banner Description Campaign Goddess Gang</h5>
                        <div className="row">
                            <div className="col-8">
                                <div className="box-file-upload">
                                    <div className="d-flex justify-content-center">
                                        <div className="upload-center">
                                            {(() => {
                                                if (appState.description != '') {
                                                return <img src={appState.description[0].image} className="img-fluid" />
                                                } else {
                                                return  <img src="/img/ico-upload.svg" width={100} className="img-fluid" />
                                                }
                                            })()}
                                            <div className="grey mb-1">Upload Gambar</div>
                                            <div className="grey mb-2">Ukuran 382x x 115px</div>
                                            <div className="upload-btn-banner mb-2">
                                                <button className="btn btn-secondary"><img src="/img/ico-folder.svg" className="img-fluid red mr-2" />
                                                    Choose
                                                    File</button>
                                                <input type="file" name="image" onChange={(event) =>onBannerDescriptionChange(event)} />
                                            </div>
                                            <div className="font-medium">or Drop JPG here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <h5 className="grey font-medium mb-4">Banner Registration Success</h5>
                        <div className="row">
                            <div className="col-8">
                                <div className="box-file-upload">
                                    <div className="d-flex justify-content-center">
                                        <div className="upload-center">
                                            {(() => {
                                                if (appState.registration != '') {
                                                return <img src={appState.registration[0].image} className="img-fluid" />
                                                } else {
                                                return  <img src="/img/ico-upload.svg" width={100} className="img-fluid" />
                                                }
                                            })()}
                                            <div className="grey mb-1">Upload Gambar</div>
                                            <div className="grey mb-2">Ukuran 382x x 115px</div>
                                            <div className="upload-btn-banner mb-2">
                                                <button className="btn btn-secondary"><img src="/img/ico-folder.svg" className="img-fluid red mr-2" />
                                                    Choose
                                                    File</button>
                                                <input type="file" name="image" onChange={(event) =>onBannerRegistrationChange(event)} />
                                            </div>
                                            <div className="font-medium">or Drop JPG here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <h5 className="grey font-medium mb-4">Banner Send Product</h5>
                        <div className="row">
                            <div className="col-8">
                                <div className="box-file-upload">
                                    <div className="d-flex justify-content-center">
                                        <div className="upload-center">
                                            {(() => {
                                                if (appState.send_product != '') {
                                                return <img src={appState.send_product[0].image} className="img-fluid" />
                                                } else {
                                                return  <img src="/img/ico-upload.svg" width={100} className="img-fluid" />
                                                }
                                            })()}
                                            <div className="grey mb-1">Upload Gambar</div>
                                            <div className="grey mb-2">Ukuran 382x x 115px</div>
                                            <div className="upload-btn-banner mb-2">
                                                <button className="btn btn-secondary"><img src="/img/ico-folder.svg" className="img-fluid red mr-2" />
                                                    Choose
                                                    File</button>
                                                <input type="file" name="image" onChange={(event) =>onBannerSendProductChange(event)} />
                                            </div>
                                            <div className="font-medium">or Drop JPG here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            } else {
            return <div className="content-wrapper">
                <div className="credential pt-5">
                    <div className="credential-box-noborder">
                    <div className="load-2">
                        <p>Loading... Please wait</p>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    </div>
                </div>
            </div>
            }
        })()}
        </div>
    );
}


export default SettingBanner;
