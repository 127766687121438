import React, { useEffect, useState, useRef } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select'
import DatePicker from 'react-date-picker';
import RichTextEditor from 'react-rte';
import { Controller, useForm } from 'react-hook-form';
import makeAnimated from 'react-select/animated';
import { updateCampaignApi } from "../../api/campaign.api";
import {cloudFunctionBaseUrl} from "../../ui-util/general-variable";
import {useHistory, useLocation} from 'react-router';
import NumberFormat from 'react-number-format';
import PickDate from "react-datepicker";

const token = localStorage.getItem('TOKEN_AUTH');
const animatedComponents = makeAnimated();

function EditCampaign({ match }) {
    const location = useLocation()
    const history = useHistory()
    const [title, setTitle]                                         = useState('');
    const [description, setDescription]                             = useState(RichTextEditor.createEmptyValue());
    const [thumbnail, setThumbnail]                                 = useState('');
    const [point, setPoint]                                         = useState('');
    const [campaign_setting, setCampaign_setting]                   = useState('');
    const [registration_start_date, setRegistration_start_date]     = useState('');
    const [registration_end_date, setRegistration_end_date]         = useState('');
    const [registration_start_time, setRegistration_start_time]     = useState('');
    const [registration_end_time, setRegistration_end_time]         = useState('');
    const [campaign_start_date, setCampaign_start_date]             = useState('');
    const [campaign_end_date, setCampaign_end_date]                 = useState('');
    const [campaign_start_time, setCampaign_start_time]             = useState('');
    const [campaign_end_time, setCampaign_end_time]                 = useState('');
    const [tiktok_task_mention, setTiktok_task_mention]             = useState('');
    const [tiktok_task_hastag, setTiktok_task_hastag]               = useState('');
    const [tiktok_task_content, setTiktok_task_content]             = useState(RichTextEditor.createEmptyValue());
    const [instagram_task_mention, setInstagram_task_mention]       = useState('');
    const [instagram_task_hastag, setInstagram_task_hastag]         = useState('');
    const [instagram_task_content, setInstagram_task_content]       = useState(RichTextEditor.createEmptyValue());
    const [youtube_task_mention, setYoutube_task_mention]           = useState('');
    const [youtube_task_hastag, setYoutube_task_hastag]             = useState('');
    const [youtube_task_content, setYoutube_task_content]           = useState(RichTextEditor.createEmptyValue());
    const [facebook_task_content, setFacebook_task_content]         = useState(RichTextEditor.createEmptyValue());
    const [facebook_task_mention, setFacebook_task_mention]         = useState('');
    const [facebook_task_hastag, setFacebook_task_hastag]           = useState('');
    const [flag_send_product, setFlag_send_product]                 = useState('');
    const [shipment_intructions, setShipment_intructions]           = useState(RichTextEditor.createEmptyValue());
    const [status, setStatus]                                       = useState('');
    const [products, setProducts]                                   = useState([]);
    const [productName, setProductName]                             = useState('');
    const [type, setType]                                           = useState('');
    const [user_id, setUser_id]                                     = useState([]);
    const [campaign_users, setCampaignUsers]                        = useState([]);
    const [campaign_invitations, setCampaignInvitations]            = useState([]);
    const [group_id, setGroup_id]                                   = useState([]);
    const [appState, setAppState]       = useState({
        loading: false,
        products: null,
        groups: null,
        users: null,
        data: null,
        campaign_users: null,
        disabled: false
    });
    const { params: { id }, } = match;
    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        mode: "all"
    });
    const today = new Date().getFullYear() + "-" + ((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1))) + "-" + ((new Date().getDate() > 9) ? new Date().getDate() : ('0' + new Date().getDate()))
    const RTE_TOOLBAR_CONFIG = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
    }
    const onSubmit = (data) => console.log(data);
    const onImageChange = (event) => {
        const thumbnail = event.target.files[0]
        let reader = new FileReader();
        reader.onloadend = () => {
            setThumbnail({
                file: thumbnail,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(thumbnail)
    }
    const onUsersChange = (e) => {
        setUser_id(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    const onGroupsChange = (e) => {
        setGroup_id(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    const onProductsChange = (e) => {
        setProducts(Array.isArray(e) ? e.map(x => x.value) : []);
        setProductName(Array.isArray(e) ? e.map(x =>  ({
            "id" : x.value,
            "name": x.name,
            "full_name": x.label
        })) : []);
        // setProductName(Array.isArray(e) ? e.reduce((obj, item) =>  ({
        //     "id" : item.value,
        //     "name": item.name
        // })) : '');
        console.log(e)
    }
    const onPublish = (data) => {
        const form = new FormData();
        form.append('title', title)
        if (description.toString('html') == "<p><br></p>"){
            form.append('description', "")
        } else {
            form.append('description', description.toString('html'))
        }
        if (thumbnail.file.type == "image/jpeg") {
            form.append('thumbnail', thumbnail.file)
        }
        form.append('point', point)
        form.append('campaign_setting', campaign_setting)
        form.append('registration_start_date', registration_start_date.getFullYear() + "-" + ((registration_start_date.getMonth() > 8) ? (registration_start_date.getMonth() + 1) : ('0' + (registration_start_date.getMonth() + 1))) + "-" + ((registration_start_date.getDate() > 9) ? registration_start_date.getDate() : ('0' + registration_start_date.getDate())))
        form.append('registration_end_date', registration_end_date.getFullYear() + "-" + ((registration_end_date.getMonth() > 8) ? (registration_end_date.getMonth() + 1) : ('0' + (registration_end_date.getMonth() + 1))) + "-" + ((registration_end_date.getDate() > 9) ? registration_end_date.getDate() : ('0' + registration_end_date.getDate())))
        form.append('registration_start_time', ((new Date(registration_start_time).getHours() > 9) ? (new Date(registration_start_time).getHours()) : ('0' + (new Date(registration_start_time).getHours()))) + ":" + ((new Date(registration_start_time).getMinutes() > 9) ? (new Date(registration_start_time).getMinutes()) : ('0' + (new Date(registration_start_time).getMinutes()))))
        form.append('registration_end_time', ((new Date(registration_end_time).getHours() > 9) ? (new Date(registration_end_time).getHours()) : ('0' + (new Date(registration_end_time).getHours()))) + ":" + ((new Date(registration_end_time).getMinutes() > 9) ? (new Date(registration_end_time).getMinutes()) : ('0' + (new Date(registration_end_time).getMinutes()))))
        form.append('campaign_start_date', campaign_start_date.getFullYear() + "-" + ((campaign_start_date.getMonth() > 8) ? (campaign_start_date.getMonth() + 1) : ('0' + (campaign_start_date.getMonth() + 1))) + "-" + ((campaign_start_date.getDate() > 9) ? campaign_start_date.getDate() : ('0' + campaign_start_date.getDate())))
        form.append('campaign_end_date', campaign_end_date.getFullYear() + "-" + ((campaign_end_date.getMonth() > 8) ? (campaign_end_date.getMonth() + 1) : ('0' + (campaign_end_date.getMonth() + 1))) + "-" + ((campaign_end_date.getDate() > 9) ? campaign_end_date.getDate() : ('0' + campaign_end_date.getDate())))
        form.append('campaign_start_time', ((new Date(campaign_start_time).getHours() > 9) ? (new Date(campaign_start_time).getHours()) : ('0' + (new Date(campaign_start_time).getHours()))) + ":" + ((new Date(campaign_start_time).getMinutes() > 9) ? (new Date(campaign_start_time).getMinutes()) : ('0' + (new Date(campaign_start_time).getMinutes()))))
        form.append('campaign_end_time', ((new Date(campaign_end_time).getHours() > 9) ? (new Date(campaign_end_time).getHours()) : ('0' + (new Date(campaign_end_time).getHours()))) + ":" + ((new Date(campaign_end_time).getMinutes() > 9) ? (new Date(campaign_end_time).getMinutes()) : ('0' + (new Date(campaign_end_time).getMinutes()))))
        form.append('flag_send_product', flag_send_product)
        if (shipment_intructions.toString('html') == "<p><br></p>"){
            form.append('shipment_intructions', "")
        } else {
            form.append('shipment_intructions', shipment_intructions.toString('html'))
        }
        form.append('status', 'Publish')
        form.append(`products`, JSON.stringify(productName))
        if (campaign_setting == 3){
            user_id.forEach((value, index) => {
                form.append(`invitations[${index}][type]`, 'user')
                form.append(`invitations[${index}][user_id]`, value)
            })
        } else if (campaign_setting == 4) {
            group_id.forEach((value, index) => {
                form.append(`invitations[${index}][type]`, 'group')
                form.append(`invitations[${index}][group_id]`, value)
            })
        } else {
            form.append('invitations[0][type]', '')
        }
        if (tiktok_task_content.toString('html') == "<p><br></p>"){
            form.append('tiktok_task[content]', "")
        } else {
            form.append('tiktok_task[content]', tiktok_task_content.toString('html'))
        }
        form.append('tiktok_task[mention]', tiktok_task_mention)
        form.append('tiktok_task[hastag]', tiktok_task_hastag)
        if (instagram_task_content.toString('html') == "<p><br></p>"){
            form.append('instagram_task[content]', "")
        } else {
            form.append('instagram_task[content]', instagram_task_content.toString('html'))
        }
        form.append('instagram_task[mention]', instagram_task_mention)
        form.append('instagram_task[hastag]', instagram_task_hastag)
        if (youtube_task_content.toString('html') == "<p><br></p>"){
            form.append('youtube_task[content]', "")
        } else {
            form.append('youtube_task[content]', youtube_task_content.toString('html'))
        }
        form.append('youtube_task[mention]', youtube_task_mention)
        form.append('youtube_task[hastag]', youtube_task_hastag)
        if (facebook_task_content.toString('html') == "<p><br></p>"){
            form.append('facebook_task[content]', "")
        } else {
            form.append('facebook_task[content]', facebook_task_content.toString('html'))
        }
        form.append('facebook_task[mention]', facebook_task_mention)
        form.append('facebook_task[hastag]', facebook_task_hastag)
        updateCampaignApi(id, form)
        .then((repos) => {
            const data = repos.message;
            if(repos.code == 200 || repos.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.assign(`/campaign`)
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    } 
    const onDraft = (data) => {
        const form = new FormData();
        form.append('title', title)
        if (description.toString('html') == "<p><br></p>"){
            form.append('description', "")
        } else {
            form.append('description', description.toString('html'))
        }
        if (thumbnail.file.type == "image/jpeg") {
            form.append('thumbnail', thumbnail.file)
        }
        form.append('point', point)
        form.append('campaign_setting', campaign_setting)
        form.append('registration_start_date', registration_start_date.getFullYear() + "-" + ((registration_start_date.getMonth() > 8) ? (registration_start_date.getMonth() + 1) : ('0' + (registration_start_date.getMonth() + 1))) + "-" + ((registration_start_date.getDate() > 9) ? registration_start_date.getDate() : ('0' + registration_start_date.getDate())))
        form.append('registration_end_date', registration_end_date.getFullYear() + "-" + ((registration_end_date.getMonth() > 8) ? (registration_end_date.getMonth() + 1) : ('0' + (registration_end_date.getMonth() + 1))) + "-" + ((registration_end_date.getDate() > 9) ? registration_end_date.getDate() : ('0' + registration_end_date.getDate())))
        form.append('registration_start_time', ((new Date(registration_start_time).getHours() > 9) ? (new Date(registration_start_time).getHours()) : ('0' + (new Date(registration_start_time).getHours()))) + ":" + ((new Date(registration_start_time).getMinutes() > 9) ? (new Date(registration_start_time).getMinutes()) : ('0' + (new Date(registration_start_time).getMinutes()))))
        form.append('registration_end_time', ((new Date(registration_end_time).getHours() > 9) ? (new Date(registration_end_time).getHours()) : ('0' + (new Date(registration_end_time).getHours()))) + ":" + ((new Date(registration_end_time).getMinutes() > 9) ? (new Date(registration_end_time).getMinutes()) : ('0' + (new Date(registration_end_time).getMinutes()))))
        form.append('campaign_start_date', campaign_start_date.getFullYear() + "-" + ((campaign_start_date.getMonth() > 8) ? (campaign_start_date.getMonth() + 1) : ('0' + (campaign_start_date.getMonth() + 1))) + "-" + ((campaign_start_date.getDate() > 9) ? campaign_start_date.getDate() : ('0' + campaign_start_date.getDate())))
        form.append('campaign_end_date', campaign_end_date.getFullYear() + "-" + ((campaign_end_date.getMonth() > 8) ? (campaign_end_date.getMonth() + 1) : ('0' + (campaign_end_date.getMonth() + 1))) + "-" + ((campaign_end_date.getDate() > 9) ? campaign_end_date.getDate() : ('0' + campaign_end_date.getDate())))
        form.append('campaign_start_time', ((new Date(campaign_start_time).getHours() > 9) ? (new Date(campaign_start_time).getHours()) : ('0' + (new Date(campaign_start_time).getHours()))) + ":" + ((new Date(campaign_start_time).getMinutes() > 9) ? (new Date(campaign_start_time).getMinutes()) : ('0' + (new Date(campaign_start_time).getMinutes()))))
        form.append('campaign_end_time', ((new Date(campaign_end_time).getHours() > 9) ? (new Date(campaign_end_time).getHours()) : ('0' + (new Date(campaign_end_time).getHours()))) + ":" + ((new Date(campaign_end_time).getMinutes() > 9) ? (new Date(campaign_end_time).getMinutes()) : ('0' + (new Date(campaign_end_time).getMinutes()))))
        form.append('flag_send_product', flag_send_product)
        if (shipment_intructions.toString('html') == "<p><br></p>"){
            form.append('shipment_intructions', "")
        } else {
            form.append('shipment_intructions', shipment_intructions.toString('html'))
        }
        form.append('status', 'Draft')
        form.append(`products`, JSON.stringify(productName))
        if (campaign_setting == 3){
            user_id.forEach((value, index) => {
                form.append(`invitations[${index}][type]`, 'user')
                form.append(`invitations[${index}][user_id]`, value)
            })
        } else if (campaign_setting == 4) {
            group_id.forEach((value, index) => {
                form.append(`invitations[${index}][type]`, 'group')
                form.append(`invitations[${index}][group_id]`, value)
            })
        } else {
            form.append('invitations[0][type]', '')
        }
        if (tiktok_task_content.toString('html') == "<p><br></p>"){
            form.append('tiktok_task[content]', "")
        } else {
            form.append('tiktok_task[content]', tiktok_task_content.toString('html'))
        }
        form.append('tiktok_task[mention]', tiktok_task_mention)
        form.append('tiktok_task[hastag]', tiktok_task_hastag)
        if (instagram_task_content.toString('html') == "<p><br></p>"){
            form.append('instagram_task[content]', "")
        } else {
            form.append('instagram_task[content]', instagram_task_content.toString('html'))
        }
        form.append('instagram_task[mention]', instagram_task_mention)
        form.append('instagram_task[hastag]', instagram_task_hastag)
        if (youtube_task_content.toString('html') == "<p><br></p>"){
            form.append('youtube_task[content]', "")
        } else {
            form.append('youtube_task[content]', youtube_task_content.toString('html'))
        }
        form.append('youtube_task[mention]', youtube_task_mention)
        form.append('youtube_task[hastag]', youtube_task_hastag)
        if (facebook_task_content.toString('html') == "<p><br></p>"){
            form.append('facebook_task[content]', "")
        } else {
            form.append('facebook_task[content]', facebook_task_content.toString('html'))
        }
        form.append('facebook_task[mention]', facebook_task_mention)
        form.append('facebook_task[hastag]', facebook_task_hastag)
        updateCampaignApi(id, form)
        .then((repos) => {
            const data = repos.message;
            if(repos.code == 200 || repos.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.assign(`/campaign?Draft`)
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    }
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
           const respProducts = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/products/all`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const optionProducts = respProducts.data.data.map(d => ({
                "value" : d.id,
                "label" : d.product.name+" - "+d.name,
                "name": d.name
            }))
            const respUsers = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=approve`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const optionUsers = respUsers.data.users.rows.map(d => ({
                "value" : d.id,
                "label" : `${d.full_name} (${d.email})`
            }))
            const respGroups = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const optionGroups = respGroups.data.data.rows.map(d => ({
                "value" : d.id,
                "label" : d.name
            }))
            const respCampaign = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const title  = respCampaign.data.data.title;
            setTitle(title);
            if (respCampaign.data.data.description) {
                setDescription(RichTextEditor.createValueFromString(respCampaign.data.data.description, 'html'));
            }
            // const point  = respCampaign.data.data.point;
            setPoint(respCampaign.data.data.point);
            const thumbnail = respCampaign.data.data.thumbnail;
            setThumbnail({imagePreviewUrl: thumbnail, file:{name: thumbnail}});
            const campaign_setting  = respCampaign.data.data.campaign_setting;
            const registration_start_date  = new Date(respCampaign.data.data.registration_start_date);
            setRegistration_start_date(registration_start_date);
            const registration_end_date  = new Date(respCampaign.data.data.registration_end_date);
            setRegistration_end_date(registration_end_date);
            const registration_start_time  = new Date(today+" "+respCampaign.data.data.registration_start_time);
            setRegistration_start_time(registration_start_time);
            const registration_end_time  = new Date(today+" "+respCampaign.data.data.registration_end_time);
            setRegistration_end_time(registration_end_time);
            const campaign_end_date  = new Date(respCampaign.data.data.campaign_end_date);
            setCampaign_end_date(campaign_end_date);
            const campaign_start_date  = new Date(respCampaign.data.data.campaign_start_date);
            setCampaign_start_date(campaign_start_date);
            const campaign_start_time  = new Date(today+" "+respCampaign.data.data.campaign_start_time);
            setCampaign_start_time(campaign_start_time);
            const campaign_end_time  = new Date(today+" "+respCampaign.data.data.campaign_end_time);
            setCampaign_end_time(campaign_end_time);
            if (respCampaign.data.data.shipment_intructions) {
                setShipment_intructions(RichTextEditor.createValueFromString(respCampaign.data.data.shipment_intructions, 'html'));
            }
            if (respCampaign.data.data.instagram_task.content) {
                const instagram_task_content  = respCampaign.data.data.instagram_task.content;
                setInstagram_task_content(RichTextEditor.createValueFromString(instagram_task_content, 'html'));
            }
            const instagram_task_hastag  = respCampaign.data.data.instagram_task.hastag;
            setInstagram_task_hastag(instagram_task_hastag);
            const instagram_task_mention  = respCampaign.data.data.instagram_task.mention;
            setInstagram_task_mention(instagram_task_mention);

            if (respCampaign.data.data.facebook_task.content) {
                const facebook_task_content = respCampaign.data.data.facebook_task.content;
                setFacebook_task_content(RichTextEditor.createValueFromString(facebook_task_content, 'html'));
            }
            const facebook_task_hastag  = respCampaign.data.data.facebook_task.hastag;
            setFacebook_task_hastag(facebook_task_hastag); 
            const facebook_task_mention  = respCampaign.data.data.facebook_task.mention;
            setFacebook_task_mention(facebook_task_mention); 
            if (respCampaign.data.data.tiktok_task.content) {
                const tiktok_task_content = respCampaign.data.data.tiktok_task.content;
                setTiktok_task_content(RichTextEditor.createValueFromString(tiktok_task_content, 'html'));
            } 
            const tiktok_task_hastag  = respCampaign.data.data.tiktok_task.hastag;
            setTiktok_task_hastag(tiktok_task_hastag); 
            const tiktok_task_mention  = respCampaign.data.data.tiktok_task.mention;
            setTiktok_task_mention(tiktok_task_mention);
            if (respCampaign.data.data.youtube_task.content) {
                const youtube_task_content = respCampaign.data.data.youtube_task.content;
                setYoutube_task_content(RichTextEditor.createValueFromString(youtube_task_content, 'html'));
            }
            const youtube_task_mention  = respCampaign.data.data.youtube_task.mention;
            setYoutube_task_mention(youtube_task_mention);
            const youtube_task_hastag  = respCampaign.data.data.youtube_task.hastag;
            setYoutube_task_hastag(youtube_task_hastag);
            const flag_send_product  = respCampaign.data.data.flag_send_product;
            if (campaign_setting == 3){
                const campaign_users = respCampaign.data.data.campaign_users;
                setUser_id( campaign_users.map((value) => (value.user_id)));
            } else if (campaign_setting == 4){
                const campaign_invitations = respCampaign.data.data.campaign_invitations;
                setGroup_id( campaign_invitations.map((value) => (value.group_id)));
            }
            if (flag_send_product == 1){
                const campaign_products = respCampaign.data.data.campaign_products;
                setProducts(campaign_products.map((value) => (value.product_id)));
            }
            setAppState({ loading: false, products: optionProducts, users: optionUsers, groups: optionGroups });
            setFlag_send_product(flag_send_product);
            setCampaign_setting(campaign_setting);
        };
        fetchData();
    }, []);
    let day = 60 * 60 * 24 * 1000;
    return ( 
        <div id="main-content" className="campaign-form">
        {(() => {
            if (appState.products || appState.users || appState.groups){
                return <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="main-dash">
                            <div className="form-add mb-4">
                                <input type="text" {...register("title", {required: "Title Campaign wajib diisi"})} value={title} onChange={(event) => setTitle(event.target.value)} className="form-control" placeholder="Add Title Campaign" />
                                {errors.title && <p className="alert-message">{errors.title.message}</p>}
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="row justify-content-between create">
                                <div className="col-5">
                                    <div className="font-medium mb-3">Description Campaign</div>
                                    <RichTextEditor
                                        {...register("description")} 
                                        value={description}
                                        onChange={setDescription}
                                        toolbarConfig={RTE_TOOLBAR_CONFIG}
                                        toolbarClassName="toolbar"
                                        className="editor-root"
                                    />
                                    <div className="font-medium mb-2 mt-5">Campaign Point</div>
                                    <div style={{maxWidth: '250px', marginBottom: '50px'}}>
                                        
                                    <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            value={point}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            decimalScale={0}
                                            displayType="input"
                                            type="text"
                                            allowNegative={false}
                                            onValueChange={
                                                (value) => {
                                                    setPoint(value.floatValue)
                                                }
                                            }
                                            min="1"
                                            className="form-control no-radius"
                                            
                                            renderText={(value, props) => (
                                                <input type="number" value={value} {...props} {...register("point", {required: "Campaign Point wajib diisi" , min: {value: 1, message: "Point harus lebih dari 0"}})}/>
                                                )}
                                            />
                                        {errors.point && <p className="alert-message">{errors.point.message}</p>}
                                    </div>
                                    <h4 className="grey font-medium mb-3">Registration Form Submission</h4>
                                    <div className="row justify-content-between mb-5">
                                        <div className="col-5">
                                            <div className="font-medium mb-2">Registration Date</div>
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <img src="/img/ico-calendar.svg" className="red img-fluid" width={35} />
                                                </div>
                                                <div>
                                                    <DatePicker format="y-MM-dd"
                                                        className="mt-1" clearIcon={null}
                                                        onChange={date => setRegistration_start_date(date)}
                                                        value={registration_start_date} required
                                                    />
                                                    <div>until</div> 
                                                    <DatePicker format="y-MM-dd" minDate={new Date(registration_start_date.getTime() + day)}
                                                        className="mt-1" clearIcon={null}
                                                        onChange={date => setRegistration_end_date(date)}
                                                        value={registration_end_date} required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="font-medium mb-2">Registration Time</div>
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <img src="/img/ico-clock.svg" className="img-fluid" width={20} />
                                                </div>
                                                <div>
                                                    <PickDate 
                                                        selected={registration_start_time}
                                                        onChange={(e) => {
                                                            setRegistration_start_time(e)
                                                        }}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        popperPlacement="right"
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        required/> 
                                                        <div>until</div> 
                                                    <PickDate 
                                                        selected={registration_end_time}
                                                        onChange={(e) => {
                                                            setRegistration_end_time(e)
                                                        }}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        popperPlacement="right"
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        required/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="grey font-medium mb-3">Campaign Duration</h4>
                                    <div className="row justify-content-between">
                                        <div className="col-5">
                                            <div className="font-medium mb-2">Campaign Date</div>
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <img src="/img/ico-calendar.svg" className="red img-fluid" width={35} />
                                                </div>
                                                <div>
                                                    <DatePicker format="y-MM-dd"
                                                        name="campaign_start_date"
                                                        onChange={date => setCampaign_start_date(date)}
                                                        value={campaign_start_date} className="mb-1" clearIcon={null} required/> 
                                                    <div>until</div> 
                                                    <DatePicker format="y-MM-dd" minDate={new Date(campaign_start_date.getTime() + day)}
                                                        name="campaign_end_date"
                                                        onChange={date => setCampaign_end_date(date)}
                                                        value={campaign_end_date} className="mt-1" clearIcon={null} required/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="font-medium mb-2">Campaign Time</div>
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <img src="/img/ico-clock.svg" className="img-fluid" width={20} />
                                                </div>
                                                <div>
                                                    <PickDate 
                                                        selected={campaign_start_time}
                                                        onChange={(e) => {
                                                            setCampaign_start_time(e)
                                                        }}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        popperPlacement="right"
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        required/> 
                                                        <div>until</div> 
                                                    
                                                        <PickDate 
                                                        selected={campaign_end_time}
                                                        onChange={(e) => {
                                                            setCampaign_end_time(e)
                                                        }}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        popperPlacement="right"
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        required/> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    {(() => {
                                        if (thumbnail != '') {
                                        return <div><div className="box-img-upload">
                                            <img src={thumbnail.imagePreviewUrl} className="img-fluid w-100" />
                                            <div className="upload-btn-wrapper">
                                                <button className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></button>
                                                <input type="file" name="thumbnail" {...register("thumbnail")} onChange={onImageChange} />
                                            </div>
                                        </div>
                                        <div className="font-medium">Gambar Thumbnail</div>
                                        <div className="path">
                                            <div className="mr-2"><img src="/img/ico-folder.svg" className="img-fluid" width={30} /></div>
                                            <div className="grey">{thumbnail.file.name}</div>
                                        </div></div>
                                        } else {
                                            return <div><div className="box-img-upload">
                                            <img src="/img/no-image.jpg" width={100} className="img-fluid w-100" />
                                            <div className="upload-btn-wrapper">
                                                <button className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></button>
                                                <input type="file" name="thumbnail" {...register("thumbnail", {required: "Gambar wajib di upload"})} onChange={onImageChange} />
                                            </div>
                                        </div>
                                        <div className="font-medium">Gambar Thumbnail</div>
                                        <div className="path">
                                            <div className="mr-2"><img src="/img/ico-folder.svg" className="img-fluid" width={30} /></div>
                                            <div className="grey">No Image</div>
                                        </div></div>
                                        }
                                    })()}
                                    {errors.thumbnail && <p className="alert-message alert-thumbnail">{errors.thumbnail.message}</p>}
                                    <h4 className="grey font-medium mb-3">Campaign Setting</h4>
                                    <div className="custom-control custom-radio mb-2">
                                        {(() => {
                                            if (campaign_setting == 1) {
                                                return <input type="radio" id="cr1" onClick={()=>setCampaign_setting(1)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input" defaultChecked/>
                                            } else {
                                                return <input type="radio" id="cr1" onClick={()=>setCampaign_setting(1)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input"/>
                                            }
                                        })()}
                                        <label className="custom-control-label" htmlFor="cr1">All with Approvement</label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                        {(() => {
                                            if (campaign_setting == 2) {
                                                return <input type="radio" id="cr2" onClick={()=>setCampaign_setting(2)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input" defaultChecked/>
                                            } else {
                                                return <input type="radio" id="cr2" onClick={()=>setCampaign_setting(2)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input"/>
                                            }
                                        })()}
                                        <label className="custom-control-label" htmlFor="cr2">All without Approvement</label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                        {(() => {
                                            if (campaign_setting == 3) {
                                                return <input type="radio" id="cr3" onClick={()=>setCampaign_setting(3)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input" defaultChecked/>
                                            } else {
                                                return <input type="radio" id="cr3" onClick={()=>setCampaign_setting(3)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input"/>
                                            }
                                        })()}
                                        <label className="custom-control-label" htmlFor="cr3">Unique by Email</label>
                                    </div>
                                    <div className="custom-control custom-radio mb-3">
                                        {(() => {
                                            if (campaign_setting == 4) {
                                                return <input type="radio" id="cr4" onClick={()=>setCampaign_setting(4)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input" defaultChecked/>
                                            } else {
                                                return <input type="radio" id="cr4" onClick={()=>setCampaign_setting(4)} name="campaign_setting" {...register("campaign_setting", {required: "Campaign setting wajib diisi"})} className="custom-control-input"/>
                                            }
                                        })()}
                                        <label className="custom-control-label" htmlFor="cr4">Added by Group</label>
                                    </div>
                                    <div className="ml-4">
                                    {(() => {
                                    if (campaign_setting == 3) {
                                        return <div className="box-white">
                                            <Select
                                                value={appState.users.filter((obj) => {
                                                    return user_id.includes(obj.value)
                                                })}
                                                components={animatedComponents}
                                                onChange={(e) => onUsersChange(e)}
                                                isMulti
                                                placeholder="pilih user"
                                                options={appState.users}
                                                required
                                            />
                                            <div className=" css-g1d714-ValueContainer mt-4">
                                                {user_id.map((repo,index) => {
                                                    return (
                                                        <div key={index}>
                                                            {(() => {
                                                            if (repo.user != null) {
                                                                return  <div style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                                                    <div className="css-1rhbuit-multiValue">
                                                                        <div className="css-12jo7m5 ">{repo.user ? repo.user.full_name : ''}</div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    } else if (campaign_setting == 4) {
                                        return <div className="box-white">
                                            <Select
                                                value={appState.groups.filter(obj => group_id.includes(obj.value))}
                                                components={animatedComponents}
                                                onChange={onGroupsChange}
                                                isMulti
                                                placeholder="pilih group"
                                                options={appState.groups}
                                                required
                                            />
                                            <div className=" css-g1d714-ValueContainer mt-4">
                                                {group_id.map((repo,index) => {
                                                    return (
                                                        <div key={index}>
                                                            {(() => {
                                                            if (repo.group != null) {
                                                                return  <div style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                                                    <div className="css-1rhbuit-multiValue">
                                                                        <div className="css-12jo7m5 ">{repo.group ? repo.group.name : ''}</div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }
                                    })()}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h4 className="grey font-medium mb-3">Task</h4>
                            <div className="row justify-content-between create">
                                <div className="col-5 mb-3">
                                    <div className="box-task mb-0">
                                        <div className="task-head">
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2"><img src="/img/tiktik-outline.svg" className="img-fluid" width={15} /></div>
                                                <div>
                                                    <h6 className="font-medium red mb-0">Tiktok Tasks</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-switch">
                                            <div className="custom-control custom-switch">
                                                {(() => {
                                                    if (tiktok_task_content.toString('html') != '<p><br></p>' || tiktok_task_hastag != '' || tiktok_task_mention != '') {
                                                        return  <input type="checkbox" className="custom-control-input" id="switch1" defaultChecked/>
                                                    } else {
                                                        return  <input type="checkbox" className="custom-control-input" id="switch1"/>
                                                    }
                                                })()}
                                                <label className="custom-control-label w-100 main-switch" htmlFor="switch1" />
                                                <div className="tab-content-switch">
                                                    <RichTextEditor
                                                        {...register("tiktok_task_content")} 
                                                        value={tiktok_task_content}
                                                        onChange={setTiktok_task_content}
                                                        toolbarConfig={RTE_TOOLBAR_CONFIG}
                                                        toolbarClassName="toolbar"
                                                        className="editor-root"
                                                    />
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mr-2">Mention</div>
                                                                <div><input type="text" {...register("tiktok_task_mention")} className="form-control no-radius" placeholder="for Mention" value={tiktok_task_mention} onChange={(event) => setTiktok_task_mention(event.target.value)}/> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mr-2">Hastag</div>
                                                                <div><input type="text" {...register("tiktok_task_hastag")} className="form-control no-radius" placeholder="for Hastag" value={tiktok_task_hastag} onChange={(event) => setTiktok_task_hastag(event.target.value)}/> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 mb-3">
                                    <div className="box-task mb-0">
                                        <div className="task-head">
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2"><img src="/img/youtube-outline.svg" className="img-fluid" width={15} /></div>
                                                <div>
                                                    <h6 className="font-medium red mb-0">Youtube Tasks</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-switch">
                                            <div className="custom-control custom-switch">
                                            {(() => {
                                                if (youtube_task_content.toString('html') != '<p><br></p>' || youtube_task_hastag != '' || youtube_task_mention != '') {
                                                    return  <input type="checkbox" className="custom-control-input" id="switch2" defaultChecked/>
                                                } else {
                                                    return  <input type="checkbox" className="custom-control-input" id="switch2"/>
                                                }
                                            })()}
                                            <label className="custom-control-label w-100 main-switch" htmlFor="switch2" />
                                            <div className="tab-content-switch">
                                                <RichTextEditor
                                                    {...register("youtube_task_content")} 
                                                    value={youtube_task_content}
                                                    onChange={setYoutube_task_content}
                                                    toolbarConfig={RTE_TOOLBAR_CONFIG}
                                                    toolbarClassName="toolbar"
                                                    className="editor-root"
                                                />
                                                <div className="row">
                                                <div className="col-6">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div className="mr-2">Mention</div>
                                                        <div><input type="text" {...register("youtube_task_mention")} className="form-control no-radius" placeholder="for Mention" value={youtube_task_mention} onChange={(event) => setYoutube_task_mention(event.target.value)}/> </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                    <div className="mr-2">Hastag</div>
                                                    <div><input type="text" {...register("youtube_task_hastag")} className="form-control no-radius" placeholder="for Hastag" value={youtube_task_hastag} onChange={(event) => setYoutube_task_hastag(event.target.value)}/> </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between create">
                                <div className="col-5 mb-3">
                                    <div className="box-task mb-0">
                                        <div className="task-head">
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2"><img src="/img/facebook-outline.svg" className="img-fluid" width={15} /></div>
                                                <div>
                                                    <h6 className="font-medium red mb-0">Facebook Tasks</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-switch">
                                            <div className="custom-control custom-switch">
                                                {(() => {
                                                    if (facebook_task_content.toString('html') != '<p><br></p>' || facebook_task_hastag != '' || facebook_task_mention != '') {
                                                        return  <input type="checkbox" className="custom-control-input" id="switch3" defaultChecked/>
                                                    } else {
                                                        return  <input type="checkbox" className="custom-control-input" id="switch3"/>
                                                    }
                                                })()}
                                                <label className="custom-control-label w-100 main-switch" htmlFor="switch3" />
                                                <div className="tab-content-switch">
                                                    <RichTextEditor
                                                        {...register("facebook_task_content")} 
                                                        value={facebook_task_content}
                                                        onChange={setFacebook_task_content}
                                                        toolbarConfig={RTE_TOOLBAR_CONFIG}
                                                        toolbarClassName="toolbar"
                                                        className="editor-root"
                                                    />
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mr-2">Mention</div>
                                                                <div><input type="text" {...register("facebook_task_mention")} className="form-control no-radius" placeholder="for Mention" value={facebook_task_mention} onChange={(event) => setFacebook_task_mention(event.target.value)}/> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mr-2">Hastag</div>
                                                                <div><input type="text" {...register("facebook_task_hastag")} className="form-control no-radius" placeholder="for Hastag" value={facebook_task_hastag} onChange={(event) => setFacebook_task_hastag(event.target.value)}/> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 mb-3">
                                    <div className="box-task mb-0">
                                        <div className="task-head">
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2"><img src="/img/insta-outline.svg" className="img-fluid" width={15} /></div>
                                                <div>
                                                    <h6 className="font-medium red mb-0">Instagram Tasks</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-switch">
                                            <div className="custom-control custom-switch">
                                                {(() => {
                                                    if (instagram_task_content.toString('html') != '<p><br></p>' || instagram_task_hastag != '' || instagram_task_mention != '') {
                                                        return  <input type="checkbox" className="custom-control-input" id="switch4" defaultChecked/>
                                                    } else {
                                                        return  <input type="checkbox" className="custom-control-input" id="switch4"/>
                                                    }
                                                })()}
                                                <label className="custom-control-label w-100 main-switch" htmlFor="switch4" />
                                                <div className="tab-content-switch">
                                                    <RichTextEditor
                                                        {...register("instagram_task_content")} 
                                                        value={instagram_task_content}
                                                        onChange={setInstagram_task_content}
                                                        toolbarConfig={RTE_TOOLBAR_CONFIG}
                                                        toolbarClassName="toolbar"
                                                        className="editor-root"
                                                    />
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mr-2">Mention</div>
                                                                <div><input type="text" {...register("instagram_task_mention")} className="form-control no-radius" placeholder="for Mention" value={instagram_task_mention} onChange={(event) => setInstagram_task_mention(event.target.value)} /> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mr-2">Hastag</div>
                                                                <div><input type="text" {...register("instagram_task_hastag")} className="form-control no-radius" placeholder="for Hastag" value={instagram_task_hastag} onChange={(event) => setInstagram_task_hastag(event.target.value)}/> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h4 className="grey font-medium mb-3">Send Product</h4>
                            <div className="row justify-content-between create">
                                <div className="col-5">
                                    <div className="mb-4">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            {(() => {
                                                if (flag_send_product == 1) {
                                                    return <input type="radio" id="sp1" name="flag_send_product" onClick={()=>setFlag_send_product(1)} {...register("flag_send_product", {required: "Send product wajib diisi"})} className="custom-control-input" defaultChecked/>
                                                } else {
                                                    return <input type="radio" id="sp1" name="flag_send_product" onClick={()=>setFlag_send_product(1)} {...register("flag_send_product", {required: "Send product wajib diisi"})} className="custom-control-input"/>
                                                }
                                            })()}
                                            <label className="custom-control-label" htmlFor="sp1">Send</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            {(() => {
                                                if (flag_send_product == 0) {
                                                    return <input type="radio" id="sp2" name="flag_send_product" onClick={()=>setFlag_send_product(0)} {...register("flag_send_product", {required: "Send product wajib diisi"})} className="custom-control-input" defaultChecked/>
                                                } else {
                                                    return <input type="radio" id="sp2" name="flag_send_product" onClick={()=>setFlag_send_product(0)} {...register("flag_send_product", {required: "Send product wajib diisi"})} className="custom-control-input"/>
                                                }
                                            })()}
                                            <label className="custom-control-label" htmlFor="sp2">No</label>
                                        </div>
                                    </div>
                                    {(() => {
                                    if (flag_send_product == 1) {
                                        return <div className="box-white">
                                            <Select
                                                value={appState.products.filter((obj) => {
                                                    return products.includes(obj.value)
                                                })}
                                                components={animatedComponents}
                                                onChange={(e) => onProductsChange(e)}
                                                isMulti
                                                placeholder="pilih produk"
                                                options={appState.products}
                                                required
                                            />
                                            <div className=" css-g1d714-ValueContainer mt-4">
                                                {products.map((repo,index) => {
                                                    return (
                                                        <div key={index}>
                                                            {(() => {
                                                            if (repo.product != null) {
                                                                return  <div style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                                                    <div className="css-1rhbuit-multiValue">
                                                                        <div className="css-12jo7m5 ">{repo.product ? repo.product.name : ''}</div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                    </div>
                                    } else {
                                        return <div></div>
                                    }
                                    })()}
                                </div>
                                <div className="col-5">
                                    <div className="font-medium mb-3">Shipment Address Instructions</div>
                                    <RichTextEditor
                                        {...register("shipment_intructions")} 
                                        value={shipment_intructions}
                                        onChange={setShipment_intructions}
                                        toolbarConfig={RTE_TOOLBAR_CONFIG}
                                        toolbarClassName="toolbar"
                                        className="editor-root"
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <button onClick={handleSubmit(onDraft)} className="btn btn-border font-red mw-150 mr5">Save as Draft</button>
                                <button onClick={handleSubmit(onPublish)} className="btn btn-primary mw-150">Publish</button>
                            </div>
                        </div>
                    </form>
            </div>
            } else {
                return <div className="content-wrapper">
                    <div className="credential pt-5">
                        <div className="credential-box-noborder">
                        <div className="load-2">
                            <p>Loading... Please wait</p>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        </div>
                    </div>
                </div>
            }
        })()}
        </div>
    );
}


export default EditCampaign;
