import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Spin } from 'antd';

function CustomDropdown(props) {
    const {title, icon, data, onClick, active} = props
    const [activeText, setActiveText]  = useState('')

  useEffect(() => {
    if (data!==null && data.length>0){
      let tempData = data.filter((raw) => {
        if(active===raw.value)
          return true
        return false
      });
      if (tempData.length>0) {
        setActiveText(tempData[0].text)
      } else {
        setActiveText("")
      }
    }
  }, [])

    if (data===null || typeof(data)==="undefined") {
      return (<div className="filterSpinner"><Spin /></div>)
    }

    function generateList(){
      let content = [];
      for (let item of data) {
        content.push(<li className={`tool-select-item ${active === item.value ? "active" : ""}`} onClick={(e) => handleClick(e, item.value)} key={item.value} style={{"cursor": "pointer"}}>{item.text}</li>);
      }
      return content;
    }

    function handleClick(e, value){
      setActiveText(value==="" ? title : e.target.innerText)
      onClick(value)
    }

    function disabledEvent(e){
      e.preventDefault()
      return false
    }
    
    return (
      <div className="toolicon-wrapper status sm">
        <button onClick={(e)=>disabledEvent(e)} className={`toolicon status ${icon}` }>
          <span className={`value-showed-text`}>
            {activeText!=="" ? activeText : title}
          </span>
        </button>
        <div className={"tool-inner "+ icon +" left"} style={{"maxWidth": "250px"}}>
            <div className="tool-wrapper">
              <ul className="tool-select">
              <li className={`tool-select-item ${active === null || active === "" ? "active" : ""}`} onClick={(e) => handleClick(e, "")} key={0} style={{"cursor": "pointer"}}>{title}</li>
                {generateList()}
              </ul>
            </div>
        </div>
      </div>
    )
}

CustomDropdown.defaultProps = {
  icon: "",
  active: "",
  data: [],
  onClick: (value) => {console.log(`element value ${value} clicked`)}
}

CustomDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  active: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
};
  
export default CustomDropdown;