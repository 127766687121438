import React,{ useState } from 'react';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import { setStatusUserApi } from "../../api/goddess-gang.api";
import QuestionerAnswers from './QuestionerAnswers';
import SortArrow from '../../component/Common/sort-arrow';

const List = (props) => {
    const { data, total, set_page, current_page, per_page, formatFollower, sort, setSort, orderby, setOrderby } = props;
    const [appState, setAppState]  = useState({
        dataState:data,
        disabled: false
    });
    const [answersModal, setAnswersModal] = useState();
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    const updateSorting = (value) => {
      setSort(value.sort)
      setOrderby(value.orderBy)
    };
    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            appState.dataState[index].isChecked= event.target.checked
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState });
    }
    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].id)
    
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
        setAppState({dataState:appState.dataState });
    
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    function approveUser(e){
        setAppState({disabled: true, dataState:appState.dataState});
        let bodyRequest = []
        const {id} = appState.dataState[e]
        console.log(id)
        let values = {
            status: "approve"
        }
        bodyRequest.push(values)
        setStatusUserApi(id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    function declineUser(e){
        setAppState({disabled: true, dataState:appState.dataState});
        let bodyRequest = []
        const {id} = appState.dataState[e]
        console.log(id)
        let values = {
            status: "reject"
        }
        bodyRequest.push(values)
        setStatusUserApi(id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data user registered, sorry</p>;
   
    const page_count = total / per_page;
    return (
        <div className="content-wrapper">
            <div className="head-data-info">
                <div className="tablerow-1">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} />
                        <label className="custom-control-label" htmlFor="customCheck1" />
                    </div>
                </div>
                <div className="tablerow-2">User
                    <SortArrow
                    valueSort={sort}
                    valueOrderBy={"full_name"}
                    activeOrderBy={orderby}
                    onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="tablerow-3">
                    <div className="d-flex align-items-end justify-content-center">
                        <div><img src="/img/insta-outline.svg" className="sosmed-icon" /></div>
                        <div>Username</div>
                    </div>
                </div>
                <div className="tablerow-4">Followers
                    <SortArrow
                    valueSort={sort}
                    valueOrderBy={"follower_instagram"}
                    activeOrderBy={orderby}
                    onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="tablerow-5">ER
                    <SortArrow
                    valueSort={sort}
                    valueOrderBy={"er_instagram"}
                    activeOrderBy={orderby}
                    onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="tablerow-6">Reach</div>
                <div className="tablerow-7">
                    <div className="d-flex align-items-end justify-content-center">
                        <div><img src="/img/tiktik-outline.svg" className="sosmed-icon" /></div>
                        <div>Username</div>
                    </div>
                </div>
                <div className="tablerow-8">Followers
                    <SortArrow
                    valueSort={sort}
                    valueOrderBy={"follower_tiktok"}
                    activeOrderBy={orderby}
                    onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="tablerow-9">ER
                    <SortArrow
                    valueSort={sort}
                    valueOrderBy={"er_tiktok"}
                    activeOrderBy={orderby}
                    onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="tablerow-10">Reach</div>
                <div className="tablerow-11">Social Media</div>
                <div className="tablerow-12" title="Register Season">Reg. Ses</div>
                <div className="tablerow-12" style={{width: '5%'}}>
                    <img src="/img/ico-check-red.svg" alt=""/>
                </div>
                <div className="tablerow-13">Status</div>
            </div>
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="data-items" key={index}>
                        <div className="di-01">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)} />
                                <label className="custom-control-label" htmlFor={index} />
                            </div>
                        </div>
                        <div className="di-02">
                            <a href={`/mission-registered/${repo.id}/detail`} className="link-user">
                                <div className="box-user">
                                    <div className="img-part">
                                        <img src={repo.photo} className="img-user img-fluid" alt="user" />
                                    </div>
                                    <div className="user-part">
                                        <h5>
                                            {repo.full_name}
                                            {(() => {
                                            if (repo.is_potential == true) {
                                                return  <span className="tool-tip floating">
                                                        <button className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></button>
                                                        <div className="tool-tip-content right">
                                                            <span>Influencer Potential</span>
                                                        </div>
                                                    </span>
                                                } else {
                                                    return <span></span>
                                                }
                                            })()}
                                        </h5>
                                        <p>{new Date(repo.birth_date).getDate()+ " " + monthNames[new Date(repo.birth_date).getMonth()]+ " "+ new Date(repo.birth_date).getFullYear()}</p>
                                        <p className="for-mail">{repo.email}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="di-03"><p className="for-username">{repo.username_instagram == null ? "-" : "@"+repo.username_instagram}</p></div>
                        <div className="di-04">{repo.follower_instagram == null ? "-" : formatFollower(repo.follower_instagram)}</div>
                        <div className="di-05">{repo.er_instagram == null ? "-" : formatFollower(repo.er_instagram)}</div>
                        <div className="di-06">{repo.reach_instagram == null ? "-" : formatFollower(repo.reach_instagram)}</div>
                        <div className="di-07"><p className="for-username">{repo.username_tiktok == null ? "-" : "@"+repo.username_tiktok}</p></div>
                        <div className="di-08">{repo.follower_tiktok == null ? "-" : formatFollower(repo.follower_tiktok)}</div>
                        <div className="di-09">{repo.er_tiktok == null ? "-" : formatFollower(repo.er_tiktok)}</div>
                        <div className="di-10">{repo.reach_tiktok == null ? "-" : formatFollower(repo.reach_tiktok)}</div>
                        <div className="di-11">
                            {repo.social_medias.map((item, index) => {
                                return (
                                    <span key={index}>
                                    {(() => {
                                        if (item.user_social_medias.value != "") {
                                            return <a href={item.user_social_medias.value} target="_blank" className="icon-sm"><img src={item.logo} className="user-ico-sm" /></a>
                                        } else {
                                            return <span></span>
                                        }
                                    })()}
                                    </span>
                                );
                            })}
                        </div>
                        <div className="di-12">{repo.goddes_gang_session}X</div>
                        <div className="di-12" style={{width: '5%'}}>
                            <span style={{fontSize: 24, color: '#953232'}}>
                            {repo.total_questioner_correct_answer}
                            </span>
                            <span style={{fontSize: 16, color: '#000000'}}>/{repo.total_questioner_answer}</span>
                        </div>
                        <div className="di-13">
                            <button className="btn btn-transparent btn-block approve" onClick={(e)=>approveUser(index)} disabled={appState.disabled}><img src="/img/ico-approve.svg" /> Approve</button>
                            <button className="btn btn-transparent btn-block decline" onClick={(e)=>declineUser(index)} disabled={appState.disabled}><img src="/img/ico-decline.svg" /> Reject</button>
                            <button className="btn btn-transparent btn-block see-data" onClick={(e)=> setAnswersModal(repo.id)} disabled={appState.disabled}><img src="/img/ico-file.svg" style={{height: 18}} />See Data</button>
                        </div>
                    </div>
                );
            })}
            <div className="d-flex justify-content-center mt-5">
                {(() => {
                if (total < 5) {
                    return <ReactPaginate
                        pageCount={1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                } else {
                    return <ReactPaginate
                        pageCount={Math.ceil(page_count)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                }
                })()}
            </div>
            {!!answersModal && (
                <QuestionerAnswers
                    userId={answersModal}
                    onHide={() => setAnswersModal(undefined)}
                    />
            )}
        </div>
    );
}

export default List;
