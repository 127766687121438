import React from "react";
import {Button, Drawer, Input, Table, Tabs} from "antd";
import StandardAlertModal from "../../component/standard-alert.modal";
import {getRedeemRewardApi, updateRedeemRewardApi} from "../../api/redeem-reward.api";
import RedeemRewardDetailComponent from "./redeem-reward-detail.component";
import {setRedeemRewardData} from "../../ui-util/redeem-reward-export-util";
import ExportRedeemRewardListToExcel from "./export-redeem-reward.component";

const {TabPane} = Tabs;
const {Search} = Input;

class RedeemRewardComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Nama', dataIndex: 'user', key: 'name', render: (user) => !!user && user.full_name},
                {title: 'Email', dataIndex: 'user', key: 'email', render: (user) => !!user && user.email},
                {title: 'Alamat', key: 'address', render: record => this.renderAddressRow(record)},
                {title: 'No. Hp', dataIndex: 'user', key: 'phoneNumber', render: (user) => !!user && user.reward_phone},
                {title: 'Reward', dataIndex: 'reward', key: 'reward', render: (reward) => !!reward && reward.name},
                {title: 'Action', key: 'action', render: (record) => this.renderActionRow(record)}
            ],
            tabList: [
                {tab: 'All', key: ''},
                {tab: 'Submitted', key: 'SUBMITTED'},
                {tab: 'Processing', key: 'PROCESSING'},
                {tab: 'Done', key: 'DONE'},
                {tab: 'Rejected', key: 'REJECTED'},
            ],
            redeemRewardModalVisibility: false,
            redeemRewardDrawerVisibility: false,
            redeemRewardList: [],
            redeemRewardDetail: {},
            modalSlug: '',
            activeTab: '',
            totalItems: 0,
            currentPage: 1,
            selectedRowKeys: [],
            selectedRow: [],
            loading: false,
            searchKey: '',
            pageSize: 10
        }
    }

    componentDidMount(): void {
        const {activeTab} = this.state
        this.getDataList(activeTab, 1)
    }

    getDataList = (activeTab, currentPage, table) => {
        const {pageSize} = this.state
        let params = {
            size: pageSize,
            page: currentPage - 1
        }
        getRedeemRewardApi(activeTab, params, table)
            .then(response => {
                this.setState({redeemRewardList: response.result.datas, totalItems: response.result.totalItems})
            }).catch(err => {
            console.log(err)
        })
    }

    renderAddressRow = (record) => {
        return (
            <div>
                {!!record.address && record.address + ', '}
                {!!record.city && record.city.name + ', ' + record.city.province.name + ', ' + record.city.postal_code}
            </div>
        )
    }

    renderActionRow = (record) => {
        const {activeTab} = this.state
        return (
            <>
                {
                    activeTab === 'SUBMITTED' &&
                    <>
                        <Button htmlType="submit" style={{marginRight: 10}}
                                onClick={() => this.changeVisibility('redeemRewardModalVisibility', true, record, 'Approve')}>Approve</Button>
                        <Button htmlType="submit" style={{marginRight: 10}}
                                onClick={() => this.changeVisibility('redeemRewardModalVisibility', true, record, 'Reject')}>Reject</Button>
                    </>
                }
                <Button htmlType="submit"
                        onClick={() => this.changeVisibility('redeemRewardDrawerVisibility', true, record)}>Detail</Button>
            </>
        )
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setRedeemRewardData(rows)
        this.setState({selectedRow, selectedRowKeys});
    }

    onOkButtonHandler = () => {
        this.setState({loading: true})
        const {modalSlug, redeemRewardDetail} = this.state
        const statusRedeemReward = modalSlug === 'Approve' ? 'PROCESSING' : 'REJECTED'
        const alertMessage = modalSlug === 'Approve' ? 'Redeem reward processing' : 'Redeem reward rejected'
        updateRedeemRewardApi(redeemRewardDetail.id, statusRedeemReward)
            .then(response => {
                this.setState({loading: false})
                this.changeVisibility('redeemRewardModalVisibility', false)
                alert(alertMessage)
                this.setState({activeTab: statusRedeemReward})
                this.getDataList(statusRedeemReward, 1, 'status')
            }).catch(err => {
            console.log(err)
        })
    }

    changeVisibility = (key, value, redeemRewardDetail, modalSlug) => {
        this.setState({[key]: value, redeemRewardDetail, modalSlug})
    }

    onTabChange = (tab) => {
        this.setState({activeTab: tab})
        this.getDataList(tab, 1, 'status')
    }

    onChangePagination = (current) => {
        const {activeTab, searchKey} = this.state
        this.setState({currentPage: current})
        if (searchKey.length > 0) {
            this.getDataList(searchKey, current, 'name')
        } else {
            this.getDataList(activeTab, current, 'status')
        }

    };

    exportButtonHandler = () => {
        const {selectedRow, activeTab} = this.state

        if(activeTab === 'PROCESSING'){
            selectedRow.forEach(redeemReward => {
                updateRedeemRewardApi(redeemReward.id, 'DONE')
                    .then(response => {
                    }).catch(err => {
                    console.log(err)
                })
            })
            setTimeout(() => {
                this.setState({activeTab: 'DONE'})
                this.getDataList('DONE', 1, 'status')
            }, 1000);
        }
    }

    searchHandler = (value) => {
        this.setState({searchKey: value})
        this.getDataList(value, 1, 'email')
    }

    onSizeChange = (currentPage, pageSize) => {
        const {activeTab} = this.state
        this.setState({currentPage, pageSize})
        setTimeout(() => {
            this.getDataList(activeTab, 1)
        }, 1000);

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns, selectedRowKeys, pageSize, totalItems, currentPage, redeemRewardList, redeemRewardModalVisibility, modalSlug, tabList, activeTab, redeemRewardDrawerVisibility, redeemRewardDetail, selectedRow, loading} = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <>
                <Tabs
                    activeKey={activeTab}
                    onChange={this.onTabChange}
                >
                    {
                        tabList.map(item => (
                            <TabPane tab={item.tab} key={item.key}>
                                <div align="right" style={{marginBottom: 20}}>
                                    {item.key === '' &&
                                    <Search placeholder="Search by email"
                                            onSearch={(value) => this.searchHandler(value)}
                                            style={{width: 250}} enterButton="Search"/>
                                    }
                                    {
                                        item.key === '' | item.key === 'PROCESSING' ?
                                            <ExportRedeemRewardListToExcel
                                                redeemRewardList={selectedRow}
                                                element={<Button style={{marginLeft: 20}}
                                                                 disabled={selectedRow.length < 1}
                                                                 onClick={() => this.exportButtonHandler()}>Export</Button>}/> : ''
                                    }
                                </div>
                                <Table
                                    rowSelection={activeTab === 'PROCESSING' | activeTab === '' && rowSelection}
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={redeemRewardList}
                                    rowKey={"id"}
                                    pagination={{
                                        pageSize: pageSize,
                                        total: totalItems,
                                        pageSizeOptions: ['25', '50', '100'],
                                        onChange: this.onChangePagination,
                                        current: currentPage,
                                        onShowSizeChange: this.onSizeChange,
                                    }}
                                />
                            </TabPane>
                        ))
                    }
                </Tabs>
                <Drawer
                    title={`Redeem Reward Detail`}
                    width={520}
                    visible={redeemRewardDrawerVisibility}
                    closable={true}
                    onClose={() => this.setState({redeemRewardDrawerVisibility: false})}
                >
                    <RedeemRewardDetailComponent redeemRewardDetail={redeemRewardDetail}/>
                </Drawer>
                <StandardAlertModal
                    loading={loading}
                    onOkButtonHandler={this.onOkButtonHandler}
                    skinConditionKey={"redeemRewardModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    modalVisibility={redeemRewardModalVisibility}
                    title={`${modalSlug} Redeem Reward`}
                    bodyMessage={modalSlug === 'Approve' ? 'Apakah anda akan memproses redeem reward ini?' : 'Apakah anda akan menolak redeem reward ini?'}
                />
            </>
        )
    }
}

export default RedeemRewardComponent
