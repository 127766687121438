import React from "react";
import {deleteBannerApi, getBannerListApi, updateBannerApi} from "../../api/banner.api";
import StandardAlertModal from "../../component/standard-alert.modal";
import EditFormModal from "../../component/edit-form.modal";
import BannerFormComponent from "../create-banner/banner-form.component";
import {Button, Image, Table} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";

class BannerListComponent extends React.Component {

    constructor() {
        super();

        this.state = {
            columns: [
                {dataIndex: 'valid', key: 'valid', render: (valid) => valid && <CheckCircleOutlined key="check"/>},
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => <Image src={image} width={100} height={100}/>
                },
                {title: 'Title', dataIndex: 'title', key: 'title'},
                {title: 'Url', dataIndex: 'url', key: 'url'},
                {
                    title: 'Action', key: 'edit',
                    render: (record) =>
                        <>
                            <Button style={{marginRight: 10}}
                                    onClick={() => this.editButtonHandler(record)}>Edit</Button>
                            <Button
                                onClick={() => this.changeVisibility('deleteBannerModalVisibility', true, record)}>Delete</Button>
                        </>
                }
            ],
            bannerList: [],
            editBannerModalVisibility: false,
            deleteBannerModalVisibility: false,
            bannerDetail: {},
            loading: false,
            loadingDelete: false,
            switchValue: true
        }

    }

    componentDidMount(): void {
        this.getBannerListData()
    }

    getBannerListData = () => {
        getBannerListApi()
            .then(result => {
                this.setState({bannerList: result.banner})
            }).catch(err => {
            console.log(err)
        })
    }

    changeVisibility = (key, value, bannerDetail) => {
        this.setState({[key]: value, bannerDetail})
    }

    editButtonHandler = (bannerDetail) => {
        this.changeVisibility('editBannerModalVisibility', true)
        this.setState({switchValue: bannerDetail.valid, bannerDetail})
    }

    onOkButtonHandler = () => {
        this.setState({loadingDelete: true})
        const {id} = this.state.bannerDetail
        deleteBannerApi(id)
            .then(() => {
                this.getBannerListData()
                this.setState({loadingDelete: false})
                this.changeVisibility('deleteBannerModalVisibility', false)
            }).catch(err => {
            this.setState({loadingDelete: false})
            this.changeVisibility('deleteBannerModalVisibility', false)
            console.log(err)
        })
    }

    onFinishEditBanner = (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const {switchValue, bannerDetail} = this.state
            const valid = switchValue ? 1 : 0
            const form = new FormData();
            const url = !!values.url ? values.url : null
            form.append('image', fileImage[0])
            form.append('title', values.title)
            form.append('url', url)
            form.append('valid', valid)
            updateBannerApi(bannerDetail.id, form)
                .then(() => {
                    this.getBannerListData()
                    this.setState({loading: false, editBannerModalVisibility: false})
                    alert('Banner berhasil di update')
                }).catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
        }
    }

    onChangeSwitchValue = (value) => {
        this.setState({switchValue: value})
    }

    render() {
        const {bannerList, editBannerModalVisibility, deleteBannerModalVisibility, bannerDetail, loadingDelete, switchValue, loading, columns} = this.state
        return (
            <div>
                <div style={{marginLeft: 50}}>
                    <Table
                        className="components-table-demo-nested"
                        columns={columns}
                        dataSource={bannerList}
                        pagination={false}
                        rowKey={'id'}
                    />
                </div>
                <EditFormModal
                    modalVisibility={editBannerModalVisibility}
                    changeVisibility={this.changeVisibility}
                    modalVisibilityKey={"editBannerModalVisibility"}
                    title={"Edit Banner"}
                    content={
                        <BannerFormComponent
                            bannerDetail={bannerDetail}
                            onFinish={this.onFinishEditBanner}
                            switchValue={switchValue}
                            onChange={this.onChangeSwitchValue}
                            loading={loading}
                            labelCol={4}
                            wrapperCol={20}
                        />
                    }
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onOkButtonHandler}
                    changeVisibility={this.changeVisibility}
                    skinConditionKey={"deleteBannerModalVisibility"}
                    modalVisibility={deleteBannerModalVisibility}
                    title={"Delete banner"}
                    bodyMessage={"Apakah anda yakin ingin menghapus banner ini?"}
                />
            </div>
        );
    }
}

export default BannerListComponent
