import React from "react";
import {getDetailInterestRecommendationApi, updateInterestRecommendationApi} from "../../api/interest.api";
import {Button, Form, Image, Input, Select} from "antd";
import _ from "lodash";
import {getProductListApi} from "../../api/product-list.api";
import StandardAlertModal from "../../component/standard-alert.modal";

const {Option} = Select;

class InterestRecommendationUpdatePage extends React.Component {

    constructor() {
        super();

        this.state = {
            interestRecommendationDetail: {},
            productList: [],
            standardAlertModalVisibility: false,
            loading: false
        }
    }

    componentDidMount() {
        this.getProductList()
        this.getInterestRecommendationData()
    }

    getProductList = () => {
        getProductListApi()
            .then(response => {
                this.setState({productList: response.data})
            })
            .catch(err => {
                console.log(err)
            })
    }

    getInterestRecommendationData = () => {
        const {id} = this.props.match.params
        getDetailInterestRecommendationApi(id)
            .then(response => {
                this.setState({interestRecommendationDetail: response.data})
            })
            .catch(err => console.log(err))
    }

    handleChangeProductRecommendation = (key, item) => {
        let {interestRecommendationDetail} = this.state
        interestRecommendationDetail.recomendation = item
        this.setState({interestRecommendationDetail})
    }

    onFinish = () => {
        let {id, recomendation} = this.state.interestRecommendationDetail
        if (recomendation.length > 0) {
            let bodyRequest = []
            recomendation.map(item => {
                let data = {
                    product_id: Number(item.key),
                    product_slug: item.value,
                    product_name: item.children
                }
                bodyRequest.push(data)
            })
            if (!!bodyRequest[0].product_slug) {
                this.setState({loading: true})
                updateInterestRecommendationApi(id, bodyRequest)
                    .then(response => {
                        this.setState({loading: false})
                        alert('berhasil update interest recommendation')
                        window.location.href = '/interest/recommendation'
                    })
                    .catch(err => {
                        this.setState({loading: false})
                        console.log(err)
                    })
            } else {
                this.changeVisibilityStandardAlert('standardAlertModalVisibility', true)
            }
        } else {
            alert('Anda belum memasukkan interest rekomendasi!')
        }
    }

    onOkStandardAlert = () => window.location.href = '/recommendation/product-recommendation'

    changeVisibilityStandardAlert = (key, value) => this.setState({[key]: value})

    render() {
        const {interestRecommendationDetail, productList, standardAlertModalVisibility, loading} = this.state
        return (
            <>
                <Form className="form-group" labelCol={{span: 4}}
                      wrapperCol={{span: 10}}>
                    <Form.Item
                        key={'image'}
                        label={'Image'}
                    >
                        <Image
                            src={interestRecommendationDetail.image}
                            width={100}
                        />
                    </Form.Item>
                    <Form.Item
                        key={'name'}
                        label={'Name'}
                    >
                        <Input value={interestRecommendationDetail.name} disabled/>
                    </Form.Item>
                    <Form.Item
                        key={'recommendation'}
                        label={'Product Recommendation'}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Please select"
                            style={{width: '100%'}}
                            value={_.map(interestRecommendationDetail.recomendation, e => e.product_slug || e.value)}
                            onChange={this.handleChangeProductRecommendation}
                        >
                            {
                                productList.map((item) => (
                                    <Option key={item.id} value={item.slug}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}>
                        <Button type="primary" onClick={() => this.onFinish()} loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <StandardAlertModal
                    onOkButtonHandler={this.onOkStandardAlert}
                    changeVisibility={this.changeVisibilityStandardAlert}
                    skinConditionKey={"standardAlertModalVisibility"}
                    modalVisibility={standardAlertModalVisibility}
                    title={"Anda belum melakukan perubahan data!"}
                    bodyMessage={"Apakah anda ingin kembali ke halaman interest rekomendasi?"}
                />
            </>
        )
    }
}

export default InterestRecommendationUpdatePage