import React from "react";
import {Button, Form, Image, Input, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {isEqual} from "../../ui-util/general-variable";

const {TextArea} = Input;

class ReferFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            fileImageList: []
        }
    }

    componentDidMount(): void {
        const {referFriend, slug} = this.props
        if(slug === 'edit'){
            this.formRef.current.setFieldsValue(referFriend)
        } else {
            this.formRef.current.setFieldsValue({})
        }
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {slug, referFriend} = this.props
        if (!isEqual(slug, prevProps.slug)) {
            if(slug === 'edit'){
                this.formRef.current.setFieldsValue(referFriend)
            } else {
                let referFriendEmpty = {
                    title: '',
                    description: '',
                    image: ''
                }
                this.formRef.current.setFieldsValue(referFriendEmpty)
            }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {fileImageList} = this.state
        const {onFinish, referFriend, loading} = this.props
        return (
            <Form ref={this.formRef} onFinish={(values) => onFinish(values, fileImageList)} labelCol={{span: 6}}
                  wrapperCol={{span: 20}}>
                <Form.Item
                    key='title'
                    label='Title'
                    name='title'
                    rules={[{
                        required: true,
                        message: 'Please input title',
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    key='description'
                    label='Description'
                    name='description'
                    rules={[{
                        required: true,
                        message: 'Please input description',
                    }]}
                >
                    <TextArea/>
                </Form.Item>
                <Form.Item
                    key='image'
                    label='Image'
                    name='image'
                    rules={[{
                        required: true,
                        message: 'Please input image',
                    }]}
                >
                    <Upload
                        fileList={fileImageList}
                        onRemove={this.removeButtonHandler}
                        beforeUpload={this.beforeUploadImage}
                    >
                        {
                            !!referFriend &&
                            <Image
                                src={referFriend.image}
                                width={100}
                            />
                        }
                        <div style={{marginTop: 10}}>
                            <Button icon={<UploadOutlined/>}>
                                Upload
                            </Button>
                            <p style={{marginTop: 10}}>Ukuran gambar maksimal 2MB</p>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default ReferFormComponent
