import React from "react";
import {Switch} from "react-router-dom";
import Route from "./Route";
import LoginPages from "../pages/login";
import RegisterPage from "../pages/register";
import ForgotPasswordPage from "../pages/forgot-password";
import BannerListPage from "../pages/banner";
import CreateBannerPage from "../pages/create-banner";
import ChangePasswordPage from "../pages/change-password";
import SkinConditionPage from "../pages/skin-condition";
import PointsettingPage from "../pages/point-setting";
import RewardSettingPage from "../pages/reward-setting";
import RedeemRewardPage from "../pages/redeem-reward";
import LevelSettingPage from "../pages/level-setting";
import ProductRecommendationPage from "../pages/product-recommendation";
import UserListPage from "../pages/user";
import CreateRewardPage from "../pages/create-reward";
import CreatePointPage from "../pages/create-point";
import CreateLevelPage from "../pages/create-level";
import CreateProductRecommendationPage from "../pages/create-product-recommendation";
import RecommendationUpdatePage from "../pages/product-recommendation-update";
import CreateNotificationPage from "../pages/create-notification";
import NotificationPage from "../pages/notification";
import ReferFriendPage from "../pages/refer-friend";
import InterestPage from "../pages/interest";
import CreateInterestPage from "../pages/create-interest";
import InterestCategoryPage from "../pages/interest-category";
import CreateInterestCategoryPage from "../pages/create-interest-category";
import BeautyProfilePage from "../pages/beauty-profile";
import InterestRecommendationPage from "../pages/interest-recommendation";
import InterestRecommendationUpdatePage from "../pages/interest-recommendation-update";
// import UserExportPage from "../pages/user-export";
import MissionRegistered from "../pages/mission-registered";
import DetailMissionRegistered from "../pages/mission-registered/detail";
import MissionRegisteredRejected from "../pages/mission-registered/rejected";
import GoddessGang from "../pages/goddess-gang";
import DetailGoddessGang from "../pages/goddess-gang/detail";
import ContentDetailGoddessGang from "../pages/goddess-gang/content-detail";
import GoddessGangGroup from "../pages/goddess-gang/group";
import SettingBanner from "../pages/setting-banner";
import Campaign from "../pages/campaign";
import DetailGoddessGangGroup from "../pages/goddess-gang/group/detail";
import CreateCampaign from "../pages/campaign/create";
import ApproveCampaign from "../pages/campaign/approvement-campaign";
import DetailCampaign from "../pages/campaign/detail";
import CreateGoddessGangGroup from "../pages/goddess-gang/group/create";
import Approvement from "../pages/campaign/approvement";
import RedeemPoint from "../pages/redeem-point";
import EditCampaign from "../pages/campaign/edit";
import DetailRedeemPoint from "../pages/redeem-point/detail";
import AnalyticsGoddessGang from "../pages/goddess-gang/analytics";
import CreateQuestionerForm from "../pages/create-questioner-form";
import CreateTimeline from "../pages/setting-create-timeline";
import TrackingPage from "../pages/tracking";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={LoginPages}/>
            <Route path="/register" exact component={RegisterPage}/>
            <Route path="/forgot-password" exact component={ForgotPasswordPage}/>

            <Route path="/banners/" exact component={BannerListPage} isPrivate/>
            <Route path="/banners/create" exact component={CreateBannerPage} isPrivate/>
            <Route path="/banners/refer" exact component={ReferFriendPage} isPrivate/>

            <Route path="/admin/change-password" exact component={ChangePasswordPage} isPrivate/>
            <Route path="/admin/register" exact component={RegisterPage} isPrivate/>

            <Route path="/recommendation/create" exact component={SkinConditionPage} isPrivate/>
            <Route path="/recommendation/create-product-recommendation" exact
                   component={CreateProductRecommendationPage} isPrivate/>
            <Route path="/recommendation/product-recommendation" exact component={ProductRecommendationPage} isPrivate/>
            <Route path="/recommendation/edit/:skinType/:id" exact component={RecommendationUpdatePage} isPrivate/>
            <Route path="/recommendation/" exact component={BeautyProfilePage} isPrivate/>

            <Route path="/interest/" exact component={InterestPage} isPrivate/>
            <Route path="/interest/create" exact component={CreateInterestPage} isPrivate/>
            <Route path="/interest/recommendation" exact component={InterestRecommendationPage} isPrivate/>
            <Route path="/interest/recommendation/:id" exact component={InterestRecommendationUpdatePage} isPrivate/>

            <Route path="/interest-category/" exact component={InterestCategoryPage} isPrivate/>
            <Route path="/interest-category/create" exact component={CreateInterestCategoryPage} isPrivate/>

            <Route path="/point" exact component={PointsettingPage} isPrivate/>
            <Route path="/point/create" exact component={CreatePointPage} isPrivate/>

            <Route path="/reward" exact component={RewardSettingPage} isPrivate/>
            <Route path="/reward/create" exact component={CreateRewardPage} isPrivate/>
            <Route path="/reward/redeem-reward" exact component={RedeemRewardPage} isPrivate/>

            <Route path="/level" exact component={LevelSettingPage} isPrivate/>
            <Route path="/level/create" exact component={CreateLevelPage} isPrivate/>

            <Route path="/users/" exact component={UserListPage} isPrivate/>
            <Route path="/users/tracking/" exact component={TrackingPage} isPrivate/>

            <Route path="/notification/create" exact component={CreateNotificationPage} isPrivate/>
            <Route path="/notification" exact component={NotificationPage} isPrivate/>

            <Route path="/mission-registered" exact component={MissionRegistered} isPrivate/>
            <Route path="/mission-registered/rejected" exact component={MissionRegisteredRejected} isPrivate/>
            <Route path="/mission-registered/:id/:id/analytics" exact component={AnalyticsGoddessGang} isPrivate/>
            <Route path="/mission-registered/:id/detail" exact component={DetailMissionRegistered} isPrivate/>
            <Route path="/user-rejected/:id/detail" exact component={DetailMissionRegistered} isPrivate/>
            <Route path="/user-rejected/:id/:id/analytics" exact component={AnalyticsGoddessGang} isPrivate/>

            <Route path="/goddess-gang" exact component={GoddessGang} isPrivate/>
            <Route path="/goddess-gang/:id/detail" exact component={DetailGoddessGang} isPrivate/>
            <Route path="/goddess-gang/:id/content-detail" exact component={ContentDetailGoddessGang} isPrivate/>
            <Route path="/goddess-gang/:id/:id/analytics" exact component={AnalyticsGoddessGang} isPrivate/>
            <Route path="/goddess-gang/group" exact component={GoddessGangGroup} isPrivate/>
            <Route path="/goddess-gang/:id/group" exact component={DetailGoddessGangGroup} isPrivate/>
            <Route path="/goddess-gang/create-group" exact component={CreateGoddessGangGroup} isPrivate/>

            <Route path="/campaign" exact component={Campaign} isPrivate/>
            <Route path="/campaign/:id/detail" exact component={DetailCampaign} isPrivate/>
            <Route path="/campaign/create" exact component={CreateCampaign} isPrivate/>
            <Route path="/campaign/:id/edit" exact component={EditCampaign} isPrivate/>
            <Route path="/campaign/approvement" exact component={Approvement} isPrivate/>
            <Route path="/campaign/:id/approvement" exact component={ApproveCampaign} isPrivate/>

            <Route path="/redeem-point" exact component={RedeemPoint} isPrivate/>
            <Route path="/redeem-point/:id/detail" exact component={DetailRedeemPoint} isPrivate/>

            <Route path="/setting-banner" exact component={SettingBanner} isPrivate/>
            <Route path="/setting-create-questioner-form" exact component={CreateQuestionerForm} isPrivate/>
            <Route path="/setting-create-timeline" exact component={CreateTimeline} isPrivate/>

        </Switch>
    )
}
