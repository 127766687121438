import React from "react";
import NotificationFormComponent from "./notification-form.component";
import {createNotificationApi} from "../../api/notification.api";
import PageHeaderComponent from "../../component/page-header.component";

const FormData = require('form-data');

class CreateNotificationPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            loading: false
        }
    }

    onFinishCreateNotification = (values, fileImage, excelFileExtract, receiverIdList) => {
        const form = new FormData();
        this.setState({loading: true})
        let sendAll = !!values.sendAll ? 1 : 0
        form.append('title', values.title)
        form.append('description', values.description)
        form.append('image', fileImage[0])
        form.append('specifiedFor', values.specifiedFor)
        form.append('sendAll', sendAll)
        if(!!values.receiverId || !!values.receiverIdBulk){
            receiverIdList.forEach(item => {
                form.append('receiverId', item.id)
            })
        }
        if(!!values.receiverEmailBulk){
            excelFileExtract.forEach(item => {
                form.append('receiverEmailBulk', item)
            })
        }

        createNotificationApi(form)
            .then(response => {
                this.setState({loading: false})
                alert('Notification berhasil dikirim')
                window.location.href = '/notification'
            })
            .catch(err => {
                console.log(err)
            })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {loading} = this.state
        return (
            <>
                <PageHeaderComponent title={'Create Notification'}/>
                <NotificationFormComponent onFinish={this.onFinishCreateNotification} loading={loading}/>
            </>
        )
    }
}

export default CreateNotificationPage
