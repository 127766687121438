import React from "react";
import RedeemRewardComponent from "./redeem-reward.component";
import PageHeaderComponent from "../../component/page-header.component";

class RedeemRewardPage extends React.PureComponent {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <PageHeaderComponent title={'Redeem Reward List'}/>
                <RedeemRewardComponent/>
            </>
        )
    }
}

export default RedeemRewardPage
