import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import InterestRecommendationComponent from "./interest-recommendation.component";

class InterestRecommendationPage extends React.PureComponent {
    render() {
        const {params} = this.props.match

        return (
            <>
                <PageHeaderComponent title={"Interest Recommendation"}/>
                <InterestRecommendationComponent params={params}/>
            </>
        )
    }
}

export default InterestRecommendationPage