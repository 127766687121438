import React, { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { getDetailUserApi } from "../../../api/goddess-gang.api";
import {useHistory, useLocation} from 'react-router';
import {cloudFunctionBaseUrl} from "../../../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');
function ContentDetailGoddessGang({ match }) {
    const location = useLocation()
    const history = useHistory()
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        checked: 1,
        showUser: false,
        social_medias: null,
        soc_ig: null,
        soc_fb: null,
        soc_tiktok: null,
        soc_youtube: null,
        socmed_ig: null,
        socmed_fb: null,
        socmed_tiktok: null,
        socmed_youtube: null,
        disabled: false,
    });
    const { params: { id }, } = match;
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            const respUserDetail = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocmedIG= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id}/1`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocmedFB= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id}/2`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocmedTiktok= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id}/4`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respUserSocmedYoutube= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc/${id}/5`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocIG= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc-report/${respSocmedIG.data.data!=null ? respSocmedIG.data.data.username : "null"}/1`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocFB= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc-report/${respSocmedFB.data.data!=null ? respSocmedFB.data.data.username : "null"}/2`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocTiktok= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc-report/${respSocmedTiktok.data.data!=null ? respSocmedTiktok.data.data.username : "null"}/4`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSocYoutube= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/analityc-report/${respUserSocmedYoutube.data.data !=null? respUserSocmedYoutube.data.data.username : "null"}/5`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            setAppState({ loading: false, showUser: false, data: respUserDetail.data.user, social_medias: respUserDetail.data.user.social_medias, socmed_ig: respSocmedIG.data.data, socmed_fb: respSocmedFB.data.data, socmed_tiktok: respSocmedTiktok.data.data, socmed_youtube: respUserSocmedYoutube.data.data, soc_ig: respSocIG.data.result });
        };
        fetchData();
    }, []);
    const showDetail = () => {
        setAppState({showUser: true, soc_ig:appState.soc_ig, soc_fb:appState.soc_fb, soc_tiktok:appState.soc_tiktok, soc_youtube:appState.soc_youtube, data:appState.data, social_medias:appState.social_medias, socmed_ig: appState.socmed_ig, socmed_fb: appState.socmed_fb, socmed_tiktok: appState.socmed_tiktok, socmed_youtube: appState.socmed_youtube});
    }
    const hideDetail = () => {
        setAppState({showUser: false, soc_ig:appState.soc_ig, soc_fb:appState.soc_fb, soc_tiktok:appState.soc_tiktok, soc_youtube:appState.soc_youtube, data:appState.data, social_medias:appState.social_medias, socmed_ig: appState.socmed_ig, socmed_fb: appState.socmed_fb, socmed_tiktok: appState.socmed_tiktok, socmed_youtube: appState.socmed_youtube});
    }
    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
        
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed
        
        return (value + format.letter);
    }
    function getArraySum(a){
        var total=0;
        for(var i in a) { 
            total += a[i];
        }
        return total;
    }
    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    return (
        <div> 
        {(() => {
            if (appState.data != null){
                return <div>
                    <div id="main-content">
                        <div className="main-dash">
                            <div className={appState.showUser == false ? "data-hide" : "data-hide hide-medias" || appState.showUser == true ? "data-hide hide-medias" : "data-hide"}>
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-5">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="for-img hide"><img src={appState.data.photo} className="img-fluid" /></div>
                                                    <div className="ud-text hide">
                                                        <h4>{appState.data.full_name}
                                                            {(() => {
                                                                if (appState.data.is_potential == true) {
                                                                    return <img src="/img/ico-potential.svg" className="logo-pi"/>
                                                                } else {
                                                                    return <div className="pi"></div>
                                                                }
                                                            })()}
                                                        </h4>
                                                        <p>{appState.data.dob}</p>
                                                        <div className="pi">{appState.data.is_potential == true ? "Potential Influencer" : ""}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex">
                                                {appState.social_medias.map((repo,index) => {
                                                    return (
                                                        <div className={repo.name == 'Twitter' ? "hide-medias": "social-info-data"} key={index}>
                                                        {(() => {
                                                            if (repo.name=="Instagram" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/insta-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            } else if (repo.name=="Facebook" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/facebook-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            } else if (repo.name=="TikTok" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/tiktik-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            } else if (repo.name=="Youtube" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/youtube-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            }
                                                        })()}
                                                        </div>
                                                    );
                                                })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button onClick={showDetail} className="gg-link block btn-user-gg">Show Detail <img src="/img/arrow-down.svg" className="gg-icon-arrow red" /></button>
                                    </div>
                                </div>
                            </div>
                            <div className={appState.showUser == true ? "data-expand" : "data-expand hide-medias" || appState.showUser == false ? "data-expand hide-medias" : "data-expand"}>
                                <div className="row align-items-center">
                                    <div className="col-5">
                                        <div className="row align-items-center">
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                            <div id="gg" className="for-img"><img src={appState.data.photo} className="img-fluid" /></div>
                                            <div className="ud-text">
                                                <h3>{appState.data.full_name}</h3>
                                                <p>{appState.data.dob}</p>
                                                {(() => {
                                                    if (appState.data.is_potential == true) {
                                                        return <div className="pi"><img src="/img/ico-potential.svg" /> Potential Influencer</div>
                                                    } else {
                                                        return <div className="pi"></div>
                                                    }
                                                })()}
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <h6 style={{fontFamily: '"Futura Medium"'}}>Notes</h6>
                                            <p>-</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                        {appState.social_medias.map((repo,index) => {
                                            return (
                                                <div className={repo.name == 'Twitter' ? "hide-medias": "col-3"} key={index}>
                                                    {(() => {
                                                        if (repo.name=="Instagram"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/insta-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>Instagram</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        } else if (repo.name=="Facebook"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/facebook-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>Facebook</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        } else if (repo.name=="Youtube"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/youtube-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>Youtube</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        } else if (repo.name=="TikTok"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/tiktik-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>TikTok</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        }
                                                    })()}
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button onClick={hideDetail} className="gg-link block btn-user-gg">Hide Detail <img src="/img/arrow-up.svg" className="gg-icon-arrow" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="inner-campaign-wrapper">
                        <div className="tab-analytic">
                            <input defaultChecked="checked" id="tab-r-1" type="radio" name="report-tab" />
                            <input id="tab-r-2" type="radio" name="report-tab" />
                            <input id="tab-r-3" type="radio" name="report-tab" />
                            <input id="tab-r-4" type="radio" name="report-tab" />
                            <div className="tab-icon dp">
                            <label htmlFor="tab-r-1" className="tab-icon-item tab-r-1">
                                <div className="box-item-tab">
                                    <div className="fi-tab"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                    <div>Instagram</div>
                                </div>
                            </label>
                            <label htmlFor="tab-r-2" className="tab-icon-item tab-r-2 pl-4 pr-4">
                                <div className="box-item-tab">
                                    <div className="fi-tab"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                    <div>Facebook</div>
                                </div>
                            </label>
                            <label htmlFor="tab-r-3" className="tab-icon-item tab-r-3 pl-4 pr-4">
                                <div className="box-item-tab">
                                    <div className="fi-tab"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                    <div>Tiktok</div>
                                </div>
                            </label>
                            <label htmlFor="tab-r-4" className="tab-icon-item tab-r-4">
                                <div className="box-item-tab">
                                    <div className="fi-tab"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                    <div>Youtube</div>
                                </div>
                            </label>
                            </div>
                            <section className="tab-container">
                                <div className="tabs tab-r-1">
                                {(() => {
                                    if (appState.soc_ig != null){
                                        return  <div>
                                                    {appState.soc_ig && appState.soc_ig.length> 0 ? appState.soc_ig.map((repo,index) => {
                                                        const totalAgeIG = getArraySum(repo.age.buckets.map(repo=> repo.count))
                                                        return (
                                                            <div className="box-white" key={index}>
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <iframe src={repo.media.urlThumbnail.replace(`media/?size=t`, 'embed')} className="img-fluid w-100 mb-4 img-caption" frameBorder="0"/>
                                                                        <h6 className="font-medium">Caption</h6>
                                                                        <p>{repo.media.caption== '' ? '-': repo.media.caption}</p>
                                                                    </div>
                                                                    <div className="col-9">
                                                                        <div className="data-hide">
                                                                            <div className="row align-items-center justify-content-between">
                                                                                <div className="col-8">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-8">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <div className="for-img hide"><img src={repo.creator.picture != null ? repo.creator.picture : appState.data.photo} className="img-fluid img-circle" />
                                                                                                </div>
                                                                                                <div className="ud-text hide">
                                                                                                    <h4>{repo.creator.fullName}
                                                                                                        {(() => {
                                                                                                        if (appState.data.is_potential == true) {
                                                                                                            return <img src="/img/ico-potential.svg" className="logo-pi" />
                                                                                                        } else {
                                                                                                            return <div className="pi"></div>  
                                                                                                        }
                                                                                                        })()}
                                                                                                    </h4>
                                                                                                    <p>{appState.data.dob}</p>
                                                                                                    <div>{appState.data.email}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <a href={`/goddess-gang/${appState.data.id}/detail`} className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row mb-4">
                                                                            <div className="col-7">
                                                                                <p className="font-medium">Engagement</p>
                                                                                <div className="engagment-box another">
                                                                                    <div className="e-items">
                                                                                        <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                                        <h5>{formatFollower(repo.media.likeCount ?? 0)}</h5>
                                                                                        <p>Likes</p>
                                                                                    </div>
                                                                                    <div className="e-items">
                                                                                        <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                                        <h5>{formatFollower(repo.media.commentCount ?? 0)}</h5>
                                                                                        <p>Comments</p>
                                                                                    </div>
                                                                                    <div className="e-items">
                                                                                        <img src="/img/icon-bookmark.svg" className="img-fluid mb-3" />
                                                                                        <h5>{formatFollower(repo.media.viewCount ?? 0)}</h5>
                                                                                        <p>Views</p>
                                                                                    </div>
                                                                                    <div className="e-items">
                                                                                        <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                                                                        <h5>-</h5>
                                                                                        <p>Share</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5">
                                                                                <p className="font-medium">Gender Distribution</p>
                                                                                <div className="box-gd">
                                                                                    <div className="row">
                                                                                    <div className="col-6">
                                                                                        <div className="box-gd-item">
                                                                                        <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                                        <div>
                                                                                            <h3 className="font-medium">{repo.gender.buckets[0].count!=0?Math.round((repo.gender.buckets[0].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                            <p>Laki - Laki</p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="box-gd-item">
                                                                                        <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                                        <div>
                                                                                            <h3 className="font-medium">{repo.gender.buckets[1].count!=0?Math.round((repo.gender.buckets[1].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                            <p>Wanita</p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-7">
                                                                                <p className="font-medium">Age Ditribution</p>
                                                                                <div className="engagment-box ad">
                                                                                {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                                    return (
                                                                                        <div className="e-items" key={index}>
                                                                                            {(() => {
                                                                                                if (item.min == 0){
                                                                                                    return <h5 className="red">{">"+item.max}</h5>
                                                                                                } else if (index === repo.age.buckets.length - 1){
                                                                                                    return <h5 className="red">{"<"+item.max}</h5>
                                                                                                } else {
                                                                                                    return <h5 className="red">{item.label}</h5>
                                                                                                }
                                                                                            })()}
                                                                                            <h5>{item.count!=0?Math.round(item.count/totalAgeIG*100)+'%':0+'%'}</h5>
                                                                                        </div>
                                                                                    );
                                                                                    }): <div className="e-items">No data</div>
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-5">
                                                                                <p className="font-medium">Estimated Reach</p>
                                                                                <div className="box-gd">
                                                                                    <div className="row">
                                                                                    <div className="col-6">
                                                                                        <div className="box-gd-item">
                                                                                        <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                                        <div>
                                                                                            <h3 className="font-medium">{"IDR "+currencyFormat(repo.estimatedMediaValue)}</h3>
                                                                                            <p>ESR. Media Values</p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="box-gd-item">
                                                                                        <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                                        <div>
                                                                                            <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                                            <p>ESR. Reach</p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6 mt-4">
                                                                                        <div className="box-gd-item">
                                                                                        <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                                        <div>
                                                                                            <h3 className="font-medium">{formatFollower(repo.estimatedImpressions)}</h3>
                                                                                            <p>EST. Impressions</p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                        }): <div className="box-white">No data</div>
                                                    } 
                                                </div>
                                    } else {
                                        return <div className="box-white">No data</div>
                                    }
                                })()}
                                </div>{/* end of tab1 */}
                                <div className="tabs tab-r-2">
                                {(() => {
                                    if (appState.soc_fb != null){
                                        return  <div>  
                                        {appState.soc_fb && appState.soc_fb.length> 0 ? appState.soc_fb.map((repo,index) => {
                                            const totalAgeFB = getArraySum(repo.age.buckets.map(repo=> repo.count))
                                            return (
                                                <div className="box-white">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <iframe src={repo.media.urlThumbnail.replace(`media/?size=t`, 'embed')} className="img-fluid w-100 mb-4 img-caption" frameBorder="0"/>
                                                            <h6 className="font-medium">Caption</h6>
                                                            <p>{repo.media.caption== '' ? '-': repo.media.caption}</p>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="data-hide">
                                                                <div className="row align-items-center justify-content-between">
                                                                    <div className="col-8">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-8">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="for-img hide"><img src={appState.socmed_ig.detail.influencer.profile.picture != null ? appState.socmed_ig.detail.influencer.profile.picture : appState.data.photo} className="img-fluid img-circle" />
                                                                                    </div>
                                                                                    <div className="ud-text hide">
                                                                                        <h4>{repo.creator.fullName}
                                                                                            {(() => {
                                                                                            if (appState.data.is_potential == true) {
                                                                                                return <img src="/img/ico-potential.svg" className="logo-pi" />
                                                                                            } else {
                                                                                                return <div className="pi"></div>  
                                                                                            }
                                                                                            })()}
                                                                                        </h4>
                                                                                        <p>{appState.data.dob}</p>
                                                                                        <div>{appState.data.email}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <a href={`/goddess-gang/${appState.data.id}/detail`} className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row mb-4">
                                                                <div className="col-7">
                                                                    <p className="font-medium">Engagement</p>
                                                                    <div className="engagment-box another">
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.likeCount ?? 0)}</h5>
                                                                            <p>Likes</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.commentCount ?? 0)}</h5>
                                                                            <p>Comments</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-bookmark.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.viewCount ?? 0)}</h5>
                                                                            <p>Views</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                                                            <h5>-</h5>
                                                                            <p>Share</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <p className="font-medium">Gender Distribution</p>
                                                                    <div className="box-gd">
                                                                        <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{repo.gender.buckets[0].count!=0?Math.round((repo.gender.buckets[0].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                <p>Laki - Laki</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{repo.gender.buckets[1].count!=0?Math.round((repo.gender.buckets[1].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                <p>Wanita</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <p className="font-medium">Age Ditribution</p>
                                                                    <div className="engagment-box ad">
                                                                    {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                        return (
                                                                            <div className="e-items" key={index}>
                                                                                {(() => {
                                                                                    if (item.min == 0){
                                                                                        return <h5 className="red">{">"+item.max}</h5>
                                                                                    } else if (index === repo.age.buckets.length - 1){
                                                                                        return <h5 className="red">{"<"+item.max}</h5>
                                                                                    } else {
                                                                                        return <h5 className="red">{item.label}</h5>
                                                                                    }
                                                                                })()}
                                                                                <h5>{item.count!=0?Math.round(item.count/totalAgeFB*100)+'%':0+'%'}</h5>
                                                                            </div>
                                                                        );
                                                                        }): <div className="e-items">No data</div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <p className="font-medium">Estimated Reach</p>
                                                                    <div className="box-gd">
                                                                        <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{"IDR "+currencyFormat(repo.estimatedMediaValue)}</h3>
                                                                                <p>ESR. Media Values</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                                <p>ESR. Reach</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-4">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{formatFollower(repo.estimatedImpressions)}</h3>
                                                                                <p>EST. Impressions</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            }): <div className="box-white">No data</div>
                                        }
                                        </div>
                                    } else {
                                        return <div className="box-white">No data</div>
                                    }
                                })()}
                                </div>{/* end of tab2 */}
                                <div className="tabs tab-r-3">
                                {(() => {
                                    if (appState.soc_tiktok != null){
                                        return  <div>  
                                        {appState.soc_tiktok && appState.soc_tiktok.length> 0 ? appState.soc_tiktok.map((repo,index) => {
                                            const totalAgeTiktok = getArraySum(repo.age.buckets.map(repo=> repo.count))
                                            return (
                                                <div className="box-white">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <iframe src={repo.media.urlThumbnail.replace(`media/?size=t`, 'embed')} className="img-fluid w-100 mb-4 img-caption" frameBorder="0"/>
                                                            <h6 className="font-medium">Caption</h6>
                                                            <p>{repo.media.caption== '' ? '-': repo.media.caption}</p>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="data-hide">
                                                                <div className="row align-items-center justify-content-between">
                                                                    <div className="col-8">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-8">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="for-img hide"><img src={appState.socmed_ig.detail.influencer.profile.picture != null ? appState.socmed_ig.detail.influencer.profile.picture : appState.data.photo} className="img-fluid img-circle" />
                                                                                    </div>
                                                                                    <div className="ud-text hide">
                                                                                        <h4>{repo.creator.fullName}
                                                                                            {(() => {
                                                                                            if (appState.data.is_potential == true) {
                                                                                                return <img src="/img/ico-potential.svg" className="logo-pi" />
                                                                                            } else {
                                                                                                return <div className="pi"></div>  
                                                                                            }
                                                                                            })()}
                                                                                        </h4>
                                                                                        <p>{appState.data.dob}</p>
                                                                                        <div>{appState.data.email}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <a href={`/goddess-gang/${appState.data.id}/detail`} className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row mb-4">
                                                                <div className="col-7">
                                                                    <p className="font-medium">Engagement</p>
                                                                    <div className="engagment-box another">
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.likeCount ?? 0)}</h5>
                                                                            <p>Likes</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.commentCount ?? 0)}</h5>
                                                                            <p>Comments</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-bookmark.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.viewCount ?? 0)}</h5>
                                                                            <p>Views</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                                                            <h5>-</h5>
                                                                            <p>Share</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <p className="font-medium">Gender Distribution</p>
                                                                    <div className="box-gd">
                                                                        <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{repo.gender.buckets[0].count!=0?Math.round((repo.gender.buckets[0].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                <p>Laki - Laki</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{repo.gender.buckets[1].count!=0?Math.round((repo.gender.buckets[1].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                <p>Wanita</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <p className="font-medium">Age Ditribution</p>
                                                                    <div className="engagment-box ad">
                                                                    {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                        return (
                                                                            <div className="e-items" key={index}>
                                                                                {(() => {
                                                                                    if (item.min == 0){
                                                                                        return <h5 className="red">{">"+item.max}</h5>
                                                                                    } else if (index === repo.age.buckets.length - 1){
                                                                                        return <h5 className="red">{"<"+item.max}</h5>
                                                                                    } else {
                                                                                        return <h5 className="red">{item.label}</h5>
                                                                                    }
                                                                                })()}
                                                                                <h5>{item.count!=0?Math.round(item.count/totalAgeTiktok*100)+'%':0+'%'}</h5>
                                                                            </div>
                                                                        );
                                                                        }): <div className="e-items">No data</div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <p className="font-medium">Estimated Reach</p>
                                                                    <div className="box-gd">
                                                                        <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{"IDR "+currencyFormat(repo.estimatedMediaValue)}</h3>
                                                                                <p>ESR. Media Values</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                                <p>ESR. Reach</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-4">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{formatFollower(repo.estimatedImpressions)}</h3>
                                                                                <p>EST. Impressions</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            }): <div className="box-white">No data</div>
                                        }
                                        </div>
                                    } else {
                                        return <div className="box-white">No data</div>
                                    }
                                })()} 
                                </div>{/* end of tab3 */}
                                <div className="tabs tab-r-4">
                                {(() => {
                                    if (appState.soc_youtube != null){
                                        return  <div>  
                                        {appState.soc_youtube && appState.soc_youtube.length> 0 ? appState.soc_youtube.map((repo,index) => {
                                            const totalAgeYoutube = getArraySum(repo.age.buckets.map(repo=> repo.count))
                                            return (
                                                <div className="box-white">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <iframe src={repo.media.urlThumbnail.replace(`media/?size=t`, 'embed')} className="img-fluid w-100 mb-4 img-caption" frameBorder="0"/>
                                                            <h6 className="font-medium">Caption</h6>
                                                            <p>{repo.media.caption== '' ? '-': repo.media.caption}</p>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="data-hide">
                                                                <div className="row align-items-center justify-content-between">
                                                                    <div className="col-8">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-8">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="for-img hide"><img src={appState.socmed_ig.detail.influencer.profile.picture != null ? appState.socmed_ig.detail.influencer.profile.picture : appState.data.photo} className="img-fluid img-circle" />
                                                                                    </div>
                                                                                    <div className="ud-text hide">
                                                                                        <h4>{repo.creator.fullName}
                                                                                            {(() => {
                                                                                            if (appState.data.is_potential == true) {
                                                                                                return <img src="/img/ico-potential.svg" className="logo-pi" />
                                                                                            } else {
                                                                                                return <div className="pi"></div>  
                                                                                            }
                                                                                            })()}
                                                                                        </h4>
                                                                                        <p>{appState.data.dob}</p>
                                                                                        <div>{appState.data.email}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <a href={`/goddess-gang/${appState.data.id}/detail`} className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row mb-4">
                                                                <div className="col-7">
                                                                    <p className="font-medium">Engagement</p>
                                                                    <div className="engagment-box another">
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.likeCount ?? 0)}</h5>
                                                                            <p>Likes</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.commentCount ?? 0)}</h5>
                                                                            <p>Comments</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-bookmark.svg" className="img-fluid mb-3" />
                                                                            <h5>{formatFollower(repo.media.viewCount ?? 0)}</h5>
                                                                            <p>Views</p>
                                                                        </div>
                                                                        <div className="e-items">
                                                                            <img src="/img/icon-plane.svg" className="img-fluid mb-3" />
                                                                            <h5>-</h5>
                                                                            <p>Share</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <p className="font-medium">Gender Distribution</p>
                                                                    <div className="box-gd">
                                                                        <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{repo.gender.buckets[0].count!=0?Math.round((repo.gender.buckets[0].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                <p>Laki - Laki</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{repo.gender.buckets[1].count!=0?Math.round((repo.gender.buckets[1].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                                <p>Wanita</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <p className="font-medium">Age Ditribution</p>
                                                                    <div className="engagment-box ad">
                                                                    {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                        return (
                                                                            <div className="e-items" key={index}>
                                                                                {(() => {
                                                                                    if (item.min == 0){
                                                                                        return <h5 className="red">{">"+item.max}</h5>
                                                                                    } else if (index === repo.age.buckets.length - 1){
                                                                                        return <h5 className="red">{"<"+item.max}</h5>
                                                                                    } else {
                                                                                        return <h5 className="red">{item.label}</h5>
                                                                                    }
                                                                                })()}
                                                                                <h5>{item.count!=0?Math.round(item.count/totalAgeYoutube*100)+'%':0+'%'}</h5>
                                                                            </div>
                                                                        );
                                                                        }): <div className="e-items">No data</div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <p className="font-medium">Estimated Reach</p>
                                                                    <div className="box-gd">
                                                                        <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{"IDR "+currencyFormat(repo.estimatedMediaValue)}</h3>
                                                                                <p>ESR. Media Values</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                                <p>ESR. Reach</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-4">
                                                                            <div className="box-gd-item">
                                                                            <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                            <div>
                                                                                <h3 className="font-medium">{formatFollower(repo.estimatedImpressions)}</h3>
                                                                                <p>EST. Impressions</p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            }): <div className="box-white">No data</div>
                                        }
                                        </div>
                                    } else {
                                        return <div className="box-white">No data</div>
                                    }
                                })()} 
                                </div>{/* end of tab4 */}
                            </section>
                        </div>
                    </div>
                </div>
            } else if (appState.data == null) {
                return <div className="container">
                    <div className="credential pt-5">
                        <div className="credential-box-noborder">
                        <div className="load-2">
                            <p>Loading... Please wait</p>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        </div>
                    </div>
                </div>
            }
        })()}
        </div>
    );
}


export default ContentDetailGoddessGang;
