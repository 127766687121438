import React,{ useState } from 'react';
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";
import ReactPaginate from 'react-paginate';
import { RatingView } from 'react-simple-star-rating';
import FusionCharts, { formatNumber } from 'fusioncharts';
import ReactFC from 'react-fusioncharts';
import Chart from 'react-apexcharts';
import { setSendNotifApi } from "../../../api/goddess-gang.api";
import { deleteUserCampaignApi } from "../../../api/user-campaign.api";
import { getCampaignContent } from "../../../api/campaign.api";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {useHistory, useLocation} from 'react-router';

ReactFC.fcRoot(FusionCharts);
const List = (props) => {
    const { formatAMPM, data, calculation, detail, total, set_page, current_page, per_page, currencyFormat, justNumbers, formatFollower, defaultTab, influencerTab, contentTab, reportTab, content, report, id, contentMediaInstagram, reportMediaInstagram, printContent} = props;
    const [user_id, setUser_id] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const [image, setImage]           = useState('');
    const [title_notif, setTitleNotif] = useState("");
    const [desc_notif, setDescNotif] = useState("");
    const [mentions, setMentions]           = useState([]);
    const [hastags, setHastags]             = useState([]);
    const [suggestions, setSuggestions]     = useState([]);
    const [startDate, setStartDate]         = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]             = useState(location.state?.end_date ?? '');
    
    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
      };
      
    const delimiters = [...KeyCodes.enter, KeyCodes.comma];
    const [appState, setAppState]  = useState({
        dataState:data,
        detail:detail,
        content: content,
        report: report,
        calculation: calculation,
        disabled: false,
        showNotif: false,
        mention_ig : null
    });
    const {
        control,
        formState: { errors },
        watch,
        register,
        setValue,
        getValues,
        handleSubmit,
        reset,
      } = useForm({ mode: "all" });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const onImageChange = (event) => {
        const image = event.target.files[0]
        let reader = new FileReader();
        reader.onloadend = () => {
            setImage({
                file: image,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(image)
    }
    const openNotif = (e) => {
        let user_id = e;
        setUser_id(user_id);
        setAppState({
            detail: appState.detail,
            dataState: appState.dataState,
            content: appState.content,
            report: appState.report,
            showNotif: true,
        });
    };
    function closeNotif() {
        setAppState({
            detail: appState.detail,
            dataState: appState.dataState,
            content: appState.content,
            report: appState.report,
            showNotif: false,
        });
      }
    function sendNotification() {
        setAppState({
          detail: appState.detail,
          content: appState.content,
          report: appState.report,
          total: appState.total,
          current_page: appState.current_page,
          dataState: appState.dataState,
        });
        const form = new FormData();
        form.append('title', title_notif)
        form.append('description', desc_notif)
        form.append('user_ids', JSON.stringify([user_id]))
        if (image.file.type == "image/jpeg") {
            form.append('image', image.file)
        }
        setSendNotifApi(form).then((repos) => {
          const data = repos.message;
          if (repos.code == 200) {
            swal({
              title: "Done!",
              text: data,
              icon: "success",
              timer: 3000,
              button: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          } else {
            swal({
              title: "Error!",
              text: data,
              icon: "error",
              button: true,
            });
          }
          setAppState({
            detail: appState.detail,
            content: appState.content,
            report: appState.report,
            total: appState.total,
            current_page: appState.current_page,
            dataState: appState.dataState,
          });
        });
      }
      function deleteUserCampaign(e){
        let user_id = e;
        setUser_id(user_id); 
        setAppState({disabled: true, dataState: appState.dataState , total: appState.total, current_page: appState.current_page, per_page: appState.per_page, content: appState.content, report: appState.report});
        swal({
            title: "Confirmation",
            text: "Are you sure user delete campaign ?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    closeModal: true
                },
            },
            dangerMode: true,
        }).then((isConfirm) => {
            if (!isConfirm){ return false;}
            setAppState({disabled: true, dataState: appState.dataState , total: appState.total, current_page: appState.current_page, per_page: appState.per_page, content: appState.content, report: appState.report});
            deleteUserCampaignApi(id,user_id)
            .then((result) => {
                const data = result.message;
                if(result.code == 200 || result.code == 201) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        button: true
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                }
            });
        }) 
    }
    function getArraySum(a){
        var total=0;
        for(var i in a) { 
            total += a[i];
        }
        return total;
    }
    
    const largestAuditionLocationReport = (appState?.report?.instagram?.aggLocations?.reduce(
        (max, item) => item.count > max ? item.count : max, 0
    )  ?? 0)
    const genderFemaleReport = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.gender?.buckets[1]?.count) : 0);
    const genderMaleReport = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.gender?.buckets[0]?.count) : 0);
    const totalGenderReport = genderFemaleReport + genderMaleReport;
    let genderColorReport = []
    if (genderMaleReport < genderFemaleReport){
        genderColorReport = ['#893930','#E2E2E2']
    } else if (genderMaleReport > genderFemaleReport) {
        genderColorReport = ['#E2E2E2','#893930']
    }
    let genderLabelColorReport = []
    if (genderMaleReport < genderFemaleReport){
        genderLabelColorReport = ['#E2E2E2','#893930']
    } else if (genderMaleReport > genderFemaleReport) {
        genderLabelColorReport = ['#893930','#E2E2E2']
    }
    const ageKurang18 = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.age?.buckets[0]?.count) : 0);
    const age18Sampai25 = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.age?.buckets[1]?.count) : 0);
    const age25Sampai32 = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.age?.buckets[2]?.count) : 0);
    const age32Sampai39 = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.age?.buckets[3]?.count) : 0);
    const age39Sampai46 = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.age?.buckets[4]?.count) : 0);
    const age46Sampai100 = getArraySum(appState?.report?.instagram?.mediaReports != undefined ? reportMediaInstagram.map(item=> item?.age?.buckets[5]?.count) : 0);
    const arrayReportAgeIG = [
        {
            label : "0-18",
            count : ageKurang18
        },
        {
            label : "18-25",
            count : age18Sampai25
        },
        {
            label : "25-32",
            count : age25Sampai32
        },
        {
            label : "32-39",
            count : age32Sampai39
        },
        {
            label : "39-46",
            count : age39Sampai46
        },
        {
            label : "39-100",
            count : age46Sampai100
        },
    ]
    const arrAgeReportIG = [ageKurang18, age18Sampai25, age25Sampai32, age32Sampai39, age39Sampai46, age46Sampai100];
    const totalAgeReportIG = getArraySum(arrAgeReportIG);
    const largestAgeGroupReport = (Math.max(...arrAgeReportIG))
    const [tagMentionIG, setTagMentionIG]         = useState([appState.detail.instagram_task.mention]);
    const [tagHashtagIG, setTagHashtagIG]         = useState([appState.detail.instagram_task.hashtag]);
    const TagsMention = props => {
        const removeTags = indexToRemove => {
            setTagMentionIG([...tagMentionIG.filter((_, index) => index !== indexToRemove)]);
        };
        const addTags = event => {
            props.handleFunction(event)
        };
        return (
          <div className="tags-input">
            <ul id="tags">
                {tagMentionIG.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon'
                            onClick={() => removeTags(index)}
                        >
                            x
                        </span>
                    </li>
                ))}
            </ul>
            <input type="text" onKeyUp={(event) => event.key === "Enter" ? addTags(event) : null} placeholder="Add new mention"/>
          </div>
        );
    };
    const selectedTags = tags => {
    };
    const TagsHashtag = props => {
        const removeTags = indexToRemove => {
            setTagHashtagIG([...tagHashtagIG.filter((_, index) => index !== indexToRemove)]);
        };
        const addTags = event => {
            props.handleFunction(event)
        };
        return (
          <div className="tags-input">
            <ul id="tags">
                {tagHashtagIG.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon'
                            onClick={() => removeTags(index)}
                        >
                            x
                        </span>
                    </li>
                ))}
            </ul>
            <input type="text" onKeyUp={(event) => event.key === "Enter" ? addTags(event) : null} placeholder="Add new hashtag"/>
          </div>
        );
    };
    function handleAddTagMention(e) {
       let arr =[]
       tagMentionIG.map((data)=>{
           arr.push(data)
       })
       
       arr.push(e)
       console.log(arr)
       setTagMentionIG(arr)
       let params = {
            mention: { toJSON: () => arr}
        }
        getCampaignContent(id, params)
        .then(result => {
            setAppState({ loading: false, content: result.data, total: appState.total, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, report: appState.report });
        }).catch(err => {
            console.log(err)
        })
    }
    function handleAddTagHashtag(e) {
        let arr =[]
        tagHashtagIG.map((data)=>{
            arr.push(data)
        })
        
        arr.push(e)
        console.log(arr)
        setTagHashtagIG(arr)
        let params = {
             hashtag: { toJSON: () => arr}
         }
         getCampaignContent(id, params)
         .then(result => {
             setAppState({ loading: false, content: result.data, total: appState.total, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, report: appState.report });
         }).catch(err => {
             console.log(err)
         })
     }
    if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data user influencer, sorry</p>;
    
    const page_count = total / per_page;
    return (
        <div className="content-wrapper">
            {(() => {
                if (defaultTab || influencerTab){
                return <div>
                        <div className="head-data-info">
                            <div className="thcd-2">Influencer </div>
                            <div className="thcd-3">Followers </div>
                            <div className="thcd-4">Status </div>
                            <div className="thcd-5">Contact</div>
                            <div className="thcd-6">Cost</div>
                            <div className="thcd-7">EMV </div>
                            <div className="thcd-8">Rating </div>
                            <div className="thcd-9">Actions </div>
                        </div>
                        {appState.dataState && appState.dataState.length > 0 ? appState.dataState.map((repo, index) => {
                            return (
                                <div className="data-items sm" key={index}>
                                {(() => {
                                    if (repo.campaign_users[0].status == "Waiting Approval"){
                                        return <div className="ticd-02">
                                            <a className="link-user" href={`/mission-registered/${repo.id}/detail`}>
                                            <div className="box-user">
                                            <div className="img-part mr-3">
                                                <img src={repo.photo} className="img-user img-fluid" alt="user" />
                                            </div>
                                            <div className="user-part">
                                                <h5>
                                                {repo.full_name}
                                                {(() => {
                                                if (repo.is_potential == true) {
                                                    return  <span className="tool-tip cd">
                                                            <button className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></button>
                                                            <div className="tool-tip-content right">
                                                                <span>Influencer Potential</span>
                                                            </div>
                                                        </span>
                                                    } else {
                                                        return <span></span>
                                                    }
                                                })()}
                                                </h5>
                                                <p>{repo.user_analityc == null ? "-" : "@"+repo.user_analityc.username}</p>
                                            </div>
                                            </div>
                                        </a>
                                    </div>
                                    } else {
                                        return <div className="ticd-02">
                                            <a className="link-user" href={`/goddess-gang/${repo.id}/detail`}>
                                            <div className="box-user">
                                            <div className="img-part mr-3">
                                                <img src={repo.photo} className="img-user img-fluid" alt="user" />
                                            </div>
                                            <div className="user-part">
                                                <h5>
                                                {repo.full_name}
                                                {(() => {
                                                if (repo.is_potential == true) {
                                                    return  <span className="tool-tip cd">
                                                            <button className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></button>
                                                            <div className="tool-tip-content right">
                                                                <span>Influencer Potential</span>
                                                            </div>
                                                        </span>
                                                    } else {
                                                        return <span></span>
                                                    }
                                                })()}
                                                </h5>
                                                <p>{repo.user_analityc == null ? "-" : "@"+repo.user_analityc.username}</p>
                                            </div>
                                            </div>
                                        </a>
                                    </div> 
                                    }
                                })()}
                                <div className="ticd-03">{repo.user_analityc == null ? "-" : formatFollower(repo.user_analityc.follower)}</div>
                                <div className="ticd-04">
                                {(() => {
                                    if (repo.campaign_users[0].status == "Approve"){
                                       return <div className="box-red">Published</div>
                                    } else {
                                        return <div className="box-grey">{repo.campaign_users[0].status}</div>
                                    }
                                    })()}
                                </div>
                                <div className="ticd-05">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="mr-2"><img src="/img/ico-email.svg" width={20} className="img-fluid" /></div>
                                    <div>{repo.email}</div>
                                </div>
                                </div>
                                <div className="ticd-06">
                                <div style={{fontStyle: 'italic'}}>{repo.cost}</div>
                                </div>
                                {(() => {
                                    if(repo.emv != null) {
                                        let emv = justNumbers(repo.emv)
                                        return <div className="ticd-07">{"$"+formatNumber(emv)}</div>
                                    } else {
                                        return <div className="ticd-07">-</div>
                                    }
                                })()}
                                <div className="ticd-08">
                                    <RatingView ratingValue={repo.rating} size={25} className='star-rate' transition fillColor='#953232' emptyColor='#e2e2e2' />
                                </div>
                                <div className="ticd-09">
                                    <a className="link-user" href={`/goddess-gang/${repo.id}/detail`}><img src="/img/ico-edited.svg" className="img-fluid" width={25} /></a>
                                    <button onClick={(e) => deleteUserCampaign(repo.id)} className="btn-unlink"><img src="/img/ico-trash.svg" className="img-fluid" width={25} /></button>
                                    <button onClick={(e) => openNotif(repo.id)} className="btn-unlink"><img src="/img/plane-outline.svg" className="img-fluid" width={25} /></button>
                                </div>
                            </div>
                          );
                        }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data user campaign, sorry</p>
                        }
                        <div className="d-flex justify-content-center mt-5">
                            {(() => {
                            if (total < 5) {
                                return <ReactPaginate
                                    pageCount={1}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={1}
                                    forcePage={current_page - 1}
                                    activeLinkClassName='active'
                                    containerClassName='pagination'
                                    previousLabel='&laquo;'
                                    nextLabel='&raquo;'
                                    onPageChange={changePage}
                                />
                            } else {
                                return <ReactPaginate
                                    pageCount={Math.ceil(page_count)}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={1}
                                    forcePage={current_page - 1}
                                    activeLinkClassName='active'
                                    containerClassName='pagination'
                                    previousLabel='&laquo;'
                                    nextLabel='&raquo;'
                                    onPageChange={changePage}
                                />
                            }
                            })()}
                        </div>
                        <Modal show={appState.showNotif} className="send-notif">
                            <div className="popup">
                            <button className="close" onClick={closeNotif}>
                                <img src="/img/ico-close.svg" alt="close" />
                            </button>
                            <h5 className="mb-3">Form Send Notification</h5>
                            <div className="content modal-campaign">
                                <div className="col-12">
                                    <div className="form-notif mb-4">
                                        <input type="text" name="title_notif" value={title_notif} onChange={(event) => setTitleNotif(event.target.value)} className="form-control" placeholder="Title Notification" />
                                        {title_notif=='' || errors.title_notif ? <p className='alert-message'>Title wajib diisi</p> : ""}
                                    </div>
                                    <div className="form-notif mb-4">
                                        <textarea className="form-control"  {...register("desc_notif")}  placeholder="Description Notification" id="inputText" rows={5} value={desc_notif} onChange={(event) => setDescNotif(event.target.value)} />
                                    </div>
                                    {(() => {
                                    if (image != '') {
                                    return  <div>
                                                <div className="box-img-upload">
                                                    <img src={image.imagePreviewUrl} className="img-fluid w-100" />
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></button>
                                                        <input type="file" name="image" {...register("image")} onChange={onImageChange} />
                                                    </div>
                                                </div>
                                                <div className="font-medium">Gambar Thumbnail</div>
                                                <div className="path">
                                                    <div className="mr-2"><img src="/img/ico-folder.svg" className="img-fluid" width={30} /></div>
                                                    <div className="grey">{image.file.name}</div>
                                                </div>
                                            </div>
                                    } else {
                                    return <div>
                                            <div className="box-img-upload">
                                                    <img src="/img/no-image.jpg" width={100} className="img-fluid w-100" />
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></button>
                                                        <input type="file" name="image" {...register("image")} onChange={onImageChange} />
                                                    </div>
                                                </div>
                                                <div className="font-medium">Gambar Thumbnail</div>
                                                <div className="path">
                                                    <div className="mr-2"><img src="/img/ico-folder.svg" className="img-fluid" width={30} /></div>
                                                    <div className="grey">No Image</div>
                                                </div>
                                            </div>      
                                    }
                                    })()}
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" onClick={sendNotification} disabled={title_notif==''|| errors.title_notif}>{appState.disabled ? "Loading..." : "Kirim"}</button>   
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Modal>
                    </div>
                } else if (contentTab) {
                    return <div className="box-white">
                        <div className="tab-analytic">
                            <input defaultChecked="checked" id="tab-s-1" type="radio" name="post-tab" />
                            <input id="tab-s-2" type="radio" name="post-tab" />
                            <input id="tab-s-3" type="radio" name="post-tab" />
                            <div className="tab-icon dp">
                                <label htmlFor="tab-s-1" className="tab-icon-item tab-s-1">
                                    <div className="box-item-tab">
                                        <div className="fi-tab"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                        <div>Instagram</div>
                                    </div>
                                </label>
                                <label htmlFor="tab-s-2" className="tab-icon-item tab-s-2 pl-4 pr-4">
                                    <div className="box-item-tab">
                                        <div className="fi-tab"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                        <div>Tiktok</div>
                                    </div>
                                </label>
                                <label htmlFor="tab-s-3" className="tab-icon-item tab-s-3">
                                    <div className="box-item-tab">
                                        <div className="fi-tab"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                        <div>Youtube</div>
                                    </div>
                                </label>
                            </div>
                            <section className="post tab-container">
                                <div className="tabs tab-s-1">
                                {(() => {
                                if (appState?.report?.instagram?.mediaReports != undefined) {
                                return <div>
                                        <div className="d-flex align-items-baseline justify-content-between mb-5">
                                            <div className="date">
                                                <div className="d-flex align-items-center">
                                                </div>
                                            </div>
                                            <div className="hide-on-print mb-5">
                                                <div className="d-flex">
                                                    <div className="mr-2">
                                                        <div className="font-medium mb-1">Mention</div>
                                                        <div className="box-mention">
                                                            <TagsMention selectedTags={selectedTags} tags={tagMentionIG} handleFunction={(e)=>handleAddTagMention(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="font-medium mb-1">Hastag</div>
                                                        <div className="box-mention">
                                                            <TagsHashtag selectedTags={selectedTags} tags={tagHashtagIG} handleFunction={(e)=>handleAddTagHashtag(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-row">
                                        {appState.content.instagram == [] ? appState.content.instagram.length>0 : appState.content.instagram.mediaReports && appState.content.instagram.mediaReports.length> 0 ? appState.content.instagram.mediaReports.map((repo,index) => {
                                            return (
                                                <div className="col-post" key={index}>
                                                    <iframe src={repo.media.urlThumbnail.replace(`media/?size=t`, 'embed')} width="224" height="500" frameBorder="0"/>
                                                </div>
                                                );
                                            }): <div className="col-post">No data</div>
                                        }
                                        </div>
                                    </div>
                                } else {
                                    return <div className="col-post">No data</div>
                                }
                                })()}
                                </div>
                                <div className="tabs tab-s-2">
                                    <div className="post-row">
                                        {appState.content.tiktok == [] ? appState.content.tiktok.length>0 : appState.content.tiktok.mediaReports && appState.content.tiktok.mediaReports.length> 0 ? appState.content.tiktok.mediaReports.map((repo,index) => {
                                            return (
                                                <div className="col-post" key={index}>
                                                    <img src={repo.media.urlLarge} className="img-fluid w-100" />
                                                </div>
                                                );
                                            }): <div className="col-post">No data</div>
                                        }
                                    </div>
                                </div>
                                <div className="tabs tab-s-3">
                                    <div className="post-row">
                                        {appState.content.youtube == [] ? appState.content.youtube.length>0 : appState.content.youtube.mediaReports && appState.content.youtube.mediaReports.length> 0 ? appState.content.youtube.mediaReports.map((repo,index) => {
                                            return (
                                                <div className="col-post" key={index}>
                                                    <img src={repo.media.urlLarge} className="img-fluid w-100" />
                                                </div>
                                            );
                                            }): <div className="col-post">No data</div>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                } else if (reportTab) {
                    return <div>
                        <div className="tab-analytic">
                            <input defaultChecked="checked" id="tab-r-1" type="radio" name="report-tab" />
                            <input id="tab-r-2" type="radio" name="report-tab" />
                            <input id="tab-r-3" type="radio" name="report-tab" />
                            <div className="tab-icon dp">
                                <label htmlFor="tab-r-1" className="tab-icon-item tab-r-1">
                                    <div className="box-item-tab">
                                        <div className="fi-tab"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                        <div>Instagram</div>
                                    </div>
                                </label>
                                <label htmlFor="tab-r-2" className="tab-icon-item tab-r-2 pl-4 pr-4">
                                    <div className="box-item-tab">
                                        <div className="fi-tab"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                        <div>Tiktok</div>
                                    </div>
                                </label>
                                <label htmlFor="tab-r-3" className="tab-icon-item tab-r-3">
                                    <div className="box-item-tab">
                                        <div className="fi-tab"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                        <div>Youtube</div>
                                    </div>
                                </label>
                            </div>
                            <section className="tab-container">
                                <div className="tabs tab-r-1">
                                {(() => {
                                if (appState?.report?.instagram?.mediaReports != undefined) {
                                return <div>
                                    <div className="toolbar two">
                                        <div className="toolbar-left">
                                        </div>
                                        <div className="toolbar-right">
                                            <div className="toolbar-inner-right hide-on-print">
                                                <div className="toolicon-wrapper status stn">
                                                    <ReactHTMLTableToExcel
                                                        id="test-table-xls-button"
                                                        className="toolicon status export"
                                                        table="table-to-xls"
                                                        filename={"INSTAGRAM CAMPAIGN REPORT"}
                                                        sheet={"INSTAGRAM Campaign Report"}
                                                        buttonText="Export Excel"/>
                                                </div>
                                                <div className="toolicon-wrapper status stn mr-0">
                                                    <button onClick={printContent} className="toolicon status export"><span>Export PDF</span></button>
                                                </div>
                                            </div>
                                            <table id="table-to-xls" className="hide-table">
                                                <tr className="txt-capital">CAMPAIGN REPORT INSTAGRAM - {appState.detail.title}</tr>
                                                    <table><thead></thead><thead></thead></table>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Information</th>
                                                                <th>Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Campaign Name</td>
                                                                <td>{appState.detail.title}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Campaign Description</td>
                                                                <td>{appState.detail.description}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Start Date</td>
                                                                <td>{dayNames[new Date(appState.detail.campaign_start_date).getDay()]+ ", " + new Date(appState.detail.campaign_start_date).getDate()+ " " + monthNames[new Date(appState.detail.campaign_start_date).getMonth()]+ " "+ new Date(appState.detail.campaign_start_date).getFullYear()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>End Date</td>
                                                                <td>{dayNames[new Date(appState.detail.campaign_end_date).getDay()]+ ", " + new Date(appState.detail.campaign_end_date).getDate()+ " " + monthNames[new Date(appState.detail.campaign_end_date).getMonth()]+ " "+ new Date(appState.detail.campaign_end_date).getFullYear()}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table><thead></thead><thead></thead></table>
                                                    <table>
                                                        <tr>STATISTIC REPORT</tr>
                                                        <thead>
                                                            <tr>
                                                                <th>Information</th>
                                                                <th>Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Total Influencer</td>
                                                                <td>{formatFollower(appState.calculation.influencer.total)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Post</td>
                                                                <td>{formatFollower(appState.calculation.posts.total)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Stories</td>
                                                                <td>{formatFollower(appState.calculation.stories.total)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Like</td>
                                                                <td>{formatFollower(appState.calculation.likes.total)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Comment</td>
                                                                <td>{formatFollower(appState.calculation.comments.total)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Engagement</td>
                                                                <td>{(appState.calculation.engagement.total*100 ?? 0).toFixed(2)+"%"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Est. Reach</td>
                                                                <td>{formatFollower(appState.calculation.esr.total)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Media Value</td>
                                                                <td>{"IDR "+currencyFormat(appState.calculation.media_value.total)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table><thead></thead><thead></thead></table>
                                                    <table>
                                                        <tr>AUDIENCE AGE STATS</tr>
                                                        <thead>
                                                            <tr>
                                                                <th>Age Range</th>
                                                                <th>Percentage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>0-18</td>
                                                                <td>{Math.round(ageKurang18/totalAgeReportIG*100)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>18-25</td>
                                                                <td>{Math.round(age18Sampai25/totalAgeReportIG*100)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>25-32</td>
                                                                <td>{Math.round(age25Sampai32/totalAgeReportIG*100)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>32-39</td>
                                                                <td>{Math.round(age32Sampai39/totalAgeReportIG*100)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>39-46</td>
                                                                <td>{Math.round(age39Sampai46/totalAgeReportIG*100)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>46-100</td>
                                                                <td>{Math.round(age46Sampai100/totalAgeReportIG*100)}%</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table><thead></thead><thead></thead></table>
                                                    <table>
                                                        <tr>AUDIENCE GENDER STATS</tr>
                                                        <thead>
                                                            <tr>
                                                                <th>Gender</th>
                                                                <th>Percentage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Male</td>
                                                                <td>{Math.round(genderMaleReport/totalGenderReport*100)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Female</td>
                                                                <td>{Math.round(genderFemaleReport/totalGenderReport*100)}%</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table><thead></thead><thead></thead></table>
                                                    <table>
                                                        <tr>AUDIENCE LOCATION STATS</tr>
                                                        <thead>
                                                            <tr>
                                                                <th>Country</th>
                                                                <th>Percentage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {appState?.content?.instagram?.aggLocations?.length>0 ? appState?.content?.instagram?.aggLocations?.map((repo,index)=> {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{repo.label}</td>
                                                                    <td>{Math.round(repo.count*100)}%</td>
                                                                </tr>
                                                            );
                                                            }): <td>-</td>
                                                        }
                                                        </tbody>
                                                    </table>
                                                    {appState.report.instagram == [] ? appState.report.instagram.length>0 : appState.report.instagram.mediaReports && appState.report.instagram.mediaReports.length> 0 ? appState.report.instagram.mediaReports.map((item,index) => {
                                                    const totalAgeIG = getArraySum(item.age.buckets.map(repo=> repo.count))
                                                    return (
                                                        <div>
                                                            <table><thead></thead></table>
                                                            <table>
                                                                <tr>INFLUENCER BASIC INFORMATION</tr>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Influencer Information</th>
                                                                        <th>Value</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Name</td>
                                                                        <td>{item?.creator?.fullName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Username</td>
                                                                        <td>{item?.creator?.username}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Follower Count</td>
                                                                        <td>{formatFollower(item?.creator?.followerCount)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Content</td>
                                                                        <td>{item?.media?.urlLarge}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Content Type</td>
                                                                        <td>{item?.media?.mediaType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Caption</td>
                                                                        <td>{item?.media?.caption}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Date Posted</td>
                                                                        <td>{dayNames[new Date(item?.media?.takenAt).getDay()]+ ", " + new Date(item?.media?.takenAt).getDate()+ " " + monthNames[new Date(item?.media?.takenAt).getMonth()]+ " "+ new Date(item?.media?.takenAt).getFullYear()+" "+formatAMPM(new Date(item?.media?.takenAt))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total View</td>
                                                                        <td>{item?.media?.viewCount}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Like</td>
                                                                        <td>{item?.media?.likeCount}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Comment</td>
                                                                        <td>{item?.media?.commentCount}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table><thead></thead><thead></thead></table>
                                                            <table>
                                                                <tr>AUDIENCE AGE STATS</tr>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Age Range</th>
                                                                        <th>Percentage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {item?.age?.buckets?.length>0 ? item?.age?.buckets?.map((repo,index)=> {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{repo.label}</td>
                                                                            <td>{Math.round(repo.count/totalAgeIG*100).toFixed(0)}%</td>
                                                                        </tr>
                                                                    );
                                                                    }): <td>-</td>
                                                                }
                                                                </tbody>
                                                            </table>
                                                            <table><thead></thead><thead></thead></table>
                                                            <table>
                                                                <tr>AUDIENCE GENDER STATS</tr>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Gender</th>
                                                                        <th>Percentage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Laki-laki</td>
                                                                        <td>{item.gender.buckets[0].count!=0?Math.round((item.gender.buckets[0].count/(item.gender.buckets[0].count+item.gender.buckets[1].count))*100)+'%':0+'%'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Perempuan</td>
                                                                        <td>{item.gender.buckets[1].count!=0?Math.round((item.gender.buckets[1].count/(item.gender.buckets[0].count+item.gender.buckets[1].count))*100)+'%':0+'%'}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table><thead></thead><thead></thead></table>
                                                            <table>
                                                                <tr>ENGAGEMENT STATS</tr>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Influencer Information</th>
                                                                        <th>Percentage</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Average Comment Ratio</td>
                                                                        <td>{(item?.creator?.engagement?.avgCommentsRatio*100 ?? 0).toFixed(2)}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Average Like Ratio</td>
                                                                        <td>{(item?.creator?.engagement?.avgLikesRatio*100 ?? 0).toFixed(2)}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Est. Reach</td>
                                                                        <td></td>
                                                                        <td>{(formatFollower(Math.round(item?.estimatedReach ?? 0)))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Est. Impressions</td>
                                                                        <td></td>
                                                                        <td>{(formatFollower(Math.round(item?.estimatedImpressions ?? 0)))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Est. Media</td>
                                                                        <td></td>
                                                                        <td>{(formatFollower(Math.round(item?.estimatedMediaValue ?? 0)))}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );
                                                    }): <div>No data</div>
                                                    }
                                            </table>
                                        </div>
                                    </div>     
                                    <div className="accordions-01">
                                        <div className="accordion-01">
                                            <input type="checkbox" id="chck2" defaultChecked />
                                            <label className="analytic-accordion" htmlFor="chck2">
                                                <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6 className="font-medium red">Distribution</h6>
                                                </div>
                                                <div>
                                                    <div className="font-medium hide-on-print">Hide <img src="/img/arrow-up.svg" className="gg-icon-arrow black" />
                                                    </div>
                                                </div>
                                                </div>
                                            </label>
                                            <div className="tab-content big">
                                                <div className="row-of-info">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="box-white for-chart">
                                                                <div className="bw-head">
                                                                <h6 className="font-medium">Age Distribution</h6>
                                                                <div className="help">
                                                                    <span className="tool-tip">
                                                                        <button className="btn btn-help">?</button>
                                                                        <div className="tool-tip-content big">
                                                                            Age distribution of influencer's engaged followers
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                </div>
                                                                <Chart
                                                                    type="bar"
                                                                    options={{
                                                                        chart: {
                                                                            toolbar: {
                                                                                export: {
                                                                                    csv: {
                                                                                        filename: 'Age Distribution - '+appState?.report?.instagram?.campaign?.name,
                                                                                    },
                                                                                    svg: {
                                                                                        filename: 'Age Distribution - '+appState?.report?.instagram?.campaign?.name,
                                                                                    },
                                                                                    png: {
                                                                                        filename: 'Age Distribution - '+appState?.report?.instagram?.campaign?.name,
                                                                                    }
                                                                                }
                                                                            }
                                                                        },
                                                                        labels: arrayReportAgeIG.map(item => item.label.split(' ')) ?? [],
                                                                        yaxis: {
                                                                            min: 0,
                                                                            max: Math.round((largestAgeGroupReport/totalAgeReportIG*100) / 1) * 1,
                                                                            tickAmount: 5,
                                                                            labels: {
                                                                                formatter: function (value) {
                                                                                    return (Math.round(value)) + "%";
                                                                                },
                                                                                offsetX: -12,
                                                                                minWidth: 42,
                                                                                style: {
                                                                                    colors: '#626262',
                                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                                    fontSize: 12,
                                                                                }
                                                                            }
                                                                        },
                                                                        xaxis: {
                                                                            labels: {
                                                                                style: {
                                                                                    colors: '#626262',
                                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                                    fontSize: 12,
                                                                                }
                                                                            }
                                                                        },
                                                                        plotOptions: {
                                                                            bar: {
                                                                                columnWidth: '40%',
                                                                                distributed: true
                                                                            }
                                                                        },
                                                                        stroke: {
                                                                            width: 1,
                                                                            colors: arrayReportAgeIG.map(item => Math.round(item.count/totalAgeReportIG*100) === Math.round(largestAgeGroupReport/totalAgeReportIG*100) ? '#893930' : '#E2E2E2'),
                                                                        },
                                                                        tooltip: {
                                                                            x: {show: false}
                                                                        },
                                                                        colors: arrayReportAgeIG.map(item => Math.round(item.count/totalAgeReportIG*100) === Math.round(largestAgeGroupReport/totalAgeReportIG*100) ? '#893930' : '#E2E2E2'),
                                                                        dataLabels: {
                                                                            enabled: false
                                                                        },
                                                                        legend: {
                                                                            show: false,
                                                                        },
                                                                    }}
                                                                    series={[{ name: 'Jumlah', data: arrayReportAgeIG.map(item => Math.round(item.count/totalAgeReportIG*100)) ?? [] }]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="box-white for-chart">
                                                                <div className="bw-head">
                                                                <h6 className="font-medium">Gender Distribution</h6>
                                                                <div className="help">
                                                                    <span className="tool-tip">
                                                                        <button className="btn btn-help">?</button>
                                                                        <div className="tool-tip-content big">
                                                                            Gender Distribution of influencer's engaged followers
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                </div>
                                                                <div className="chart-center-1">
                                                                    <Chart
                                                                        options={{
                                                                            chart: {
                                                                                type: 'pie',
                                                                            },
                                                                            colors: genderColorReport,
                                                                            legend: {
                                                                                show: false,
                                                                            },
                                                                            plotOptions: {
                                                                                pie: {
                                                                                    dataLabels: {
                                                                                        offset: -30,
                                                                                    },
                                                                                }
                                                                            },
                                                                            stroke: {
                                                                                width: 0,
                                                                            },
                                                                            dataLabels: {
                                                                                formatter: (val) => `${Math.round(val)}%`,
                                                                                style: {
                                                                                    fontFamily: "'Nunito Sans', sans-serif",
                                                                                    fontSize: '14px',
                                                                                    colors: genderLabelColorReport,
                                                                                },
                                                                                dropShadow: {
                                                                                    enabled: false,
                                                                                }
                                                                            },
                                                                            tooltip: {
                                                                                enabled: false,
                                                                            },
                                                                            labels: ['Perempuan', 'Laki-laki']
                                                                        }}
                                                                        type="pie"
                                                                        height={250}
                                                                        width={220}
                                                                        series={[
                                                                            genderFemaleReport,
                                                                            genderMaleReport
                                                                        ]}
                                                                    />
                                                                </div>
                                                                <div className="legend-wrapper">
                                                                {(() => {
                                                                    if (genderFemaleReport > genderMaleReport) {
                                                                        return <div className="legend red"><span className="legend-data">Perempuan ({genderFemaleReport? Math.round(genderFemaleReport/totalGenderReport*100)+'%' : '...'} User)</span></div>
                                                                    } else if (genderFemaleReport < genderMaleReport) {
                                                                        return <div className="legend gray"><span className="legend-data">Perempuan ({genderFemaleReport? Math.round(genderFemaleReport/totalGenderReport*100)+'%' : '...'} User)</span></div>
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (genderMaleReport < genderFemaleReport) {
                                                                        return <div className="legend gray"><span className="legend-data">Laki-laki ({genderMaleReport?  Math.round(genderMaleReport/totalGenderReport*100)+'%'  : '...'} User)</span></div>
                                                                    } else if (genderMaleReport > genderFemaleReport) {
                                                                        return <div className="legend red"><span className="legend-data">Laki-laki ({genderMaleReport?  Math.round(genderMaleReport/totalGenderReport*100)+'%'  : '...'} User)</span></div>
                                                                    }
                                                                })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="box-white for-chart">
                                                                <div className="bw-head">
                                                                    <h6 className="font-medium">Audience Location Distribution</h6>
                                                                    <div className="help">
                                                                        <span className="tool-tip">
                                                                            <button className="btn btn-help">?</button>
                                                                            <div className="tool-tip-content big">
                                                                                Location Distribution of influencer's engagaed followers
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <Chart
                                                                    type="bar"
                                                                    options={{
                                                                        chart: {
                                                                            toolbar: {
                                                                                export: {
                                                                                    csv: {
                                                                                        filename: 'Audience Location Distribution '+appState?.report?.instagram?.campaign?.name,
                                                                                    },
                                                                                    svg: {
                                                                                        filename: 'Audience Location Distribution '+appState?.report?.instagram?.campaign?.name,
                                                                                    },
                                                                                    png: {
                                                                                        filename: 'Audience Location Distribution '+appState?.report?.instagram?.campaign?.name,
                                                                                    }
                                                                                }
                                                                            }
                                                                        },
                                                                        labels: appState?.report?.instagram?.aggLocations?.map(item => item.label.split(' ')) ?? [],
                                                                        yaxis: {
                                                                            min: 0,
                                                                            max: Math.round(Math.round(largestAuditionLocationReport*100) / 1) * 1,
                                                                            tickAmount: 5,
                                                                            labels: {
                                                                                formatter: function (value) {
                                                                                    return (value).toFixed(0) + "%";
                                                                                },
                                                                                offsetX: -12,
                                                                                minWidth: 42,
                                                                                style: {
                                                                                    colors: '#626262',
                                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                                    fontSize: 12,
                                                                                }
                                                                            }
                                                                        },
                                                                        xaxis: {
                                                                            labels: {
                                                                                style: {
                                                                                    colors: '#626262',
                                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                                    fontSize: 12,
                                                                                }
                                                                            }
                                                                        },
                                                                        plotOptions: {
                                                                            bar: {
                                                                                columnWidth: '40%',
                                                                                distributed: true
                                                                            }
                                                                        },
                                                                        stroke: {
                                                                            width: 1,
                                                                            colors: appState?.report?.instagram?.aggLocations?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocationReport*100) ? '#893930' : '#E2E2E2'),
                                                                        },
                                                                        tooltip: {
                                                                            x: {show: false}
                                                                        },
                                                                        colors: appState?.report?.instagram?.aggLocations?.map(item => Math.round(item.count*100) === Math.round(largestAuditionLocationReport*100) ? '#893930' : '#E2E2E2'),
                                                                        dataLabels: {
                                                                            enabled: false
                                                                        },
                                                                        legend: {
                                                                            show: false,
                                                                        },
                                                                    }}
                                                                    series={[{ name: 'Jumlah', data: appState?.report?.instagram?.aggLocations?.map(item =>Math.round(item.count*100)) ?? [] }]}
                                                                />
                                                            </div>
                                                        </div>{/* end location*/}
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>{/* end of info*/}
                                            </div>
                                        </div>
                                    </div>
                                    {appState.report.instagram == [] ? appState.report.instagram.length>0 : appState.report.instagram.mediaReports && appState.report.instagram.mediaReports.length> 0 ? appState.report.instagram.mediaReports.map((repo,index) => {
                                        const totalAgeIG = getArraySum(repo.age.buckets.map(item=> item.count))
                                        return (
                                            <div className="box-white size-a4" key={index}>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <iframe src={repo.media.urlThumbnail.replace(`media/?size=t`, 'embed')} className="img-fluid w-100 mb-4 img-caption" frameBorder="0"/>
                                                        <h6 className="font-medium  hide-on-print">Caption</h6>
                                                        <p className=" hide-on-print">{repo.media.caption}</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="data-hide">
                                                            <div className="row align-items-center justify-content-between">
                                                                <div className="col-8">
                                                                    <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="d-flex align-items-center">
                                                                        <div className="for-img hide"><img src={repo.creator.picture} className="img-fluid img-circle" />
                                                                        </div>
                                                                        <div className="ud-text hide">
                                                                            <h4>{repo.creator.fullName==''?repo.creator.username:repo.creator.fullName} <img src="/img/ico-potential.svg" className="logo-pi" /></h4>
                                                                            <p>{repo.creator.dob}</p>
                                                                            <div>{repo.creator.email}</div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 hide-on-print">
                                                                    <a href={`/goddess-gang/${repo.creator.id}/detail`} className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-4">
                                                            <div className="col-7">
                                                                <p className="font-medium">Engagement</p>
                                                                <div className="engagment-box another">
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.likes)}</h5>
                                                                        <p>Likes</p>
                                                                    </div>
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.comments)}</h5>
                                                                        <p>Comments</p>
                                                                    </div>
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-male.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.followers)}</h5>
                                                                        <p>Followers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                            <p className="font-medium">Gender Distribution</p>
                                                            <div className="box-gd">
                                                                <div className="row">
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{repo.gender.buckets[0].count!=0?Math.round((repo.gender.buckets[0].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                        <p>Laki - Laki</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{repo.gender.buckets[1].count!=0?Math.round((repo.gender.buckets[1].count/(repo.gender.buckets[0].count+repo.gender.buckets[1].count))*100)+'%':0+'%'}</h3>
                                                                        <p>Wanita</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-7">
                                                            <p className="font-medium">Age Distribution</p>
                                                                <div className="engagment-box ad">
                                                                {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                    return (
                                                                        <div className="e-items" key={index}>
                                                                            {(() => {
                                                                                if (item.min == 0){
                                                                                    return <h5 className="red">{">"+item.max}</h5>
                                                                                } else if (index === repo.age.buckets.length - 1){
                                                                                    return <h5 className="red">{"<"+item.max}</h5>
                                                                                } else {
                                                                                    return <h5 className="red">{item.label}</h5>
                                                                                }
                                                                            })()}
                                                                            <h5>{item.count!=0?Math.round(item.count/totalAgeIG*100)+'%':0+'%'}</h5>
                                                                        </div>
                                                                    );
                                                                    }): <div className="e-items">No data</div>
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                            <p className="font-medium">Estimated Reach</p>
                                                            <div className="box-gd">
                                                                <div className="row">
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h5 className="font-medium">{"IDR "+currencyFormat(repo.estimatedMediaValue)}</h5>
                                                                        <p>ESR. Media Values</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                        <p>ESR. Reach</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-4">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{formatFollower(repo.estimatedImpressions)}</h3>
                                                                        <p>EST. Impressions</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        }): <div className="box-white">No data</div>
                                    }
                                    </div>
                                } else {
                                    return <div className="box-white">No data</div>
                                }
                                })()}
                                </div>
                                <div className="tabs tab-r-2">
                                    {appState.report.tiktok == [] ? appState.report.tiktok.length>0 : appState.report.tiktok.mediaReports && appState.report.tiktok.mediaReports.length> 0 ? appState.report.tiktok.mediaReports.map((repo,index) => {
                                        const totalAgeTiktok = getArraySum(repo.age.buckets.map(item=> item.count))
                                        return (
                                            <div className="box-white size-a4" key={index}>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <img src={repo.userdetail.picture} className="img-fluid w-100 mb-4" />
                                                        <h6 className="font-medium  hide-on-print">Caption</h6>
                                                        <p className=" hide-on-print">{repo.media.caption}</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="data-hide">
                                                            <div className="row align-items-center justify-content-between">
                                                                <div className="col-6">
                                                                    <div className="row align-items-center">
                                                                    <div className="col-6">
                                                                        <div className="d-flex align-items-center">
                                                                        <div className="for-img hide"><img src={repo.userdetail.picture} className="img-fluid img-circle" />
                                                                        </div>
                                                                        <div className="ud-text hide">
                                                                            <h4>{repo.userdetail.name} <img src="/img/ico-potential.svg" className="logo-pi" /></h4>
                                                                            <p>{repo.userdetail.dob}</p>
                                                                            <div>{repo.userdetail.email}</div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    {/* <a href="gg-detail.html" className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-4">
                                                            <div className="col-7">
                                                                <p className="font-medium">Engagement</p>
                                                                <div className="engagment-box another">
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.likes)}</h5>
                                                                        <p>Likes</p>
                                                                    </div>
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.comments)}</h5>
                                                                        <p>Comments</p>
                                                                    </div>
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-male.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.followers)}</h5>
                                                                        <p>Followers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                            <p className="font-medium">Gender Distribution</p>
                                                            <div className="box-gd">
                                                                <div className="row">
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{Math.ceil(genderMaleReport/totalGenderReport*100)+'%'}</h3>
                                                                        <p>Laki - Laki</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{Math.ceil(genderFemaleReport/totalGenderReport*100)+'%'}</h3>
                                                                        <p>Wanita</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-7">
                                                            <p className="font-medium">Age Distribution</p>
                                                                <div className="engagment-box ad">
                                                                {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                    return (
                                                                        <div className="e-items" key={index}>
                                                                            {(() => {
                                                                                if (item.min == 0){
                                                                                    return <h5 className="red">{">"+item.max}</h5>
                                                                                } else if (index === repo.age.buckets.length - 1){
                                                                                    return <h5 className="red">{"<"+item.max}</h5>
                                                                                } else {
                                                                                    return <h5 className="red">{item.label}</h5>
                                                                                }
                                                                            })()}
                                                                            <h5>{Math.round(item.count/totalAgeTiktok*100)+'%'}</h5>
                                                                        </div>
                                                                    );
                                                                    }): <div className="e-items">No data</div>
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                            <p className="font-medium">Estimated Reach</p>
                                                            <div className="box-gd">
                                                                <div className="row">
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{formatFollower(repo.estimatedMediaValue)}</h3>
                                                                        <p>ESR. Media Values</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                        <p>ESR. Reach</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        }): <div className="box-white">No data</div>
                                    }
                                </div>
                                <div className="tabs tab-r-3">
                                    {appState.report.youtube == [] ? appState.report.youtube.length>0 : appState.report.youtube.mediaReports && appState.report.youtube.mediaReports.length> 0 ? appState.report.youtube.mediaReports.map((repo,index) => {
                                        const totalAgeYoutube = getArraySum(repo.age.buckets.map(item=> item.count))
                                        return (
                                            <div className="box-white size-a4" key={index}>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <img src={repo.userdetail.picture} className="img-fluid w-100 mb-4" />
                                                        <h6 className="font-medium  hide-on-print">Caption</h6>
                                                        <p className=" hide-on-print">{repo.media.caption}</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="data-hide">
                                                            <div className="row align-items-center justify-content-between">
                                                                <div className="col-6">
                                                                    <div className="row align-items-center">
                                                                    <div className="col-6">
                                                                        <div className="d-flex align-items-center">
                                                                        <div className="for-img hide"><img src={repo.userdetail.picture} className="img-fluid img-circle" />
                                                                        </div>
                                                                        <div className="ud-text hide">
                                                                            <h4>{repo.userdetail.name} <img src="/img/ico-potential.svg" className="logo-pi" /></h4>
                                                                            <p>{repo.userdetail.dob}</p>
                                                                            <div>{repo.userdetail.email}</div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    {/* <a href="gg-detail.html" className="gg-link block text-right">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-4">
                                                            <div className="col-7">
                                                                <p className="font-medium">Engagement</p>
                                                                <div className="engagment-box another">
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-heart.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.likes)}</h5>
                                                                        <p>Likes</p>
                                                                    </div>
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-comment.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.comments)}</h5>
                                                                        <p>Comments</p>
                                                                    </div>
                                                                    <div className="e-items">
                                                                        <img src="/img/icon-male.svg" className="img-fluid mb-3" />
                                                                        <h5>{formatFollower(repo.engagement.followers)}</h5>
                                                                        <p>Followers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                            <p className="font-medium">Gender Distribution</p>
                                                            <div className="box-gd">
                                                                <div className="row">
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div><img src="/img/icon-male.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{Math.ceil(genderMaleReport/totalGenderReport*100)+'%'}</h3>
                                                                        <p>Laki - Laki</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div><img src="/img/icon-female.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{Math.ceil(genderFemaleReport/totalGenderReport*100)+'%'}</h3>
                                                                        <p>Wanita</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-7">
                                                            <p className="font-medium">Age Distribution</p>
                                                                <div className="engagment-box ad">
                                                                {repo.age.buckets && repo.age.buckets.length> 0 ? repo.age.buckets.map((item,index) => {
                                                                    return (
                                                                        <div className="e-items" key={index}>
                                                                            {(() => {
                                                                                if (item.min == 0){
                                                                                    return <h5 className="red">{">"+item.max}</h5>
                                                                                } else if (index === repo.age.buckets.length - 1){
                                                                                    return <h5 className="red">{"<"+item.max}</h5>
                                                                                } else {
                                                                                    return <h5 className="red">{item.label}</h5>
                                                                                }
                                                                            })()}
                                                                            <h5>{Math.round(item.count/totalAgeYoutube*100)+'%'}</h5>
                                                                        </div>
                                                                    );
                                                                    }): <div className="e-items">No data</div>
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                            <p className="font-medium">Estimated Reach</p>
                                                            <div className="box-gd">
                                                                <div className="row">
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-engagment.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{formatFollower(repo.estimatedMediaValue)}</h3>
                                                                        <p>ESR. Media Values</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="box-gd-item">
                                                                    <div className="mr-2"><img src="/img/icon-signal.svg" className="img-fluid" /></div>
                                                                    <div>
                                                                        <h3 className="font-medium">{formatFollower(repo.estimatedReach)}</h3>
                                                                        <p>ESR. Reach</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        }): <div className="box-white">No data</div>
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                }
            })()}
        </div>
    );
}

export default List;