import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getRedeemRewardApi = (searchKey, params, searchTable) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/redeem_reward`,
            {
                table: searchKey.length < 1 ? '' : searchTable,
                search: searchKey
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const updateRedeemRewardApi = (id, statusRedeemReward) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/redeem_reward/${id}`,
            {status: statusRedeemReward},
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {getRedeemRewardApi, updateRedeemRewardApi}
