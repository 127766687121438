import React from "react";
import {Button, Form, Input} from "antd";
import {Link} from "react-router-dom";
import {KeyOutlined, UserOutlined} from '@ant-design/icons';
import {loginUserApi} from "../../api/user.api";

class LoginFormComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            loginForm: [
                {
                    name: 'email',
                    placeholder: 'Email',
                    icon: <UserOutlined className="site-form-item-icon"/>
                }, {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Password',
                    icon: <KeyOutlined/>
                }
            ],
            loadings: false
        }
    }

    onFinishLoginForm = (values) => {
        this.setState({loadings: true})
        loginUserApi(values)
            .then(result => {
                this.setState({loadings: false})
                localStorage.setItem('TOKEN_AUTH', result.token)
                window.location.reload()
            }).catch(err => {
            console.log(err)
            this.setState({loadings: false})
            alert('Maaf terjadi kesalahan!')
        })
    }

    render() {
        const {loginForm, loadings} = this.state
        return (
            <Form
                name="normal_login"
                className="login-form"
                onFinish={this.onFinishLoginForm}
            >
                {
                    loginForm.map((item) => (
                        <Form.Item
                            key={item.name}
                            name={item.name}
                            rules={[{required: true, message: item.message}]}
                        >
                            <Input prefix={item.icon} placeholder={item.placeholder} type={item.type}/>
                        </Form.Item>
                    ))
                }
                <a className="d-flex flex-column align-items-end" href="/forgot-password">
                    Forgot password
                </a>
                <Form.Item style={{marginTop: 10}}>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loadings}>
                        Login
                    </Button>
                    {/*<Link to={'/register'}>*/}
                    {/*    <Button htmlType="button" style={{margin: '0 8px'}} className="login-form-button">*/}
                    {/*        Register*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                </Form.Item>
            </Form>
        );
    }
}

export default LoginFormComponent
