import {formatDateToReadable} from "./general-variable";

const setRedeemRewardData = (rows) => {
    let redeemRewardList = []

    rows.forEach((row) => {
        let redeemRewardDetail = {
            id: row?.id,
            createdAt: formatDateToReadable(row?.createdAt),
            name: row?.user?.full_name,
            email: row?.user?.email,
            phoneNumber: row?.user?.reward_phone,
            reward: row?.reward?.name,
            point: row?.history_point,
            address: `${row?.address}, ${row?.city?.name}, ${row?.city?.province?.name}, ${row?.city?.postal_code}`,
            status: row?.status,

        }
        redeemRewardList.push(redeemRewardDetail)
    })
    return redeemRewardList
}

export {setRedeemRewardData}
