import React from "react";
import {Select, Spin} from "antd";
import _ from "lodash";
import {searchUserByEmailApi} from "../api/user.api";

export const DebounceSelect = ({fetchOptions, debounceTimeout = 800, ...props}) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const {disabled, value, onChange} = props

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchUserByEmailApi({email: value}).then((response) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                let newOptions = []
                response.map((user) => {
                    let data = {
                        label: `${user.email}`,
                        value: user.email,
                        id: user.id
                    }
                    newOptions.push(data)
                })

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return _.debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small"/> : null}
            disabled={disabled}
            value={value}
            onChange={onChange}
            options={options}
            style={{
                width: '100%',
            }}
            mode="multiple"
            placeholder="Select users"
        />
    );
}
