import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const uploadImage = async (file) => {
    const fd = new FormData();
    fd.append('image', file);
    const response = await axios.post(
        `${cloudFunctionBaseUrl()}/api/v2/admin/questioner/upload-image`, fd,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token,
            }
        }
    );

    return response.data?.data
}

const getQuestioner = async () => {
    const response = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/questioner`,
        {
            headers: {
                'Authorization': token,
            }
        }
    );

    return response.data;
}

const saveQuestioner = async (payload) => {
    const response = await axios.post(
        `${cloudFunctionBaseUrl()}/api/v2/admin/questioner`,
        payload,
        {
            headers: {
                'Authorization': token,
            }
        }
    );

    return response.data;
}

const CreateQuestionerApi = {
    uploadImage,
    getQuestioner,
    saveQuestioner
}

export default CreateQuestionerApi;
