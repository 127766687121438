import React, { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import List from './List';
import Loading from '../../component/Loading';
import { getCampaignApi } from "../../api/campaign.api";
import {useHistory, useLocation} from 'react-router';
import ReactExport from "react-data-export";
import SortFilter from '../../component/Common/sort-filter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Campaign() {
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [search, setSearch]         = useState('');
    const [limit, setLimit]           = useState(6);
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('id');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const dateRangePickerRef          = useRef(null);
    const [selectedPage, setPage]     = useState(1);
    const [ranges, setRanges]         = useState([50, 100]);
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        pageStatus: window.location.search.split('?')[1],
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1
    });
    const {control, formState: {errors}, watch, register, setValue, getValues, handleSubmit, reset} = useForm({mode: "all"});
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const updateSorting = (value) => {
        setAppState({loading: true});
        setSort(value)
        let status = appState.pageStatus
        let params = {
            size: limit,
            page: appState.current_page,
            orderby: orderby,
            sort: value,
            start_date: startDate,
            end_date: endDate,
            key: search
        }
        getCampaignApi(status, params)
        .then(result => {
            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, pageStatus: appState.pageStatus });
        }).catch(err => {
            console.log(err)
        })
    }
    function onChangeStatus(e) {
        setAppState({ loading: true, pageStatus: e });
        let status = e
        let params = {
            size: limit,
            page: selectedPage,
            orderby: orderby,
            sort: sort
        }
        getCampaignApi(status, params)
        .then(result => {
            setAppState({ loading: false, pageStatus: e, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage });
        }).catch(err => {
            console.log(err)
        })
        
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        const fetchData = async () => {
            let status = appState.pageStatus
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignApi(status, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, pageStatus: appState.pageStatus });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const handleCancel = (event, picker) => {
        setAppState({ loading: true});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        const fetchData = async () => {
            let status = appState.pageStatus
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignApi(status, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, pageStatus: appState.pageStatus});
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const onSubmit = (data) => alert(JSON.stringify(data));
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        setAppState({ loading: true});
        const fetchData = async () => {
            let status = appState.pageStatus
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignApi(status, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, pageStatus: appState.pageStatus });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 6) {
            const limit = 6;
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, pageStatus: appState.pageStatus});
                const fetchData = async () => {
                    let status = appState.pageStatus
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        orderby: orderby,
                        sort: sort,
                        start_date: startDate,
                        end_date: endDate,
                        key: search
                    }
                    getCampaignApi(status, params)
                        .then(result => {
                            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, pageStatus: appState.pageStatus });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        } else {
            const limit = e.target.value
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, pageStatus: appState.pageStatus});
                const fetchData = async () => {
                    let status = appState.pageStatus
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        orderby: orderby,
                        sort: sort,
                        start_date: startDate,
                        end_date: endDate,
                        key: search
                    }
                    getCampaignApi(status, params)
                        .then(result => {
                            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, pageStatus: appState.pageStatus });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        }
    }
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true});
        const fetchData = async () => {
            let status = appState.pageStatus
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort
            }
            getCampaignApi(status, params)
                .then(result => {
                    setAppState({ loading: false, pageStatus: appState.pageStatus, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const fetchData = async () => {  
        setAppState({ loading: true });
        if (appState.pageStatus == "Draft") {
            let status = ""
            let params = {
                status: "Draft",
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort
            }
            getCampaignApi(status, params)
            .then(result => {
                setAppState({ loading: false, pageStatus: status, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page });
            }).catch(err => {
                console.log(err)
            }) 
        } else {
            let status = appState.pageStatus == undefined ? "upcoming" : appState.pageStatus 
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort
            }
            getCampaignApi(status, params)
            .then(result => {
                setAppState({ loading: false, pageStatus: status, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page });
            }).catch(err => {
                console.log(err)
            })
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    const defaultTab = window.location.search == "";
    const upcomingTab = window.location.search == "?upcoming";
    const approvementTab = window.location.search == "?approvement";
    const ongoingTab = window.location.search == "?ongoing";
    const pastTab = window.location.search == "?past";
    const draftTab = window.location.search == "?Draft";
    // const MIN_VAL = 5;
    // const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
    return ( 
        <div id="campaign-wrapper">
            <div className="tab-campaign">
                {(() => {
                    if (defaultTab || upcomingTab){
                        return <input defaultChecked="checked" id="tab1" type="radio" name="status"/>
                    } else if (approvementTab) {
                        return <input defaultChecked="checked"  id="tab2" type="radio" name="status"/>
                    } else if (ongoingTab) {
                        return <input defaultChecked="checked" id="tab3" type="radio" name="status"/>
                    } else if (pastTab) {
                        return <input defaultChecked="checked" id="tab4" type="radio" name="status"/>
                    } else if (draftTab) {
                        return <input defaultChecked="checked" id="tab5" type="radio" name="status"/>
                    }
                })()}
                <div className="tab-campaign">
                    <Link to={{
                            pathname: `/campaign`,
                            search: 'upcoming',
                        }} onClick={() => onChangeStatus('upcoming')}>
                        <label htmlFor="tab1" className="tab-campaign-item tab1">Upcoming</label>
                    </Link>
                    <Link to={{
                            pathname: `/campaign`,
                            search: 'approvement',
                        }} onClick={() => onChangeStatus('approvement')}>
                        <label htmlFor="tab2" className="tab-campaign-item tab2">Approvement</label>
                    </Link>
                    <Link to={{
                            pathname: `/campaign`,
                            search: 'ongoing',
                        }} onClick={() => onChangeStatus('ongoing')}>
                        <label htmlFor="tab3" className="tab-campaign-item tab3">Ongoing</label>
                    </Link>
                    <Link to={{
                            pathname: `/campaign`,
                            search: 'past',
                        }} onClick={() => onChangeStatus('past')}>
                        <label htmlFor="tab4" className="tab-campaign-item tab4">Past</label>
                    </Link>
                    <Link to={{
                            pathname: `/campaign`,
                            search: 'Draft',
                        }} onClick={() => onChangeStatus('?status=Draft')}>
                        <label htmlFor="tab5" className="tab-campaign-item tab5">Draft</label>
                    </Link>
                </div>
                <section className="tab-container">
                    <div id="inner-campaign-wrapper">
                        <div className="main-dash">
                            <div className="toolbar three ">
                                <div className="toolbar-left">
                                    <div className="toolbar-inner-left">
                                        <div>
                                            <Link to="/campaign/create" className="btn btn-primary"><span className="font-plus">+ </span>
                                            Create Campaign</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="toolbar-center" style={{marginLeft: '-80px'}}>
                                <div className="d-flex justify-content-center">
                                    <SortFilter value={sort} onClick={(e) => updateSorting(e)} />
                                    <div className="toolicon-wrapper status date mr-2 ml-2">
                                        <div className="toolicon status date">
                                            <span>
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                initialSettings={{
                                                    autoUpdateInput: false,
                                                    locale: {
                                                    cancelLabel: 'Clear',
                                                    },
                                                    startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
                                                    endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                                                }}
                                                autoUpdateInput={true}
                                                startDate={moment(startDate, 'YYYY-MM-DD')}
                                                endDate={moment(endDate, 'YYYY-MM-DD')}
                                                locale={{ format: "YYYY-MM-DD" }} 
                                                onCancel={handleCancel}
                                                onApply={onChangeDate}>
                                                <input type="text" className="form-control noborder-date" value={labelDate} onChange={onChangeDate} placeholder="Start Date - End Date"/>
                                            </DateRangePicker>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="toolicon-wrapper status export">
                                        <ExcelFile element={<button  className="toolicon status export"><span>Export</span></button>} filename={"campaign-list-"+dateNow+"-"+timeNow}>
                                            <ExcelSheet data={appState.data} name="List Campaign">
                                                <ExcelColumn label="Title" value="title"/>
                                                <ExcelColumn label="Point" value="point"/>
                                                <ExcelColumn label="Status" value="status"/>
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>
                                    <div className="page-item">
                                        <form className="page-input">
                                            <div className="form-group mb-0">
                                                <label htmlFor="page-count" className="sr-only">Page</label>
                                                <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            decimalScale={0}
                                            displayType="input"
                                            type="text"
                                            value={limit}
                                            allowNegative={false}
                                            onChange={onChangeLimit}
                                            className="form-control"
                                        />
                                            </div>
                                        </form>
                                        <div className="text-page">/ Page</div>
                                    </div>
                                </div>
                                </div>
                                <div className="toolbar-right">
                                <div className="toolbar-inner-right">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group search-input">
                                            <label htmlFor="inputSearch" className="sr-only">Search</label>
                                            <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                            <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                        </div>
                                    </form>
                                </div>
                                
                                </div>
                            </div>{/* end of toolbar*/}
                        </div>
                        <ListLoading isLoading={appState.loading} pageStatus={appState.pageStatus} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} total={appState.total} defaultTab={defaultTab} upcomingTab={upcomingTab} approvementTab={approvementTab} ongoingTab={ongoingTab} pastTab={pastTab} draftTab={draftTab} currencyFormat={currencyFormat} set_page={(e)=>set_page(e)}/>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default Campaign;
