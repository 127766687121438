import React from "react";
import LoginFormComponent from "./login-form.component";

class LoginPages extends React.PureComponent {

    render() {
        return (
            <div className="App">
                <div className="auth-wrapper">
                    <div className="auth-inner" >
                        <LoginFormComponent />
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginPages
