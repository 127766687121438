import React from "react";
import {createBannerApi} from "../../api/banner.api";
import BannerFormComponent from "./banner-form.component";

const FormData = require('form-data');

class CreateBannerComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            switchValue: true,
            loading: false
        }
    }

    onFinishCreateBannerHandler = (values, fileImage) => {
        if(fileImage.length < 1){
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData();
            const url = !!values.url ? values.url : ''
            const valid = this.state.switchValue ? 1 : 0
            form.append('image', fileImage[0])
            form.append('title', values.title)
            form.append('url', url)
            form.append('valid', valid)
            createBannerApi(form)
                .then(result => {
                    this.setState({loading: false})
                    alert("Banner berhasil di upload")
                    window.location.href = '/banners'
                }).catch(err => {
                console.log(err)
            })
        }
    }

    onChangeSwitchValue = (value) => {
        this.setState({switchValue: value})
    }

    render() {
        const {switchValue, loading} = this.state
        return <BannerFormComponent switchValue={switchValue} onChange={this.onChangeSwitchValue} loading={loading}
                                 onFinish={this.onFinishCreateBannerHandler} labelCol={2} wrapperCol={10}/>
    }
}

export default CreateBannerComponent
