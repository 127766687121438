import React, { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import swal from 'sweetalert';
import parse from 'html-react-parser';
import FusionCharts, { formatNumber } from 'fusioncharts';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loading from '../../../component/Loading';
import { getCampaignInfluencer, getshareCampaign, getCampaignContent } from "../../../api/campaign.api";
import ReactExport from "react-data-export";
import {cloudFunctionBaseUrl} from "../../../ui-util/general-variable";
import List from './List';
import SortFilter from '../../../component/Common/sort-filter';
import CustomPillSelect from '../../../component/Common/custom-pill-select';
const token = localStorage.getItem('TOKEN_AUTH');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function DetailCampaign({ match }) {
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [search, setSearch]               = useState('');
    const [limit, setLimit]                 = useState(3);
    const [sort, setSort]                   = useState('desc');
    const [orderby, setOrderby]             = useState('id');
    const [startDate, setStartDate]         = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]             = useState(location.state?.end_date ?? '');
    const dateRangePickerRef                = useRef(null);
    const [selectedPage, setPage]           = useState(1);
    const [pillSelected, setPillSelected]   = useState([]);
    const pillValue = [
        {value: "Publish Konten", text: "Konten Published"},
    ];
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        detail: null,
        calculation: null,
        detailcal: null,
        content: null,
        report: null,
        disabled: false,
        total: null,
        contentMediaInstagram: null,
        reportMediaInstagram: null,
        per_page: 3,
        current_page: 1
    });
    const {control, formState: {errors}, watch, register, setValue, getValues, handleSubmit, reset} = useForm({mode: "all"});
    const { params: { id }, } = match;
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = new Date().getFullYear() + "-" + ((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1))) + "-" + ((new Date().getDate() > 9) ? new Date().getDate() : ('0' + new Date().getDate()));     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const updateSorting = (value) => {
        setSort(value)
        fetchData()
    }

    const editPill = (value) => {
        var array = pillSelected
        var index = array.indexOf(value)
        if (index !== -1) {
            array.splice(index, 1)
        }else{
            array.push(value)
        }
        setPillSelected(array)
        fetchData()
    }

    const resetPill = () => {
        setPillSelected([])
        fetchData()
    }
    const onSubmit = (data) => alert(JSON.stringify(data));
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report });
        const fetchData = async () => {
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignInfluencer(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignInfluencer(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report  });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const handleCancel = (event, picker) => {
        setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignInfluencer(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeDateContent (event,picker) {
        setAppState({loading: true, calculation: appState.calculation, detail: appState.detail, report: appState.report, content: appState.content});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                start_date: startDate,
                end_date: endDate
            }
            getCampaignContent(id, params)
                .then(result => {
                    setAppState({ loading: false, data: appState.data, total: appState.total, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, report: appState.report, reportMediaInstagram: appState.reportMediaInstagram, content: result.data });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const handleCancelContent = (event, picker) => {
        setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                start_date: startDate,
                end_date: endDate
            }
            getCampaignContent(id, params)
                .then(result => {
                    setAppState({ loading: false, data: appState.data, total: appState.total, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, content: result.data, report: appState.report, reportMediaInstagram: appState.reportMediaInstagram, });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 3) {
            const limit = 3;
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        orderby: orderby,
                        sort: sort,
                        start_date: startDate,
                        end_date: endDate,
                        key: search
                    }
                    getCampaignInfluencer(id, params)
                        .then(result => {
                            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        } else {
            const limit = e.target.value
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        orderby: orderby,
                        sort: sort,
                        start_date: startDate,
                        end_date: endDate,
                        key: search
                    }
                    getCampaignInfluencer(id, params)
                        .then(result => {
                            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        }
    }
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getCampaignInfluencer(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const fetchData = async () => {
        setAppState({ loading: true, calculation: appState.calculation, detail: appState.detail, content: appState.content, report: appState.report});
        let params = {
            size: limit,
            page: selectedPage,
            orderby: orderby,
            sort: sort,
            status: pillSelected
        }
        const respDetailCampaign= await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/detail/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        const respInfluencer= await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/influencer/${id}`, {
            params,
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        const respContent = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/content/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        const respReport = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/report/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        setAppState({ loading: false, detail: respDetailCampaign.data.data, calculation: respDetailCampaign.data.calculation, data: respInfluencer.data.data.rows, total: respInfluencer.data.data.count,  per_page: limit, current_page: appState.current_page, content: respContent.data.data, contentMediaInstagram : respContent.data.data.instagram.mediaReports, report: respReport.data.data, reportMediaInstagram : respReport.data.data.instagram.mediaReports});
    };
    useEffect(() => {
        fetchData();
    }, []);
    function shareCampaign(){
        setAppState({disabled: true, detail: appState.detail, calculation: appState.calculation, data: appState.data, total: appState.total, per_page: appState.per_page,  per_page: limit, current_page: appState.current_page, content: appState.content,  report: appState.report});
        getshareCampaign(id)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    function justNumbers(string) {
        var numsStr = string.replace(/\D/g,"");
        return parseInt(numsStr);
    }
    const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const defaultTab = window.location.search == "";
    const influencerTab = window.location.search == "?tab=influencer";
    const contentTab = window.location.search == "?tab=content";
    const reportTab = window.location.search == "?tab=report";
    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
        
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.ceil(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    function printContent() {
        window.print();
    }
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
        return strTime;
    }
    const MIN_VAL = 3;
    const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
    return ( 
        <div id="campaign-wrapper" className="page-print">
            {(() => {
                if (appState.detail != null){
                    var start_campaign_time = appState.detail.campaign_start_time.slice(0, 2);
                    if (start_campaign_time < 12) {
                        var start_campaign_time = "Pagi";
                    } else if (start_campaign_time < 15) {
                        var start_campaign_time = "Siang";
                    } else if (start_campaign_time < 18) {
                        var start_campaign_time = "Sore";
                    } else {
                        var start_campaign_time = "Malam";
                    }

                    var end_campaign_time = appState.detail.campaign_end_time.slice(0, 2);
                    if (end_campaign_time < 12) {
                        var end_campaign_time = "Pagi";
                    } else if (end_campaign_time < 15) {
                        var end_campaign_time = "Siang";
                    } else if (end_campaign_time < 18) {
                        var end_campaign_time = "Sore";
                    } else {
                        var end_campaign_time = "Malam";
                    }
            return <div className="tab-campaign">
                {(() => {
                    if (defaultTab || influencerTab){
                        return <input defaultChecked="checked" id="tab1" type="radio" name="looke-tab" />
                    } else if (contentTab) {
                        return <input defaultChecked="checked"  id="tab2" type="radio" name="looke-tab" />
                    } else if (reportTab) {
                        return <input defaultChecked="checked" id="tab3" type="radio" name="looke-tab" />
                    }
                })()}
                <div className="tab-campaign hide-on-print">
                    <Link to={{
                            pathname: `/campaign/${id}/detail`,
                            search: 'tab=influencer',
                        }}>
                        <label htmlFor="tab1" className="tab-campaign-item tab1">Influencer</label>
                    </Link>
                    <Link to={{
                            pathname: `/campaign/${id}/detail`,
                            search: 'tab=content',
                        }}>
                        <label htmlFor="tab2" className="tab-campaign-item tab2">Content</label>
                    </Link>
                    <Link to={{
                            pathname: `/campaign/${id}/detail`,
                            search: 'tab=report',
                        }}>
                        <label htmlFor="tab3" className="tab-campaign-item tab3">Report</label>
                    </Link>
                </div>
                <section className="tab-container">
                    <div id="inner-campaign-wrapper">
                    {(() => {
                        if (defaultTab || influencerTab || contentTab){
                        return  <div className="main-dash">
                            <div className="row justify-content-between mb-4">
                                <div className="col-4">
                                    <h4 className="font-medium">{appState.detail.title}</h4>
                                </div>
                                <div className="col-4 hide-on-print">
                                    <div className="d-flex justify-content-end">
                                        {(() => {
                                            if (appState.detail.campaign_start_date > dateNow){
                                                return <button onClick={shareCampaign} className="btn btn-primary mr-2"><img src="/img/ico-share.svg" className="img-fluid white" /> Share Campaign</button>
                                            } else {
                                                return <span></span>
                                            }
                                        })()}
                                        <Link to="/goddess-gang" className="btn btn-primary"><img src="/img/ico-add.svg" className="img-fluid white" /> Add Influencer</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <img src={appState.detail.thumbnail} className="img-fluid" />
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between mb-3">
                                                <div style={{width: '49%'}}>
                                                    <div className="font-medium mb-2">Start Date</div>
                                                    <div className="d-flex align-items-center">
                                                        <div><img src="/img/ico-calendar.svg" width={25} className="img-fluid red" /></div>
                                                        <div style={{fontSize: '0.82vw'}}>{dayNames[new Date(appState.detail.campaign_start_date).getDay()]+ ", " + new Date(appState.detail.campaign_start_date).getDate()+ " " + monthNames[new Date(appState.detail.campaign_start_date).getMonth()]+ " "+ new Date(appState.detail.campaign_start_date).getFullYear()}</div>
                                                    </div>
                                                </div>
                                                <div style={{width: '49%'}}>
                                                    <div className="font-medium mb-2">End Date</div>
                                                    <div className="d-flex align-items-center">
                                                        <div><img src="/img/ico-calendar.svg" width={25} className="img-fluid red" /></div>
                                                        <div style={{fontSize: '0.82vw'}}>{dayNames[new Date(appState.detail.campaign_end_date).getDay()]+ ", " + new Date(appState.detail.campaign_end_date).getDate()+ " " + monthNames[new Date(appState.detail.campaign_end_date).getMonth()]+ " "+ new Date(appState.detail.campaign_end_date).getFullYear()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-3">
                                                <div style={{width: '49%'}}>
                                                    <div className="font-medium  mb-2">Start Time</div>
                                                    <div className="d-flex align-items-center">
                                                        <div><img src="/img/ico-clock.svg" width={25} className="img-fluid" /></div>
                                                        <div style={{fontSize: '0.82vw'}}>{start_campaign_time+ ", " + appState.detail.campaign_start_time.slice(0, 5)} WIB</div>
                                                    </div>
                                                </div>
                                                <div style={{width: '49%'}}>
                                                    <div className="font-medium  mb-2">End Time</div>
                                                    <div className="d-flex align-items-center">
                                                        <div><img src="/img/ico-clock.svg" width={25} className="img-fluid" /></div>
                                                        <div style={{fontSize: '0.82vw'}}>{end_campaign_time+ ", " + appState.detail.campaign_end_time.slice(0, 5)} WIB</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div style={{width: '49%'}}>
                                                    <div className="font-medium mb-2">Point Campaign</div>
                                                    <p style={{fontSize: '0.82vw'}}>{currencyFormat(appState.detail.point)} Point</p>
                                                </div>
                                                <div style={{width: '49%'}}>
                                                    <div className="font-medium mb-2">Upload</div>
                                                    <div className="d-flex align-items-center">
                                                        {(() => {
                                                            if (appState.detail.instagram_task.content != '' || appState.detail.instagram_task.mention != '' || appState.detail.instagram_task.hastag != ''){
                                                                return <div className="mr-2"><img src="/img/insta-outline.svg" width={20} className="img-fluid red" /></div>
                                                            } else {
                                                                return <div></div>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (appState.detail.facebook_task.content != '' || appState.detail.facebook_task.mention != '' || appState.detail.facebook_task.hastag != ''){
                                                                return <div className="mr-2"><img src="/img/facebook-outline.svg" width={20} className="img-fluid red" /></div>
                                                            } else {
                                                                return <div></div>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (appState.detail.youtube_task.content != '' || appState.detail.youtube_task.mention != '' || appState.detail.youtube_task.hastag != ''){
                                                                return <div className="mr-2"><img src="/img/youtube-outline.svg" width={20} className="img-fluid red" /></div>
                                                            } else {
                                                                return <div></div>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (appState.detail.tiktok_task.content != '' || appState.detail.tiktok_task.mention != '' || appState.detail.tiktok_task.hastag != ''){
                                                                return <div><img src="/img/tiktik-outline.svg" width={20} className="img-fluid red" /></div>
                                                            } else {
                                                                return <div></div>
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="font-medium mb-3">Deskripsi Campaign</div>
                                            <div className="bx-caption">
                                                {parse(appState.detail.description)}
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="d-flex justify-content-between mb-2">
                                                <div style={{width: '49%'}}>
                                                    <div className="ud-bx-info">
                                                        <h6>Total Influencers</h6>
                                                        <div className="main-di">{formatFollower(appState.calculation.influencer.total)} People</div>
                                                        <p>{appState.calculation.influencer.caption}</p>
                                                        <div className="for-ico-bottom">
                                                            <img src="/img/ico-man.svg" className="img-fluid" alt="Icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width: '49%'}}>
                                                    <div className="ud-bx-info">
                                                        <h6>Total Engagement</h6>
                                                        <div className="main-di">{(appState.calculation.engagement.total*100 ?? 0).toFixed(2)+"%"} People </div>
                                                        <p>{appState.calculation.engagement.caption}</p>
                                                        <div className="for-ico-bottom">
                                                            <img src="/img/plane-outline.svg" className="img-fluid white" alt="Icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div style={{width: '49%'}}>
                                                    <div className="ud-bx-info">
                                                        <h6>Total Stories</h6>
                                                        <div className="main-di">{formatFollower(appState.calculation.stories.total)} Story</div>
                                                        <p>{appState.calculation.stories.caption}</p>
                                                        <div className="for-ico-bottom">
                                                            <img src="/img/ico-video.svg" width={30} className="img-fluid white" alt="Icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width: '49%'}}>
                                                    <div className="ud-bx-info">
                                                        <h6>Total Post</h6>
                                                        <div className="main-di">{formatFollower(appState.calculation.posts.total)} Post</div>
                                                        <p>{appState.calculation.posts.caption} </p>
                                                        <div className="for-ico-bottom">
                                                            <img src="/img/ico-image.svg" className="img-fluid" alt="Icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(() => {
                                if (defaultTab || influencerTab){
                                return  <div className="toolbar two hide-on-print">
                                    <div className="toolbar-left">
                                        <div className="d-flex">
                                            
                                            <CustomPillSelect data={pillValue} title="Status" onClick={(e) => editPill(e)} onReset={(e) => resetPill()} selected={pillSelected} width="300px" />
                                            <SortFilter value={sort} onClick={(e) => updateSorting(e)} />
                                            <div className="toolicon-wrapper status date">
                                                <div className="toolicon status date">
                                                    <span>
                                                    <DateRangePicker
                                                        ref={dateRangePickerRef}
                                                        initialSettings={{
                                                            autoUpdateInput: false,
                                                            locale: {
                                                            cancelLabel: 'Clear',
                                                            },
                                                            startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
                                                            endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                                                        }}
                                                        autoUpdateInput={true}
                                                        startDate={moment(startDate, 'YYYY-MM-DD')}
                                                        endDate={moment(endDate, 'YYYY-MM-DD')}
                                                        locale={{ format: "YYYY-MM-DD" }} 
                                                        onCancel={handleCancel}
                                                        onApply={onChangeDate}>
                                                        <input type="text" className="form-control noborder-date" value={labelDate} onChange={onChangeDate} placeholder="Start Date - End Date"/>
                                                    </DateRangePicker>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="toolicon-wrapper status export mr-0">
                                                <ExcelFile element={<button  className="toolicon status export"><span>Export</span></button>} filename={"campaign-influencer-list-"+dateNow+"-"+timeNow}>
                                                    <ExcelSheet data={appState.data} name="List Campaign">
                                                        <ExcelColumn label="Influencer" value={(col) => col.full_name}/>
                                                        <ExcelColumn label="Followers" value={(col) => col.user_analityc == null ? "-" : formatFollower(col.user_analityc.follower)}/>
                                                        <ExcelColumn label="Status" value={(col) => col.campaign_users[0].status}/>
                                                        <ExcelColumn label="Contact" value={(col) => col.email}/>
                                                        <ExcelColumn label="Cost" value={(col) => col.cost}/>
                                                        <ExcelColumn label="EMV" value={(col) => "$"+justNumbers(formatNumber(col.emv))}/>
                                                        <ExcelColumn label="Rating" value={(col) => currencyFormat(col.rating)}/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </div>
                                            <div className="page-item">
                                                <form className="page-input">
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="page-count" className="sr-only">Page</label>
                                                        <NumberFormat
                                                            thousandsGroupStyle="thousand"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix=""
                                                            decimalScale={0}
                                                            displayType="input"
                                                            type="text"
                                                            value={limit}
                                                            allowNegative={false}
                                                            onChange={onChangeLimit}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </form>
                                                <div className="text-page">/ Page</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="toolbar-right">
                                        <div className="toolbar-inner-right">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group search-input">
                                                    <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                    <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                                    <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                } else if(contentTab) {
                                    return <div className="hide-on-print">
                                    <div className="toolbar two mb-4">
                                        <div className="toolbar-left">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="font-medium mb-1">Start Date - End Date</div>
                                                    <div className="toolicon-wrapper status date">
                                                        <div className="toolicon status date">
                                                            <span>
                                                            <DateRangePicker
                                                                ref={dateRangePickerRef}
                                                                initialSettings={{
                                                                    autoUpdateInput: false,
                                                                    locale: {
                                                                    cancelLabel: 'Clear',
                                                                    },
                                                                    startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
                                                                    endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                                                                }}
                                                                autoUpdateInput={true}
                                                                startDate={moment(startDate, 'YYYY-MM-DD')}
                                                                endDate={moment(endDate, 'YYYY-MM-DD')}
                                                                locale={{ format: "YYYY-MM-DD" }} 
                                                                onCancel={handleCancelContent}
                                                                onApply={onChangeDateContent}>
                                                                <input type="text" className="form-control noborder-date" defaultValue={labelDate} placeholder="Start Date - End Date"/>
                                                            </DateRangePicker>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolbar-right mt-4">
                                            <div className="toolbar-inner-right">
                                                <div className="toolicon-wrapper status stn">
                                                    <button onClick={printContent} className="toolicon status export"><span>Export</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            })()}
                        </div>
                        } else if (reportTab){
                            return <div className="main-dash">
                            <div className="row mb-4">
                              <div className="col-4">
                                <h4 className="font-medium">{appState.detail.title}</h4>
                              </div>
                              <div className="col-4">
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col-8">
                                <div className="row">
                                  <div className="col-4">
                                    <img src={appState.detail.thumbnail} className="img-fluid" />
                                  </div>
                                  <div className="col-8">
                                    <div className="font-medium mb-3">Detail Campaign</div>
                                    <div className="bx-caption">
                                      <div className="d-flex justify-content-between mb-2">
                                        <div style={{width: '49%'}}>
                                          <div className="font-medium mb-2">Start Date</div>
                                          <div className="d-flex align-items-center">
                                            <div><img src="/img/ico-calendar.svg" width={25} className="img-fluid red" /></div>
                                            <div style={{fontSize: '0.82vw'}}>{dayNames[new Date(appState.detail.campaign_start_date).getDay()]+ ", " + new Date(appState.detail.campaign_start_date).getDate()+ " " + monthNames[new Date(appState.detail.campaign_start_date).getMonth()]+ " "+ new Date(appState.detail.campaign_start_date).getFullYear()}</div>
                                          </div>
                                        </div>
                                        <div style={{width: '49%'}}>
                                          <div className="font-medium mb-2">End Date</div>
                                          <div className="d-flex align-items-center">
                                            <div><img src="/img/ico-calendar.svg" width={25} className="img-fluid red" /></div>
                                            <div style={{fontSize: '0.82vw'}}>{dayNames[new Date(appState.detail.campaign_end_date).getDay()]+ ", " + new Date(appState.detail.campaign_end_date).getDate()+ " " + monthNames[new Date(appState.detail.campaign_end_date).getMonth()]+ " "+ new Date(appState.detail.campaign_end_date).getFullYear()}</div>
                                          </div>
                                        </div>
                                        <div style={{width: '49%'}}>
                                          <div className="font-medium mb-2">Point Campaign</div>
                                          <p style={{fontSize: '0.82vw'}}>{currencyFormat(appState.detail.point)} Point</p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <div style={{width: '49%'}}>
                                          <div className="font-medium  mb-2">Start Time</div>
                                          <div className="d-flex align-items-center">
                                            <div><img src="/img/ico-clock.svg" width={25} className="img-fluid" /></div>
                                            <div style={{fontSize: '0.82vw'}}>{start_campaign_time+ ", " + appState.detail.campaign_start_time.slice(0, 5)} WIB</div>
                                          </div>
                                        </div>
                                        <div style={{width: '49%'}}>
                                          <div className="font-medium  mb-2">End Time</div>
                                          <div className="d-flex align-items-center">
                                            <div><img src="/img/ico-clock.svg" width={25} className="img-fluid" /></div>
                                            <div style={{fontSize: '0.82vw'}}>{end_campaign_time+ ", " + appState.detail.campaign_end_time.slice(0, 5)} WIB</div>
                                          </div>
                                        </div>
                                        <div style={{width: '49%'}}>
                                            <div className="font-medium mb-2">Upload</div>
                                            <div className="d-flex align-items-center">
                                                {(() => {
                                                    if (appState.detail.instagram_task.content != '' || appState.detail.instagram_task.mention != '' || appState.detail.instagram_task.hastag != ''){
                                                        return <div className="mr-2"><img src="/img/insta-outline.svg" width={20} className="img-fluid red" /></div>
                                                    } else {
                                                        return <div></div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (appState.detail.facebook_task.content != '' || appState.detail.facebook_task.mention != '' || appState.detail.facebook_task.hastag != ''){
                                                        return <div className="mr-2"><img src="/img/facebook-outline.svg" width={20} className="img-fluid red" /></div>
                                                    } else {
                                                        return <div></div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (appState.detail.youtube_task.content != '' || appState.detail.youtube_task.mention != '' || appState.detail.youtube_task.hastag != ''){
                                                        return <div className="mr-2"><img src="/img/youtube-outline.svg" width={20} className="img-fluid red" /></div>
                                                    } else {
                                                        return <div></div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (appState.detail.tiktok_task.content != '' || appState.detail.tiktok_task.mention != '' || appState.detail.tiktok_task.hastag != ''){
                                                        return <div><img src="/img/tiktik-outline.svg" width={20} className="img-fluid red" /></div>
                                                    } else {
                                                        return <div></div>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="font-medium mb-3">Deskripsi Campaign</div>
                                    <div className="bx-caption mh170">
                                        {parse(appState.detail.description)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                    <h6>Total Influencers</h6>
                                        <div className="main-di">{formatFollower(appState.calculation.influencer.total)} People</div>
                                        <p>{appState.calculation.influencer.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-man.svg" className="img-fluid" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                    <h6>Total Likes</h6>
                                        <div className="main-di">{formatFollower(appState.calculation.likes.total)} Likes </div>
                                        <p>{appState.calculation.likes.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/plane-outline.svg" className="img-fluid white" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                        <h6>Total Stories</h6>
                                        <div className="main-di">{formatFollower(appState.calculation.stories.total)} Story</div>
                                        <p>{appState.calculation.stories.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-video.svg" width={30} className="img-fluid white" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                        <h6>Total Post</h6>
                                        <div className="main-di">{formatFollower(appState.calculation.posts.total)} Post</div>
                                        <p>{appState.calculation.posts.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-image.svg" className="img-fluid" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                        <h6>Total Comment</h6>
                                        <div className="main-di">{formatFollower(appState.calculation.comments.total)} Comment</div>
                                        <p>{appState.calculation.comments.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-comment.svg" className="img-fluid" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                    <h6>Total Engagement</h6>
                                        <div className="main-di">{(appState.calculation.engagement.total*100 ?? 0).toFixed(2)+"%"} People</div>
                                        <p>{appState.calculation.engagement.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-broadcast.svg" className="img-fluid white" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                        <h6>Total EST. Reach</h6>
                                        <div className="main-di">{formatFollower(appState.calculation.esr.total)}</div>
                                        <p>{appState.calculation.esr.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-video.svg" width={30} className="img-fluid white" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '12%'}}>
                                    <div className="ud-bx-info">
                                        <h6>Total Media Value</h6>
                                        <div className="main-di">{"IDR "+currencyFormat(appState.calculation.media_value.total)}</div>
                                        <p>{appState.calculation.media_value.caption}</p>
                                        <div className="for-ico-bottom">
                                            <img src="/img/ico-image.svg" className="img-fluid" alt="Icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        }
                    })()}
                        <ListLoading isLoading={appState.loading} formatAMPM={formatAMPM} calculation={appState.calculation} defaultTab={defaultTab} influencerTab={influencerTab} contentTab={contentTab} reportTab={reportTab} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} detail={appState.detail} contentMediaInstagram={appState.contentMediaInstagram} reportMediaInstagram={appState.reportMediaInstagram} content={appState.content} report={appState.report} total={appState.total} justNumbers={justNumbers} currencyFormat={currencyFormat} formatFollower={formatFollower} set_page={(e)=>set_page(e)} id={id} printContent={printContent}/>
                    </div>
                </section>
            </div>
            } else {
                return <div className="content-wrapper">
                    <div className="credential pt-5">
                        <div className="credential-box-noborder">
                        <div className="load-2">
                            <p>Loading... Please wait</p>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        </div>
                    </div>
                </div>
                }
            })()}
        </div>
    );
}


export default DetailCampaign;
