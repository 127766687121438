import React from 'react';
import PropTypes from "prop-types";
import { SortDownAlt, SortUpAlt } from 'react-bootstrap-icons';

function SortFilter(props) {
  const {value, onClick} = props

  function itemSelected(e){
    let returnValue = value === "asc" ? "desc" : "asc"
    onClick(returnValue)
  }
  
  return (
    <div className="toolicon-wrapper check-btn" onClick={(e) => itemSelected(e)}>
      <button className="toolicon" type="button" style={{background: "transparent"}}>
        {value==="asc" ? <SortUpAlt color="black" size={18} /> : <SortDownAlt color="black" size={18} />}
      </button>
    </div>
  )
}

SortFilter.defaultProps = {
  value: "asc",
  onClick: (value) => {console.log(`element value ${value} clicked`)}
}

SortFilter.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};
  
export default SortFilter;