import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import List from './List';
import Loading from '../../../component/Loading';
import { getGroupApi } from "../../../api/group.api";
import {useHistory, useLocation} from 'react-router';
import ReactExport from "react-data-export";
import SortFilter from '../../../component/Common/sort-filter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function GoddessGangGroup() {
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const [search, setSearch]         = useState('');
    const [limit, setLimit]           = useState(6);
    const [sorting, setSorting]       = useState('desc');
    const [orderby, setOrderby]       = useState('created_at');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const dateRangePickerRef          = useRef(null);
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        disabled: false,
        total: null,
        sort: "desc",
        per_page: 6,
        current_page: 1
    });
    const {control, formState: {errors}, watch, register, setValue, getValues, handleSubmit, reset} = useForm({mode: "all"});
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const updateSorting = (value) => {
        setSorting(value)
        setAppState({loading: true});
        let params = {
            size: limit,
            page: appState.current_page,
            start_date: startDate,
            end_date: endDate,
            sort: value,
            orderby: orderby,
            key: search
        }
        getGroupApi(params)
            .then(result => {
                setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, status: appState.status });
            }).catch(err => {
                console.log(err)
            })
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                start_date: startDate,
                end_date: endDate,
                sort: sorting,
                orderby: orderby,
                key: search
            }
            getGroupApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, status: appState.status });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const handleCancel = (event, picker) => {
        setAppState({ loading: true});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                start_date: startDate,
                end_date: endDate,
                sort: sorting,
                orderby: orderby,
                key: search
            }
            getGroupApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, status: appState.status });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const onSubmit = (data) => alert(JSON.stringify(data));
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                start_date: startDate,
                end_date: endDate,
                sort: sorting,
                orderby: orderby,
                key: search
            }
            getGroupApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, status: appState.status });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: selectedPage,
                start_date: startDate,
                end_date: endDate,
                sort: sorting,
                orderby: orderby,
                key: search
            }
            getGroupApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 6) {
            const limit = 6;
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true});
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        start_date: startDate,
                        end_date: endDate,
                        sort: sorting,
                        orderby: orderby,
                        key: search
                    }
                    getGroupApi(params)
                        .then(result => {
                            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        } else {
            const limit = e.target.value
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true});
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        start_date: startDate,
                        end_date: endDate,
                        sort: sorting,
                        orderby: orderby,
                        key: search
                    }
                    getGroupApi(params)
                        .then(result => {
                            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        }
    }
    const fetchData = async () => {
        setAppState({ loading: true});
        let params = {
            size: limit,
            page: appState.current_page,
            start_date: startDate,
            end_date: endDate,
            sort: sorting,
            orderby: orderby,
            key: search
        }
        getGroupApi(params)
        .then(result => {
            setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page });
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        fetchData();
    }, []);
    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const MIN_VAL = 6;
    const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
    return ( 
        <div id="main-content">
            <div className="main-dash">
                <div className="toolbar three ">
                    <div className="toolbar-left toolbar-left-group">
                        <div className="toolbar-inner-left">
                            <Link to="/goddess-gang/create-group" className="btn btn-primary"><span className="font-medium">+</span> Create Group</Link>
                        </div>
                    </div>
                    <div className="toolbar-center">
                        <div className="d-flex justify-content-center">
                            <SortFilter value={sorting} onClick={(e) => updateSorting(e)} />

                            <div className="toolicon-wrapper status date ml-3 mr-4">
                                <div className="toolicon status date">
                                    <span>
                                    <DateRangePicker
                                        ref={dateRangePickerRef}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                            cancelLabel: 'Clear',
                                            },
                                            maxDate : new Date().toLocaleDateString("en-US"),
                                            startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
                                            endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                                        }}
                                        autoUpdateInput={true}
                                        startDate={moment(startDate, 'YYYY-MM-DD')}
                                        endDate={moment(endDate, 'YYYY-MM-DD')}
                                        locale={{ format: "YYYY-MM-DD" }} 
                                        onCancel={handleCancel}
                                        onApply={onChangeDate}>
                                        <input type="text" className="form-control noborder-date" value={labelDate} onChange={onChangeDate} placeholder="Start Date - End Date"/>
                                    </DateRangePicker>
                                    </span>
                                </div>
                            </div>
                            <div className="toolicon-wrapper status export mr-0">
                                <ExcelFile element={<button  className="toolicon status export"><span>Export</span></button>} filename={"group-list-"+dateNow+"-"+timeNow}>
                                    <ExcelSheet data={appState.data} name="Group">
                                        <ExcelColumn label="Group Name" value="name"/>
                                        <ExcelColumn label="Date" value={(col) => new Date(col.created_at).getDate()+ " " + monthNames[new Date(col.created_at).getMonth()]+ " "+ new Date(col.created_at).getFullYear()}/>
                                        <ExcelColumn label="Total User" value={(col) => currencyFormat(col.total_user)+" Orang"}/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            <div className="page-item">
                                <form className="page-input">
                                    <div className="form-group mb-0">
                                        <label htmlFor="page-count" className="sr-only">Page</label>
                                        <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            decimalScale={0}
                                            displayType="input"
                                            type="text"
                                            value={limit}
                                            allowNegative={false}
                                            onChange={onChangeLimit}
                                            className="form-control"
                                        />
                                    </div>
                                </form>
                                <div className="text-page">/ Page</div>
                            </div>
                        </div>
                    </div>
                    <div className="toolbar-right">
                        <div className="toolbar-inner-right">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group search-input">
                                    <label htmlFor="inputSearch" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                    <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ListLoading isLoading={appState.loading} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} total={appState.total} set_page={(e)=>set_page(e)}  currencyFormat={currencyFormat} monthNames={monthNames}/>               
        </div>
    );
}


export default GoddessGangGroup;
