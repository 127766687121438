import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Spin } from 'antd';

function CustomAutoComplete(props) {
  const {title, icon, data, onClick, active} = props
  const [searchValue, setSearchValue] = useState("")
  const [showDropdown, setShowDropdown]  = useState('')
  const [activeText, setActiveText]  = useState('')
  const node = useRef()

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOuter)
    if (data!==null && data.length>0){
      let tempData = data.filter((raw) => {
        if(active===raw.value)
          return true
        return false
      });
      if (tempData.length>0) {
        setActiveText(tempData[0].text)
      } else {
        setActiveText("")
      }
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOuter)
    }
  }, [])

    function handleSearchChange(value) {
      setSearchValue(value)
    }

    if (data===null || typeof(data)==="undefined") {
      return (<div className="filterSpinner"><Spin /></div>)
    }

    function generateList(){
      let content = [];
      let lowerCaseValue = searchValue.toLowerCase()
      for (let item of data) {
        if (searchValue==="" || item.text.toLowerCase().includes(lowerCaseValue)) {
          content.push(<li className={`tool-select-item ${active === item.value ? "active" : ""}`} onClick={(e) => handleClick(e, item.value)} key={item.value} style={{"cursor": "pointer"}}>{item.text}</li>);
        }
      }
      return content;
    }

    function handleClick(e, value){
      setShowDropdown('')
      setActiveText(value==="" ? title : e.target.innerText)
      onClick(value)
    }

    function handleClickOuter(e) {
      if (node.current.contains(e.target)) {
        return;
      }
      
      setShowDropdown('')
      return;
    }

    function disabledEvent(e){
      setShowDropdown('showed')
      e.preventDefault()
      return false
    }
    
    return (
      <div className="toolicon-wrapper status sm" ref={node}>
        <button onClick={(e)=>disabledEvent(e)} className={`toolicon status ${icon}` }>
          <span className={`value-showed-text`}>
            {activeText!=="" ? activeText : title}
          </span>
        </button>
        <div className={`tool-inner ${icon} left ${showDropdown}`} style={{"maxWidth": "250px"}}>
            <div className="tool-wrapper">
              <input type="text" value={searchValue} onChange={(e) => handleSearchChange(e.target.value)} style={{"width": "100%", "border": "1px solid #ccc", "padding": "5px 7px", "marginBottom": "10px"}} placeholder={`Search...`} />
              <ul className="tool-select">
              <li className={`tool-select-item ${active === null || active === "" ? "active" : ""}`} onClick={(e) => handleClick(e, "")} key={0} style={{"cursor": "pointer"}}>{title}</li>
                {generateList()}
              </ul>
            </div>
        </div>
      </div>
    )
}

CustomAutoComplete.defaultProps = {
  icon: "",
  active: "",
  searchValue: "",
  data: [],
  onClick: (value) => {console.log(`element value ${value} clicked`)}
}

CustomAutoComplete.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  active: PropTypes.string,
  searchValue: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
};
  
export default CustomAutoComplete;