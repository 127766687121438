import React from "react";
import RecommendationUpdateComponent from "./recommendation-update.component";
import PageHeaderComponent from "../../component/page-header.component";

class RecommendationUpdatePage extends React.PureComponent {

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {params} = this.props.match
        return (
            <>
                <PageHeaderComponent title={'Update Product Recommendation'}/>
                <RecommendationUpdateComponent params={params}/>
            </>
        )
    }
}

export default RecommendationUpdatePage
