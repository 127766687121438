import React, { useEffect, useState } from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';

function Header() {
    const history = useHistory();
    const logoutHandler = () => {
        localStorage.removeItem('TOKEN_AUTH')
        localStorage.removeItem('full_name')
        localStorage.removeItem('group_id')
        localStorage.removeItem('group_name')
        window.location.reload()
    }
    const pathRaw = window.location.pathname.split('/')
    const id_socmed = pathRaw[3]
    return ( 
        <div className="top-nav hide-on-print">
            <ul className="main-title">
                <Switch>
                    <Route exact path='/mission-registered'>
                        <li className="list-mt"><Link to="/mission-registered">User Registered</Link></li>
                    </Route>
                    <Route exact path='/mission-registered/:id/detail'>
                        <li className="list-mt"><a href="/mission-registered">User Registered</a></li><li className="list-mt"><div>User Detail</div></li>
                    </Route>
                    <Route exact path='/user-rejected/:id/detail'>
                        <li className="list-mt"><a href="/mission-registered/rejected">User Rejected</a></li><li className="list-mt"><div>User Detail</div></li>
                    </Route>
                    <Route path='/mission-registered/rejected'>
                        <li className="list-mt"><Link to="/mission-registered/rejected">Rejected</Link></li>
                    </Route>
                    <Route exact path='/goddess-gang'>
                        <li className="list-mt"><Link to="/goddess-gang">List Goddess Gang User</Link></li>
                    </Route>
                    <Route exact path='/goddess-gang/:id/detail'>
                        <li className="list-mt"><a href="/goddess-gang">Goddess Gang</a></li><li className="list-mt"><div>User Detail</div></li>
                    </Route>
                    <Route exact path='/goddess-gang/:id/content-detail'>
                        <li className="list-mt"><a href="/goddess-gang">Goddess Gang</a></li><li className="list-mt"><div>User Detail</div></li>
                    </Route>
                    <Route exact path='/goddess-gang/:id/:id/analytics'>
                        <li className="list-mt"><a href="/goddess-gang">Goddess Gang</a></li><li className="list-mt"><button onClick={() => history.goBack()}>User Detail</button></li><li className="list-mt">
                        {(() => {
                            if (id_socmed == 1){
                                return <div>Instagram Analytics</div>
                            } else if (id_socmed == 2){
                                return <div>Facebook Analytics</div>
                            } else if (id_socmed == 4){
                                return <div>Tiktok Analytics</div>
                            }else if (id_socmed == 5){
                                return <div>Youtube Analytics</div>
                            }
                        })()}
                        </li>
                    </Route>
                    <Route exact path='/mission-registered/:id/:id/analytics'>
                        <li className="list-mt"><a href="/mission-registered">User Registered</a></li><li className="list-mt"><button onClick={() => history.goBack()}>User Detail</button></li><li className="list-mt">
                        {(() => {
                            if (id_socmed == 1){
                                return <div>Instagram Analytics</div>
                            } else if (id_socmed == 2){
                                return <div>Facebook Analytics</div>
                            } else if (id_socmed == 4){
                                return <div>Tiktok Analytics</div>
                            }else if (id_socmed == 5){
                                return <div>Youtube Analytics</div>
                            }
                        })()}
                        </li>
                    </Route>
                    <Route exact path='/user-rejected/:id/:id/analytics'>
                        <li className="list-mt"><a href="/mission-registered/rejected">User Rejected</a></li><li className="list-mt"><button onClick={() => history.goBack()}>User Detail</button></li><li className="list-mt">
                        {(() => {
                            if (id_socmed == 1){
                                return <div>Instagram Analytics</div>
                            } else if (id_socmed == 2){
                                return <div>Facebook Analytics</div>
                            } else if (id_socmed == 4){
                                return <div>Tiktok Analytics</div>
                            }else if (id_socmed == 5){
                                return <div>Youtube Analytics</div>
                            }
                        })()}
                        </li>
                    </Route>
                    <Route path='/goddess-gang/group'>
                        <li className="list-mt"><Link to="/goddess-gang/group">Group</Link></li>
                    </Route>
                    <Route path='/goddess-gang/:id/group'>
                        <li className="list-mt"><Link to="/goddess-gang/group">Group</Link></li>
                    </Route>
                    <Route path='/goddess-gang/create-group'>
                        <li className="list-mt"><Link to="/goddess-gang/group">Group</Link></li>
                    </Route>
                    <Route exact path='/campaign'>
                        <li className="list-mt"><Link to="/campaign">List Campaign</Link></li>
                    </Route>
                    <Route exact path='/campaign/create'>
                        <li className="list-mt"><Link to="/campaign/create">Create Campaign</Link></li>
                    </Route>
                    <Route exact path='/campaign/:id/edit'>
                        <li className="list-mt"><Link to="/campaign/create">Edit Campaign</Link></li>
                    </Route>
                    <Route exact path='/campaign/:id/detail'>
                        <li className="list-mt"><a href="/campaign">List Campaign</a></li><li className="list-mt"><div>Detail Campaign</div></li>
                    </Route>
                    <Route exact path='/campaign/approvement'>
                        <li className="list-mt"><Link to="/campaign/:id/approvement">Approvement Campaign</Link></li>
                    </Route>
                    <Route exact path='/campaign/:id/approvement'>
                        <li className="list-mt"><Link to="/campaign/:id/approvement">Approvement Campaign</Link></li>
                    </Route>
                    <Route exact path='/redeem-point'>
                        <li className="list-mt"><Link to="/redeem-point">List User Reedem Point</Link></li>
                    </Route>
                    <Route exact path='/redeem-point/:id/detail'>
                        <li className="list-mt"><a href="/redeem-point">List User Reedem Point</a></li><li className="list-mt"><div>Reedem Point Detail</div></li>
                    </Route>
                    <Route path='/setting-banner'>
                        <li className="list-mt"><Link to="/setting-banner">Goddess Gang Cover</Link></li>
                    </Route>
                    <Route path='/setting-create-questioner-form'>
                        <li className="list-mt"><Link to="/setting-create-questioner-form">Create Questioner Form</Link></li>
                    </Route>
                    <Route path='/setting-create-timeline'>
                        <li className="list-mt"><Link to="/setting-create-timeline">Create Timeline</Link></li>
                    </Route>
                </Switch>
            </ul>
            <div className="top-nav-left">
                <div className="panel-admin-wrap">
                    <Link to="#dropdown" className="panel-admin">
                        <img src="/img/ico-user.svg" alt="notification" />
                        <div className="admin-info">
                            <div className="admin-name">{localStorage.getItem('full_name')}</div>
                            <div className="admin-info">Admin</div>
                        </div>
                    </Link>
                    <div className="panel-admin-dropdown">
                        <button onClick={logoutHandler} className="panel-admin-btn">Logout</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
