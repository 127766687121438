import React from "react";
import LevelFormComponent from "./level-form.component";
import {createLevelApi} from "../../api/level.api";

class CreateLevelComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            loading: false
        }
    }

    onFinishCreateLevel = (values) => {
        this.setState({loading: true})
        createLevelApi(values)
            .then(response => {
                alert('Level berhasil dibuat!')
                this.setState({loading: false})
                window.location.href = '/level'
            })
            .catch(err => {
                console.log(err)
            })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {loading} = this.state
        return <LevelFormComponent onFinish={this.onFinishCreateLevel} loading={loading} labelCol={3}/>
    }
}

export default CreateLevelComponent
