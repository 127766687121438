import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import InterestFormComponent from "./interest-form.component";
import {createInterestApi} from "../../api/interest.api";

class CreateInterestPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            loading: false
        }
    }

    onFinishCreateInterest = (values, fileImage) => {
        if (fileImage.length < 1) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData();
            form.append('interestCategoryId', values.interestCategoryId)
            form.append('name', values.name)
            form.append('image', fileImage[0])

            createInterestApi(form)
                .then(result => {
                    this.setState({loading: false})
                    alert("Interest berhasil di upload")
                    window.location.href = '/interest'
                }).catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {loading} = this.state
        return (
            <>
                <PageHeaderComponent title={'Create Interest'}/>
                <InterestFormComponent onFinish={this.onFinishCreateInterest} labelCol={2} loading={loading}/>
            </>
        )
    }
}

export default CreateInterestPage
