import React from "react";
import {Button, Form, Image, Input, Row, Switch, Upload} from "antd";
import {BannerDetailObj} from "../../models/banner.model";
import {UploadOutlined} from '@ant-design/icons';
import {isEqual} from "../../ui-util/general-variable";

class BannerFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            bannerForm: [
                {
                    label: 'Title',
                    name: 'title',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input title!',
                    placeholder: 'Cth: Looke Cosmetics'
                }, {
                    label: 'URL',
                    name: 'url',
                    type: 'input',
                    required: false,
                    placeholder: 'Cth: https://www.lookecosmetics.com/'
                }, {
                    label: 'Image',
                    name: 'image',
                    type: 'image',
                    required: true,
                    messageAlert: 'Please upload image!'
                }, {
                    label: 'Active',
                    name: 'valid',
                    type: 'toggle',
                }
            ],
            fileImageList: [],
            showImage: false,
            imagePath: '',
            bannerDetail: {...BannerDetailObj}
        }
    }

    componentDidMount(): void {
        const {bannerDetail} = this.props
        if (!!bannerDetail) {
            this.setState({showImage: true})
        }
        this.formRef.current.setFieldsValue(bannerDetail)
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {bannerDetail} = this.props
        if (!isEqual(this.props.bannerDetail, prevProps.bannerDetail)) {
            this.formRef.current.setFieldsValue(bannerDetail)
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {bannerForm, fileImageList, showImage} = this.state
        const {bannerDetail, onFinish, switchValue, onChange, loading, labelCol, wrapperCol} = this.props
        return (
            <>
                <Form ref={this.formRef}
                      name="control-hooks" onFinish={(value) => onFinish(value, fileImageList)} labelCol={{span: labelCol}}
                      wrapperCol={{span: wrapperCol}}>
                    {
                        bannerForm.map((bannerItem, index) => {
                            switch (bannerItem.type) {
                                case 'image':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={bannerItem.label}
                                            name={bannerItem.name}
                                            rules={[{
                                                required: bannerItem.required,
                                                message: bannerItem.messageAlert,
                                            }]}
                                        >
                                            <Upload
                                                fileList={fileImageList}
                                                onRemove={this.removeButtonHandler}
                                                beforeUpload={this.beforeUploadImage}
                                            >
                                                {
                                                    showImage &&
                                                    <Image
                                                        src={bannerDetail.image}
                                                        width={100}
                                                    />
                                                }
                                                <Row
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginTop: 10
                                                    }}>
                                                    <Button icon={<UploadOutlined/>}>
                                                        Upload
                                                    </Button>
                                                    <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                                </Row>
                                            </Upload>
                                        </Form.Item>

                                    )
                                case 'toggle':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={bannerItem.label}
                                            name={bannerItem.name}
                                            rules={[{
                                                required: bannerItem.required,
                                                message: bannerItem.messageAlert,
                                            }]}
                                        >
                                            <Switch checked={switchValue} onChange={(value) => onChange(value)}/>
                                        </Form.Item>
                                    )
                                default:
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={bannerItem.label}
                                            name={bannerItem.name}
                                            rules={[{
                                                required: bannerItem.required,
                                                message: bannerItem.messageAlert,
                                            }]}
                                        >
                                            <Input placeholder={bannerItem.placeholder}/>
                                        </Form.Item>
                                    )
                            }
                        })
                    }
                    <Form.Item
                        wrapperCol={{
                            span: 8,
                            offset: 4,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default BannerFormComponent
