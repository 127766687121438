import React from "react";
import {Button, Form, Input} from "antd";
import {changePasswordApi} from "../../api/user.api";

class ChangePasswordComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            changePasswordForm: [
                {
                    name: 'old_password',
                    label: 'Password Lama',
                    messageAlert: 'Masukkan password lama!',
                    hasFeedback: false,
                    additionalRule: null
                }, {
                    name: 'password',
                    label: 'Password Baru',
                    messageAlert: 'Masukkan password baru!',
                    hasFeedback: true,
                    additionalRule: null
                }, {
                    name: 'confirm_password',
                    label: 'Konfirmasi Password Baru',
                    messageAlert: 'Masukkan konfirmasi password!',
                    hasFeedback: true,
                    additionalRule: ({getFieldValue}) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Password yang anda masukkan tidak cocok');
                        },
                    })
                }
            ],
            loadings: false
        }
    }

    onFinishChangePassword = (values) => {
        this.setState({loadings: true})
        changePasswordApi(values)
            .then(result => {
                this.setState({loadings: false})
                alert('Password anda berhasil diupdate. Silahkan login kembali!')
                localStorage.removeItem('TOKEN_AUTH')
                window.location.href = '/'
            }).catch(err => {
            this.setState({loading: false})
            alert('Maaf terjadi kesalahan!')
            console.log(err)
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {changePasswordForm, loadings} = this.state
        return (
            <Form name="normal_login"
                  className="login-form" onFinish={this.onFinishChangePassword} labelCol={{span: 4}}
                  wrapperCol={{span: 10}}>
                {
                    changePasswordForm.map((item) => (
                        <Form.Item
                            key={item.name}
                            label={item.label}
                            name={item.name}
                            hasFeedback={item.hasFeedback}
                            rules={[{
                                required: true,
                                message: item.messageAlert,
                            },
                                !!item.additionalRule && item.additionalRule
                            ]}

                        >
                            <Input.Password/>
                        </Form.Item>
                    ))
                }
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loadings}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default ChangePasswordComponent
