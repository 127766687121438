import React,{ useState } from 'react';
import ReactPaginate from 'react-paginate';
import ReactExport from "react-data-export";
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import { useForm } from 'react-hook-form';
import { Popover } from 'antd';
import { createGroupApi } from "../../../../api/group.api";
import SortArrow from "../../../../component/Common/sort-arrow";
import { getUserApprovedApi } from '../../../../api/goddess-gang.api';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const List = (props) => {
    const { data, total, current_page, per_page, formatFollower } = props;
    let newData = data;
    const [appState, setAppState]  = useState({
        dataState: data,
        sort: 'desc',
        orderBy: 'created_at',
        current_page: current_page,
        total: total,
        per_page: per_page,
        disabled: false,
        total_member : null
    });
    const {
        register,
        formState: { errors },
        handleSubmit,
        control
      } = useForm({
        mode: "all"// "onChange"
    });
    let today = new Date();
    let dateNow = today
        .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        })
        .replace(/[^\d,.-]/g, "-");
    let timeNow =
    today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const [search, setSearch]         = useState('');
    const [sort, setSort]             = useState('');
    const [orderby, setOrderby]       = useState('');
    const [isCheckAll, setIsCheckAll]       = useState(false);
    const [isCheck, setIsCheck]             = useState([]);
    const [isEmailAll, setIsEmailAll] = useState(false);
    const [isEmail, setIsEmail] = useState([]);
    const [name, setName]                   = useState('');
    const [user_id, setUser_id]             = useState(0);
    const [total_member, setTotalMember]    = useState(0);
    const [ig_followers_min, setIgFollowersMin]            = useState(0);
    const [ig_followers_max, setIgFollowersMax]            = useState('');
    const [ig_er_min, setIgErMin]                          = useState(0);
    const [ig_er_max, setIgErMax]                          = useState('');
    const [ig_reach_min, setIgReachMin]                    = useState(0);
    const [ig_reach_max, setIgReachMax]                    = useState('');
    const [tiktok_followers_min, setTiktokFollowersMin]    = useState(0);
    const [tiktok_followers_max, setTiktokFollowersMax]    = useState('');
    const [tiktok_er_min, setTiktokErMin]                  = useState(0);
    const [tiktok_er_max, setTiktokErMax]                  = useState('');
    const [tiktok_reach_min, setTiktokReachMin]            = useState(0);
    const [tiktok_reach_max, setTiktokReachMax]            = useState('');
    const [filterSosmedOpen, setFilterSosmedOpen] = useState(false);
    function updateSorting (value) {
        setSort(value.sort)
        setOrderby(value.orderBy)
        let params = {
            size: appState.per_page,
            page: appState.current_page,
            orderby: value.orderBy,
            sort: value.sort,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: search
        }
        getUserApprovedApi(params)
        .then(result => {
            newData = result.users.rows;
            setAppState({dataState: newData, groups: appState.groups, total: result.users.count, current_page: appState.current_page, per_page: appState.per_page, sort: sort, orderBy: orderby})
        }).catch(err => {
            console.log(err)
        })
        setIsCheckAll(false)
    }
    function FilterRange(){
        setAppState({ loading: true});
        setFilterSosmedOpen(false);
        const fetchData = async () => {
            let params = {
                size: appState.per_page,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max,
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                key: search
            }
            getUserApprovedApi(params)
                .then(result => {
                    newData = result.users.rows;
                    setAppState({dataState: newData, 
                      campaigns: appState.campaigns,
                      groups: appState.groups,
                      total: result.users.count,
                      per_page: appState.per_page,
                      current_page: appState.current_page})
                }).catch(err => {
                    console.log(err)
                })
            };
            setIsCheckAll(false)
        fetchData();
    }
    function resetIg() {
      const ig_followers_min = 0
      setIgFollowersMin(ig_followers_min)
      const ig_followers_max = ''
      setIgFollowersMax(ig_followers_max)
      const ig_er_min = 0
      setIgErMin(ig_er_min)
      const ig_er_max = ''
      setIgErMax(ig_er_max)
      const ig_reach_min = 0
      setIgReachMin(ig_reach_min)
      const ig_reach_max = ''
      setIgReachMax(ig_reach_max)
      const fetchData = async () => {
          let params = {
              size: per_page,
              page: appState.current_page,
              orderby: orderby,
              sort: sort,
              ig_followers_min: ig_followers_min,
              ig_followers_max: ig_followers_max,
              ig_er_min: ig_er_min,
              ig_er_max: ig_er_max,
              ig_reach_min: ig_reach_min,
              ig_reach_max: ig_reach_max,
              tiktok_followers_min: tiktok_followers_min,
              tiktok_followers_max: tiktok_followers_max,
              tiktok_er_min: tiktok_er_min,
              tiktok_er_max: tiktok_er_max,
              tiktok_reach_min: tiktok_reach_min,
              tiktok_reach_max: tiktok_reach_max,
              key: search
          }
          getUserApprovedApi(params)
              .then(result => {
                newData = result.users.rows;
                  setAppState({ loading: false, dataState: newData, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page });
              }).catch(err => {
                  console.log(err)
              })
          };
      fetchData()
    }
    function resetTiktok() {
      const tiktok_followers_min = 0
      setTiktokFollowersMin(tiktok_followers_min)
      const tiktok_followers_max = ''
      setTiktokFollowersMax(tiktok_followers_max)
      const tiktok_er_min = 0
      setTiktokErMin(tiktok_er_min)
      const tiktok_er_max = ''
      setTiktokErMax(tiktok_er_max)
      const tiktok_reach_min = 0
      setTiktokReachMin(tiktok_reach_min)
      const tiktok_reach_max = ''
      setTiktokReachMax(tiktok_reach_max)
      const fetchData = async () => {
          let params = {
              size: per_page,
              page: appState.current_page,
              orderby: orderby,
              sort: sort,
              ig_followers_min: ig_followers_min,
              ig_followers_max: ig_followers_max,
              ig_er_min: ig_er_min,
              ig_er_max: ig_er_max,
              ig_reach_min: ig_reach_min,
              ig_reach_max: ig_reach_max,
              tiktok_followers_min: tiktok_followers_min,
              tiktok_followers_max: tiktok_followers_max,
              tiktok_er_min: tiktok_er_min,
              tiktok_er_max: tiktok_er_max,
              tiktok_reach_min: tiktok_reach_min,
              tiktok_reach_max: tiktok_reach_max,
              key: search
          }
          getUserApprovedApi(params)
              .then(result => {
                  newData = result.users.rows;
                  setAppState({ loading: false, dataState: newData, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page });
              }).catch(err => {
                  console.log(err)
              })
          };
      fetchData()
    }
    function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 5) {
            const limit = 5;
            setTimeout(function () {
                setAppState({ loading: true, current_page: 1, per_page : limit, current_page: appState.current_page, total: appState.total});
                setName(name)
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: current_page,
                        orderby: orderby,
                        sort: sort,
                        ig_followers_min: ig_followers_min,
                        ig_followers_max: ig_followers_max,
                        ig_er_min: ig_er_min,
                        ig_er_max: ig_er_max,
                        ig_reach_min: ig_reach_min,
                        ig_reach_max: ig_reach_max,
                        tiktok_followers_min: tiktok_followers_min,
                        tiktok_followers_max: tiktok_followers_max,
                        tiktok_er_min: tiktok_er_min,
                        tiktok_er_max: tiktok_er_max,
                        tiktok_reach_min: tiktok_reach_min,
                        tiktok_reach_max: tiktok_reach_max,
                        key: search
                    }
                    getUserApprovedApi(params)
                        .then(result => {
                            newData = result.users.rows;
                            setAppState({dataState: newData, campaigns: appState.campaigns,
                                groups: appState.groups,
                                total: result.users.count,
                                current_page: 1, per_page: limit})
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                    setIsCheckAll(false)
                fetchData();
            },  3000);
        } else {
            const limit = e.target.value;
            setTimeout(function () {
                setAppState({ loading: true, current_page: 1, per_page : limit, current_page: appState.current_page, total: appState.total});
                setName(name)
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: current_page,
                        orderby: orderby,
                        sort: sort,
                        ig_followers_min: ig_followers_min,
                        ig_followers_max: ig_followers_max,
                        ig_er_min: ig_er_min,
                        ig_er_max: ig_er_max,
                        ig_reach_min: ig_reach_min,
                        ig_reach_max: ig_reach_max,
                        tiktok_followers_min: tiktok_followers_min,
                        tiktok_followers_max: tiktok_followers_max,
                        tiktok_er_min: tiktok_er_min,
                        tiktok_er_max: tiktok_er_max,
                        tiktok_reach_min: tiktok_reach_min,
                        tiktok_reach_max: tiktok_reach_max,
                        key: search
                    }
                    getUserApprovedApi(params)
                        .then(result => {
                            newData = result.users.rows;
                            setAppState({dataState: newData, campaigns: appState.campaigns,
                                groups: appState.groups,
                                total: result.users.count,
                                current_page: 1, per_page: limit})
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                    setIsCheckAll(false)
                fetchData();
            },  3000);
        }
    }
    function setPage(e){
        const selectedPage = e
        setAppState({ loading: true, current_page: selectedPage, per_page: appState.per_page, total: appState.total });
        setName(name)
        const fetchData = async () => {
            let params = {
                size: appState.per_page,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max,
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                key: search
            }
            getUserApprovedApi(params)
                .then(result => {
                    newData = result.users.rows;
                    setAppState({dataState: newData, campaigns: appState.campaigns,
                        groups: appState.groups,
                        total: result.users.count,
                        current_page: selectedPage, per_page: appState.per_page})
                }).catch(err => {
                    console.log(err)
                })
            };
            setIsCheckAll(false)
        fetchData();
    }
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        setName(name)
        setAppState({ loading: true, current_page: appState.current_page, per_page: appState.per_page, total: appState.total });
        const fetchData = async () => {
            let params = {
                size: appState.per_page,
                page: appState.current_page,
                orderby: appState.orderby,
                sort: appState.sort,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max,
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                key: search
            }
            getUserApprovedApi(params)
                  .then(result => {
                      setAppState({dataState: result.users.rows, 
                        campaigns: appState.campaigns,
                        groups: appState.groups,
                        total: result.users.count,
                        current_page: appState.current_page, per_page: appState.per_page})
                  }).catch(err => {
                      console.log(err)
                  })
              };
              setIsCheckAll(false)
        fetchData();
    }
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        setPage(selectedPage)
    }
    const handleAllChecked = async (e) => {
        let isChecked = e.target.checked
        setIsCheckAll(!isCheckAll);
        let tempIsCheck = isCheck
        let tempIsEmail = isEmail
        if (isChecked) {
            appState.dataState.forEach(element => {
                let index = tempIsCheck.indexOf(element.id)
                if (index < 0) {
                    tempIsCheck.push(element.id)
                    tempIsEmail.push(element.email)
                }
            });
        } else {
            appState.dataState.forEach(element => {
                let index = tempIsCheck.indexOf(element.id)
                tempIsCheck.splice(index, 1)
                tempIsEmail.splice(index, 1)
            });
        }
        // console.log("concat", tempIsCheck);
            await setIsCheck(tempIsCheck);
            await setIsEmail(tempIsEmail);
            let check= appState.dataState.map(li => li.id)
            
        if (isCheckAll) {
            // setIsCheck([]);
            // setIsEmail([]);
            // check = [];
        }
    }
    const checkAllEmail = async () => {
        // setIsEmailAll(!isCheckAll);
        // await setIsEmail(appState.dataState.map((li) => li.email));
        // let check = appState.dataState.map((li) => li.email);
    
        // if (isCheckAll) {
        //   setIsEmail([]);
        //   check = [];
        // }
    }
    const handleCheckChieldElement = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        let check = [...isCheck, id];
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
            check = isCheck.filter(item => item !== id);
        }
    }
    const checkEmail = (e) => {
        const email = e.email
        setIsEmail([...isEmail, email]);
    }
    const onSubmit = data => console.log(data);
    const addInfluencer = () => {
        let total = isCheck.length
        setTotalMember(total)
        let user_id = isCheck
        setUser_id(user_id)
    }
    const addGroup = () => {
        let bodyRequest = []
        let values = {
            name: name,
            user_ids: user_id
        }
        bodyRequest.push(values)
        createGroupApi(values)
        .then((repos) => {
            const data = repos.message;
            if(repos.code == "201") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const page_count = appState.total / appState.per_page;
    // const MIN_VAL = 5;
    // const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
    return (
        <div>
            <div className="main-dash">
                <div className="form-add mb-4">
                    <input type="text" {...register("name")} value={name} onChange={(event) => setName(event.target.value)} className="form-control" placeholder="Tulis Nama Group" />
                    {name=='' || errors.name ? <p className='alert-message'>Nama Group wajib diisi</p> : ""}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                        <div className="col-3">
                            <h6 className="font-medium">Member</h6>
                            <p>{total_member} Member</p>
                        </div>
                        <div className="col-3"><button onClick={addInfluencer} className="btn btn-primary"><img src="/img/ico-add.svg" className="img-fluid white" /> Add Influencer</button></div>
                        <div className="col-6">
                            <div className="d-flex justify-content-end">
                                <button onClick={addGroup}  disabled={name=='' || errors.name} className="btn btn-primary">Add Group</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="toolbar two">
                    <div className="toolbar-left">
                        <div className="toolbar-inner-left">
                            <div className="toolicon-wrapper check-btn">
                            <Popover
                                trigger="click"
                                placement="bottomLeft"
                                visible={filterSosmedOpen}
                                onVisibleChange={(visible) => {
                                    setFilterSosmedOpen(visible);
                                }}
                                content={(
                                    <div className="tool-wrapper" style={{ border: 'none', boxShadow: 'none', alignItems: 'flex-end', paddingLeft: 14 }}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filter-head">
                                                    <div className="font-medium">Instagram</div>
                                                    <div>
                                                        <button className="btn-reset-filter" onClick={resetIg}>Reset</button>
                                                    </div>
                                                </div>
                                                <p>Followers</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_followers_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgFollowersMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            /> 
                                                        </form>
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_followers_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgFollowersMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                                <p>ER</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_er_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgErMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_er_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgErMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                                <p>Reach</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_reach_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgReachMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_reach_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgReachMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="filter-head">
                                                    <div className="font-medium">Tiktok</div>
                                                    <div>
                                                        <button className="btn-reset-filter" onClick={resetTiktok}>Reset</button>
                                                    </div>
                                                </div>
                                                <p>Followers</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_followers_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokFollowersMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />  
                                                        </form>  
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_followers_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokFollowersMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div>
                                                </div>
                                                <p>ER</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_er_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokErMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />    
                                                        </form>
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_er_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokErMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                                <p>Reach</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_reach_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokReachMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(FilterRange)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_reach_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokReachMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}>
                                <label htmlFor="myid" className="toolicon filter" />
                                </Popover>
                            </div>
                            <div className="toolicon-wrapper status export">
                                <ExcelFile
                                    element={
                                        <button className="toolicon status export">
                                        <span>Export</span>
                                        </button>
                                    }
                                    filename={"user-list-" + dateNow + "-" + timeNow}
                                    >
                                    <ExcelSheet data={appState.dataState} name="User Registered">
                                        <ExcelColumn label="Name" value="full_name" />
                                        <ExcelColumn label="Birthdate" value="dob" />
                                        <ExcelColumn label="Email" value="email" />
                                        <ExcelColumn
                                        label="Register Season"
                                        value={(col) => col.goddes_gang_session + "x"}
                                        />
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            <div className="toolicon-wrapper status ste">
                                {(() => {
                                if (isEmail != "" || isEmailAll != "") {
                                    return <a href={'mailto:'+isEmail} className="toolicon status ste">
                                    <span>Send to Email</span>
                                    </a>
                                } else {
                                    return <button disabled className="toolicon status ste" style={{ cursor: 'not-allowed'}}>
                                    <span>Send to Email</span>
                                    </button>
                                }
                                })()}
                            </div>
                            <div className="page-item limit-detail-group">
                                <form className="page-input">
                                    <div className="form-group mb-0">
                                        <label htmlFor="page-count" className="sr-only">Page</label>
                                        <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            decimalScale={0}
                                            displayType="input"
                                            type="text"
                                            value={appState.per_page}
                                            allowNegative={false}
                                            onChange={onChangeLimit}
                                            className="form-control"
                                        />
                                    </div>
                                </form>
                                <div className="text-page">/ Page</div>
                            </div>
                        </div>
                    </div>
                    <div className="toolbar-right">
                        <div className="toolbar-inner-right">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group search-input">
                                    <label htmlFor="inputSearch" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                    <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
            {(() => {
            if (appState.dataState){
            return <div>    
                    <div className="head-data-info">
                        <div className="tablerow-1">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" name="selectAll" id="selectAll" onChange={handleAllChecked} checked={isCheckAll} onClick={checkAllEmail} />
                            <label className="custom-control-label" htmlFor="selectAll" />
                        </div>
                        </div>
                        <div className="tablerow-2">User
                            <SortArrow
                                valueSort={sort}
                                valueOrderBy={"full_name"}
                                activeOrderBy={orderby}
                                onClick={(e) => updateSorting(e)}
                            />
                        </div>
                        <div className="tablerow-3">
                        <div className="d-flex align-items-end justify-content-center">
                            <div><img src="/img/insta-outline.svg" className="sosmed-icon" /></div>
                            <div>Username</div>
                        </div>
                        </div>
                        <div className="tablerow-4">Followers
                            <SortArrow
                                valueSort={sort}
                                valueOrderBy={"follower_instagram"}
                                activeOrderBy={orderby}
                                onClick={(e) => updateSorting(e)}
                            />
                        </div>
                        <div className="tablerow-5">ER
                        <SortArrow
                            valueSort={sort}
                            valueOrderBy={"er_instagram"}
                            activeOrderBy={orderby}
                            onClick={(e) => updateSorting(e)}
                        />
                        </div>
                        <div className="tablerow-6">Reach</div>
                        <div className="tablerow-7">
                        <div className="d-flex align-items-end justify-content-center">
                            <div><img src="/img/tiktik-outline.svg" className="sosmed-icon" /></div>
                            <div>Username</div>
                        </div>
                        </div>
                        <div className="tablerow-8">Followers 
                            <SortArrow
                                valueSort={sort}
                                valueOrderBy={"follower_tiktok"}
                                activeOrderBy={orderby}
                                onClick={(e) => updateSorting(e)}
                            />
                        </div>
                        <div className="tablerow-9">ER
                            <SortArrow
                                valueSort={sort}
                                valueOrderBy={"er_tiktok"}
                                activeOrderBy={orderby}
                                onClick={(e) => updateSorting(e)}
                            />
                        </div>
                        <div className="tablerow-10">Reach</div>
                        <div className="tablerow-11">Social Media</div>
                        <div className="tablerow-12">Register Season</div>
                        <div className="tablerow-13">Status</div>
                    </div>
                    {appState.dataState && appState.dataState.length > 0 ? appState.dataState.map((repo, index) => {
                        return (
                            <div className="data-items" key={index}>
                                <div className="di-01">
                                    <div className="custom-control custom-checkbox" key={index}>
                                        <input type="checkbox" className="custom-control-input" id={repo.id} checked={isCheck.includes(repo.id)} onChange={handleCheckChieldElement} onClick={()=>checkEmail({email:repo.email})} />
                                        <label className="custom-control-label" htmlFor={repo.id} />
                                    </div>
                                </div>
                                <div className="di-02">
                                    <a
                                        href={`/goddess-gang/${repo.id}/detail`}
                                        className="link-user"
                                        >
                                        <div className="box-user">
                                            <div className="img-part">
                                                <img src={repo.photo} className="img-user img-fluid" alt="user" />
                                            </div>
                                            <div className="user-part">
                                                <h5>
                                                    {repo.full_name}
                                                    <span className="tool-tip floating">
                                                        <button className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></button>
                                                        <div className="tool-tip-content right">
                                                            <span>Influencer Potential</span>
                                                        </div>
                                                    </span>
                                                </h5>
                                                <p>{new Date(repo.birth_date).getDate()+ " " + monthNames[new Date(repo.birth_date).getMonth()]+ " "+ new Date(repo.birth_date).getFullYear()}</p>
                                                <p className="for-mail">{repo.email}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="di-03">
                                    <p className="for-username">
                                        {repo.username_instagram == null
                                        ? "-"
                                        : "@" + repo.username_instagram}
                                    </p>
                                </div>
                                <div className="di-04">
                                    {repo.follower_instagram == null
                                    ? "-"
                                    : formatFollower(repo.follower_instagram)}
                                </div>
                                <div className="di-05">
                                    {repo.er_instagram == null
                                    ? "-"
                                    : formatFollower(repo.er_instagram)}
                                </div>
                                <div className="di-06">
                                    {repo.reach_instagram == null
                                    ? "-"
                                    : formatFollower(repo.reach_instagram)}
                                </div>
                                <div className="di-07">
                                    <p className="for-username">
                                        {repo.username_tiktok == null
                                        ? "-"
                                        : "@" + repo.username_tiktok}
                                    </p>
                                </div>
                                <div className="di-08">
                                    {repo.follower_tiktok == null
                                    ? "-"
                                    : formatFollower(repo.follower_tiktok)}
                                </div>
                                <div className="di-09">
                                    {repo.er_tiktok == null ? "-" : formatFollower(repo.er_tiktok)}
                                </div>
                                <div className="di-10">
                                    {repo.reach_tiktok == null
                                    ? "-"
                                    : formatFollower(repo.reach_tiktok)}
                                </div>
                                <div className="di-11">
                                    {repo.social_medias.map((item, index) => {
                                        return (
                                            <span key={index}>
                                            {(() => {
                                                if (item.user_social_medias.value != "") {
                                                    return <a href={item.user_social_medias.value} className="icon-sm"><img src={item.logo} className="user-ico-sm" /></a>
                                                } else {
                                                    return <span></span>
                                                }
                                            })()}
                                            </span>
                                        );
                                    })}
                                </div>
                                <div className="di-12">{repo.goddes_gang_session}X</div>
                                <div className="di-13">
                                    <a href={`/goddess-gang/${repo.id}/detail`} className="gg-link">Detail <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                </div>
                            </div>
                        );
                    }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data user goddess gang, sorry</p>
                    }
                    <div className="d-flex justify-content-center mt-5">
                        {(() => {
                        if (total < 5 || appState.total < 5) {
                            return <ReactPaginate
                                pageCount={1}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={1}
                                forcePage={appState.current_page - 1}
                                activeLinkClassName='active'
                                containerClassName='pagination'
                                previousLabel='&laquo;'
                                nextLabel='&raquo;'
                                onPageChange={changePage}
                            />
                        } else {
                            return <ReactPaginate
                                pageCount={Math.ceil(page_count)}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={1}
                                forcePage={appState.current_page - 1}
                                activeLinkClassName='active'
                                containerClassName='pagination'
                                previousLabel='&laquo;'
                                nextLabel='&raquo;'
                                onPageChange={changePage}
                            />
                        }
                        })()}
                    </div>    
                </div>
                } else {
                    return <div className="content-wrapper">
                        <div className="credential pt-5">
                            <div className="credential-box-noborder">
                            <div className="load-2">
                                <p>Loading... Please wait</p>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                    }
                })()}
            </div>
        </div>
    );
}

export default List;