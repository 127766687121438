import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getCampaignApi = (status,params) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/${status}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    }))
}

const getCampaignDetailApi = (campaignId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/${campaignId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const createCampaignApi = async (payload) => {
    return new Promise((resolve, reject) => {
    axios.post(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/create`,
        payload,
        {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
    )
        .then(response => {
            resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const updateCampaignApi = async (id, payload) => {
    return new Promise((resolve, reject) => { 
    axios.post(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/update/${id}`,
        payload,
        {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
    )
        .then(response => {
            resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const deleteCampaignApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/delete/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getCampaignInfluencer = (campaignId,params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/influencer/${campaignId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getCampaignContent = (campaignId,params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/content/${campaignId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const getshareCampaign = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/share/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}

const createReportApi = async (id, payload) => {
    return new Promise((resolve, reject) => { 
    axios.post(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign-report/create-report/${id}`,
        payload,
        {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data',
                'Authorization': token
            },
        }
    ).then(response => {
                resolve(response.data)
            }).catch(err => {
                resolve(err.response.data)
        })
    })
}


export {
    getCampaignApi,
    getCampaignDetailApi,
    createCampaignApi,
    updateCampaignApi,
    deleteCampaignApi,
    getCampaignInfluencer,
    getCampaignContent,
    getshareCampaign,
    createReportApi
}
