import React, { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { getDetailUserApi } from "../../../api/goddess-gang.api";
import {useHistory, useLocation} from 'react-router';
import {cloudFunctionBaseUrl} from "../../../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');
function DetailGoddessGang({ match }) {
    const location = useLocation()
    const history = useHistory()
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        checked: 1,
        showUser: false,
        mission_followed: null,
        social_medias: null,
        disabled: false,
    });
    const { params: { id }, } = match;
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            const respUserMission= await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/mission/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            const respUserDetail = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            setAppState({ loading: false, showUser: false, data: respUserDetail.data.user, social_medias: respUserDetail.data.user.social_medias, mission_followed: respUserMission.data.data.rows });
        };
        fetchData();
    }, []);
    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const showDetail = () => {
        setAppState({showUser: true, data:appState.data, social_medias:appState.social_medias, mission_followed: appState.mission_followed});
    }
    const hideDetail = () => {
        setAppState({showUser: false, data:appState.data, social_medias:appState.social_medias, mission_followed: appState.mission_followed});
    }
    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
        
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    const onChangeChecked = event =>  {
        const checked = event
        setAppState({checked: checked, loading: false, showUser: false, data: appState.data, social_medias: appState.social_medias, mission_followed: appState.mission_followed})
    }
    return (
        <div> 
        {(() => {
            if (appState.data){
                return <div>
                    <div id="main-content">
                        <div className="main-dash">
                            <div className={appState.showUser == false ? "data-hide" : "data-hide hide-medias" || appState.showUser == true ? "data-hide hide-medias" : "data-hide"}>
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-5">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="for-img hide"><img src={appState.data.photo} className="img-fluid" /></div>
                                                    <div className="ud-text hide">
                                                        <h4>{appState.data.full_name}
                                                        {(() => {
                                                            if (appState.data.is_potential == true) {
                                                                return <img src="/img/ico-potential.svg" className="logo-pi"/>
                                                            } else {
                                                                return <div className="pi"></div>
                                                            }
                                                        })()}
                                                        </h4>
                                                        <p>{appState.data.dob}</p>
                                                        <div className="pi">{appState.data.is_potential == true ? "Potential Influencer" : ""}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex">
                                                {appState.social_medias.map((repo,index) => {
                                                    return (
                                                        <div className={repo.name == 'Twitter' ? "hide-medias": "social-info-data"} key={index}>
                                                        {(() => {
                                                            if (repo.name=="Instagram" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/insta-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            } else if (repo.name=="Facebook" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/facebook-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            } else if (repo.name=="TikTok" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/tiktik-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            } else if (repo.name=="Youtube" && repo.username!=null){
                                                                return <a href={`/goddess-gang/${id}/${repo.id}/analytics`}>
                                                                <img src="/img/youtube-outline.svg" className="img-fluid" alt="Icon" />
                                                                <div>{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                            </a>
                                                            }
                                                        })()}
                                                        </div>
                                                    );
                                                })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button onClick={showDetail} className="gg-link block btn-user-gg">Show Detail <img src="/img/arrow-down.svg" className="gg-icon-arrow red" /></button>
                                    </div>
                                </div>
                            </div>
                            <div className={appState.showUser == true ? "data-expand" : "data-expand hide-medias" || appState.showUser == false ? "data-expand hide-medias" : "data-expand"}>
                                <div className="row align-items-center">
                                    <div className="col-5">
                                        <div className="row align-items-center">
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                            <div id="gg" className="for-img"><img src={appState.data.photo} className="img-fluid" /></div>
                                            <div className="ud-text">
                                                <h3>{appState.data.full_name}</h3>
                                                <p>{appState.data.dob}</p>
                                                {(() => {
                                                if (appState.data.is_potential == true) {
                                                    return <div className="pi"><img src="/img/ico-potential.svg" /> Potential Influencer</div>
                                                    } else {
                                                        return <div className="pi"></div>
                                                    }
                                                })()}
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <h6 style={{fontFamily: '"Futura Medium"'}}>Notes</h6>
                                            <p>-</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                        {appState.social_medias.map((repo,index) => {
                                            return (
                                                <div className={repo.name == 'Twitter' ? "hide-medias": "col-3"} key={index}>
                                                    {(() => {
                                                        if (repo.name=="Instagram"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/insta-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>Instagram</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        } else if (repo.name=="Facebook"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/facebook-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>Facebook</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        } else if (repo.name=="Youtube"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/youtube-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>Youtube</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        } else if (repo.name=="TikTok"){
                                                            return <div className="ud-bx-gg">
                                                            <div className="social-item">
                                                                <div className="si-ico">
                                                                    <img src="/img/tiktik-outline.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="si-text">
                                                                    <h6>TikTok</h6>
                                                                    <div className="ft-acc">{repo.username == null ? "-" : "@"+repo.username}</div>
                                                                    <div className="ft-count">{repo.followers==null ? "-" : formatFollower(repo.followers)} <span>Followers</span></div>
                                                                    <div className="for-gg">
                                                                        <a href={`/goddess-gang/${id}/${repo.id}/analytics`} className="gg-link">Analytics <img src="/img/arrow-right.svg" className="gg-icon-arrow" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="for-ico-bottom left">
                                                                <img src="/img/ico-chart.svg" className="img-fluid" alt="Icon" />
                                                            </div>
                                                        </div>
                                                        }
                                                    })()}
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button onClick={hideDetail} className="gg-link block btn-user-gg">Hide Detail <img src="/img/arrow-up.svg" className="gg-icon-arrow" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="for-tc">
                        <div className="mission-title">
                            <div className="misson-head">
                                <div className="d-flex align-items-center">
                                <div><img src="/img/ico-mission.svg" className="mission-ico red" /></div>
                                <h4 className="font-medium mb-0">Mission Followed</h4>
                                </div>
                            </div>
                            <div className="total-mission">
                                Total {appState.mission_followed.length} Missions
                            </div>
                        </div>
                        <div className="tab-gg">
                        {appState.mission_followed.map((repo,index) => {
                            var idx = index + 1;
                            return (
                                <input value={idx} onChange={()=>onChangeChecked(idx)} defaultChecked={idx == 1 ? "checked" : appState.checked == idx ?  "checked" : ""} id={"tab"+idx} type="radio" name="looke-tab" key={index} />
                            );
                        })}
                            <div className="tab-bar scroll-mission">
                            {appState.mission_followed.map((repo,index) => {
                                var idx = index + 1;
                                return (
                                    <label htmlFor={"tab"+idx} value={idx} onClick={()=>onChangeChecked(idx)} className={appState.checked == idx ? "tab-bar-item tab"+idx+" tabs-active": "tab-bar-item tab"+idx} key={index}>
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <div className="thumbnail-mission">
                                                    <img src={repo.thumbnail} className="img-fluid img-round" />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="banner-info">
                                                    <h5 className="font-medium">{repo.title}</h5>
                                                    <div className="box-point">
                                                    <div className="text-point font-medium">{currencyFormat(repo.point)} Point</div>
                                                    <div className="social">
                                                        {(() => {
                                                            if (repo.instagram_task.content == '' && repo.instagram_task.mention == '' && repo.instagram_task.hastag == ''){
                                                                return <span></span>
                                                            } else {
                                                                return <img src="/img/insta-outline.svg" className="black mr-2" />
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (repo.facebook_task.content == '' && repo.facebook_task.mention == '' && repo.facebook_task.hastag == ''){
                                                                return <span></span>
                                                            } else {
                                                                return <img src="/img/facebook-outline.svg" className="black mr-2" />
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (repo.tiktok_task.content == '' && repo.tiktok_task.mention == '' && repo.tiktok_task.hastag == ''){
                                                                return <span></span>
                                                            } else {
                                                                return <img src="/img/tiktik-outline.svg" className="black mr-2" />
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (repo.youtube_task.content == '' && repo.youtube_task.mention == '' && repo.youtube_task.hastag == ''){
                                                                return <span></span>
                                                            } else {
                                                                return <img src="/img/youtube-outline.svg" className="black mr-2" />
                                                            }
                                                        })()}
                                                    </div>
                                                    </div>
                                                    <p>Valid until {new Date(repo.campaign_end_date).getDate()+ " " + monthNames[new Date(repo.campaign_end_date).getMonth()]+ " "+ new Date(repo.campaign_end_date).getFullYear()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                );
                            })}
                            </div>
                            <section className="tab-container scroll-task-mission">
                            {appState.mission_followed.map((repo,index) => {
                                var idx = index + 1;
                                return (    
                                <div value={idx} onClick={()=>onChangeChecked(idx)} className={appState.checked == idx ? "tabs tab"+idx+" tabs-content-active": "tabs tab"+idx} key={index}>
                                    <div className="big-banner" style={{background: `url(${repo.thumbnail})`, backgroundSize: 'cover'}}>
                                        <div className="row justify-content-end">
                                            <div className="col-4" />
                                            <div className="col-7">
                                                <div className="banner-inner">
                                                    <a href="detail.campaign.html">
                                                        <h3 className="font-medium font-white mb-3">{repo.title}</h3>
                                                    </a>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <div className="box-point">
                                                            <div className="font-white font-medium mr-3">{currencyFormat(repo.point)} Point</div>
                                                            <p className="font-white mr-3">Valid until {new Date(repo.campaign_end_date).getDate()+ " " + monthNames[new Date(repo.campaign_end_date).getMonth()]+ " "+ new Date(repo.campaign_end_date).getFullYear()}</p>
                                                            <div className="social">
                                                                {(() => {
                                                                    if (repo.instagram_task.content == '' && repo.instagram_task.mention == '' && repo.instagram_task.hastag == ''){
                                                                        return <span></span>
                                                                    } else {
                                                                        return <img src="/img/insta-outline.svg" className="white mr-2" />
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (repo.facebook_task.content == '' && repo.facebook_task.mention == '' && repo.facebook_task.hastag == ''){
                                                                        return <span></span>
                                                                    } else {
                                                                        return <img src="/img/facebook-outline.svg" className="white mr-2" />
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (repo.tiktok_task.content == '' && repo.tiktok_task.mention == '' && repo.tiktok_task.hastag == ''){
                                                                        return <span></span>
                                                                    } else {
                                                                        return <img src="/img/tiktik-outline.svg" className="white mr-2" />
                                                                    }
                                                                })()}
                                                                {(() => {
                                                                    if (repo.youtube_task.content == '' && repo.youtube_task.mention == '' && repo.youtube_task.hastag == ''){
                                                                        return <span></span>
                                                                    } else {
                                                                        return <img src="/img/youtube-outline.svg" className="white mr-2" />
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className={repo.campaign_users[0].status=="Waiting Approval" ? "font-white mb-3": "hidden"}><strong>{repo.campaign_users[0].user.full_name}</strong> wants to join this campaign</p>
                                                    <Link to={`/campaign/${repo.id}/detail`} className="box-status">
                                                        {repo.campaign_users[0].status}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-desc-banner">
                                        <div className="bdb-inner">
                                            <h4 className="font-medium">Campaign Description</h4>
                                            <span className="paragraph">{parse(repo.description)}</span>
                                            <h4 className="font-medium mb-4">Tasks</h4>
                                            {(() => {
                                                if (repo.instagram_task.content == '' && repo.instagram_task.mention == '' && repo.instagram_task.hastag == ''){
                                                return <div>
                                                </div>
                                                } else {
                                                    return <div className="d-flex mb-5">
                                                    <div className="ico-task"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                    <div className="text-task">
                                                        <h6 className="font-medium">Instagram</h6>
                                                        {parse(repo.instagram_task.content)}{repo.instagram_task.hastag == "" ? "" : " Upload dengan Hastag #"+repo.instagram_task.hastag} {repo.instagram_task.mention== "" ? "" : "dan mention ke @"+repo.instagram_task.mention}
                                                    </div>
                                                </div>
                                                }
                                            })()}
                                            {(() => {
                                                if (repo.facebook_task.content == '' && repo.facebook_task.mention == '' && repo.facebook_task.hastag == ''){
                                                return <div>
                                                </div>
                                                } else {
                                                    return <div className="d-flex mb-5">
                                                    <div className="ico-task"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                                    <div className="text-task">
                                                        <h6 className="font-medium">Facebook</h6>
                                                        {parse(repo.facebook_task.content)} {repo.facebook_task.hastag == "" ? "" : " Upload dengan Hastag #"+repo.facebook_task.hastag} {repo.facebook_task.mention== "" ? "" : "dan mention ke @"+repo.facebook_task.mention}
                                                    </div>
                                                </div>
                                                }
                                            })()}
                                            {(() => {
                                                if (repo.tiktok_task.content == '' && repo.tiktok_task.mention == '' && repo.tiktok_task.hastag == ''){
                                                return <div>
                                                </div>
                                                } else {
                                                    return <div className="d-flex mb-5">
                                                    <div className="ico-task"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                                    <div className="text-task">
                                                        <h6 className="font-medium">Tiktok</h6>
                                                        {parse(repo.tiktok_task.content)}{repo.tiktok_task.hastag == "" ? "" : " Upload dengan Hastag #"+repo.tiktok_task.hastag} {repo.tiktok_task.mention== "" ? "" : "dan mention ke @"+repo.tiktok_task.mention}
                                                    </div>
                                                </div>
                                                }
                                            })()}
                                            {(() => {
                                                if (repo.youtube_task.content == '' && repo.youtube_task.mention == '' && repo.youtube_task.hastag == ''){
                                                return <div>
                                                </div>
                                                } else {
                                                    return <div className="d-flex mb-5">
                                                    <div className="ico-task"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                                    <div className="text-task">
                                                        <h6 className="font-medium">Youtube</h6>
                                                        {parse(repo.youtube_task.content)}{repo.youtube_task.hastag == "" ? "" : " Upload dengan Hastag #"+repo.youtube_task.hastag} {repo.youtube_task.mention== "" ? "" : "dan mention ke @"+repo.youtube_task.mention}
                                                    </div>
                                                </div>
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                );
                            })}
                            </section>
                        </div>
                    </section>
                </div>
            } else {
                return <div className="container">
                    <div className="credential pt-5">
                        <div className="credential-box-noborder">
                        <div className="load-2">
                            <p>Loading... Please wait</p>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        </div>
                    </div>
                </div>
            }
        })()}
        </div>
    );
}


export default DetailGoddessGang;
