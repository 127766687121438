import React from "react";
import {Button, Drawer, Form, Select, Table} from "antd";
import {getSkinConditionApi} from "../../api/skinCondition.api";
import {getProductListApi} from "../../api/product-list.api";
import {createAndUpdateRecommendationApi} from "../../api/recommendation.api";

const {Option} = Select;

class CreateProductRecommendationComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {
                    title: 'Action', key: 'action',
                    render: (record) => <Button htmlType="submit"
                                                onClick={() => this.getSkinTypeList(true, record)}>Add</Button>
                }
            ],
            skinConditionList: [
                {
                    key: 'skin-type',
                    name: 'Skin Type'
                }, {
                    key: 'skin-tone',
                    name: 'Skin Tone'
                }, {
                    key: 'skin-undertone',
                    name: 'Skin Undertone'
                }, {
                    key: 'skin-concern',
                    name: 'Skin Concern'
                }
            ],
            productRecommendationForm: [
                {
                    label: 'Skin Type',
                    name: 'skinType',
                    list: []
                }, {
                    label: 'Recommendation',
                    name: 'recommendation',
                    mode: 'tags',
                    list: []
                }
            ],
            loadings: false,
            createRecommendationDrawerVisibility: false,
            skinConditionDetail: {},
            skinTypeList: [],
            productList: [],
            selectedItems: []
        }
    }

    componentDidMount(): void {
        let {productRecommendationForm} = this.state
        getProductListApi()
            .then(response => {
                productRecommendationForm[1].list = response.data
            })
            .catch(err => {
                console.log(err)
            })
    }

    getSkinTypeList = (value, skinConditionDetail) => {
        let {productRecommendationForm} = this.state
        getSkinConditionApi(skinConditionDetail.key)
            .then(response => {
                productRecommendationForm[0].list = response.result
                this.setState({createRecommendationDrawerVisibility: value, skinConditionDetail})
            })
            .catch(err => {
                console.log(err)
            })
    }

    onFinishCreateRecommendation = (values) => {
        const {selectedItems, skinConditionDetail} = this.state
        let bodyRequest = []
        selectedItems.map(item => {
            let data = {
                product_id : Number(item.value),
                product_slug: item.children.toLowerCase(),
                product_name: item.children
            }
            bodyRequest.push(data)
        })
        createAndUpdateRecommendationApi(skinConditionDetail.key, values.skinType, bodyRequest)
            .then(response => {
                alert('Recommendation berhasil diinput!')
                this.setState({createRecommendationDrawerVisibility: false})
                window.location.href='/recommendation/product-recommendation'
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChange = (key, item) => {
        this.setState({selectedItems: item})
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {productRecommendationForm, loadings, skinConditionList, columns, createRecommendationDrawerVisibility, skinConditionDetail} = this.state
        return (
            <>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={skinConditionList}
                    pagination={false}
                />
                <Drawer
                    title={`Create ${skinConditionDetail.name} Recommendation Product`}
                    width={520}
                    visible={createRecommendationDrawerVisibility}
                    closable={true}
                    onClose={() => this.setState({createRecommendationDrawerVisibility: false})}
                >
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={this.onFinishCreateRecommendation}
                    >
                        {
                            productRecommendationForm.map((item, index) => (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Select mode={item.mode} style={{width: '100%'}} onChange={this.handleChange}>
                                        {
                                            item.list.map(item => <Option key={item.id}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            ))
                        }
                        <Form.Item style={{marginTop: 10}}>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loadings}>
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>
        )
    }
}

export default CreateProductRecommendationComponent
