import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getTimelineApi = async () => {
    const response = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/setting-label`,
        {
            headers: {
                'Authorization': token,
            }
        }
    );

    return response.data;
}

const saveTimelineApi = async (payload) => {
    const response = await axios.post(
        `${cloudFunctionBaseUrl()}/api/v2/admin/setting-label`,
        payload,
        {
            headers: {
                'Authorization': token,
            }
        }
    );

    return response.data;
}

export {
    getTimelineApi,
    saveTimelineApi
}
