import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { getDetailUserApi, setStatusUserApi } from "../../../api/goddess-gang.api";
import {useHistory, useLocation} from 'react-router';


function DetailMissionRegistered({ match }) {
    const location = useLocation()
    const history = useHistory()
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        social_medias: null,
        disabled: false,
    });
    const { params: { id }, } = match;
    function approveUser() {
        let bodyRequest = []
        let values = {
            status: "approve"
        }
        bodyRequest.push(values)
        setStatusUserApi(id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
            setAppState({ loading: false, data: appState.data});
        });
    }
    function rejectUser() {
        let bodyRequest = []
        let values = {
            status: "reject"
        }
        bodyRequest.push(values)
        setStatusUserApi(id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
            setAppState({ loading: false, data: appState.data});
        });
    }
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            getDetailUserApi(id)
                .then(result => {
                    setAppState({ loading: false, data: result.user, social_medias: result.user.social_medias });
                }).catch(err => {
                    console.log(err)
                })
            };
            fetchData();
        }, []);
        
    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    const pathRaw = window.location.pathname.split('/')
    const pathName = pathRaw[4] ? pathRaw[1]+pathRaw[4] : pathRaw[3] ? pathRaw[1]+pathRaw[3] : pathRaw[1]
    console.log(pathName)
    return ( 
        <div id="main-content">
        {(() => {
            if (appState.data){
            return <div className="container">
                <div className="main-dash">
                    <div className="row">
                        <div className="col-4 br-1">
                            <div className="d-flex align-items-center">
                            <div className="for-img hide"><img src={appState.data.photo} className="img-fluid img-circle" /></div>
                            <div className="ud-text">
                                <h3>{appState.data.full_name}</h3>
                                <p>{appState.data.dob=="Invalid date" ? "-" : appState.data.dob}</p>
                                {(() => {
                                    if (appState.data.is_potential == 1) {
                                        return <div className="pi"><img src="/img/ico-potential.svg" /> Potential Influencer</div>
                                    } else {
                                        return <div className="pi"></div> 
                                    }
                                })()}
                            </div>
                            </div>
                        </div>
                        <div className="col-4 br-1">
                            <div className="ud-info">
                                <h6>Address</h6>
                                <p>{appState.data.address ? appState.data.address : "-"}</p>
                                <h6>Phone Number</h6>
                                <p>{appState.data.phone ? appState.data.phone : "-"}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="ud-info">
                                <h6>Approval</h6>
                                <p>{appState.data.full_name} Wants to be a member of Goddess Gang</p>
                                {(() => {
                                    if (appState.data.goddes_gang_status == "approve") {
                                    return <div>
                                            <div className="green font-medium">Approved</div>
                                        </div>
                                } else if (appState.data.goddes_gang_status == "waiting verification") {
                                    return <div>
                                        <button onClick={approveUser} className="btn btn-accept mr-1"><img src="/img/ico-approve.svg" className="ico-btn" /> Accept</button> 
                                        <button onClick={rejectUser} className="btn btn-primary"><img src="/img/ico-decline.svg" className="ico-btn" /> Reject</button>
                                    </div>
                                } else {
                                    return <div>
                                            <div className="red font-medium">Rejected</div>
                                        </div>
                                }
                            })()}
                            </div>
                        </div>
                    </div>
                    <div className="sm-detail">
                    {appState.social_medias.map((repo,index) => {
                        return (
                        <div className={repo.name == 'Twitter' || repo.name == 'Blog' || repo.name == 'Whatsapp' ? "hide-medias": "sm-detail-items"} key={index}>
                            <div className="smd-title">
                                <div className="for-icon"><img src={repo.logo} className="img-fluid" alt="Icon" /></div>
                                <div>
                                    <h5 className="font-medium">{repo.name}</h5>
                                    <p>{repo.username == null ? "-" : "@"+repo.username}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="box-sm-info">
                                    <div className="d-flex">
                                        <div className="for-img hide"><img src={repo.photo} className="img-fluid img-circle" /></div>
                                        <div style={{width: '80%'}}>
                                            <h6 style={{fontFamily: '"Futura Medium"'}}>{repo.name} Bio</h6>
                                            <p> {repo.biography ? repo.biography : "-"}</p>
                                            {(() => {
                                                if (pathName=="mission-registereddetail") {
                                                    return <a href={`/mission-registered/${id}/${repo.id}/analytics`} className="btn btn-primary"><img src="/img/ico-chart.svg" className="ico-btn mr-2" />See Analysis Data <img src="/img/arrow-right.svg" className="ico-btn ml-2" /></a>
                                                } else {
                                                    return <a href={`/user-rejected/${id}/${repo.id}/analytics`} className="btn btn-primary"><img src="/img/ico-chart.svg" className="ico-btn mr-2" />See Analysis Data <img src="/img/arrow-right.svg" className="ico-btn ml-2" /></a>
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="ud-bx-info">
                                                <h6>Followers</h6>
                                                <div className="main-di">{repo.followers ? formatFollower(repo.followers) : "-"}</div>
                                                <div className="for-ico-bottom">
                                                    <img src="/img/ico-man.svg" className="img-fluid" alt="Icon" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="ud-bx-info">
                                                <h6>Age Distribution</h6>
                                                <div className="main-di">{repo.age_distribution ? repo.age_distribution.substring(0, 5).replace(/(^,)|(,$)/g, "") : "-"}</div>
                                                <p>{repo.age_distribution ? repo.age_distribution.substring(6) : "-"}</p>
                                                <div className="for-ico-bottom">
                                                    <img src="/img/ico-hour.svg" className="img-fluid" alt="Icon" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="ud-bx-info">
                                                <h6>Gender Distribution</h6>
                                                <div className="main-di">{repo.gender_distribution ? repo.gender_distribution.substring(0, 4) : "-"}</div>
                                                <p>{repo.gender_distribution ? repo.gender_distribution.substring(4) : "-"}</p>
                                                <div className="for-ico-bottom">
                                                    <img src="/img/ico-gender.svg" className="img-fluid" alt="Icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        );
                    })}
                    </div>
                </div>
            </div>
        } else {
            return <div className="container">
                <div className="credential pt-5">
                    <div className="credential-box-noborder">
                    <div className="load-2">
                        <p>Loading... Please wait</p>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    </div>
                </div>
            </div>
            }
        })()}
        </div>
    );
}


export default DetailMissionRegistered;
