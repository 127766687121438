import React from "react";
import ProductRecommendationComponent from "./product-recommendation.component";
import PageHeaderComponent from "../../component/page-header.component";

class ProductRecommendationPage extends React.PureComponent {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <PageHeaderComponent title={'Product Recommendation'}/>
                <ProductRecommendationComponent/>
            </>
        )
    }
}

export default ProductRecommendationPage
