import React from "react";
import {Button, Card, Drawer} from "antd";
import {EditOutlined} from '@ant-design/icons';
import ReferFormComponent from "./refer-form.component";
import {createReferApi, getReferFriendApi, updateReferFriendApi} from "../../api/refer.api";

const {Meta} = Card;

class ReferFriendComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            referDrawerVisibility: false,
            referFriend: [],
            slug: '',
            loading: false
        }
    }

    componentDidMount(): void {
        this.getReferFriendData()
    }

    getReferFriendData = () => {
        getReferFriendApi()
            .then(response => {
                this.setState({referFriend: response.result[0]})
            }).catch(err => {
            console.log(err)
        })
    }

    onFinish = (values, fileImageList) => {
        if (fileImageList.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData()
            const {slug} = this.state

            form.append('title', values.title)
            form.append('description', values.description)
            form.append('image', values.image.file)

            if (slug === 'edit') {
                this.updateReferFriend(form)
            } else {
                this.createReferFriend(form)
            }
        }
    }

    updateReferFriend = (form) => {
        const {id} = this.state.referFriend
        updateReferFriendApi(form, id)
            .then(result => {
                this.getReferFriendData()
                alert("Refer a friend berhasil di ubah")
                this.setState({referDrawerVisibility: false, loading: false})
            }).catch(err => {
            console.log(err)
        })
    }

    createReferFriend = (form) => {
        createReferApi(form)
            .then(result => {
                this.getReferFriendData()
                this.setState({referDrawerVisibility: false, loading: false})
                alert("Refer a friend berhasil di upload")
            }).catch(err => {
            console.log(err)
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {referDrawerVisibility, referFriend, slug, loading} = this.state
        return (
            <div>
                {!referFriend && <Button style={{marginBottom: 10}} onClick={() => this.setState({referDrawerVisibility: true, slug: 'add'})}>Create Refer Friend Banner</Button>}
                {
                    !!referFriend &&
                    <Card
                        style={{width: 300}}
                        cover={
                            <img
                                alt="example"
                                src={referFriend.image}
                            />
                        }
                        actions={[
                            <EditOutlined onClick={() => this.setState({referDrawerVisibility: true, slug: 'edit'})}/>
                        ]}
                    >
                        <Meta
                            title={referFriend.title}
                            description={referFriend.description}
                        />
                    </Card>
                }
                <Drawer
                    title={`Edit Refer A Friend`}
                    width={520}
                    visible={referDrawerVisibility}
                    closable={true}
                    onClose={() => this.setState({referDrawerVisibility: false})}
                >
                    <ReferFormComponent onFinish={this.onFinish} referFriend={referFriend} slug={slug} loading={loading}/>
                </Drawer>
            </div>
        )
    }
}

export default ReferFriendComponent
