import React from "react";
import ForgotPasswordFormComponent from "./forgot-password-form.component";

class ForgotPasswordPage extends React.PureComponent {

    render() {
        return (
            <div className="App">
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <ForgotPasswordFormComponent />
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPasswordPage
