import React from "react";
import {Button, Tag, Timeline, List} from "antd";
import {formatDateToReadable} from "../../ui-util/general-variable";

class PointTrackerComponent extends React.PureComponent {

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {userPointList, totalItems, loadMore} = this.props
        return (
            <>
                <br/>
                <Timeline>
                    <List
                        dataSource={userPointList}
                        renderItem={(item, index) => (
                            <Timeline.Item key={index.toString()}>
                                <div>{formatDateToReadable(item.created_at)}</div>
                                <div>{item?.name} {item.point} <Tag
                                    color={item.type === 'point' ? 'magenta' : 'cyan'}>{item.type}</Tag></div>
                            </Timeline.Item>
                        )}
                    />
                </Timeline>
                {
                    userPointList.length > 0 && totalItems !== userPointList.length &&
                    <Button onClick={() => loadMore()}>Load More</Button>
                }
            </>
        )
    }
}

export default PointTrackerComponent
