import React from "react";
import {Layout} from 'antd';
import HeaderComponent from "./header.component";
import {Colors} from "../ui-util/colors";
import SideBarComponent from "./side-bar.component";
import {getDetailAdminApi} from "../api/user.api";
import Sidebar from "./Sidebar";
import Header from "./Header";

const {Content, Footer} = Layout;

class DashboardComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            show: false
        }
    }

    componentDidMount(): void {
        if(localStorage.getItem('TOKEN_AUTH')){
            this.setState({show: true})
            this.getDetailAdmin()
        }
    }

    getDetailAdmin = () => {
        getDetailAdminApi()
            .then(result => {
                localStorage.setItem('full_name', result.data.full_name)
            })
            .catch(err => {
                console.log(err)
                if(localStorage.getItem('TOKEN_AUTH')){
                    localStorage.removeItem('TOKEN_AUTH')
                    window.location.reload()
                }
            })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {show} = this.state
        const {content} = this.props
        const pathRaw = window.location.pathname.split('/')
        const pathName = pathRaw[4] ? pathRaw[1]+pathRaw[4] : pathRaw[3] ? pathRaw[1]+pathRaw[3] : pathRaw[1]
        return (
            <div>
                {(() => {
                  if ([
                    "mission-registered",
                    "goddess-gang",
                    "campaign",
                    "setting-banner",
                    "setting-create-questioner-form",
                    "setting-create-timeline",
                    "campaignapprovement",
                    "campaignedit",
                    "goddess-ganggroup",
                    "redeem-point",
                  ].includes(pathName)) {
                      return <section id="dashboard-wrapper">
                                <div className="container-wrapper">
                                    {show && <Sidebar/>}
                                    <section id="main-dashboard">
                                    {show && <Header/>}
                                    {content}
                                    </section>
                                </div>
                            </section>
                } else if ( pathName == "campaigndetail" || pathName == "mission-registereddetail" || pathName == "user-rejecteddetail" || pathName == "goddess-gangdetail" ||  pathName == "goddess-gangcontent-detail" || pathName == "redeem-pointdetail" || pathName == "goddess-ganganalytics" || pathName == "mission-registeredanalytics" || pathName == "user-rejectedanalytics") {
                    return  <section id="dashboard-wrapper">
                                <div className="full-wrapper">
                                    <section id="main-dashboard">
                                    {show && <Header/>}
                                    {content}
                                    </section>
                                </div>
                            </section>
                } else {
                    return <Layout>
                        <HeaderComponent/>
                        <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
                            {show && <SideBarComponent/>}
                            <Content style={{marginLeft: 25, marginRight: 25, padding: 20, backgroundColor: 'white', minHeight: 1000}}>
                                {content}
                            </Content>
                        </Layout>
                        <Footer style={{backgroundColor: Colors.navy, color: 'white', position: "sticky", bottom: "0", zIndex: 100}}
                                className="d-flex justify-content-center align-items-center">
                            IT Arena 2020
                        </Footer>
                    </Layout>
                }
                })()} 
            </div>
        )
    }
}

export default DashboardComponent
