import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const createRewardApi = (bodyRequest) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/reward/create`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const getRewardListApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/reward`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.result)
            }).catch(err => {
            reject(err)
        })
    }))
}

const updateRewardApi = (id, bodyRequest) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/reward/${id}`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const deleteRewardApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v2/admin/reward/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {createRewardApi, getRewardListApi, updateRewardApi, deleteRewardApi}
