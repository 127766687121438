import React,{ useState } from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { deleteGroupApi } from "../../../api/group.api";
import ReactPaginate from 'react-paginate';

const List = (props) => {
    const { data, total, set_page, current_page, per_page, currencyFormat, monthNames } = props;
    const [appState, setAppState]  = useState({
        dataState:data,
        disabled: false,
        current_page: current_page,
        per_page: per_page,
        total: total
    });
    function deleteGroup(e){
        setAppState({disabled: true, dataState: appState.dataState , total: appState.total, current_page: appState.current_page, per_page: appState.per_page});
        swal({
            title: "Confirmation",
            text: "Are you sure delete group ?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                },
            },
            dangerMode: true,
        }).then((isConfirm) => {
            if (!isConfirm){ return false;}
            setAppState({disabled: true, dataState: appState.dataState , total: appState.total, current_page: appState.current_page, per_page: appState.per_page});
            const banner_id = e
            deleteGroupApi(banner_id)
            .then((result) => {
                const data = result.message;
                if(result.code == 201) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        button: true
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                }
            });
            
        }) 
    }
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data group, sorry</p>;
   
    const page_count = total / per_page;
    return (
        <div className="content-wrapper">
            <div className="row-group">
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="col-group" key={index}>
                        <div className="box-group">
                            <button onClick={(e)=>deleteGroup(repo.id)} className="btn btn-delete"><img src="/img/ico-delete.svg" className="img-fluid" /></button>
                            <h6 className="font-medium box-name-group">{repo.name}</h6>
                            {(() => {
                                if (repo.date != null) {
                                    return <div className="box-challange">
                                                    <div className="bc-name">{repo.campaign_name}</div>
                                                <div className="time">{new Date(repo.created_at).getDate()+ " " + monthNames[new Date(repo.created_at).getMonth()]+ " "+ new Date(repo.created_at).getFullYear()}</div>
                                            </div>
                                } else {
                                    return <div className="box-challange"></div>
                                }
                            })()}
                            <div className="d-flex align-items-center justify-content-center w-100 mb-3">
                                {repo.group_users.map((item, index) => {
                                    return (
                                    <div className="img-group" key={index}>
                                        {(() => {
                                            if (item.user != null) {
                                            return <img src={item.user.photo} className="img-fluid img-circle" />
                                            } else {
                                            return <img src="" className="img-fluid img-circle" />
                                            }
                                        })()}
                                    </div>
                                    );
                                })}
                                <div className="ig-text">{currencyFormat(repo.total_user)} Orang</div>
                            </div>
                            <Link to={`/goddess-gang/${repo.id}/group`} className="gg-link">See Detail</Link>
                            <div className="top-left"><img src="/img/group-deco.jpg" className="img-fluid" /></div>
                            <div className="bottom-right"><img src="/img/group-deco.jpg" className="img-fluid rotate" /></div>
                        </div>
                    </div>
                );
            })}
            </div>
            <div className="d-flex justify-content-center mt-5">
                {(() => {
                if (total < 5) {
                    return <ReactPaginate
                        pageCount={1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                } else {
                    return <ReactPaginate
                        pageCount={Math.ceil(page_count)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                }
                })()}
            </div>
      </div>
    );
}

export default List;