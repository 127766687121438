import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useFieldArray, useForm} from 'react-hook-form';
import CreateQuestionerApi from '../../api/create-questioner.api';
import {message} from 'antd';

const QuestionFieldForm = ({isEdit,  defaultValues, onSubmit: onSubmitProps, submitBtnContent}) => {
    const [uploadingCount, setUploadingCount] = useState(0);
    const UploadedImages = useMemo(() => new Map(), []);

    const {register, control, watch, setValue, handleSubmit, formState: {errors}} = useForm({defaultValues})
    const {fields, append, remove} = useFieldArray({control, name: 'options'})

    const {correctOptionIndex, question, image} = watch()
    const fileInputOptionRef = useRef(null)
    const fileInputImageRef = useRef(null)

    const handleInputImage = useCallback(async (e) => {
        if (!e.target.files || !e.target.files[0]) return;
        setUploadingCount(ps => ps + 1);
        try {
            const fileUrl = URL.createObjectURL(e.target.files[0]) 
            setValue('image', fileUrl)
            const url = await CreateQuestionerApi.uploadImage(e.target.files[0]);
            UploadedImages.set(fileUrl, url)
        } catch (err) {
            message.error(err.message)
        }
        e.target.value = '';
        setUploadingCount(ps => ps - 1)
    }, [UploadedImages, setValue])

    const addOption = useCallback(() => {
        append({type: 'text', label: 'Opsi baru'})
    }, [append])

    const handleInputOptionImage = useCallback(async (e) => {
        if (!e.target.files || !e.target.files[0]) return;
        setUploadingCount(ps => ps + 1);
        try {
            const fileUrl = URL.createObjectURL(e.target.files[0]) 
            append({type: 'image', label: fileUrl})
            const url = await CreateQuestionerApi.uploadImage(e.target.files[0]);
            UploadedImages.set(fileUrl, url)
        } catch (err) {
            message.error(err.message)
        }
        e.target.value = '';
        setUploadingCount(ps => ps - 1)
    }, [UploadedImages, append])

    const onSubmit = (formData) => {
        if (formData.image && UploadedImages.has(formData.image)) {
            formData.image = UploadedImages.get(formData.image)
        }

        if (Array.isArray(formData.options)) {
            formData.options.forEach(item => {
                if (item.type === 'image' && UploadedImages.has(item.label)) {
                    item.label = UploadedImages.get(item.label)
                }
            })
        }

        onSubmitProps(formData)
        UploadedImages.clear()
    }

    useEffect(() => {
        register('image')
        register('correctOptionIndex', {required: 'Opsi yang benar harus dipilih!'})
    }, [register])

    return (
        <div className="question-field" >
            <div className="d-flex mb-3">
                {!isEdit && (
                    <h5 className="read-only-title">{question}</h5>
                )}
                <input
                    className="form-control no-radius mr-3 flex-grow-1"
                    type={isEdit ? 'text' : 'hidden'}
                    readOnly={!isEdit}
                    {...register('question', {required: 'Harus diisi!'})}
                />
                {isEdit && (
                    <>
                        <button
                            type="button"
                            className="btn btn-gray icon mr-3"
                            style={{paddingRight: 0}}
                            onClick={() => fileInputImageRef.current?.click()}
                        >
                            <img
                                src="/img/ico-plus-img.svg"
                                alt=""
                            />
                        </button>
                        <select className="btn btn-gray">
                            <option value="">Radio Button</option>
                        </select>
                    </>
                )}
            </div>
            {errors.question?.message && <p className="alert-message">{errors.question.message}</p>}
            {image && (
                <div className="mb-3 d-flex align-items-center">
                    <div
                        style={{
                            maxWidth: 406,
                            marginRight: 16,
                        }}
                    >
                        <img 
                            src={image}
                            style={{
                                width: '100%',
                            }}
                            alt=""
                        />
                    </div>
                    {isEdit && (
                        <button
                            type="button"
                            style={{
                                border: 'none',
                                background: 'transparent',
                                marginRight: 24,
                            }}
                            onClick={() => {
                                setValue('image', undefined)
                            }}
                        >
                            <img src="/img/ico-btn-remove.svg" alt="remove"/>
                        </button>
                    )}
                </div>
            )}
            <input ref={fileInputImageRef} type="file" className="d-none" onChange={handleInputImage} />
            <div>
                {fields.map((item, index) => {
                    const labelField = register(`options[${index}].label`, {required: 'Harus diisi!'})
                    return  (
                        <div key={item.id} className="option mb-3">
                            <button
                                type="button"
                                className={`option-radio ${correctOptionIndex === index ? 'active': ''}`}
                                onClick={() => isEdit && setValue('correctOptionIndex', index)}
                            />
                            {item.type === 'text' && (
                                <>
                                    <input
                                        className="option-text"
                                        type="text"
                                        readOnly={!isEdit}
                                        defaultValue={item.label}
                                        {...labelField}
                                    />
                                    {errors.label?.message && <p className="alert-message">{errors.label.message}</p>}
                                </>
                            )}
                            {item.type === 'image' && (
                                <img
                                    src={item.label}
                                    style={{width: 50, height: 50, objectFit: 'contain'}}
                                    alt="option"
                                />
                            )}
                            {isEdit && (
                                <button type="button" className="option-remove" onClick={() => {
                                    if (correctOptionIndex === index) setValue('correctOptionIndex', undefined)
                                    remove(index)
                                }}>
                                    <img src="/img/ico-btn-remove.svg" alt="remove"/>
                                </button>
                            )}
                        </div>
                    )
                })}
                {isEdit && (
                    <div className="option mb-3">
                        <div className="option-radio" />
                        <button type="button" className="option-add-text" onClick={addOption}>
                        Tambah Opsi
                        </button>
                        <button type="button" className="option-remove" onClick={() => fileInputOptionRef.current?.click()}>
                            <img src="/img/ico-btn-img.svg" alt="add option" style={{width: 24, height: 24}} />
                        </button>
                        <input ref={fileInputOptionRef} type="file" className="d-none"  onChange={handleInputOptionImage} />
                    </div>
                )}
            </div>
            {errors.correctOptionIndex?.message && <p className="alert-message">{errors.correctOptionIndex.message}</p>}
            {!!isEdit && !!onSubmit && (
                <div className="d-flex justify-content-end">
                    <button type="button" onClick={handleSubmit(onSubmit)} className="btn icon btn-primary" disabled={uploadingCount > 0}>{uploadingCount > 0 ? (
                        <>
                            <img
                                src="/img/ico-new-field.svg"
                                alt=""
                                />
                            Uploading...
                            </>
                    ) : submitBtnContent}</button>
                </div>
            )}
        </div>
    )
}

QuestionFieldForm.propTypes = {
    isEdit: PropTypes.bool,
    defaultValues: PropTypes.shape({
        question: PropTypes.string,
        image: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.oneOf(['text', 'image']),
            label: PropTypes.string,
        })),
        correctOptionIndex: PropTypes.number,
    }),
    onSubmit: PropTypes.func,
    submitBtnContent: PropTypes.node,
}

QuestionFieldForm.defaultProps = {
    isEdit: false,
    defaultValues: undefined,
    onSubmit: undefined,
    submitBtnContent: (
        <>
            <img
                src="/img/ico-new-field.svg"
                alt=""
            />
        Add New Field
        </>
    )
}

export default QuestionFieldForm;
