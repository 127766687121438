import React from "react";
import {Button, Form, Input, Row} from "antd";
import {KeyOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import {registerUserApi} from "../../api/user.api";

class RegisterFormComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            registerForm: [
                {
                    label: 'Name',
                    name: 'full_name',
                    placeholder: 'Name',
                    message: 'Please input your Name!',
                    icon: <UserOutlined className="site-form-item-icon"/>
                }, {
                    label: 'Username',
                    name: 'username',
                    placeholder: 'Username',
                    message: 'Please input your Username!',
                    icon: <UserOutlined className="site-form-item-icon"/>
                }, {
                    label: 'Email',
                    name: 'email',
                    placeholder: 'Email',
                    message: 'Please input your Email!',
                    icon: <MailOutlined className="site-form-item-icon"/>
                }, {
                    label: 'Password',
                    name: 'password',
                    placeholder: 'Password',
                    message: 'Please input your Password!',
                    icon: <KeyOutlined/>,
                    type: 'password'
                }, {
                    label: 'Confirm Password',
                    name: 'confirm_password',
                    placeholder: 'Confirm Password',
                    message: 'Please input your Confirm Password!',
                    icon: <KeyOutlined/>,
                    type: 'password'
                }
            ],
            loadings: false
        }

    }

    onFinishRegisterForm = (values) => {
        this.setState({loadings: true})
        registerUserApi(values)
            .then(result => {
                this.setState({loadings: false})
                alert('Akun berhasil dibuat!')
                window.location.reload()
            }).catch(err => {
            console.log(err)
            this.setState({loadings: false})
            alert('Maaf terjadi kesalahan!')
        })
    }

    render() {
        const {registerForm, loadings} = this.state
        return (
            <Form
                wrapperCol={{span: 8}}
                labelCol={{span: 3}}

                onFinish={this.onFinishRegisterForm}
            >
                {
                    registerForm.map((item) => {
                        switch (item.type) {
                            case 'password':
                                return (
                                    <Form.Item
                                        label={item.label}
                                        key={item.name}
                                        name={item.name}
                                        hasFeedback={true}
                                        rules={[
                                            {required: true, message: item.message},
                                            ({getFieldValue}) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('Password yang anda masukkan tidak cocok');
                                                },
                                            })
                                        ]}
                                    >
                                        <Input.Password prefix={item.icon} placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        label={item.label}
                                        key={item.name}
                                        name={item.name}
                                        rules={[{required: true, message: item.message}]}
                                    >
                                        <Input prefix={item.icon} placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    })
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{marginRight: 10}}
                            loading={loadings}>
                        Register
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default RegisterFormComponent
