import React from "react";
import {Button, Form, Image, Input, Row, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {isEqual} from "../../ui-util/general-variable";

class InterestCategoryFormComponent extends React.PureComponent{

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            fileImageList: []
        }
    }

    componentDidMount(): void {
        const {interestCategoryDetail} = this.props
        this.formRef.current.setFieldsValue(interestCategoryDetail)
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage]});
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {interestCategoryDetail} = this.props
        if (!isEqual(this.props.interestCategoryDetail, prevProps.interestCategoryDetail)) {
            this.formRef.current.setFieldsValue(interestCategoryDetail)
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {fileImageList} = this.state
        const {onFinish, interestCategoryDetail, labelCol, wrapperCol, loading} = this.props
        return (
            <Form ref={this.formRef} onFinish={(value) => onFinish(value, fileImageList)} labelCol={{span: labelCol}}
                  wrapperCol={{span: wrapperCol}}>
                <Form.Item
                    key='name'
                    label='Name'
                    name='name'
                    rules={[{
                        required: true,
                        message: 'Please input name',
                    }]}
                >
                    <Input placeholder={"Cth: Favorite Look"}/>
                </Form.Item>
                <Form.Item
                    key='image'
                    label='Image'
                    name='image'
                    rules={[{
                        required: true,
                        message: 'Please input image',
                    }]}
                >
                    <Upload
                        fileList={fileImageList}
                        onRemove={this.removeButtonHandler}
                        beforeUpload={this.beforeUploadImage}
                    >
                        {
                            !!interestCategoryDetail
                            &&
                            <Image
                                src={interestCategoryDetail.image}
                                width={100}
                            />
                        }
                        <Row style={{marginTop: 10}}>
                            <Button style={{marginRight: 10}} icon={<UploadOutlined/>}>
                                Upload
                            </Button>
                            <p>Ukuran gambar maksimal 2MB</p>
                        </Row>
                    </Upload>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default InterestCategoryFormComponent
