import React from "react";
import {Button, Form, Input} from "antd";
import {isEqual} from "../../ui-util/general-variable";

class LevelFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            userLevelForm: [
                {
                    label: 'Nama Level',
                    name: 'name',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input nama level!',
                    placeholder: 'Cth: Roman'
                }, {
                    label: 'Poin Minimal',
                    name: 'minimum_point',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input point start!',
                    placeholder: 'Cth: 1'
                },  {
                    label: 'Poin Maximal',
                    name: 'maximum_point',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input point end!',
                    placeholder: 'Cth: 100'
                }
            ],
        }
    }

    componentDidMount(): void {
        const {levelDetail} = this.props
        this.formRef.current.setFieldsValue(levelDetail)
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {levelDetail} = this.props
        if (!isEqual(this.props.levelDetail, prevProps.levelDetail)) {
            this.formRef.current.setFieldsValue(levelDetail)
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {userLevelForm} = this.state
        const {loading, onFinish, labelCol} = this.props
        return (
            <>
                <Form onFinish={onFinish} labelCol={{span: labelCol}} ref={this.formRef}
                      wrapperCol={{span: 10}}>
                    {
                        userLevelForm.map((item, index) => {
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Input placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                        })
                    }
                    <Form.Item
                        wrapperCol={{
                            span: 4,
                            offset: 4,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

            </>
        )
    }
}

export default LevelFormComponent
