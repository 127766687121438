import React,{ useState } from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { getshareCampaign, deleteCampaignApi, createReportApi  } from "../../../api/campaign.api";

const List = (props) => {
    const { data, total, set_page, current_page, per_page, pageStatus, currencyFormat, defaultTab, upcomingTab, ongoingTab, approvementTab, pastTab, draftTab} = props;
    const [appState, setAppState]  = useState({
        dataState:data,
        disabled: false,
        total: total,
        current_page: current_page,
        per_page: per_page
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    function shareCampaign(e){
        setAppState({disabled: true, dataState:appState.dataState});
        const {id} = appState.dataState[e]
        getshareCampaign(id)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    function deleteCampaign(e){
        setAppState({disabled: true, dataState: appState.dataState , total: appState.total, current_page: appState.current_page, per_page: appState.per_page});
        swal({
            title: "Confirmation",
            text: "Are you sure delete campaign ?",
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    closeModal: true
                },
            },
            dangerMode: true,
        }).then((isConfirm) => {
            if (!isConfirm){ return false;}
            setAppState({disabled: true, dataState: appState.dataState , total: appState.total, current_page: appState.current_page, per_page: appState.per_page});
            const {id} = appState.dataState[e]
            deleteCampaignApi(id)
            .then((result) => {
                const data = result.message;
                if(result.code == 201) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        button: true
                    })
                    setTimeout(function () {
                        window.location.reload()
                    }, 3000);
                }
            });
        }) 
    }
    function reportCampaign(e){
        setAppState({disabled: true, dataState:appState.dataState});
        const {id} = appState.dataState[e]
        console.log(id)
        createReportApi(id)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    }
    if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data campaign, sorry</p>;
    
    const page_count = total / per_page;
    return (
        <div className="content-wrapper">
            <div className="c-row">
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="c-items" key={index}>
                        <div className="lc-img-cover">
                            <a href={`/campaign/${repo.id}/detail`}><img src={repo.thumbnail} className="img-fluid" /></a>
                        </div>
                        <div className="box-c-desc">
                            <div>
                                <a href={`/campaign/${repo.id}/detail`}>
                                    <h6 className="font-medium">{repo.title}</h6>
                                </a>
                                {(() => {
                                    if (upcomingTab) {
                                        return <div className="grey font-small">{repo.status}</div>
                                    } else if (defaultTab || approvementTab) {
                                        return <div className="grey font-small">{new Date(repo.registration_start_date).getDate()+ " " + monthNames[new Date(repo.registration_start_date).getMonth()]+ " "+ new Date(repo.registration_start_date).getFullYear()}</div>
                                    } else if (ongoingTab) {
                                        return <div className="grey font-small">{new Date(repo.campaign_start_date).getDate()+ " " + monthNames[new Date(repo.campaign_start_date).getMonth()]+ " "+ new Date(repo.campaign_start_date).getFullYear()}</div>
                                    } else if (pastTab) {
                                        return <div className="grey font-small">{new Date(repo.campaign_end_date).getDate()+ " " + monthNames[new Date(repo.campaign_end_date).getMonth()]+ " "+ new Date(repo.campaign_end_date).getFullYear()}</div>
                                    }
                                })()}
                            </div>
                            <div className="btn-group-action">
                            {(() => {
                            if (defaultTab || upcomingTab || approvementTab || ongoingTab || draftTab) {
                                return <Link to={`/campaign/${repo.id}/edit`} className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></Link>
                            } else if (pastTab) {
                                return <a href={`/campaign/${repo.id}/detail`} className="btn btn-edit"><img src="/img/ico-detail.svg" className="img-fluid red ico-detail" /></a>
                            }
                            })()}
                                <button onClick={(e)=>deleteCampaign(index)}  className="btn btn-edit ml-1"><img src="/img/ico-delete.svg" className="img-fluid red" /></button>
                            </div>
                        </div>
                        {(() => {
                            if (upcomingTab) {
                            return <div className="d-flex align-items-center justify-content-between">
                                    <button onClick={(e)=>shareCampaign(index)} className="btn sm btn-primary mr5"> <img src="/img/ico-share.svg" className="img-fluid white" />Share</button>
                                    <Link to="/goddess-gang" className="btn sm btn-primary mr5"><img src="/img/ico-add.svg" className="img-fluid white" /> Add Influencer</Link>
                                    <div className="font-medium text-right">{currencyFormat(repo.point)} Point</div>
                                </div>
                            } else if (defaultTab || approvementTab) {
                                return <div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center w-100 mb-3">
                                                {repo.campaign_users && repo.campaign_users.length> 0 ? repo.campaign_users.slice(0, 3).map((item,index) => {
                                                    return (
                                                        <div className="img-group" key={index}>
                                                            {(() => {
                                                                if (item.user != null) {
                                                                    return <img src={item.user.photo} className="img-fluid img-circle" />
                                                                } else {
                                                                    return <img src="" className="img-fluid img-circle" />
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                    }): <div className="img-group"></div>
                                                }
                                                <div className="ig-text">{repo.total_user > 0 ? repo.total_user+" Orang" : ""}</div>
                                            </div>
                                        </div>
                                        <div className="font-medium text-center">{currencyFormat(repo.point)} Point</div>
                                    </div>
                                    <Link to={`/campaign/${repo.id}/approvement`} className="btn sm btn-primary mr-2"> <img src="/img/ico-check.svg" className="img-fluid white" />Approval</Link>
                                </div>
                            } else if (ongoingTab || draftTab) {
                                return <div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center w-100 mb-3">
                                                {repo.campaign_users && repo.campaign_users.length> 0 ? repo.campaign_users.slice(0, 3).map((item,index) => {
                                                    return (
                                                        <div className="img-group" key={index}>
                                                            {(() => {
                                                                if (item.user != null) {
                                                                    return <img src={item.user.photo} className="img-fluid img-circle" />
                                                                } else {
                                                                    return <img src="" className="img-fluid img-circle" />
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                    }): <div className="img-group"></div>
                                                }
                                                <div className="ig-text">{repo.total_user > 0 ? repo.total_user+" Orang" : ""}</div>
                                            </div>
                                        </div>
                                        <div className="font-medium text-center">{currencyFormat(repo.point)} Point</div>
                                    </div>
                                    {(() => {
                                        if (ongoingTab) {
                                            return <Link to={`/campaign/${repo.id}/approvement`} className="btn sm btn-primary mr-2"> <img src="/img/ico-check.svg" className="img-fluid white" />Approval</Link>
                                        } else {
                                            return <span></span>
                                        }
                                    })()}
                                </div>
                            } else if (pastTab) {
                                return <div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center w-100 mb-3">
                                                {repo.campaign_users && repo.campaign_users.length> 0 ? repo.campaign_users.slice(0, 3).map((item,index) => {
                                                    return (
                                                        <div className="img-group" key={index}>
                                                            {(() => {
                                                                if (item.user != null) {
                                                                    return <img src={item.user.photo} className="img-fluid img-circle" />
                                                                } else {
                                                                    return <img src="" className="img-fluid img-circle" />
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                    }): <div className="img-group"></div>
                                                }
                                                <div className="ig-text">{repo.total_user > 0 ? repo.total_user+" Orang" : ""}</div>
                                            </div>
                                        </div>
                                        <div className="font-medium text-center">{currencyFormat(repo.point)} Point</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Link to={`/campaign/${repo.id}/approvement`} className="btn sm btn-primary mr5"> <img src="/img/ico-check.svg" className="img-fluid white mr5" />Approval</Link>
                                        <button onClick={(e)=>reportCampaign(index)}  className="btn sm btn-primary mr5"><img src="/img/ico-info.svg" className="img-fluid white mr5" /> Report</button>
                                    </div>
                                </div>
                            }
                        })()}
                    </div>
                );
            })}
            </div>
            <div className="d-flex justify-content-center mt-5">
                {(() => {
                if (total < 5) {
                    return <ReactPaginate
                        pageCount={1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                } else {
                    return <ReactPaginate
                        pageCount={Math.ceil(page_count)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                }
                })()}
            </div>
        </div>
    );
}

export default List;