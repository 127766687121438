import {message} from 'antd';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import RichTextEditor from 'react-rte';
import CreateQuestionerApi from '../../api/create-questioner.api';
import QuestionFieldForm from './QuestionFieldForm.component';
import './style.css'

const RTE_TOOLBAR_CONFIG = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
}

const CreateQuestionerForm = () => {
    const {control, formState: {errors}, watch, register, setValue, getValues, handleSubmit, reset} = useForm();
    const fileInputRef = useRef(null);
    const [description, setDescription] = useState(RichTextEditor.createEmptyValue());
    const unpublished = useFieldArray({control, name: 'unpublished'});
    const published = useFieldArray({control, name: 'published'});

    const image = watch('image');

    const onSubmit = useCallback(async (data) => {
        const hideLoading = message.loading('Saving...', 0);
        try {
        const createMapQuestionsFn = ({published = false}) => (item, index) => ({
            question: item.question,
            image: item.image,
            type: 'radio',
            sequence: index + 1,
            is_published: published ? 1 : 0,
            options: item.options.map((option, optionIdx) => ({
                type: option.type,
                label: option.label,
                is_correct: optionIdx === item.correctOptionIndex,
            })),
        })

        const payload = {
            title: data.title,
            image: data.image,
            description: description.toString('html'),
            details: [
                ...data.published.map(createMapQuestionsFn({published: true})),
                ...data.unpublished.map(createMapQuestionsFn({published: false})),
            ],
        }

        await CreateQuestionerApi.saveQuestioner(payload)
        message.info('Saved')
        } catch (err) {
            message.error(err.message)
        }
        hideLoading()
    }, [description])

    const handleInputImage = useCallback(async (e) => {
        if (!e.target.files || !e.target.files[0]) return;
        const hideLoading = message.loading('Uploading...', 0);
        try {
            const url = await CreateQuestionerApi.uploadImage(e.target.files[0]);
            setValue('image', url);
        } catch (err) {
            message.error(err.message);
        }
        e.target.value = '';
        hideLoading();
    }, [setValue]);

    useEffect(() => {
        const hideLoading = message.loading('Loading...', 0);
        CreateQuestionerApi.getQuestioner()
            .then(data => {
                hideLoading()
                if (!data?.data) return;

                const unpublished = []
                const published = []

                if (Array.isArray(data.data.details)) {
                    data.data.details.forEach(item => {
                        const options = Array.isArray(item.options)
                        ? item.options
                        : []
                        const correctOptionIndex = options.findIndex(option => !!option.is_correct);
                        const questionField = {
                            question: item.question,
                            image: item.image,
                            options,
                            correctOptionIndex,
                        }

                        if (item.is_published) published.push(questionField)
                        else unpublished.push(questionField)
                    })
                }

                unpublished.sort((a, b) => {
                    if (a.sequence > b.sequence) return 1;
                    if (a.sequence < b.sequence) return -1;
                    return 0
                })

                published.sort((a, b) => {
                    if (a.sequence > b.sequence) return 1;
                    if (a.sequence < b.sequence) return -1;
                    return 0
                })

                reset({
                    title: data.data.title,
                    image: data.data.image,
                })
                if (data.data.description) {
                    setDescription(RichTextEditor.createValueFromString(data.data.description, 'html'))
                }
                setValue('unpublished', unpublished)
                setValue('published', published)
            })
            .catch((err) => {
                message.error(err.message)
                hideLoading()
            })
    }, [reset, setValue])

    return (
        <div id="main-content" className="create-questioner-form">
            <div className="content-wrapper">
                <h4 className="title mb-3">Introduction</h4>
                <div className="card intro">
                    <div className="d-flex mb-3">
                        <input
                            className="form-control no-radius w-50 mr-3 font-weight-bold"
                            type="text"
                            {...register('title', { required: 'Harus diisi!'})}
                        />
                        <button className="btn btn-gray icon" type="button" onClick={() => fileInputRef.current?.click()}>
                            <img
                                src="/img/ico-img.svg"
                                alt="add"
                            />
                            Insert Image
                        </button>
                    </div>
                    {errors.title?.message && <p className="alert-message">{errors.title.message}</p>}
                    {image && (
                        <div className="mb-3 d-flex align-items-center">
                            <div
                                style={{
                                    maxWidth: 406,
                                    marginRight: 16,
                                }}
                            >
                                <img 
                                    src={image}
                                    style={{
                                        width: '100%',
                                    }}
                                    alt=""
                                />
                            </div>
                            <button
                                type="button"
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    marginRight: 24,
                                }}
                                onClick={() => {
                                    setValue('image', undefined)
                                }}
                            >
                                <img src="/img/ico-btn-remove.svg" alt="remove"/>
                            </button>
                        </div>
                    )}
                    <input ref={fileInputRef} type="file" className="d-none" onChange={handleInputImage} />
                    <RichTextEditor
                        value={description}
                        onChange={setDescription}
                        toolbarConfig={RTE_TOOLBAR_CONFIG}
                        toolbarClassName="toolbar"
                        className="editor-root"
                    />
                </div>
                <div className="d-flex mt-4 b-divider">
                    <div className="d-flex flex-column pr-5 w-50 r-divider">
                        <h4 className="title mb-3">Unpublished Questionnaire</h4>
                        <div className="card active mb-4">
                            <QuestionFieldForm
                                isEdit
                                onSubmit={(data) => unpublished.prepend(data)}
                            />
                        </div>
                        {unpublished.fields.map((item, index) => (
                            <div className="card mb-4 d-flex flex-row pb-0" key={item.id}>
                                <div className="question-number flex-shrink-0">
                                    {index+1}
                                </div>
                                <div className="flex-grow-1">
                                    <UnpublishedQuestionField
                                        control={control}
                                        item={item}
                                        index={index}
                                        unpublished={unpublished}
                                        published={published}
                                        getValues={getValues}
                                        setValue={setValue}
                                        />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-column pl-5 w-50">
                        <h4 className="title mb-3">Published Questionnaire</h4>
                        {published.fields.map((item, index) => (
                            <div className="card mb-4 d-flex flex-row pb-0" key={item.id}>
                                <div className="question-number flex-shrink-0">
                                    {index+1}
                                </div>
                                <div className="flex-grow-1">
                                    <QuestionFieldForm
                                        defaultValues={item}
                                        onSubmit={() => undefined}
                                    />
                                    <div className="d-flex justify-content-end flex-wrap">
                                        <button
                                            type="button"
                                            className="btn btn-gray icon mr-3 mb-3"
                                            onClick={() => {
                                                const data = getValues().published[index];
                                                published.remove(index)
                                                unpublished.append(data)
                                            }}
                                        >
                                            <img
                                                src="/img/ico-lock.svg"
                                                alt=""
                                            />
                                            Move To Unpublished List
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-gray icon mb-3 mover"
                                            onClick={() => {
                                                if (index >= published.fields.length - 1) return;
                                                published.move(index, index + 1)
                                            }}
                                            style={{padding: 0}}
                                        >
                                            <img
                                                style={{margin: 0}}
                                                src="/img/ico-btn-down.svg"
                                                alt=""
                                            />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-gray icon mb-3 mover"
                                            style={{padding: 0}}
                                            onClick={() => {
                                                if (index <= 0) return;
                                                published.move(index, index - 1)
                                            }}
                                        >
                                            <img
                                                style={{margin: 0}}
                                                src="/img/ico-btn-up.svg"
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pt-4 text-right">
                    <button type="button" className="btn btn-primary" style={{width: 186}} onClick={handleSubmit(onSubmit)}>Publish</button>
                </div>
            </div>
        </div>
    )
}

export default CreateQuestionerForm

const UnpublishedQuestionField = ({control, item, unpublished, published, getValues, setValue, index}) => {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <>
            <QuestionFieldForm
                isEdit={isEdit}
                defaultValues={item}
                onSubmit={(data) => {
                    unpublished.update(index, data)
                    setIsEdit(false)
                }}
                submitBtnContent={(
                    <>
                        <img
                            src="/img/ico-save-white.svg"
                            alt=""
                        />
                        Save
                    </>
                )}
            />
            {isEdit && <div className="mb-3" />}
            {!isEdit && (
                <div className="d-flex justify-content-end flex-wrap">
                    <button
                        type="button"
                        className="btn btn-gray icon mr-3 mb-3"
                        onClick={() => unpublished.remove(index)}
                    >
                        <img
                            src="/img/ico-trashcan.svg"
                            alt=""
                        />
                        Delete
                    </button>
                    <button
                        type="button"
                        className="btn btn-gray icon mr-3 mb-3"
                        onClick={() => setIsEdit(true)}
                    >
                        <img
                            src="/img/ico-edit-2.svg"
                            alt=""
                        />
                        Edit
                    </button>
                    <button
                        type="button"
                        className="btn btn-gray icon mr-3 mb-3"
                        onClick={() => {
                            const data = getValues().unpublished[index];
                            published.append(data)
                            unpublished.remove(index)
                        }}
                    >
                        <img
                            src="/img/ico-send.svg"
                            alt=""
                        />
                        Move To Published List
                    </button>
                </div>
            )}
        </>
    )
}
