import React from "react";
import {Button, Collapse, Image, Table} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";
import EditFormModal from "../../component/edit-form.modal";
import SkinConditionFormComponent from "../skin-condition/skin-condition-form.component";
import StandardAlertModal from "../../component/standard-alert.modal";
import {deleteSkinConditionApi, getSkinConditionApi, updateSkinConditionApi} from "../../api/skinCondition.api";

const {Panel} = Collapse;

class BeautyProfileComponent extends React.Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => <Image src={image} width={100} height={100}/>
                },
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {
                    key: 'edit', render: (record) =>
                        <>
                            <Button style={{marginRight: 10}}
                                    onClick={() => this.changeVisibility('editSkinConditionModalVisibility', true, record)}>Edit</Button>
                            <Button
                                onClick={() => this.changeVisibility('deleteSkinConditionModalVisibility', true, record)}>Delete</Button>
                        </>
                }
            ],
            panelList: [
                {key: 'skin-type', name: 'Skin Type', beautyProfileList: []},
                {key: 'skin-tone', name: 'Skin Tone', beautyProfileList: []},
                {key: 'skin-undertone', name: 'Skin Undertone', beautyProfileList: []},
                {key: 'skin-concern', name: 'Skin Concern', beautyProfileList: []},
            ],
            skinConditionList: [],
            skinConditionDetail: {},
            editSkinConditionModalVisibility: false,
            deleteSkinConditionModalVisibility: false,
            skinConditionKey: 'skin-type',
            loading: false,
            loadingDelete: false,
        }
    }

    componentDidMount(): void {
        const {panelList} = this.state
        Promise.all([
            getSkinConditionApi('skin-type'),
            getSkinConditionApi('skin-tone'),
            getSkinConditionApi('skin-undertone'),
            getSkinConditionApi('skin-concern')
        ])
            .then(response => {
                panelList[0].beautyProfileList = response[0].result
                panelList[1].beautyProfileList = response[1].result
                panelList[2].beautyProfileList = response[2].result
                panelList[3].beautyProfileList = response[3].result
                this.setState({panelList})
            }).catch(err => {
            console.log(err)
        })
    }


    changeVisibility = (key, value, skinConditionDetail) => {
        this.setState({[key]: value, skinConditionDetail})
    }

    onFinishEditSkinCondition = (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const {skinConditionKey} = this.state
            const {id} = this.state.skinConditionDetail
            const form = new FormData()
            form.append('name', values.name)
            form.append('image', values.image.file)

            updateSkinConditionApi(skinConditionKey, id, form)
                .then(result => {
                    alert(`Edit beauty profile berhasil!`)
                    this.setState({loading: false, editSkinConditionModalVisibility: false})
                    window.location.reload()
                }).catch(err => {
                this.setState({loading: false})
                console.log(err)
            })
        }
    }

    onOkButtonHandler = () => {
        this.setState({loadingDelete: true})
        const {id} = this.state.skinConditionDetail
        const {skinConditionKey} = this.state
        deleteSkinConditionApi(skinConditionKey, id)
            .then(result => {
                alert(`Beauty profile berhasil dihapus!`)
                this.setState({loadingDelete: false, deleteSkinConditionModalVisibility: false})
                window.location.reload()
            }).catch(err => {
            this.setState({loadingDelete: false})
            console.log(err)
        })
    }

    onChangePanel = (value) => {
        let skinConditionKey = value[value.length - 1]
        this.setState({skinConditionKey})
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return true
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {editSkinConditionModalVisibility, deleteSkinConditionModalVisibility, skinConditionDetail, loadingDelete, panelList, columns, loading} = this.state
        return (
            <div>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['skin-type']}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    ghost
                    onChange={this.onChangePanel}
                    className="site-collapse-custom-collapse">
                    {
                        panelList.map(item => (
                            <Panel header={item.name} key={item.key}>
                                <div style={{marginLeft: 50}}>
                                    <Table
                                        showHeader={false}
                                        className="components-table-demo-nested"
                                        columns={columns}
                                        dataSource={item.beautyProfileList}
                                        pagination={false}
                                        rowKey={'id'}
                                    />
                                </div>
                            </Panel>
                        ))
                    }
                </Collapse>
                <EditFormModal
                    modalVisibility={editSkinConditionModalVisibility}
                    modalVisibilityKey={"editSkinConditionModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    title={`Edit beauty profile`}
                    content={
                        <SkinConditionFormComponent
                            skinToneDetail={skinConditionDetail}
                            onFinish={this.onFinishEditSkinCondition}
                            loading={loading}
                        />
                    }
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onOkButtonHandler}
                    skinConditionKey={"deleteSkinConditionModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    modalVisibility={deleteSkinConditionModalVisibility}
                    title={`Delete beauty profile`}
                    bodyMessage={`Apakah anda yakin ingin menghapus beauty profile ini?`}
                />
            </div>
        )
    }
}

export default BeautyProfileComponent