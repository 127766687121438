import React from "react";
import moment from 'moment';
import {Button, Col, DatePicker, Input, Row, Select} from "antd";
import {getLevelApi} from "../../api/level.api";

const {Search} = Input;
const {Option} = Select;
const {RangePicker} = DatePicker;

class FilterUserComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            levelList: [],
            disabled: true,
            startDate: '',
            endDate: ''
        }
    }

    componentDidMount(): void {
        this.getLevelList()
    }

    getLevelList = () => {
        getLevelApi()
            .then(response => {
                let levelList = response.result
                levelList[levelList.length] = {
                    id: '', name: 'ALL'
                }
                this.setState({levelList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    onChangeDatePicker = (value) => {
        let startDate = moment(value[0]).format('YYYY-MM-DD')
        let endDate = moment(value[1]).format('YYYY-MM-DD')
        this.setState({startDate, endDate})
    }

    filterUserListByDate = () => {
        const {startDate, endDate} = this.state
        this.props.searchAndFilter('', 'createdAt', startDate, endDate)
    }

    onChangeSelect = (value, table) => {
        if (!!value) {
            this.props.searchAndFilter(value, table)
        } else {
            this.props.searchAndFilter(value, '')
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {levelList} = this.state
        const {searchAndFilter} = this.props
        return (
            <Row style={{marginBottom: 20}} justify="space-around">
                <Col>
                    <div style={{marginBottom: 10}}>Search :</div>
                    <Search placeholder="input nama"
                            onSearch={(value) => searchAndFilter(value, 'name')}
                            style={{width: 200}} enterButton="Search"/>
                </Col>
                <Col>
                    <div style={{marginBottom: 10}}>Level :</div>
                    <Select
                        showSearch
                        style={{minWidth: 150}}
                        defaultValue=''
                        onSelect={(value) => this.onChangeSelect(value, 'level')}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            levelList.map(item =>
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                        }
                    </Select>
                </Col>
                <Col>
                    <div style={{marginBottom: 10}}>Filter :</div>
                    <Row>
                        <RangePicker onChange={this.onChangeDatePicker}/>
                        <Button style={{marginLeft: 10}} type={"primary"}
                                onClick={() => this.filterUserListByDate()}>Filter</Button>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default FilterUserComponent
