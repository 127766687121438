import React from "react";
import {Layout, Menu} from 'antd';

const {Header} = Layout;

class HeaderComponent extends React.PureComponent {
    logoutHandler = () => {
        localStorage.removeItem('TOKEN_AUTH')
        localStorage.removeItem('full_name')
        localStorage.removeItem('group_id')
        localStorage.removeItem('group_name')
        window.location.reload()
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const token = localStorage.getItem('TOKEN_AUTH');
        return (
            <Header className="d-flex align-items-center justify-content-between header" >
                <h3 style={{color: 'white'}}>Looke Dashboard</h3>
                <Menu theme={"dark"} mode="horizontal" onClick={this.logoutHandler}>
                    <Menu.Item>{!!token && 'Logout'}</Menu.Item>
                </Menu>
            </Header>
        )
    }
}

export default HeaderComponent
