import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { Controller, useForm } from 'react-hook-form';
import {getTimelineApi, saveTimelineApi} from "../../api/create-timeline.api";

function CreateTimeline() {
    const [inputList, setInputList] = useState([{ title: "", text: "" }]);
    const [appState, setAppState]     = useState({
        loading: false,
        disabled: false
    });
    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        mode: "all"
    });
    const onSend = () => {
        let bodyRequest = []
        const timeline = inputList.map(d => ({
            "title" : d.title,
            "text" : d.text
        }))
        let values = {
            labels: timeline
        }
        bodyRequest.push(values)
        console.log(values)
        saveTimelineApi(values)
        .then((repos) => {
            const data = repos.message;
            if(repos.code == 200 || repos.code == 201) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload();
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    }
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    
    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { title: "", text: "" }]);
    };
    useEffect(() => {
    setAppState({ loading: true});
    const fetchData = async () => {
        getTimelineApi()
            .then(result => {
                setInputList(result.data)
                setAppState({ loading: false });
            }).catch(err => {
                console.log(err)
            })
        };
        fetchData();
    }, []);
    // console.log(inputList)
    return ( 
        <div id="main-content">
            {inputList.map((x, i) => {
                return (
                    <div className="box" key={i}>
                        <div className="row">
                            {(() => {
                                if (x.title != '') {
                                return <div className="col-5"> 
                                    <div className="font-medium mb-2">Title</div>
                                    <input
                                    className="form-control"
                                    name="title"
                                    placeholder="Title"
                                    value={x.title}
                                    readOnly
                                    onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                } else {
                                    return <div className="col-5"> 
                                    <div className="font-medium mb-2">Title</div>
                                    <input
                                    className="form-control"
                                    name="title"
                                    placeholder="Title"
                                    value={x.title}
                                    onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                }
                            })()}
                            <div className="col-5"> 
                                <div className="font-medium mb-2">Description</div>
                                <textarea
                                className="form-control"
                                name="text"
                                placeholder="Description"
                                value={x.text}
                                onChange={e => handleInputChange(e, i)}
                                />
                            </div>
                            <div className="btn-box mb-4">
                            {inputList.length !== 1 && <button
                                className="btn btn-primary mr10"
                                onClick={() => handleRemoveClick(i)}>Remove</button>}
                            {<button className="btn btn-primary" onClick={handleAddClick}>Add</button>}
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="d-flex justify-content-end">
                <button className="btn btn-primary mw-150" onClick={handleSubmit(onSend)}>Submit</button>
            </div>
        </div>
    );
}


export default CreateTimeline;
