import React,{ useState } from 'react';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import parse from 'html-react-parser';
import { setUserCampaignApi, getContentCampaignApi, setContentCampaignApi } from "../../../api/user-campaign.api";
import SortArrow from '../../../component/Common/sort-arrow';

const List = (props) => {
    const { data, total, set_page, current_page, per_page, pageStatus, link_id, campaign, justNumbers, currencyFormat, sort, setSort, orderby, setOrderby } = props;
    const [appState, setAppState]  = useState({
        dataState:data,
        campaign: campaign,
        showSosmed: false,
        content:null,
        disabled: false
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            if(appState.dataState[index].campaign_users[0].status == "Menunggu Konfirmasi" || appState.dataState[index].campaign_users[0].status == "Menunggu Submit Konten" || appState.dataState[index].campaign_users[0].status == "Done"){
                appState.dataState[index].isChecked= false
            } else {
                appState.dataState[index].isChecked= event.target.checked
            }
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState, campaign:appState.campaign });
    }
    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].id)
    
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
        setAppState({dataState:appState.dataState, campaign:appState.campaign });
    
    }
    const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const updateSorting = (value) => {
        setSort(value.sort)
        setOrderby(value.orderBy)
    };
    function approveUser(e){
        setAppState({disabled: true, dataState:appState.dataState});
        let bodyRequest = []
        const user_id = appState.dataState[e].id
        let values = {
            status: "Approve",
            user_id: user_id
        }
        bodyRequest.push(values)
        setUserCampaignApi(link_id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    function declineUser(e){
        setAppState({disabled: true, dataState:appState.dataState});
        let bodyRequest = []
        const user_id = appState.dataState[e].id
        let values = {
            status: "Reject",
            user_id: user_id
        }
        bodyRequest.push(values)
        setUserCampaignApi(link_id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    const openSosmed = (e) => {
        setAppState({showSosmed: true, dataState:appState.dataState, campaign:appState.campaign});
        let bodyRequest = []
        let values = {
            campaign_id: link_id,
            user_id: e.id,
            task_name: e.task_name
        }
        bodyRequest.push(values)
        getContentCampaignApi(values)
        .then(result => {
            setAppState({ showSosmed: true, content: result, dataState:appState.dataState, campaign:appState.campaign });
        }).catch(err => {
            console.log(err)
        })
    }
    function closeSosmed() {
        setAppState({showSosmed: false, dataState:appState.dataState, campaign:appState.campaign});
    }
    function approveContent(e){
        setAppState({disabled: true, showSosmed: true, dataState:appState.dataState, campaign:appState.campaign, content: appState.content});
        let bodyRequest = []
        const content_id = e
        let values = {
            status: "Approve",
        }
        bodyRequest.push(values)
        setContentCampaignApi(content_id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    }
    function rejectContent(e){
        setAppState({disabled: true, showSosmed: true, dataState:appState.dataState, campaign:appState.campaign, content: appState.content});
        let bodyRequest = []
        const content_id = e
        let values = {
            status: "Reject",
        }
        bodyRequest.push(values)
        setContentCampaignApi(content_id, values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
        });
    }
    if (!data || data.length === 0) return <div className="content-wrapper">
        {(() => {
            if (pageStatus == 'Send Product') {
            return <div className="head-data-info">
                <div className="table-crh-1">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} />
                        <label className="custom-control-label" htmlFor="customCheck1" />
                    </div>
                </div>
                <div className="table-crh-2">User</div>
                <div style={{width: '20%', padding: '5px', borderRight: 'solid 1px #fff'}}>Nama Product</div>
                <div style={{width: '15%', padding: '5px', borderRight: 'solid 1px #fff'}}>Telepon User</div>
                <div className="table-crh-3">Total Point
                    <SortArrow
                        valueSort={sort}
                        valueOrderBy={"point"}
                        activeOrderBy={orderby}
                        onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="table-crh-5">Tgl Join Campaign</div>
                <div className="table-crh-4">Detail Alamat</div>
                <div className="table-crh-6">Status</div>
            </div>
            } else {
                return <div className="head-data-info">
                    <div className="table-crh-1">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} />
                            <label className="custom-control-label" htmlFor="customCheck1" />
                        </div>
                    </div>
                    <div className="table-crh-2">User</div>
                    <div className="table-crh-3">Total Point
                        <SortArrow
                            valueSort={sort}
                            valueOrderBy={"point"}
                            activeOrderBy={orderby}
                            onClick={(e) => updateSorting(e)}
                        />
                    </div>
                    {(() => {
                        if (pageStatus == 'Waiting Approval' || pageStatus == "undefined" || pageStatus == 'Approve') {
                            return <div className="table-crh-4">Potential Influencer</div>
                        } else if (pageStatus == 'Draft Konten') {
                            return <div className="table-crh-4">Submited Content</div>
                        } else if (pageStatus == 'Publish Konten') {
                            return <div className="table-crh-4">Published Content</div>
                        }
                    })()}
                    <div className="table-crh-5">Tgl Join Campaign</div>
                    <div className="table-crh-6">Status</div>
                </div>
            }
        })()}
        <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data user campaign, sorry</p>
    </div>;
    console.log(pageStatus)
    const page_count = total / per_page;
    return (
        <div className="content-wrapper">
            {(() => {
                if (pageStatus == 'Send Product') {
                return <div className="head-data-info">
                    <div className="table-crh-1">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} />
                            <label className="custom-control-label" htmlFor="customCheck1" />
                        </div>
                    </div>
                    <div className="table-crh-2">User</div>
                    <div className="table-crh-7">Nama Product</div>
                    <div className="table-crh-8">No. Telp</div>
                    <div className="table-crh-3">Total Point
                        <SortArrow
                            valueSort={sort}
                            valueOrderBy={"point"}
                            activeOrderBy={orderby}
                            onClick={(e) => updateSorting(e)}
                        />
                    </div>
                    <div className="table-crh-5">Tgl Join Campaign</div>
                    <div className="table-crh-4">Detail Alamat</div>
                    <div className="table-crh-6">Status</div>
                </div>
                } else {
                    return <div className="head-data-info">
                        <div className="table-crh-1">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} />
                                <label className="custom-control-label" htmlFor="customCheck1" />
                            </div>
                        </div>
                        <div className="table-crh-2">User</div>
                        <div className="table-crh-3">Total Point
                            <SortArrow
                                valueSort={sort}
                                valueOrderBy={"point"}
                                activeOrderBy={orderby}
                                onClick={(e) => updateSorting(e)}
                            />
                        </div>
                        {(() => {
                            if (pageStatus == 'Waiting Approval' || pageStatus == "undefined" || pageStatus == 'Approve') {
                                return <div className="table-crh-4">Potential Influencer</div>
                            } else if (pageStatus == 'Draft Konten') {
                                return <div className="table-crh-4">Submited Content</div>
                            } else if (pageStatus == 'Publish Konten') {
                                return <div className="table-crh-4">Published Content</div>
                            }
                        })()}
                        <div className="table-crh-5">Tgl Join Campaign</div>
                        <div className="table-crh-6">Status</div>
                    </div>
                }
            })()}
            {appState.dataState.map((repo,index) => {
                var point = justNumbers(repo.point);
                return (
                    <div className="data-items" key={index}>
                        <div className="crh-01">
                        {(() => {
                            if (pageStatus == 'Send Product') {
                                return <div className={repo.campaign_users[0].status == "Menunggu Konfirmasi" || repo.campaign_users[0].status == "Menunggu Submit Konten" ? "custom-control custom-checkbox disabled-check" : "custom-control custom-checkbox"}>
                                    <input type={repo.campaign_users[0].status == "Menunggu Konfirmasi" || repo.campaign_users[0].status == "Menunggu Submit Konten" ? "hidden" : "checkbox"}  className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)} />
                                    <label className="custom-control-label" htmlFor={index} />
                                </div>
                            } else if (pageStatus == 'Publish Konten') {
                                return <div className={repo.campaign_users[0].status == "Done" ? "custom-control custom-checkbox disabled-check" : "custom-control custom-checkbox"}>
                                    <input type={repo.campaign_users[0].status == "Done" ? "hidden" : "checkbox"}  className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)} />
                                    <label className="custom-control-label" htmlFor={index} />
                                </div>
                            } else {
                                return <div className="custom-control custom-checkbox">
                                    <input type="checkbox"  className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)} />
                                    <label className="custom-control-label" htmlFor={index} />
                                </div>
                            }
                        })()}
                        </div>
                        <div className="crh-02">
                            <a href={`/goddess-gang/${repo.id}/detail`} className="link-user">
                            <div className="box-user">
                                <div className="img-part mr-2">
                                    <img src={repo.photo} className="img-user img-fluid" alt="user" />
                                </div>
                                <div className="user-part">
                                <h5>
                                    {repo.full_name}
                                    {(() => {
                                    if (repo.is_potential == true) {
                                        return  <span className="tool-tip floating">
                                                <button className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></button>
                                                <div className="tool-tip-content right">
                                                    <span>Influencer Potential</span>
                                                </div>
                                            </span>
                                        } else {
                                            return <span></span>
                                        }
                                    })()}
                                </h5>
                                <p className="for-mail">{repo.email}</p>
                                <p>{repo.dob}</p>
                                </div>
                            </div>
                            </a>
                        </div>
                        {(() => {
                            if (pageStatus == 'Send Product') {
                                return <div className="table-crh-7">
                                    {repo.campaign_users[0].campaign_products!=[] || repo.campaign_users[0].campaign_products.length> 0 ? repo.campaign_users[0].campaign_products.map((item,index) => {
                                        return (
                                            <ul key={index}>
                                                <li>{item.product == null ? "-" : item.product.name}</li>
                                            </ul>
                                        );
                                    }): <span>-</span>
                                }
                                <table id="table-to-xls" className="hide-table">
                                    <thead>
                                        <tr>
                                            <th>Nama</th>
                                            <th>Email</th>
                                            <th>No. Telp</th>
                                            <th>Date of Birth</th>
                                            <th>Total Point</th>
                                            <th>Tanggal Join Campaign</th>
                                            <th>Detail Alamat</th>
                                            <th>Notes</th>
                                            <th>Status</th>
                                            <th>Nama Product</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {appState.dataState.map((item,index) => (
                                        <tr key={index}>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.full_name}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.email}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.phone}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.dob}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{currencyFormat(justNumbers(item.point))+" Point"}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{new Date(item.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(item.campaign_users[0].created_at).getMonth()]+ " "+ new Date(item.campaign_users[0].created_at).getFullYear()}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.campaign_user_addresses[0].address+", "+item.campaign_user_addresses[0].district+", "+item.campaign_user_addresses[0].city+", "+item.campaign_user_addresses[0].province+" "+item.campaign_user_addresses[0].post_code}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.campaign_user_addresses[0].description ? item.campaign_user_addresses[0].description: "-"}</td>
                                            <td rowSpan={item.campaign_users[0].campaign_products.length}>{item.campaign_users[0].status}</td>
                                            {item.campaign_users[0].campaign_products!=[] || item.campaign_users[0].campaign_products.length> 0 ? item.campaign_users[0].campaign_products.map((repo,index) => {
                                                    return (
                                                        <div key={index}>
                                                            {(() => {
                                                            if (index==0) {
                                                                return <td>{repo.product!=null ? repo.product.full_name??repo.product.name : "-"}</td>       
                                                            } else {
                                                                return <tr>
                                                                        <td>{repo.product!=null ? repo.product.full_name??repo.product.name : "-"}</td>
                                                                    </tr>;
                                                            }
                                                        })()}
                                                        </div>
                                                    );
                                                }): <td>-</td>
                                            }
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                </div>
                            }
                        })()}
                        {(() => {
                            if (pageStatus == 'Send Product') {
                                return <div className="table-crh-8">{repo.phone}</div>
                            }
                        })()}
                        <div className="crh-03">{currencyFormat(point)} Point</div>
                        {(() => {
                            if (pageStatus == 'Send Product') {
                            return  <div className="crh-05">{new Date(repo.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(repo.campaign_users[0].created_at).getMonth()]+ " "+ new Date(repo.campaign_users[0].created_at).getFullYear()}</div>
                            }
                        })()}
                        {(() => {
                            if (pageStatus == 'Waiting Approval' || pageStatus == "undefined" || pageStatus == 'Approve') {
                            return  <div className="crh-04">
                                    <div className="sosmed-potential">
                                        {(() => {
                                        if (repo.is_potential == false) {
                                            return  <div className="sp-item no-active">
                                                        <div className="ico-social"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                        <img src="/img/ico-minus.svg" className="img-fluid" />
                                                    </div>
                                        } else {
                                            return  <div className="sp-item">
                                                <div className="ico-social"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-potential.svg" className="img-fluid" />
                                            </div>
                                        }
                                        })()} 
                                        <div className="sp-item no-active">
                                            <div className="ico-social"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                            <img src="/img/ico-minus.svg" className="img-fluid" />
                                        </div>
                                        <div className="sp-item no-active">
                                            <div className="ico-social"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                            <img src="/img/ico-minus.svg" className="img-fluid" />
                                        </div><div className="sp-item no-active">
                                            <div className="ico-social"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                            <img src="/img/ico-minus.svg" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            } else if (pageStatus == 'Draft Konten') {
                                let haveInstagram = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="instagram_task")
                                        return true
                                })
                                let haveFacebook = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="facebook_task")
                                        return true
                                })
                                let haveTikTok = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="tiktok_task")
                                        return true
                                })
                                let haveYoutube = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="youtube_task")
                                        return true
                                })
                                return  (<div className="crh-04">
                                    <div className="sosmed-potential">
                                        {(() => {
                                        if (haveInstagram.length < 1) {
                                            return  <div className="sp-item no-active">
                                                        <div className="ico-social"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                        <img src="/img/ico-minus.svg" className="img-fluid" />
                                                    </div>
                                        } else {
                                            return  <div className="sp-item">
                                                        <div className="ico-social"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                        <button onClick={()=>openSosmed({id:repo.id, task_name:"instagram_task"})} className="btn-submited-content"><img src="/img/ico-potential.svg" className="img-fluid" /></button>
                                                    </div>
                                            }
                                        })()}
                                        {(() => {
                                        if (haveFacebook < 1) {
                                            return  <div className="sp-item no-active">
                                                <div className="ico-social"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-minus.svg" className="img-fluid" />
                                            </div>
                                        } else {
                                            return <div className="sp-item">
                                                <div className="ico-social"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                                <button onClick={()=>openSosmed({id:repo.id, task_name:"facebook_task"})} className="btn-submited-content"><img src="/img/ico-potential.svg" className="img-fluid" /></button>
                                            </div>
                                        }
                                        })()}
                                        {(() => {
                                        if (haveTikTok < 1) {
                                            return  <div className="sp-item no-active">
                                                <div className="ico-social"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-minus.svg" className="img-fluid" />
                                            </div>
                                        } else {
                                            return <div className="sp-item">
                                                <div className="ico-social"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                                <button onClick={()=>openSosmed({id:repo.id, task_name:"tiktok_task"})} className="btn-submited-content"><img src="/img/ico-potential.svg" className="img-fluid" /></button>
                                            </div>
                                        }
                                        })()}
                                        {(() => {
                                        if (haveYoutube < 1) {
                                            return  <div className="sp-item no-active">
                                                <div className="ico-social"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-minus.svg" className="img-fluid" />
                                            </div>
                                        } else {
                                            return  <div className="sp-item">
                                                <div className="ico-social"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                                <button onClick={()=>openSosmed({id:repo.id, task_name:"youtube_task"})} className="btn-submited-content"><img src="/img/ico-potential.svg" className="img-fluid" /></button>
                                            </div>
                                        }
                                        })()}
                                    </div>
                                </div>);
                            } else if (pageStatus == 'Publish Konten') {
                                let validSosmed = repo.campaign_user_submits.filter((e) => {
                                    if( e['is_valid'])
                                        return true
                                })
                                let haveInstagram = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="instagram_task")
                                        return true
                                })
                                let haveFacebook = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="facebook_task")
                                        return true
                                })
                                let haveTikTok = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="tiktok_task")
                                        return true
                                })
                                let haveYoutube = repo.campaign_user_submits.filter((e) => {
                                    if(e['task_name']==="youtube_task")
                                        return true
                                })
                                return  <div className="crh-04">
                                        <div className="sosmed-potential">
                                        {(() => {
                                        if (haveInstagram < 1) {
                                            return  <div className="sp-item no-active">
                                                        <div className="ico-social"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                        <img src="/img/ico-minus.svg" className="img-fluid" />
                                                    </div>
                                        } else {
                                            return  <div className="sp-item">
                                                <div className="ico-social"><img src="/img/insta-outline.svg" className="img-fluid" /></div>
                                                {(() => {
                                                if (validSosmed == 1) {
                                                    return <img src="/img/ico-potential.svg" className="img-fluid" />
                                                } else {
                                                    return <img src="/img/ico-minus.svg" className="img-fluid" />
                                                }
                                                })()}
                                            </div>
                                        }
                                        })()}
                                        {(() => {
                                        if (haveFacebook < 1) {
                                            return  <div className="sp-item no-active">
                                                <div className="ico-social"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-minus.svg" className="img-fluid" />
                                            </div>
                                        } else {
                                            return <div className="sp-item">
                                                <div className="ico-social"><img src="/img/facebook-outline.svg" className="img-fluid" /></div>
                                                {(() => {
                                                    if (validSosmed == 1) {
                                                        return <img src="/img/ico-potential.svg" className="img-fluid" />
                                                    } else {
                                                        return <img src="/img/ico-minus.svg" className="img-fluid" />
                                                    }
                                                })()}
                                            </div>
                                        }
                                        })()}
                                        {(() => {
                                        if (haveTikTok < 1) {
                                            return  <div className="sp-item no-active">
                                                <div className="ico-social"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-minus.svg" className="img-fluid" />
                                            </div>
                                        } else {
                                            return <div className="sp-item">
                                                <div className="ico-social"><img src="/img/tiktik-outline.svg" className="img-fluid" /></div>
                                                {(() => {
                                                    if (validSosmed == 1) {
                                                        return <img src="/img/ico-potential.svg" className="img-fluid" />
                                                    } else {
                                                        return <img src="/img/ico-minus.svg" className="img-fluid" />
                                                    }
                                                })()}
                                            </div>
                                        }
                                        })()}
                                        {(() => {
                                        if (haveYoutube < 1) {
                                            return  <div className="sp-item no-active">
                                                <div className="ico-social"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                                <img src="/img/ico-minus.svg" className="img-fluid" />
                                            </div>
                                        } else {
                                            return  <div className="sp-item">
                                                <div className="ico-social"><img src="/img/youtube-outline.svg" className="img-fluid" /></div>
                                                {(() => {
                                                    if (validSosmed == 1) {
                                                        return <img src="/img/ico-potential.svg" className="img-fluid" />
                                                    } else {
                                                        return <img src="/img/ico-minus.svg" className="img-fluid" />
                                                    }
                                                })()}
                                            </div>
                                        }
                                        })()}
                                        </div>
                                    </div>
                            } else {
                                return <div className="crh-04">
                                    <div className="d-flex pl-3 pr-3">
                                        <div className="black icon-address mr-4"><img src="/img/ico-home.svg" className="img-fluid" /></div>
                                        {(() => {
                                        if (repo.campaign_user_addresses != "") {
                                            return <div className="for-address">{repo.campaign_user_addresses[0].address}, {repo.campaign_user_addresses[0].district}, {repo.campaign_user_addresses[0].city}, {repo.campaign_user_addresses[0].province} {repo.campaign_user_addresses[0].post_code}. {repo.campaign_user_addresses[0].description ? "Notes: "+ repo.campaign_user_addresses[0].description: ""}</div>
                                        } else {
                                            return <div>-</div>
                                        }
                                        })()}
                                    </div>
                                </div>
                            }
                        })()}
                        {(() => {
                            if (pageStatus != 'Send Product') {
                            return  <div className="crh-05">{new Date(repo.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(repo.campaign_users[0].created_at).getMonth()]+ " "+ new Date(repo.campaign_users[0].created_at).getFullYear()}</div>
                            }
                        })()}
                        <div className="crh-06">
                            {(() => {
                            if (pageStatus == 'Waiting Approval' || pageStatus == "undefined") {
                                return <div className="d-flex justify-content-center">
                                    <div>
                                    <button className="btn btn-transparent btn-block approve" onClick={(e)=>approveUser(index)} disabled={appState.disabled}><img src="/img/ico-approve.svg" />Approve</button>
                                    <button className="btn btn-transparent btn-block decline" onClick={(e)=>declineUser(index)} disabled={appState.disabled}><img src="/img/ico-decline.svg" />Reject</button>
                                    </div>
                                </div>
                            } else if (pageStatus == 'Approve') {
                                return <div className="d-flex justify-content-center">
                                    <div className="ico-home">
                                        <img src="/img/ico-home.svg" className="img-fluid" />
                                    </div>
                                    <div className="grey font-medium">{repo.campaign_users[0].status}</div>
                                </div>
                            } else if (pageStatus == 'Send Product') {
                                return <div className="d-flex justify-content-center">
                                    <div className="ico-home">
                                        <img src="/img/ico-sp.svg" className="img-fluid red" />
                                    </div>
                                    <div className="red font-medium">{repo.campaign_users[0].status}</div>
                                </div>
                            } else if (pageStatus == 'Draft Konten') {
                                return <div className="d-flex justify-content-center">
                                    <div className="ico-home">
                                        <img src="/img/ico-draft.svg" className="img-fluid red" />
                                    </div>
                                    <div className="red font-medium">List Draft Content</div>
                                </div>
                            } else if (pageStatus == 'Publish Konten') {
                                return <div className="d-flex justify-content-center">
                                        <a href={`/goddess-gang/${repo.id}/detail`} className="btn-detail-content">
                                            <div className="ico-home">
                                                <img src="/img/ico-dc.svg" className="img-fluid" />
                                            </div>
                                            <div className="font-medium">Detail Content</div>
                                        </a>
                                    </div>
                            }
                            })()}
                        </div>

                        <div id="popup-draft" className="overlay">
                            <Modal id="popup-draft" show={appState.showSosmed}>
                                <div className="popup">
                                    <button className="close" onClick={closeSosmed}><img src="/img/ico-close.svg" alt="close" /></button>
                                    <div className="content">
                                        <div className="content-top">
                                            <h5 className="font-medium mb-3">Detail Draft Konten</h5>
                                            <div className="row mb-2">
                                                {(() => {
                                                if (appState.content != null) {
                                                return <div className="col-5">
                                                        <p className="font-medium">Profile</p>
                                                        <div className="box-user">
                                                            <div className="img-part mr-2">
                                                                <img src={appState.content.user.photo} style={{maxWidth: '90px'}} className="img-circle img-fluid mr-4" alt="user" />
                                                            </div>
                                                            <div className="user-part">
                                                                <h6 className="mb-0 font-medium">
                                                                    {appState.content.user.full_name}
                                                                </h6>
                                                                <p className="mb-0">{appState.content.user.email}</p>
                                                                <p className="mb-0">{appState.content.user.dob}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                })()}
                                                <div className="col-7">
                                                    <p className="font-medium">Task</p>
                                                    <div className="row align-items-center">
                                                        <div className="col-5">
                                                            <img src={appState.campaign.thumbnail} className="img-fluid" />
                                                        </div>
                                                        <div className="col-7">
                                                            <p className="font-medium mb-0">{appState.campaign.title}</p>
                                                            {(() => {
                                                                if (appState.content != null) {
                                                                return <div>
                                                                    {(() => {
                                                                        if (appState.content.task_name=="instagram_task") {
                                                                            return <a href={`/goddess-gang/${appState.content.user_id}/detail`}><img src="/img/insta-outline.svg" className="insta-draft black" /></a>
                                                                        } else if (appState.content.task_name=="facebook_task") {
                                                                            return <a href={`/goddess-gang/${appState.content.user_id}/detail`}><img src="/img/facebook-outline.svg" className="facebook-draft black" /></a>
                                                                        } else if (appState.content.task_name=="tiktok_task") {
                                                                            return <a href={`/goddess-gang/${appState.content.user_id}/detail`}><img src="/img/tiktik-outline.svg" className="tiktok-draft black" /></a>
                                                                        } else if (appState.content.task_name=="youtube_task") {
                                                                            return <a href={`/goddess-gang/${appState.content.user_id}/detail`}><img src="/img/youtube-outline.svg" className="youtube-draft black" /></a>
                                                                        }
                                                                    })()}
                                                                </div>
                                                                }
                                                            })()}
                                                            <div className="d-flex">
                                                                <div className="grey font-medium mr-3">{new Date(appState.campaign.campaign_start_date).getDate()+ " " + monthNames[new Date(appState.campaign.campaign_start_date).getMonth()]+ " "+ new Date(appState.campaign.campaign_start_date).getFullYear()}</div>
                                                                <div className="font-medium font-small">{currencyFormat(appState.campaign.point)} Point</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(() => {
                                        if (appState.content != null) {
                                        return <div className="content-bottom">
                                                <div className="popup-detail">
                                                    <div className="for-konten">
                                                        <p className="font-medium">Content</p>
                                                        <embed src={appState.content.content} className="img-fluid embed-bg" width="500" height="500"/>
                                                    </div>
                                                    <div className="for-caption">
                                                        <p className="font-medium">Caption</p>
                                                        <div className="bx-caption">
                                                            {parse(appState.content.caption)}
                                                        </div>
                                                    </div>
                                                    <div className="for-action pt-1">
                                                        <div className="ft-med mt-4">Published Content</div>
                                                        <div className="ft-med mb-3">{((new Date(appState.content.created_at).getHours() > 9) ? (new Date(appState.content.created_at).getHours()) : ('0' + (new Date(appState.content.created_at).getHours()))) + "." + ((new Date(appState.content.created_at).getMinutes() > 9) ? (new Date(appState.content.created_at).getMinutes()) : ('0' + (new Date(appState.content.created_at).getMinutes()))) + " " + dayNames[new Date(appState.content.created_at).getDay()]+ " " + new Date(appState.content.created_at).getDate()+ " " + monthNames[new Date(appState.content.created_at).getMonth()]+ " "+ new Date(appState.content.created_at).getFullYear()}</div>
                                                        <p className="font-medium">Submit</p>
                                                        {(() => {
                                                            if (appState.content.status == "Waiting Approval" || pageStatus == "undefined") {
                                                            return <div>
                                                                <button onClick={(e)=>approveContent(appState.content.id)} className="btn btn-transparent btn-block approve"><img src="/img/ico-approve.svg" />Approve</button>
                                                                <button onClick={(e)=>rejectContent(appState.content.id)} className="btn btn-transparent btn-block decline"><img src="/img/ico-decline.svg" />Reject</button>
                                                            </div>
                                                            }
                                                            else if (appState.content.status == "Approve"){
                                                                return <div className="green font-medium">Approved</div>
                                                            } else {
                                                                return <div className="red font-medium">Rejected</div>
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="deco-cb">
                                                    <img src="/img/group-deco.jpg" className="img-fluid rotate" />
                                                </div>
                                            </div>
                                        } else {
                                            return <div className="content-bottom">No data</div>
                                        }
                                        })()}
                                    </div>
                                </div>
                            </Modal>
                        </div>    
                    </div>
                );
            })}
            <div className="d-flex justify-content-center mt-5">
                {(() => {
                if (total < 5) {
                    return <ReactPaginate
                        pageCount={1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                } else {
                    return <ReactPaginate
                        pageCount={Math.ceil(page_count)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                }
                })()}
            </div>
        </div>
    );
}

export default List;
