import React from "react";
import {Button, Form, Image, Input, Row, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {isEqual} from "../../ui-util/general-variable";

class SkinConditionFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            fileImageList: [],
            showImage: false,
        }
    }

    componentDidMount(): void {
        const {skinToneDetail} = this.props
        if (!!skinToneDetail) {
            this.setState({showImage: true})
        }
        this.formRef.current.setFieldsValue(skinToneDetail)
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {skinToneDetail} = this.props
        if (!isEqual(this.props.skinToneDetail, prevProps.skinToneDetail)) {
            this.formRef.current.setFieldsValue(skinToneDetail)
        }
    }

    renderPlaceholder = () => {
        const {skinConditionName} = this.props
        switch (skinConditionName) {
            case 'Skin Tone':
                return 'Cth: Light'
            case 'Skin Undertone':
                return 'Cth: Neutral'
            case 'Skin Concern':
                return 'Cth: Acne'
            default:
                return 'Cth: Dry'
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {fileImageList, showImage} = this.state
        const {skinToneDetail, onFinish, loading} = this.props
        return (
            <Form ref={this.formRef} onFinish={(value) => onFinish(value, fileImageList)} labelCol={{span: 4}}
                  wrapperCol={{span: 12}}>
                <Form.Item
                    key='name'
                    label='Name'
                    name='name'
                    rules={[{
                        required: true,
                        message: 'Please input name',
                    }]}
                >
                    <Input placeholder={this.renderPlaceholder()}/>
                </Form.Item>
                <Form.Item
                    key='image'
                    label='Image'
                    name='image'
                    rules={[{
                        required: true,
                        message: 'Please input image',
                    }]}
                >
                    <Upload
                        fileList={fileImageList}
                        onRemove={this.removeButtonHandler}
                        beforeUpload={this.beforeUploadImage}
                    >
                        {
                            showImage &&
                            <Image
                                src={skinToneDetail.image}
                                width={100}
                            />
                        }
                        <Row style={{marginTop: 10}}>
                            <Button icon={<UploadOutlined/>}>
                                Upload
                            </Button>
                            <p>Ukuran gambar maksimal 2MB</p>
                        </Row>
                    </Upload>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default SkinConditionFormComponent
