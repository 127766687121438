import React,{ useState } from 'react';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import { getAllRedeemPointApi,  setRedeemPointApi } from "../../api/redeem-point.api";
import SortArrow from '../../component/Common/sort-arrow';

const List = (props) => {
    const { data, total, set_page, current_page, per_page, currencyFormat, monthNames, sort, setSort, orderby, setOrderby } = props;
    const [isCheck, setIsCheck] = useState([]);
    const [appState, setAppState]  = useState({
        dataState:data,
        disabled: false,
        datas: null
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    const updateSorting = (value) => {
        setSort(value.sort)
        setOrderby(value.orderBy)
    };
    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            appState.dataState[index].isChecked= event.target.checked
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState });
    }
    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].user_id)
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        const user_id = appState.dataState[e].id
        console.log(user_id)
        setIsCheck([...isCheck, user_id]);
        let check = [...isCheck, user_id];
        if (!appState.dataState[e].isChecked) {
            setIsCheck(isCheck.filter((item) => item !== user_id));
            check = isCheck.filter((item) => item !== user_id);
        }
        console.log(check)
        setAppState({dataState:appState.dataState });
        let params = {
            orderby: orderby,
            sort: sort,
            id: JSON.stringify(check)
        }
        getAllRedeemPointApi(params)
        .then(result => {
            setAppState({ datas: result.rows, dataState: appState.dataState });
        }).catch(err => {
            console.log(err)
        })
    }
    function approveUser(e){
        setAppState({disabled: true, dataState:appState.dataState});
        let bodyRequest = []
        const {id} = appState.dataState[e]
        console.log(id)
        let values = {
            status: "Approve",
            withdraw_ids: id
        }
        bodyRequest.push(values)
        setRedeemPointApi(values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    function rejectUser(e){
        setAppState({disabled: true, dataState:appState.dataState});
        let bodyRequest = []
        const {id} = appState.dataState[e]
        console.log(id)
        let values = {
            status: "Reject",
            withdraw_ids : id
        }
        bodyRequest.push(values)
        setRedeemPointApi(values)
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            }
        });
    }
    if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data user registered, sorry</p>;
   
    const page_count = total / per_page;
    return (
        <div className="content-wrapper">
            {(() => {
            if (appState.datas == null) {
                return  <table id="table-to-xls" className="hide-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Date of Birth</th>
                                    <th>Total Point</th>
                                    <th>Point yang di Reedem</th>
                                    <th>Jumlah Konversi(Rp)</th>
                                    <th>Waktu Reedem</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {appState.dataState.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.user.full_name}</td>
                                    <td>{item.user.email}</td>
                                    <td>{item.user.dob}</td>
                                    <td>{currencyFormat(item.total_point)} Point</td>
                                    <td>{currencyFormat(item.point_withdraw)} Point</td>
                                    <td>Rp. {currencyFormat(item.total_withdraw)}</td>
                                    <td>{new Date(item.created_at).getDate()+ " " + monthNames[new Date(item.created_at).getMonth()]+ " "+ new Date(item.created_at).getFullYear()}</td>
                                    <td>{item.status}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
            } else {
                return  <table id="table-to-xls" className="hide-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Date of Birth</th>
                            <th>Total Point</th>
                            <th>Point yang di Reedem</th>
                            <th>Jumlah Konversi(Rp)</th>
                            <th>Waktu Reedem</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {appState.datas.map((item, index) => (
                        <tr key={index}>
                            <td>{item.user.full_name}</td>
                            <td>{item.user.email}</td>
                            <td>{item.user.dob}</td>
                            <td>{currencyFormat(item.total_point)} Point</td>
                            <td>{currencyFormat(item.point_withdraw)} Point</td>
                            <td>Rp. {currencyFormat(item.total_withdraw)}</td>
                            <td>{new Date(item.created_at).getDate()+ " " + monthNames[new Date(item.created_at).getMonth()]+ " "+ new Date(item.created_at).getFullYear()}</td>
                            <td>{item.status}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                }
            })()}
            <div className="head-data-info">
                <div className="table-crh-1">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked}/>
                        <label className="custom-control-label" htmlFor="customCheck1" />
                    </div>
                </div>
                <div className="table-crh-2">User</div>
                <div className="table-crh-3">Total Point
                    <SortArrow
                        valueSort={sort}
                        valueOrderBy={"id"}
                        activeOrderBy={orderby}
                        onClick={(e) => updateSorting(e)}
                    />
                </div>
                <div className="table-crh-8">Point yang di Reedem</div>
                <div className="table-crh-7">Jumlah Konversi(Rp)</div>
                <div className="table-crh-5">Waktu Reedem</div>
                <div className="table-crh-6">Status</div>
            </div>
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="data-items" key={index}>
                        <div className="crh-01">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)} />
                                <label className="custom-control-label" htmlFor={index} />
                            </div>
                        </div>
                        <div className="crh-02">
                            <a href={`/redeem-point/${repo.user_id}/detail`} className="link-user">
                                <div className="box-user">
                                    <div className="img-part">
                                        <img src={repo.user.photo} className="img-user img-fluid" alt="user" />
                                    </div>
                                    <div className="user-part">
                                        <h5>
                                        {repo.user.full_name}
                                        {(() => {
                                        if (repo.is_potential == true) {
                                            return  <span className="tool-tip floating">
                                                    <div className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></div>
                                                    <div className="tool-tip-content right">
                                                        <span>Influencer Potential</span>
                                                    </div>
                                                </span>
                                            } else {
                                                return <span></span>
                                            }
                                        })()}
                                        </h5>
                                        <p>{repo.user.email}</p>
                                        <p>{repo.user.dob}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="crh-03">{currencyFormat(repo.total_point)} Point</div>
                        <div className="crh-07">{currencyFormat(repo.point_withdraw)} Point</div>
                        <div className="crh-07">Rp. {currencyFormat(repo.total_withdraw)}</div>
                        <div className="crh-05">{new Date(repo.created_at).getDate()+ " " + monthNames[new Date(repo.created_at).getMonth()]+ " "+ new Date(repo.created_at).getFullYear()}</div>
                        <div className="crh-06">
                            <div className="d-flex justify-content-center">
                            {(() => {
                                if (repo.status == 'Waiting Approval') {
                                    return <div>
                                        <button className="btn btn-transparent btn-block approve" onClick={(e)=>approveUser(index)} disabled={appState.disabled}><img src="/img/ico-approve.svg" /> Approve</button>
                                        <button className="btn btn-transparent btn-block decline" onClick={(e)=>rejectUser(index)} disabled={appState.disabled}><img src="/img/ico-decline.svg" /> Reject</button>
                                    </div>
                                    }
                                else if (repo.status == 'Approve') {
                                    return <div>
                                        <div className="green font-medium">Approved</div>
                                    </div>
                                    }
                                else {
                                    return <div>
                                    <div className="red font-medium">Rejected</div>
                                </div>
                                }
                            })()}
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="d-flex justify-content-center mt-5">
                {(() => {
                if (total < 5) {
                    return <ReactPaginate
                        pageCount={1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                } else {
                    return <ReactPaginate
                        pageCount={Math.ceil(page_count)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                }
                })()}
            </div>
        </div>
    );
}

export default List;