import React, { useState } from "react";
import swal from "sweetalert";
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Popover } from 'antd';
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import ReactExport from "react-data-export";
import {
  setAddCampaignApi,
  setSendEmailApi,
  setSendNotifApi,
} from "../../api/goddess-gang.api";
import { getUserApprovedApi } from "../../api/goddess-gang.api";
import { addUserGroupApi } from "../../api/group.api";
import { Slider } from "antd";
import SortArrow from "../../component/Common/sort-arrow";
import CustomDropdown from '../../component/Common/custom-dropdown';
import CustomAutoComplete from "../../component/Common/custom-autocomplete";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const formatSliderValue = (str) => {
  if (typeof str !== "string") return [undefined, undefined];
  const [a, b] = str.split("-");
  if (Number.isNaN(parseInt(a, 10))) return [undefined, undefined];
  if (Number.isNaN(parseInt(b, 10))) return [undefined, undefined];
  return [a, b];
};

const List = (props) => {
  const {
    data,
    group,
    campaign,
    total,
    current_page,
    per_page,
    formatFollower,
    cityData,
    sosmedData,
    interestData,
  } = props;
  const [image, setImage]           = useState('');
  const [search, setSearch]         = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isEmailAll, setIsEmailAll] = useState(false);
  const [isEmail, setIsEmail] = useState([]);
  const [user_id, setUser_id] = useState("");
  const [group_id, setGroup_id] = useState(localStorage.getItem('group_id') ? localStorage.getItem('group_id') : []);
  const [group_name, setGroup_name] = useState(localStorage.getItem('group_name') ? localStorage.getItem('group_name') : "Group");
  const [title_notif, setTitleNotif] = useState("");
  const [desc_notif, setDescNotif] = useState("");
  const [ig_followers_min, setIgFollowersMin]            = useState(0);
  const [ig_followers_max, setIgFollowersMax]            = useState('');
  const [ig_er_min, setIgErMin]                          = useState(0);
  const [ig_er_max, setIgErMax]                          = useState('');
  const [ig_reach_min, setIgReachMin]                    = useState(0);
  const [ig_reach_max, setIgReachMax]                    = useState('');
  const [tiktok_followers_min, setTiktokFollowersMin]    = useState(0);
  const [tiktok_followers_max, setTiktokFollowersMax]    = useState('');
  const [tiktok_er_min, setTiktokErMin]                  = useState(0);
  const [tiktok_er_max, setTiktokErMax]                  = useState('');
  const [tiktok_reach_min, setTiktokReachMin]            = useState(0);
  const [tiktok_reach_max, setTiktokReachMax]            = useState('');
  const [city, setCity]             = useState(null);
  const [interest, setInterest]     = useState(null);
  const [socmed, setSocmed]         = useState(null);
  const [filterSosmedOpen, setFilterSosmedOpen] = useState(false);
  const [sort, setSort] = useState("desc");
  const [orderby, setOrderby] = useState("full_name");
  const [appState, setAppState] = useState({
    datas: null,
    dataState: data,
    groups: group,
    campaigns: campaign,
    cityData: cityData,
    interestData: interestData,
    sosmedData: sosmedData,
    showCampaign: false,
    showEmail: false,
    showNotif: false,
    sort: sort,
    orderby: orderby,
    current_page: current_page,
    total: total,
    per_page: per_page,
    disabled: false,
  });
  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ mode: "all" });
  let today = new Date();
  let dateNow = today
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })
    .replace(/[^\d,.-]/g, "-");
  let timeNow =
  today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
  const onImageChange = (event) => {
      const image = event.target.files[0]
      let reader = new FileReader();
      reader.onloadend = () => {
          setImage({
              file: image,
              imagePreviewUrl: reader.result
          });
      }
      reader.readAsDataURL(image)
  }
  const updateSorting = (value) => {
    setSort(value.sort)
    setOrderby(value.orderBy)
    setAppState({ loading: true, groups: appState.groups, campaigns: appState.campaigns});
    let params = {
        size: appState.per_page,
        page: appState.current_page,
        orderby: value.orderBy,
        sort: value.sort,
        ig_followers_min: ig_followers_min,
        ig_followers_max: ig_followers_max,
        ig_er_min: ig_er_min,
        ig_er_max: ig_er_max,
        ig_reach_min: ig_reach_min,
        ig_reach_max: ig_reach_max,
        tiktok_followers_min: tiktok_followers_min,
        tiktok_followers_max: tiktok_followers_max,
        tiktok_er_min: tiktok_er_min,
        tiktok_er_max: tiktok_er_max,
        tiktok_reach_min: tiktok_reach_min,
        tiktok_reach_max: tiktok_reach_max,
        sosmed_id: socmed,
        interest_id: interest,
        city_id: city
    }
    getUserApprovedApi(params)
    .then(result => {
        setAppState({ loading: false, dataState: result.users.rows, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page, groups: appState.groups, campaigns: appState.campaigns, sosmed: appState.sosmed, interest: appState.interest, city: appState.city  });
    }).catch(err => {
        console.log(err)
    })
  };
  function onSearch() {
    setAppState({ loading: true, groups: appState.groups, campaigns: appState.campaigns});
    let params = {
        size: appState.per_page,
        page: appState.current_page,
        orderby: orderby,
        sort: sort,
        ig_followers_min: ig_followers_min,
        ig_followers_max: ig_followers_max,
        ig_er_min: ig_er_min,
        ig_er_max: ig_er_max,
        ig_reach_min: ig_reach_min,
        ig_reach_max: ig_reach_max,
        tiktok_followers_min: tiktok_followers_min,
        tiktok_followers_max: tiktok_followers_max,
        tiktok_er_min: tiktok_er_min,
        tiktok_er_max: tiktok_er_max,
        tiktok_reach_min: tiktok_reach_min,
        tiktok_reach_max: tiktok_reach_max,
        sosmed_id: socmed,
        interest_id: interest,
        city_id: city
    }
      getUserApprovedApi(params)
      .then(result => {
          setAppState({ loading: false, dataState: result.users.rows, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page, groups: appState.groups, campaigns: appState.campaigns, sosmed: appState.sosmed, interest: appState.interest, city: appState.city  });
      }).catch(err => {
          console.log(err)
      })
  }
  function handleSocmed(value) {
    setSocmed(value)
  }
  function hanldeCity(value) {
      setCity(value)
  }
  function handleInterest(value) {
      setInterest(value)
  }
  function FilterRange(){
      setFilterSosmedOpen(false);
      setAppState({ loading: true, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
      const fetchData = async () => {
          let params = {
              orderby: orderby,
              sort: sort,
              city_id: city,
              sosmed_id: socmed,
              interest_id: interest,
              ig_followers_min: ig_followers_min,
              ig_followers_max: ig_followers_max,
              ig_er_min: ig_er_min,
              ig_er_max: ig_er_max,
              ig_reach_min: ig_reach_min,
              ig_reach_max: ig_reach_max,
              tiktok_followers_min: tiktok_followers_min,
              tiktok_followers_max: tiktok_followers_max,
              tiktok_er_min: tiktok_er_min,
              tiktok_er_max: tiktok_er_max,
              tiktok_reach_min: tiktok_reach_min,
              tiktok_reach_max: tiktok_reach_max,
              key: search
          }
          getUserApprovedApi(params)
              .then(result => {
                  setAppState({dataState: result.users.rows, 
                    campaigns: appState.campaigns,
                    groups: appState.groups,
                    total: result.users.count,
                    current_page: appState.current_page, per_page: appState.per_page})
              }).catch(err => {
                  console.log(err)
              })
          };
          setIsCheckAll(false)
      fetchData();
  }
  function resetIg() {
    setAppState({ loading: true, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
    const ig_followers_min = 0
    setIgFollowersMin(ig_followers_min)
    const ig_followers_max = ''
    setIgFollowersMax(ig_followers_max)
    const ig_er_min = 0
    setIgErMin(ig_er_min)
    const ig_er_max = ''
    setIgErMax(ig_er_max)
    const ig_reach_min = 0
    setIgReachMin(ig_reach_min)
    const ig_reach_max = ''
    setIgReachMax(ig_reach_max)
    const fetchData = async () => {
        let params = {
            size: per_page,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            city_id: city,
            sosmed_id: socmed,
            interest_id: interest,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: props.search
        }
        getUserApprovedApi(params)
            .then(result => {
                setAppState({dataState: result.users.rows, 
                  campaigns: appState.campaigns,
                  groups: appState.groups,
                  total: result.users.count,
                  current_page: appState.current_page, per_page: appState.per_page});
            }).catch(err => {
                console.log(err)
            })
        };
    fetchData()
  }
  function resetTiktok() {
    setAppState({ loading: true, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
    const tiktok_followers_min = 0
    setTiktokFollowersMin(tiktok_followers_min)
    const tiktok_followers_max = ''
    setTiktokFollowersMax(tiktok_followers_max)
    const tiktok_er_min = 0
    setTiktokErMin(tiktok_er_min)
    const tiktok_er_max = ''
    setTiktokErMax(tiktok_er_max)
    const tiktok_reach_min = 0
    setTiktokReachMin(tiktok_reach_min)
    const tiktok_reach_max = ''
    setTiktokReachMax(tiktok_reach_max)
    const fetchData = async () => {
        let params = {
            size: per_page,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            city_id: city,
            sosmed_id: socmed,
            interest_id: interest,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: props.search
        }
        getUserApprovedApi(params)
            .then(result => {
                setAppState({dataState: result.users.rows, 
                  campaigns: appState.campaigns,
                  groups: appState.groups,
                  total: result.users.count,
                  current_page: appState.current_page, per_page: appState.per_page});
            }).catch(err => {
                console.log(err)
            })
        };
    fetchData()
  }
  const onChangeGroup = (event) => {
    const group_id = event.id;
    setGroup_id(group_id);
    const group_name = event.name;
    setGroup_name(group_name);
  };
  const onSubmit = (data) => alert(JSON.stringify(data));
  function onChangeLimit(e){
    const per_page = e.target.value
        if (per_page < 5) {
          const limit = 5;
          setTimeout(function () {
            setAppState({ loading: true, current_page: 1, campaigns: appState.campaigns, per_page : limit, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
            const fetchData = async () => {
                let params = {
                    size: limit,
                    page: current_page,
                    orderby: appState.orderby,
                    sort: appState.sort,
                    city_id: city,
                    sosmed_id: socmed,
                    interest_id: interest,
                    ig_followers_min: ig_followers_min,
                    ig_followers_max: ig_followers_max,
                    ig_er_min: ig_er_min,
                    ig_er_max: ig_er_max,
                    ig_reach_min: ig_reach_min,
                    ig_reach_max: ig_reach_max,
                    tiktok_followers_min: tiktok_followers_min,
                    tiktok_followers_max: tiktok_followers_max,
                    tiktok_er_min: tiktok_er_min,
                    tiktok_er_max: tiktok_er_max,
                    tiktok_reach_min: tiktok_reach_min,
                    tiktok_reach_max: tiktok_reach_max,
                    key: search
                }
                getUserApprovedApi(params)
                    .then(result => {
                        setAppState({dataState: result.users.rows, campaigns: appState.campaigns,
                            groups: appState.groups, current_page: 1,
                            total: result.users.count, per_page: limit, sort: appState.sort, orderby: appState.orderby})
                    }).catch(err => {
                        console.log(err)
                    })
                };
                setIsCheckAll(false)
            fetchData();
          },  3000);
        } else {
          const limit = e.target.value;
          setTimeout(function () {
            setAppState({ loading: true, current_page: 1, campaigns: appState.campaigns, per_page : limit, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
            const fetchData = async () => {
                let params = {
                    size: limit,
                    page: current_page,
                    orderby: appState.orderby,
                    sort: appState.sort,
                    city_id: city,
                    sosmed_id: socmed,
                    interest_id: interest,
                    ig_followers_min: ig_followers_min,
                    ig_followers_max: ig_followers_max,
                    ig_er_min: ig_er_min,
                    ig_er_max: ig_er_max,
                    ig_reach_min: ig_reach_min,
                    ig_reach_max: ig_reach_max,
                    tiktok_followers_min: tiktok_followers_min,
                    tiktok_followers_max: tiktok_followers_max,
                    tiktok_er_min: tiktok_er_min,
                    tiktok_er_max: tiktok_er_max,
                    tiktok_reach_min: tiktok_reach_min,
                    tiktok_reach_max: tiktok_reach_max,
                    key: search
                }
                getUserApprovedApi(params)
                    .then(result => {
                        setAppState({dataState: result.users.rows, campaigns: appState.campaigns,
                            groups: appState.groups, current_page: 1,
                            total: result.users.count, per_page: limit, sort: appState.sort, orderby: appState.orderby})
                    }).catch(err => {
                        console.log(err)
                    })
                };
                setIsCheckAll(false)
            fetchData();
          },  3000);
        }
  }
  function setPage(e){
      const selectedPage = e
      setAppState({ loading: true, current_page: selectedPage, per_page: appState.per_page, campaigns: appState.campaigns, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
      const fetchData = async () => {
          let params = {
              size: appState.per_page,
              page: selectedPage,
              orderby: appState.orderby,
              sort: appState.sort,
              city_id: city,
              sosmed_id: socmed,
              interest_id: interest,
              ig_followers_min: ig_followers_min,
              ig_followers_max: ig_followers_max,
              ig_er_min: ig_er_min,
              ig_er_max: ig_er_max,
              ig_reach_min: ig_reach_min,
              ig_reach_max: ig_reach_max,
              tiktok_followers_min: tiktok_followers_min,
              tiktok_followers_max: tiktok_followers_max,
              tiktok_er_min: tiktok_er_min,
              tiktok_er_max: tiktok_er_max,
              tiktok_reach_min: tiktok_reach_min,
              tiktok_reach_max: tiktok_reach_max,
              key: search
          }
          getUserApprovedApi(params)
              .then(result => {
                  setAppState({dataState: result.users.rows, campaigns: appState.campaigns,
                      groups: appState.groups,
                      total: result.users.count,
                      current_page: selectedPage, per_page: appState.per_page, sort: appState.sort, orderby: appState.orderby})
              }).catch(err => {
                  console.log(err)
              })
          };
          setIsCheckAll(false)
      fetchData();
  }
  const changeSearch = (e) => {
    const value = e.target.value
    setSearch(value)
  }
  function onChangeSearch() {
    setAppState({ loading: true, current_page: appState.current_page, per_page : appState.per_page, campaigns: appState.campaigns, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
    const fetchData = async () => {
        let params = {
            size: appState.per_page,
            page: appState.current_page,
            orderby: appState.orderby,
            sort: appState.sort,
            city_id: city,
            sosmed_id: socmed,
            interest_id: interest,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: search
        }
        getUserApprovedApi(params)
              .then(result => {
                  setAppState({dataState: result.users.rows, 
                    campaigns: appState.campaigns,
                    groups: appState.groups,
                    total: result.users.count,
                    current_page: appState.current_page, per_page: appState.per_page})
              }).catch(err => {
                  console.log(err)
              })
          };
          setIsCheckAll(false)
      fetchData();
  }
  const changePage = (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  };
  const handleAllChecked = async () => {
    setIsCheckAll(!isCheckAll);
    await setIsCheck(appState.dataState.map((li) => li.id));
    let check = appState.dataState.map((li) => li.id);

    if (isCheckAll) {
      setIsCheck([]);
      check = [];
    }
    setAppState({  dataState: appState.dataState, total: appState.total, per_page: appState.per_page, current_page: appState.current_page, groups: appState.groups, campaigns: appState.campaigns, sosmed: appState.sosmed, interest: appState.interest, city: appState.city});
    let params = {
        orderby: appState.orderby,
        sort: appState.sort,
        id: JSON.stringify(check),
        ig_followers_min: ig_followers_min,
        ig_followers_max: ig_followers_max,
        ig_er_min: ig_er_min,
        ig_er_max: ig_er_max,
        ig_reach_min: ig_reach_min,
        ig_reach_max: ig_reach_max,
        tiktok_followers_min: tiktok_followers_min,
        tiktok_followers_max: tiktok_followers_max,
        tiktok_er_min: tiktok_er_min,
        tiktok_er_max: tiktok_er_max,
        tiktok_reach_min: tiktok_reach_min,
        tiktok_reach_max: tiktok_reach_max,
        sosmed_id: socmed,
        interest_id: interest,
        city_id: city
    }
    getUserApprovedApi(params)
    .then(result => {
        setAppState({ datas: result.users.rows, dataState: appState.dataState, total: appState.total, per_page: appState.per_page, current_page: appState.current_page, groups: appState.groups, campaigns: appState.campaigns, sosmed: appState.sosmed, interest: appState.interest, city: appState.city });
    }).catch(err => {
        console.log(err)
    })
  };
  const handleCheckChieldElement = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    let check = [...isCheck, id];
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      check = isCheck.filter((item) => item !== id);
    }
    setAppState({  dataState: appState.dataState, total: appState.total, orderby: appState.orderby, sort: appState.sort, per_page: appState.per_page, current_page: appState.current_page, groups: appState.groups, campaigns: appState.campaigns, sosmed: appState.sosmed, interest: appState.interest, city: appState.city});
    let params = {
        orderby: appState.orderby,
        sort: appState.sort,
        id: JSON.stringify(check),
        ig_followers_min: ig_followers_min,
        ig_followers_max: ig_followers_max,
        ig_er_min: ig_er_min,
        ig_er_max: ig_er_max,
        ig_reach_min: ig_reach_min,
        ig_reach_max: ig_reach_max,
        tiktok_followers_min: tiktok_followers_min,
        tiktok_followers_max: tiktok_followers_max,
        tiktok_er_min: tiktok_er_min,
        tiktok_er_max: tiktok_er_max,
        tiktok_reach_min: tiktok_reach_min,
        tiktok_reach_max: tiktok_reach_max,
        sosmed_id: socmed,
        interest_id: interest,
        city_id: city
    }
    getUserApprovedApi(params)
    .then(result => {
        setAppState({ datas: result.users.rows, dataState: appState.dataState, total: appState.total, per_page: appState.per_page, current_page: appState.current_page, groups: appState.groups, campaigns: appState.campaigns, sosmed: appState.sosmed, interest: appState.interest, city: appState.city, orderby: appState.orderby, sort: appState.sort, });
    }).catch(err => {
        console.log(err)
    })
  };
  const checkAllEmail = async () => {
    setIsEmailAll(!isCheckAll);
    await setIsEmail(appState.dataState.map((li) => li.email));
    let check = appState.dataState.map((li) => li.email);

    if (isCheckAll) {
      setIsEmail([]);
      check = [];
    }
  }
  const checkEmail = (e) => {
    const email = e.email
    setIsEmail([...isEmail, email]);
  }
  function addUserGroup() {
    setAppState({
      loading: true,
      campaigns: appState.campaigns,
      groups: appState.groups,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      sort: sort,
      dataState: appState.dataState,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
    let bodyRequest = [];
    let user_id = isCheck;
    setUser_id(user_id);
    let values = {
      group_id: group_id,
      user_ids: user_id,
    };
    bodyRequest.push(values);
    addUserGroupApi(values).then((repos) => {
      const data = repos.message;
      if (repos.code == 201) {
        swal({
          title: "Done!",
          text: data,
          icon: "success",
          timer: 3000,
          button: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal({
          title: "Error!",
          text: data,
          icon: "error",
          button: true,
        });
      }
      setAppState({
        loading: false,
        campaigns: appState.campaigns,
        groups: appState.groups,
        total: appState.total,
        current_page: appState.current_page,
        dataState: appState.dataState,
      });
    });
  }
  function addCampaign(e) {
    setAppState({
      loading: true,
      campaigns: appState.campaigns,
      groups: appState.groups,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      dataState: appState.dataState,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
    let bodyRequest = [];
    const campaign_id = e;
    let values = {
      user_ids: user_id,
      campaign_ids: [campaign_id],
    };
    bodyRequest.push(values);
    setAddCampaignApi(values).then((repos) => {
      const data = repos.message;
      if (repos.code == 200) {
        swal({
          title: "Done!",
          text: data,
          icon: "success",
          timer: 3000,
          button: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal({
          title: "Error!",
          text: data,
          icon: "error",
          button: true,
        });
      }
      setAppState({
        loading: false,
        campaigns: appState.campaigns,
        groups: appState.groups,
        total: appState.total,
        current_page: appState.current_page,
        dataState: appState.dataState,
      });
    });
  }
  function sendEmail(e) {
    setAppState({
      loading: true,
      campaigns: appState.campaigns,
      groups: appState.groups,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      dataState: appState.dataState,
    });
    let bodyRequest = [];
    const campaign_id = e;
    let values = {
      user_ids: user_id,
      campaign_ids: [campaign_id],
    };
    bodyRequest.push(values);
    setSendEmailApi(values).then((repos) => {
      const data = repos.message;
      if (repos.code == 200) {
        swal({
          title: "Done!",
          text: data,
          icon: "success",
          timer: 3000,
          button: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal({
          title: "Error!",
          text: data,
          icon: "error",
          button: true,
        });
      }
      setAppState({
        loading: false,
        campaigns: appState.campaigns,
        groups: appState.groups,
        total: appState.total,
        current_page: appState.current_page,
        dataState: appState.dataState,
      });
    });
  }
  function sendNotification() {
    setAppState({
      loading: true,
      campaigns: appState.campaigns,
      groups: appState.groups,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      dataState: appState.dataState,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
    const form = new FormData();
    form.append('title', title_notif)
    form.append('description', desc_notif)
    form.append('user_ids', JSON.stringify(user_id))
    if (image.file.type == "image/jpeg") {
      form.append('image', image.file)
    }
    setSendNotifApi(form).then((repos) => {
      const data = repos.message;
      if (repos.code == 200) {
        swal({
          title: "Done!",
          text: data,
          icon: "success",
          timer: 3000,
          button: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal({
          title: "Error!",
          text: data,
          icon: "error",
          button: true,
        });
      }
      setAppState({
        loading: false,
        campaigns: appState.campaigns,
        groups: appState.groups,
        total: appState.total,
        current_page: appState.current_page,
        dataState: appState.dataState,
      });
    });
  }
  const openCampaign = () => {
    let user_id = isCheck;
    setUser_id(user_id);
    setAppState({
      dataState: appState.dataState,
      groups: appState.groups,
      campaigns: appState.campaigns,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      showCampaign: true,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
  };
  const openNotif = () => {
    let user_id = isCheck;
    setUser_id(user_id);
    setAppState({
      dataState: appState.dataState,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      groups: appState.groups,
      campaigns: appState.campaigns,
      showNotif: true,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
  };
  function closeCampaign() {
    setAppState({
      showCampaign: false,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      dataState: appState.dataState,
      groups: appState.groups,
      campaigns: appState.campaigns,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
  }
  function closeEmail() {
    setAppState({
      showEmail: false,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      dataState: appState.dataState,
      groups: appState.groups,
      campaigns: appState.campaigns,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
  }
  function closeNotif() {
    setAppState({
      showNotif: false,
      per_page: appState.per_page,
      total: appState.total,
      current_page: appState.current_page,
      dataState: appState.dataState,
      groups: appState.groups,
      campaigns: appState.campaigns,
      sosmed: appState.sosmed, city : appState.city, interest: appState.interest
    });
  }
  const monthNames = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const page_count = appState.total / appState.per_page;
  // const MIN_VAL = 5;
  // const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
  return (
    <div>
      <div className="main-dash">
        <div className="toolbar">
          <div className="toolbar-left inner">
            <div className="toolbar-inner-left">
              <div className="toolicon-wrapper status sm">
                <button className="toolicon status">
                  <span className="select-text-group">{group_name}</span>
                </button>
                <div className="tool-inner sm left">
                  <div className="tool-wrapper for-sm select-group">
                    <ul className="tool-select">
                      {appState.groups && appState.groups.length > 0 ? appState.groups.map((item, index) => {
                        return (
                          <li
                            className={
                              group_id == `${item.id}`
                                ? "tool-select-item active"
                                : "tool-select-item"
                            }
                            key={index}
                          >
                            <button
                              name="group"
                              value={item.id}
                              onClick={() =>
                                onChangeGroup({ id: item.id, name: item.name })
                              }
                            >
                              {item.name}
                            </button>
                          </li>
                        );
                      }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data group, sorry</p>
                      }
                      <li className="tool-select-item">
                        <Link to="/goddess-gang/create-group" className="add">
                          Add new Group +
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="toolicon-wrapper status sm">
                {(() => {
                  if (isCheck != "" || isCheckAll != "") {
                    return (
                      <button
                        onClick={addUserGroup}
                        className="btn btn-primary"
                      >
                        SUBMIT
                      </button>
                    );
                  } else {
                    return (
                      <button className="btn btn-primary" disabled>
                        SUBMIT
                      </button>
                    );
                  }
                })()}
              </div>
              <CustomDropdown title="All Sosmed" icon="sosmed" data={sosmedData} onClick={(e) => handleSocmed(e)} active={socmed} />
              <CustomAutoComplete title="All Interest" icon="interest" data={interestData} onClick={(e) => handleInterest(e)} active={interest} />
              <CustomAutoComplete title="All City" icon="city" data={cityData} onClick={(e) => hanldeCity(e)} active={city} />
              <div>
                <button className="btn btn-primary" onClick={onSearch}>SEARCH</button>
              </div>
              <div className="page-item">
                  <form className="page-input">
                      <div className="form-group mb-0">
                          <label htmlFor="page-count" className="sr-only">Page</label>
                          <NumberFormat
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix=""
                            decimalScale={0}
                            displayType="input"
                            type="text"
                            value={appState.per_page}
                            allowNegative={false}
                            onChange={onChangeLimit}
                            className="form-control"
                          />
                      </div>
                  </form>
                  <div className="text-page">/ Page</div>
              </div>
            </div>
          </div>
          <div className="toolbar-right inner">
            <div className="toolbar-inner-right">
              <div className="toolicon-wrapper check-btn">
                <Popover
                  trigger="click"
                  placement="bottomLeft"
                  visible={filterSosmedOpen}
                  onVisibleChange={(visible) => {
                      setFilterSosmedOpen(visible);
                  }}
                  content={(
                      <div className="tool-wrapper" style={{ border: 'none', boxShadow: 'none', alignItems: 'flex-end', paddingLeft: 14 }}>
                        <div className="row">
                          <div className="col-6">
                              <div className="filter-head">
                                  <div className="font-medium">Instagram</div>
                                  <div>
                                      <button className="btn-reset-filter" onClick={resetIg}>Reset</button>
                                  </div>
                              </div>
                              <p>Followers</p>
                              <div className="row mb-4">
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={ig_followers_min}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setIgFollowersMin(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Min"
                                              className="form-control no-radius input-number"
                                          /> 
                                      </form>
                                  </div> - 
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={ig_followers_max}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setIgFollowersMax(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Max"
                                              className="form-control no-radius input-number"
                                          />   
                                      </form> 
                                  </div>
                              </div>
                              <p>ER</p>
                              <div className="row mb-4">
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={ig_er_min}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setIgErMin(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Min"
                                              className="form-control no-radius input-number"
                                          />   
                                      </form> 
                                  </div> - 
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={ig_er_max}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setIgErMax(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Max"
                                              className="form-control no-radius input-number"
                                          />   
                                      </form> 
                                  </div>
                              </div>
                              <p>Reach</p>
                              <div className="row mb-4">
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={ig_reach_min}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setIgReachMin(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Min"
                                              className="form-control no-radius input-number"
                                          />
                                      </form>    
                                  </div> - 
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={ig_reach_max}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setIgReachMax(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Max"
                                              className="form-control no-radius input-number"
                                          />
                                      </form>    
                                  </div>
                              </div>
                          </div>
                          <div className="col-6">
                              <div className="filter-head">
                                  <div className="font-medium">Tiktok</div>
                                  <div>
                                      <button className="btn-reset-filter" onClick={resetTiktok}>Reset</button>
                                  </div>
                              </div>
                              <p>Followers</p>
                              <div className="row mb-4">
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={tiktok_followers_min}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setTiktokFollowersMin(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Min"
                                              className="form-control no-radius input-number"
                                          />  
                                      </form>  
                                  </div> - 
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={tiktok_followers_max}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setTiktokFollowersMax(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Max"
                                              className="form-control no-radius input-number"
                                          />
                                      </form>    
                                  </div>
                              </div>
                              <p>ER</p>
                              <div className="row mb-4">
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={tiktok_er_min}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setTiktokErMin(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Min"
                                              className="form-control no-radius input-number"
                                          />    
                                      </form>
                                  </div> - 
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={tiktok_er_max}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setTiktokErMax(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Max"
                                              className="form-control no-radius input-number"
                                          />   
                                      </form> 
                                  </div>
                              </div>
                              <p>Reach</p>
                              <div className="row mb-4">
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={tiktok_reach_min}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setTiktokReachMin(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Min"
                                              className="form-control no-radius input-number"
                                          />
                                      </form>    
                                  </div> - 
                                  <div className="col-5">
                                      <form onSubmit={handleSubmit(FilterRange)}>
                                          <NumberFormat
                                              thousandsGroupStyle="thousand"
                                              value={tiktok_reach_max}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              prefix=""
                                              decimalScale={0}
                                              displayType="input"
                                              type="text"
                                              allowNegative={false}
                                              onValueChange={
                                                  (value) => {
                                                      setTiktokReachMax(value.floatValue)
                                                  }
                                              }
                                              min="0"
                                              placeholder="Max"
                                              className="form-control no-radius input-number"
                                          />   
                                      </form> 
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                  )}>
                <label htmlFor="myid" className="toolicon filter" />
                </Popover>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group search-input inner">
                      <label htmlFor="inputSearch" className="sr-only">Search</label>
                      <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                      <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                  </div>
              </form>
              {(() => {
                if (appState.datas == null) {
                return  <div className="toolicon-wrapper status export">
                  <ExcelFile
                    element={
                      <button className="toolicon status export">
                        <span>Export</span>
                      </button>
                    }
                    filename={"user-list-" + dateNow + "-" + timeNow}
                  >
                    <ExcelSheet data={appState.dataState} name="User Registered">
                      <ExcelColumn label="Name" value="full_name" />
                      <ExcelColumn label="Birthdate" value="dob" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn label="Username Instagram" value="username_instagram" />
                      <ExcelColumn label="Follower Instagram" value="follower_instagram" />
                      <ExcelColumn label="ER Instagram" value="er_instagram" />
                      <ExcelColumn label="Reach Instagram" value="reach_instagram" />
                      <ExcelColumn label="Username Tiktok" value="username_tiktok" />
                      <ExcelColumn label="Follower Tiktok" value="follower_tiktok" />
                      <ExcelColumn label="ER Tiktok" value="er_tiktok" />
                      <ExcelColumn label="Reach Tiktok" value="reach_instagram" />
                      <ExcelColumn
                        label="Register Season"
                        value={(col) => col.goddes_gang_session + "x"}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              } else {
                return  <div className="toolicon-wrapper status export">
                  <ExcelFile
                    element={
                      <button className="toolicon status export">
                        <span>Export</span>
                      </button>
                    }
                    filename={"user-list-" + dateNow + "-" + timeNow}
                  >
                    <ExcelSheet data={appState.datas} name="User Registered">
                      <ExcelColumn label="Name" value="full_name" />
                      <ExcelColumn label="Birthdate" value="dob" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn label="Username Instagram" value="username_instagram" />
                      <ExcelColumn label="Follower Instagram" value="follower_instagram" />
                      <ExcelColumn label="ER Instagram" value="er_instagram" />
                      <ExcelColumn label="Reach Instagram" value="reach_instagram" />
                      <ExcelColumn label="Username Tiktok" value="username_tiktok" />
                      <ExcelColumn label="Follower Tiktok" value="follower_tiktok" />
                      <ExcelColumn label="ER Tiktok" value="er_tiktok" />
                      <ExcelColumn label="Reach Tiktok" value="reach_instagram" />
                      <ExcelColumn
                        label="Register Season"
                        value={(col) => col.goddes_gang_session + "x"}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              }
            })()}
              <div className="toolicon-wrapper status atc">
                <button onClick={openCampaign} className="toolicon status atc">
                  <span>Add to Campaign</span>
                </button>
              </div>
              <div className="toolicon-wrapper status ste">
                {(() => {
                  if (isEmail != "" || isEmailAll != "") {
                    return <a href={'mailto:'+isEmail} className="toolicon status ste">
                      <span>Send to Email</span>
                    </a>
                  } else {
                    return <button disabled className="toolicon status ste" style={{ cursor: 'not-allowed'}}>
                      <span>Send to Email</span>
                    </button>
                  }
                  })()}
              </div>
              <div className="toolicon-wrapper status stn">
                  {(() => {
                  if (isCheck != "" || isCheckAll != "") {
                    return  <button onClick={openNotif} className="toolicon status stn">
                      <span>Send to Notification</span>
                  </button>
                  } else {
                    return <button disabled className="toolicon status stn" style={{ cursor: 'not-allowed'}}>
                      <span>Send to Notification</span>
                    </button>
                  }
                  })()}
              </div>
            </div>
          </div>
        </div>
        {/* end of toolbar*/}
      </div>
      <div className="content-wrapper">
      {(() => {
        if (appState.dataState){
        return <div>
                <div className="head-data-info">
                  <div className="tablerow-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="selectAll"
                        id="selectAll"
                        onChange={handleAllChecked}
                        checked={isCheckAll}
                        onClick={checkAllEmail}
                      />
                      <label className="custom-control-label" htmlFor="selectAll" />
                    </div>
                  </div>
                  <div className="tablerow-2">
                    User
                    <SortArrow
                      valueSort={sort}
                      valueOrderBy={"full_name"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-3">
                    <div className="d-flex align-items-end justify-content-center">
                      <div>
                        <img src="img/insta-outline.svg" className="sosmed-icon" />
                      </div>
                      <div>Username</div>
                    </div>
                  </div>
                  <div className="tablerow-4">
                    Followers
                    <SortArrow
                      valueSort={sort}
                      valueOrderBy={"follower_instagram"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-5">
                    ER
                    <SortArrow
                      valueSort={sort}
                      valueOrderBy={"er_instagram"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-6">Reach</div>
                  <div className="tablerow-7">
                    <div className="d-flex align-items-end justify-content-center">
                      <div>
                        <img src="img/tiktik-outline.svg" className="sosmed-icon" />
                      </div>
                      <div>Username</div>
                    </div>
                  </div>
                  <div className="tablerow-8">Followers
                    <SortArrow
                      valueSort={sort}
                      valueOrderBy={"follower_tiktok"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                    /></div>
                  <div className="tablerow-9">
                    ER
                    <SortArrow
                      valueSort={sort}
                      valueOrderBy={"er_tiktok"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-10">Reach</div>
                  <div className="tablerow-11">Social Media</div>
                  <div className="tablerow-12">Register Season</div>
                  <div className="tablerow-13">Status</div>
                </div>
                {appState.dataState && appState.dataState.length > 0 ? appState.dataState.map((repo, index) => {
                  let social_medias = repo.social_medias
                  return (
                    <div className="data-items" key={index}>
                      <div className="di-01">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={repo.id}
                            checked={isCheck.includes(repo.id)}
                            onChange={handleCheckChieldElement}
                            onClick={()=>checkEmail({email:repo.email})}
                          />
                          <label className="custom-control-label" htmlFor={repo.id} />
                        </div>
                      </div>
                      <div className="di-02">
                        <a
                          href={`/goddess-gang/${repo.id}/detail`}
                          className="link-user"
                        >
                          <div className="box-user">
                            <div className="img-part">
                              <img
                                src={repo.photo}
                                className="img-user img-fluid"
                                alt="user"
                              />
                            </div>
                            <div className="user-part">
                              <h5>
                                {repo.full_name}
                                {(() => {
                                  if (repo.is_potential == true) {
                                    return (
                                      <span className="tool-tip floating">
                                        <button className="btn tool-tip-icon">
                                          <img
                                            src="/img/ico-potential.svg"
                                            className="ico-potential"
                                          />
                                        </button>
                                        <div className="tool-tip-content right">
                                          <span>Influencer Potential</span>
                                        </div>
                                      </span>
                                    );
                                  } else {
                                    return <span></span>;
                                  }
                                })()}
                              </h5>
                              <p>
                                {new Date(repo.birth_date).getDate() +
                                  " " +
                                  monthNames[new Date(repo.birth_date).getMonth()] +
                                  " " +
                                  new Date(repo.birth_date).getFullYear()}
                              </p>
                              <p className="for-mail">{repo.email}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="di-03">
                        <p className="for-username">
                          {repo.username_instagram == null
                            ? "-"
                            : "@" + repo.username_instagram}
                        </p>
                      </div>
                      <div className="di-04">
                        {repo.follower_instagram == null
                          ? "-"
                          : formatFollower(repo.follower_instagram)}
                      </div>
                      <div className="di-05">
                        {repo.er_instagram == null
                          ? "-"
                          : formatFollower(repo.er_instagram)}
                      </div>
                      <div className="di-06">
                        {repo.reach_instagram == null
                          ? "-"
                          : formatFollower(repo.reach_instagram)}
                      </div>
                      <div className="di-07">
                        <p className="for-username">
                          {repo.username_tiktok == null
                            ? "-"
                            : "@" + repo.username_tiktok}
                        </p>
                      </div>
                      <div className="di-08">
                        {repo.follower_tiktok == null
                          ? "-"
                          : formatFollower(repo.follower_tiktok)}
                      </div>
                      <div className="di-09">
                        {repo.er_tiktok == null ? "-" : formatFollower(repo.er_tiktok)}
                      </div>
                      <div className="di-10">
                        {repo.reach_tiktok == null
                          ? "-"
                          : formatFollower(repo.reach_tiktok)}
                      </div>
                      <div className="di-11">
                        {social_medias.map((item, i) => {
                          return (
                            <span key={i}>
                              {(() => {
                                if (item.user_social_medias.value != "") {
                                  return (
                                    <a
                                      href={item.user_social_medias.value}
                                      target="_blank"
                                      className="icon-sm"
                                    >
                                      <img src={item.logo} className="user-ico-sm" />
                                    </a>
                                  );
                                } else {
                                  return <span>-</span>;
                                }
                              })()}
                            </span>
                          );
                        })}
                      </div>
                      <div className="di-12">{repo.goddes_gang_session}X</div>
                      <div className="di-13">
                        <a href={`/goddess-gang/${repo.id}/detail`} className="gg-link">
                          Detail{" "}
                          <img src="/img/arrow-right.svg" className="gg-icon-arrow" />
                        </a>
                      </div>
                    </div>
                  );
                }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data user goddess gang, sorry</p>
                }
                <div id="popup-draft" className="overlay">
                  <Modal show={appState.showCampaign}>
                    <div className="popup">
                      <button className="close" onClick={closeCampaign}>
                        <img src="/img/ico-close.svg" alt="close" />
                      </button>
                      <h5 className="font-medium mb-3">List Campaign</h5>
                      <div className="content modal-campaign">
                        <div className="mb-2">
                          {appState.campaigns && appState.campaigns.length > 0 ? appState.campaigns.map((repo, index) => {
                            return (
                              <div className="col-12 mb-2" key={index}>
                                {(() => {
                                  if (user_id != "") {
                                    return (
                                      <button
                                        onClick={(e) => addCampaign(repo.id)}
                                        className="box-campaign"
                                      >
                                        <div className="img-part mr-2">
                                          <img
                                            src={repo.thumbnail}
                                            className="img-fluid mr-4"
                                            alt="user"
                                          />
                                        </div>
                                        <div className="user-part mt-2">
                                          <h6 className="mb-0 font-medium">
                                            {repo.title}
                                          </h6>
                                        </div>
                                      </button>
                                    );
                                  } else {
                                    return (
                                      <button disabled className="box-campaign">
                                        <div className="img-part mr-2">
                                          <img
                                            src={repo.thumbnail}
                                            className="img-fluid mr-4"
                                            alt="user"
                                          />
                                        </div>
                                        <div className="user-part mt-2">
                                          <h6 className="mb-0 font-medium">
                                            {repo.title}
                                          </h6>
                                        </div>
                                      </button>
                                    );
                                  }
                                })()}
                              </div>
                            );
                          }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data campaigns, sorry</p>
                          }
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <Modal show={appState.showEmail}>
                    <div className="popup">
                      <button className="close" onClick={closeEmail}>
                        <img src="/img/ico-close.svg" alt="close" />
                      </button>
                      <h5 className="font-medium mb-3">List Campaign</h5>
                      <div className="content modal-campaign">
                        <div className="mb-2">
                          {appState.campaigns && appState.campaigns.length > 0 ? appState.campaigns.map((repo, index) => {
                            return (
                              <div className="col-12 mb-2" key={index}>
                                {(() => {
                                  if (user_id != "") {
                                    return (
                                      <button
                                        onClick={(e) => sendEmail(repo.id)}
                                        className="box-campaign"
                                      >
                                        <div className="img-part mr-2">
                                          <img
                                            src={repo.thumbnail}
                                            className="img-fluid mr-4"
                                            alt="user"
                                          />
                                        </div>
                                        <div className="user-part mt-2">
                                          <h6 className="mb-0 font-medium">
                                            {repo.title}
                                          </h6>
                                        </div>
                                      </button>
                                    );
                                  } else {
                                    return (
                                      <button disabled className="box-campaign">
                                        <div className="img-part mr-2">
                                          <img
                                            src={repo.thumbnail}
                                            className="img-fluid mr-4"
                                            alt="user"
                                          />
                                        </div>
                                        <div className="user-part mt-2">
                                          <h6 className="mb-0 font-medium">
                                            {repo.title}
                                          </h6>
                                        </div>
                                      </button>
                                    );
                                  }
                                })()}
                              </div>
                            );
                          }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data campaigns, sorry</p>
                          }
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <Modal show={appState.showNotif} className="send-notif">
                    <div className="popup">
                      <button className="close" onClick={closeNotif}>
                        <img src="/img/ico-close.svg" alt="close" />
                      </button>
                      <h5 className="mb-3">Form Send Notification</h5>
                      <div className="content modal-campaign">
                          <div className="col-12">
                            <div className="form-notif mb-4">
                              <input type="text" name="title_notif" value={title_notif} onChange={(event) => setTitleNotif(event.target.value)} className="form-control" placeholder="Title Notification" />
                              {title_notif=='' || errors.title_notif ? <p className='alert-message'>Title wajib diisi</p> : ""}
                            </div>
                            <div className="form-notif mb-4">
                              <textarea className="form-control"  {...register("desc_notif")}  placeholder="Description Notification" id="inputText" rows={5} value={desc_notif} onChange={(event) => setDescNotif(event.target.value)} />
                            </div>
                            {(() => {
                              if (image != '') {
                              return  <div>
                                        <div className="box-img-upload">
                                            <img src={image.imagePreviewUrl} className="img-fluid w-100" />
                                            <div className="upload-btn-wrapper">
                                                <button className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></button>
                                                <input type="file" name="image" {...register("image")} onChange={onImageChange} />
                                            </div>
                                        </div>
                                        <div className="font-medium">Gambar Thumbnail</div>
                                        <div className="path">
                                            <div className="mr-2"><img src="/img/ico-folder.svg" className="img-fluid" width={30} /></div>
                                            <div className="grey">{image.file.name}</div>
                                        </div>
                                      </div>
                              } else {
                              return <div>
                                      <div className="box-img-upload">
                                            <img src="/img/no-image.jpg" width={100} className="img-fluid w-100" />
                                            <div className="upload-btn-wrapper">
                                                <button className="btn btn-edit"><img src="/img/ico-edit.svg" className="img-fluid red" /></button>
                                                <input type="file" name="image" {...register("image")} onChange={onImageChange} />
                                            </div>
                                        </div>
                                        <div className="font-medium">Gambar Thumbnail</div>
                                        <div className="path">
                                            <div className="mr-2"><img src="/img/ico-folder.svg" className="img-fluid" width={30} /></div>
                                            <div className="grey">No Image</div>
                                        </div>
                                      </div>      
                              }
                            })()}
                            <div className="d-flex justify-content-end">
                              <button type="submit" className="btn btn-primary" onClick={sendNotification} disabled={title_notif==''|| errors.title_notif}>{appState.disabled ? "Loading..." : "Kirim"}</button>   
                            </div>
                          </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  {(() => {
                    if (total < 5 || appState.total < 5) {
                      return (
                        <ReactPaginate
                          pageCount={1}
                          pageRangeDisplayed={4}
                          marginPagesDisplayed={1}
                          forcePage={appState.current_page - 1}
                          activeLinkClassName="active"
                          containerClassName="pagination"
                          previousLabel="&laquo;"
                          nextLabel="&raquo;"
                          onPageChange={changePage}
                        />
                      );
                    } else {
                      return (
                        <ReactPaginate
                          pageCount={Math.ceil(page_count)}
                          pageRangeDisplayed={4}
                          marginPagesDisplayed={1}
                          forcePage={appState.current_page - 1}
                          activeLinkClassName="active"
                          containerClassName="pagination"
                          previousLabel="&laquo;"
                          nextLabel="&raquo;"
                          onPageChange={changePage}
                        />
                      );
                    }
                  })()}
                </div>
          </div>
      } else {
        return <div className="content-wrapper">
            <div className="credential pt-5">
                <div className="credential-box-noborder">
                <div className="load-2">
                    <p>Loading... Please wait</p>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                </div>
            </div>
        </div>
        }
      })()}
      </div>
    </div>
  );
};

export default List;
