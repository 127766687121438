import {GenericDataModel} from "./generic-data.model";

export default class BannerDetailModel extends GenericDataModel{
    title: String
    url: String
    image: any

    constructor(bannerDetailModel: BannerDetailModel) {
        super();
        Object.assign(this, bannerDetailModel)
    }
}

export const BannerDetailObj = {
    title: null,
    url: null,
    image: null
}
