import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportRedeemRewardListToExcel extends React.Component {

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {redeemRewardList, element} = this.props

        return (
            <ExcelFile element={element}>
                <ExcelSheet data={redeemRewardList} name="Daftar Redeem Reward">
                    <ExcelColumn label="Id" value="id"/>
                    <ExcelColumn label="Tanggal Redeem Reward" value="createdAt"/>
                    <ExcelColumn label="Nama" value="name"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="No. Hp" value="phoneNumber"/>
                    <ExcelColumn label="Reward" value="reward"/>
                    <ExcelColumn label="Point Usage" value="point"/>
                    <ExcelColumn label="Alamat" value="address"/>
                    <ExcelColumn label="Status" value="status"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
