import React from "react";
import {Button, Table} from 'antd';
import {formatDateToReadable} from "../../ui-util/general-variable";

class UserListTrackingComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Name', dataIndex: 'full_name', key: 'name'},
                {title: 'Email', dataIndex: 'email', key: 'email'},
                {title: 'Join Date', dataIndex: 'createdAt', key: 'createdAt', render: (createdAt) => formatDateToReadable(createdAt)},
                {
                    title: 'Action',
                    key: 'action',
                    fixed: 'right',
                    width: 100,
                    render: (record) => <Button onClick={() => this.props.showUserDetail(record)}>Detail</Button>
                }
            ]
        }
    }

    render() {
        const {columns} = this.state
        const {userList} = this.props
        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={userList}
                rowKey={'id'}
            />
        )
    }
}

export default UserListTrackingComponent
