import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

const NavMenu = ({active}) => {
  return (
    <>
        <div className="side-tabs">
            <div className="side-tab">
                <input type="checkbox" id="chck1" defaultChecked={active.includes('/mission-registered')} />
                <label
                    className={`side-menu-btn ${active.includes('/mission-registered') ? 'active' : ''}`}
                    htmlFor="chck1"
                >
                    <span className="icon-mission" /> Mission Registered
                </label>
                <div className="tab-content">
                    <Link to="/mission-registered" className={`accordion-nav ${active === '/mission-registered' ? 'active' : ''}`}>
                        User Registered
                    </Link>
                    <Link to="/mission-registered/rejected" className={`accordion-nav ${active === '/mission-registered/rejected' ? 'active' : ''}`}>
                        Rejected
                    </Link>
                </div>
            </div>
        </div>
        <div className="side-tabs">
            <div className="side-tab">
                <input type="checkbox" id="chck2" defaultChecked={active.includes('/goddess-gang')}/>
                <label
                    className={`side-menu-btn ${active.includes('/goddess-gang') ? 'active' : ''}`}
                    htmlFor="chck2"
                >
                    <span className="icon-gg" /> Goddess Gang
                </label>
                <div className="tab-content">
                    <Link to="/goddess-gang" className={`accordion-nav ${active === '/goddess-gang' ? 'active' : ''}`}>
                        All Users
                    </Link>
                    <Link to="/goddess-gang/group" className={`accordion-nav ${active === '/goddess-gang/group' ? 'active' : ''}`}>
                        Group
                    </Link>
                </div>
            </div>
        </div>
        <div className="side-tabs">
            <div className="side-tab">
                <input type="checkbox" id="chck3" defaultChecked={active.includes('/campaign')} />
                <label
                    className={`side-menu-btn ${active.includes('/campaign') ? 'active' : ''}`}
                    htmlFor="chck3"
                >
                    <span className="icon-campaign" /> Campaign
                </label>
                <div className="tab-content">
                    <Link to="/campaign" className={`accordion-nav ${active === '/campaign' ? 'active' : ''}`}>
                        List Campaign
                    </Link>
                    <Link to="/campaign/create" className={`accordion-nav ${active === '/campaign/create' ? 'active' : ''}`}>
                        Create Campaign
                    </Link>
                    <Link to="/campaign/approvement" className={`accordion-nav ${active === '/campaign/approvement' ? 'active' : ''}`}>
                        Approvement Campaign
                    </Link>
                </div>
            </div>
        </div>
        <Link to="/redeem-point" className={`link-side ${active === '/redeem-point' ? 'active' : ''}`}><span className="icon-content"></span> Reedem Point</Link>
        <div className="side-tabs">
            <div className="side-tab">
                <input type="checkbox" id="chck4" defaultChecked={active.includes('/setting')} />
                <label
                    className="side-menu-btn"
                    htmlFor="chck4"
                >
                    <span className="icon-setting" /> Setting
                </label>
                <div className="tab-content">
                    <Link to="/setting-banner" className={`accordion-nav ${active === '/setting-banner' ? 'active' : ''}`}>
                        Banner Goddess Gang
                    </Link>
                    <Link to="/setting-create-questioner-form" className={`accordion-nav ${active === '/setting-create-questioner-form' ? 'active' : ''}`}>
                        Create Questioner Form
                    </Link>
                    <Link to="/setting-create-timeline" className={`accordion-nav ${active === '/setting-create-timeline' ? 'active' : ''}`}>
                        Create Timeline
                    </Link>
                </div>
            </div>
        </div>
    </>
  )
}

function Sidebar() {
    return ( 
        <section id="sidebar">
            <div className="sidebar-inner">
                <div className="dash-logo">
                    <Link to="/mission-registered"><img src="/img/logo.png" alt="avo-logo" className="img-fluid" /></Link>
                </div>
                <nav className="side-menu">
                    <div className="accordion" id="accordion-order">
                        <Switch>
                            <Route exact path='/mission-registered'>
                                <NavMenu active="/mission-registered" />
                            </Route>
                            <Route exact path='/mission-registered/rejected'>
                                <NavMenu active="/mission-registered/rejected" />
                            </Route>
                            <Route exact path='/goddess-gang'>
                                <NavMenu active="/goddess-gang" />
                            </Route>
                            <Route exact path='/goddess-gang/group'>
                                <NavMenu active="/goddess-gang/group" />
                            </Route>
                            <Route exact path='/goddess-gang/create-group'>
                                <NavMenu active="/goddess-gang/group" />
                            </Route>
                            <Route exact path='/goddess-gang/:id/group'>
                                <NavMenu active="/goddess-gang/group" />
                            </Route>
                            <Route exact path='/campaign'>
                                <NavMenu active="/campaign" />
                            </Route>
                            <Route exact path='/campaign/create'>
                                <NavMenu active="/campaign/create" />
                            </Route>
                            <Route exact path='/campaign/:id/edit'>
                                <NavMenu active="/campaign/create" />
                            </Route>
                            <Route exact path='/campaign/approvement'>
                                <NavMenu active="/campaign/approvement" />
                            </Route>
                            <Route exact path='/campaign/:id/approvement'>
                                <NavMenu active="/campaign/approvement" />
                            </Route>
                            <Route exact path='/redeem-point'>
                                <NavMenu active="/redeem-point" />
                            </Route>
                            <Route exact path='/setting-banner'>
                                <NavMenu active="/setting-banner" />
                            </Route>
                            <Route exact path='/setting-create-questioner-form'>
                                <NavMenu active="/setting-create-questioner-form" />
                            </Route>
                            <Route exact path='/setting-create-timeline'>
                                <NavMenu active="/setting-create-timeline" />
                            </Route>
                        </Switch>
                    </div>
                </nav>
            </div>
            <div className="side-deco">
                <img src="/img/side-deco.png" className="deco img-fluid" alt="Side" />
            </div>
        </section>
    );
}

export default Sidebar;
