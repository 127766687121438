import React from "react";
import {Avatar, Button, Card, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import {formatDateToReadable} from "../../ui-util/general-variable";

class UserDetailTrackingComponent extends React.PureComponent {
    render() {
        const {userDetail, showUserDetail} = this.props
        return (
            <Card style={{
                marginTop: 30,
                marginBottom: 30,
                borderRadius: 25,
                borderWidth: 5,
                borderColor: Colors.whitesmoke
            }}>
                <Row justify={'space-between'} style={{alignItems: 'center'}}>
                    <Row>
                        <Avatar size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100}} src={userDetail?.photo}/>
                        <div style={{marginLeft: 30}}>
                            <p>Nama : {userDetail?.full_name}</p>
                            <p>Email : {userDetail?.email}</p>
                            <p>Referral Id : {userDetail?.referal_id}</p>
                            <p>Join Date : {formatDateToReadable(userDetail?.createdAt)}</p>
                        </div>
                    </Row>
                    <Button onClick={() => showUserDetail(userDetail)}>Detail</Button>
                </Row>
            </Card>
        )
    }
}

export default UserDetailTrackingComponent
