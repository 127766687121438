import React,{ useState } from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const List = (props) => {
    const { data, total, set_page, current_page, per_page, currencyFormat } = props;
    const [appState, setAppState]  = useState({
        dataState:data,
        disabled: false
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
    }
    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            appState.dataState[index].isChecked= event.target.checked
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState });
    }
    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].id)
    
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
        setAppState({dataState:appState.dataState });
    
    }
    const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data user registered, sorry</p>;
   
    const page_count = total / per_page;
    return (
        <div>
            <div className="accordions-01">
                <div className="accordion-01">
                    <input type="checkbox" id="chck1" defaultChecked />
                    <label className="analytic-accordion" htmlFor="chck1">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h6 className="font-medium red">Detail Reedem</h6>
                            </div>
                            <div>
                                <button className="gg-link dark">Hide <img src="/img/arrow-up.svg" className="gg-icon-arrow black" /></button>
                            </div>
                        </div>
                    </label>
                    <div className="tab-content big">
                        {appState.dataState.map((repo,index) => {
                            return (
                            <div className="data-items" key={index}>
                                <div className="rdp-1">
                                    <div className="box-user">
                                        <div className="img-part mr-3">
                                            <img src={repo.user.photo} className="img-fluid img-circle" width={100} alt="user" />
                                        </div>
                                        <div className="user-part">
                                            <h5 className="font-medium mb-0">
                                            {repo.user.full_name}
                                            <span className="tool-tip floating">
                                                <button className="btn tool-tip-icon"><img src="/img/ico-potential.svg" className="ico-potential" /></button>
                                                <div className="tool-tip-content right">
                                                    <span>Influencer Potential</span>
                                                </div>
                                            </span>
                                            </h5>
                                            <p className="mb-0">{repo.user.dob}</p>
                                            <p className="mb-0">{repo.user.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="rdp-2 text-center">
                                    <p className="font-medium">Tanggal</p>
                                    <div className="grey ft-med">{dayNames[new Date(repo.createdAt).getDay()]+ " " + new Date(repo.createdAt).getDate()+ " " + monthNames[new Date(repo.createdAt).getMonth()]+ " "+ new Date(repo.createdAt).getFullYear()}</div>
                                </div>
                                <div className="rdp-3">
                                    <p className="font-medium">Point Reedem</p>
                                    <h5 className="font-medium text-red">{currencyFormat(repo.point_withdraw)} Point</h5>
                                </div>
                                <div className="rdp-4">
                                    <img src="/img/ico-equal.svg" className="img-fluid" />
                                </div>
                                <div className="rdp-5">
                                    <p className="font-medium">Konversi Rupiah</p>
                                    <h5 className="font-medium">Rp. {currencyFormat(repo.total_withdraw)}</h5>
                                </div>
                                <div className="rdp-6">
                                    <p className="font-medium">Biaya Admin</p>
                                    <h5 className="font-medium text-red">Rp. {currencyFormat(repo.admin_fee)}</h5>
                                </div>
                                <div className="rdp-7">
                                    <p className="font-medium">Total Dana</p>
                                    <h5 className="font-medium">Rp. {currencyFormat(repo.total_withdraw)}</h5>
                                </div>
                                <div className="rdp-8">
                                    <p className="font-medium">Sisa Point</p>
                                    <h5 className="font-medium grey">{currencyFormat(repo.point_left)} Point</h5>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
                {(() => {
                if (total < 5) {
                    return <ReactPaginate
                        pageCount={1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                } else {
                    return <ReactPaginate
                        pageCount={Math.ceil(page_count)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='pagination'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                }
                })()}
            </div>
        </div>
    );
}

export default List;