import React from "react";
import {Col, Row} from "antd";
import {formatDateToReadable} from "../../ui-util/general-variable";

class RedeemRewardDetailComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            redeemRewardFormatList: [
                {key: 'full_name', name: 'Name', subKey: 'user'},
                {key: 'email', name: 'Email', subKey: 'user'},
                {key: 'reward_phone', name: 'No. Hp', subKey: 'user'},
                {key: 'name', name: 'Reward Name', subKey: 'reward'},
                {key: 'history_point', name: 'Point'},
                {key: 'address', name: 'Alamat'},
                {key: 'createdAt', name: 'Tanggal Redeem Reward'},
                {key: 'status', name: 'Status'}
            ]
        }
    }

    renderItemValue = (item) => {
        const {redeemRewardDetail} = this.props
        switch (item.key) {
            case 'createdAt':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{formatDateToReadable(redeemRewardDetail[item.key])}</p>
                        </Col>
                    </Row>
                )
            case 'address':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{redeemRewardDetail[item.key]}, {redeemRewardDetail['city']['name']}, {redeemRewardDetail['city']['province']['name']}, {redeemRewardDetail['city']['postal_code']}</p>
                        </Col>
                    </Row>
                )
            case 'email':
            case 'reward_phone':
            case 'full_name':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{redeemRewardDetail[item?.subKey][item?.key]}</p>
                        </Col>
                    </Row>
                )
            case 'name':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{!!redeemRewardDetail?.reward ? redeemRewardDetail[item?.subKey][item?.key] : `Reward doesn't exist`}</p>
                        </Col>
                    </Row>
                )
            default:
                return (
                    <Row key={item?.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item?.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{redeemRewardDetail[item?.key]}</p>
                        </Col>
                    </Row>
                )
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {redeemRewardFormatList} = this.state
        return (
            <Row gutter={16}>
                <Col span={24}>
                    {redeemRewardFormatList.map(item => this.renderItemValue(item))}
                </Col>
            </Row>
        )
    }
}

export default RedeemRewardDetailComponent
