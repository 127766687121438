import React from "react";
import {Button, Form, Image, Input, Select} from "antd";
import _ from "lodash";
import {createAndUpdateRecommendationApi, getDetailRecommendationApi} from "../../api/recommendation.api";
import {getProductListApi} from "../../api/product-list.api";
import StandardAlertModal from "../../component/standard-alert.modal";

const {Option} = Select;

class RecommendationUpdateComponent extends React.Component {

    constructor() {
        super();

        this.state = {
            recommendationDetail: {},
            productList: [],
            loadingStandardAlert: false,
            standardAlertModalVisibility: false
        }
    }

    componentDidMount(): void {
        this.getProductList()
        this.getDetailRecommendation()
    }

    getDetailRecommendation = () => {
        const {id, skinType} = this.props.params
        getDetailRecommendationApi(skinType, id)
            .then(result => {
                this.setState({recommendationDetail: result.data})
            })
            .catch(err => console.log(err))
    }

    getProductList = () => {
        getProductListApi()
            .then(response => {
                this.setState({productList: response.data})
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChangeProductRecommendation = (key, item) => {
        let {recommendationDetail} = this.state
        recommendationDetail.recomendation = item
        this.setState({recommendationDetail})
    }

    submitUpdateRecommendation = () => {
        const {recomendation} = this.state.recommendationDetail
        const {id, skinType} = this.props.params
        if(recomendation.length > 0) {
            let bodyRequest = []
            recomendation.map(item => {
                let data = {
                    product_id: Number(item.key),
                    product_slug: item.value,
                    product_name: item.children
                }
                bodyRequest.push(data)
            })
            if (!!bodyRequest[0].product_slug) {
                createAndUpdateRecommendationApi(skinType, id, bodyRequest)
                    .then(response => {
                        alert('berhasil update product recommendation')
                        window.location.href = '/recommendation/product-recommendation'
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.changeVisibilityStandardAlert('standardAlertModalVisibility', true)
            }
        } else {
            alert('Anda belum memasukkan produk rekomendasi!')
        }

    }

    onOkStandardAlert = () => window.location.href = '/recommendation/product-recommendation'

    changeVisibilityStandardAlert = (key, value) => this.setState({[key]: value})

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {recommendationDetail, productList, loadingStandardAlert, standardAlertModalVisibility} = this.state
        return (
            <>
                <Form className="form-group">
                    <Form.Item
                        key={'image'}
                        label={'Image'}
                    >
                        <Image
                            src={recommendationDetail.image}
                            width={100}
                        />
                    </Form.Item>
                    <Form.Item
                        key={'name'}
                        label={'Name'}
                    >
                        <Input value={recommendationDetail.name} disabled/>
                    </Form.Item>
                    <Form.Item
                        key={'recommendation'}
                        label={'Product Recommendation'}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Please select"
                            style={{width: '100%'}}
                            value={_.map(recommendationDetail.recomendation, e => e.product_slug || e.value)}
                            onChange={this.handleChangeProductRecommendation}
                        >
                            {
                                productList.map((item) => (
                                    <Option key={item.id} value={item.slug}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Button type="primary" onClick={() => this.submitUpdateRecommendation()}>
                        Submit
                    </Button>
                </Form>
                <StandardAlertModal
                    loading={loadingStandardAlert}
                    onOkButtonHandler={this.onOkStandardAlert}
                    changeVisibility={this.changeVisibilityStandardAlert}
                    skinConditionKey={"standardAlertModalVisibility"}
                    modalVisibility={standardAlertModalVisibility}
                    title={"Anda belum melakukan perubahan data!"}
                    bodyMessage={"Apakah anda ingin kembali ke halaman produk rekomendasi?"}
                />
            </>
        )
    }
}

export default RecommendationUpdateComponent
