import React from 'react';
import PropTypes from "prop-types";

function SortArrow(props) {
  const {valueSort, valueOrderBy, activeOrderBy, onClick} = props

  function handleClick(e){
    let sort = (valueSort === "asc" && activeOrderBy===valueOrderBy) ? "desc" : "asc"
    let orderBy = valueOrderBy
    onClick({sort, orderBy})
  }
  
  return (
      <button onClick={(e) => handleClick(e)} className={`btn-filter ${(valueSort==="asc" && activeOrderBy===valueOrderBy) ? "rot180" : ""}`}>
        <img src="/img/arrow-down.svg" alt="Sorting Arrow Down" />
      </button>
  )
}

SortArrow.defaultProps = {
  valueSort: "desc",
  valueOrderBy: "full_name",
  activeOrderBy: "full_name",
  onClick: (value) => {console.log(`element value ${value} clicked`)}
}

SortArrow.propTypes = {
  valueSort: PropTypes.string,
  valueOrderBy: PropTypes.string,
  activeOrderBy: PropTypes.string,
  onClick: PropTypes.func,
};
  
export default SortArrow;