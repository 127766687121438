import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import List from './List';
import Loading from '../../../component/Loading';
import { getUserCampaignApi, setUserCampaignApi, setSendProductApi, setApproveDraftKontenApi, setPublishKonten } from "../../../api/user-campaign.api";
import {useHistory, useLocation} from 'react-router';
import ReactExport from 'react-data-export';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {cloudFunctionBaseUrl} from "../../../ui-util/general-variable";
import CustomPillSelect from '../../../component/Common/custom-pill-select';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const token = localStorage.getItem('TOKEN_AUTH');
function ApproveCampaign({ match }) {
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [search, setSearch]         = useState('');
    const [limit, setLimit]           = useState(5);
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('id');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const dateRangePickerRef          = useRef(null);
    const [selectedPage, setPage]     = useState(1);
    const [ranges, setRanges]         = useState([50, 100]);
    const [is_potential_ig, setPotentialUser] = useState([]);
    const staticInfluencer = [
        {value: "1", text: "Instagram"},
        // {value: "Tiktok", text: "Tiktok"},
        // {value: "Youtube", text: "Youtube"},
        // {value: "Twitter", text: "Twitter"},
        // {value: "Facebook", text: "Facebook"}
    ];
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        campaign: null,
        pageStatus: decodeURI(window.location.search.split('?')[1]),
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1,
        sosmed: null
    });
    const {control, formState: {errors}, watch, register, setValue, getValues, handleSubmit, reset} = useForm({mode: "all"});
    const { params: { id }, } = match;
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).replace(/[^\d,.-]/g, "-");
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    function approveUser() {
        appState.data.forEach((result,index) =>{
            if(appState.data[index].isChecked==true){
                setAppState({loading: true, pageStatus: appState.pageStatus, disabled: true, data: appState.data, campaign: appState.campaign});
                let bodyRequest = []
                const user_id = appState.data[index].id
                let values = {
                    status: "Approve",
                    user_id: user_id
                }
                bodyRequest.push(values)
                setUserCampaignApi(id, values)
                .then((repos) => {
                    const data = repos.message;
                    if(repos.status == "Successful") {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        setTimeout(function () {
                            window.location.reload()
                        }, 3000);
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                    }
                    setAppState({ loading: false,pageStatus: appState.pageStatus, total: appState.total, current_page: appState.current_page, data: appState.data, campaign: appState.campaign});
                });
            }
        })
    }
    function declineUser() {
        appState.data.forEach((result,index) =>{
            if(appState.data[index].isChecked==true){
                setAppState({loading: true, pageStatus: appState.pageStatus, disabled: true, data: appState.data, campaign: appState.campaign});
                let bodyRequest = []
                const user_id = appState.data[index].id
                let values = {
                    status: "Reject",
                    user_id: user_id
                }
                bodyRequest.push(values)
                setUserCampaignApi(id, values)
                .then((repos) => {
                    const data = repos.message;
                    if(repos.status == "Successful") {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        setTimeout(function () {
                            window.location.reload()
                        }, 3000);
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                    }
                    setAppState({ loading: false, pageStatus: appState.pageStatus, total: appState.total, current_page: appState.current_page, data: appState.data, campaign: appState.campaign});
                });
            }
        })
    }
    function sendProduct() {
        appState.data.forEach((result,index) =>{
            if(appState.data[index].isChecked==true){
                setAppState({loading: true, pageStatus: appState.pageStatus, disabled: true, data: appState.data, campaign: appState.campaign});
                let bodyRequest = []
                const user_id = appState.data[index].id
                let values = {
                    user_id: user_id
                }
                bodyRequest.push(values)
                setSendProductApi(id, values)
                .then((repos) => {
                    const data = repos.message;
                    if(repos.status == "Successful") {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        // setTimeout(function () {
                        //     window.location.reload()
                        // }, 3000);
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                    }
                    setAppState({ loading: false,pageStatus: appState.pageStatus, total: appState.total, current_page: appState.current_page, data: appState.data, campaign: appState.campaign});
                });
            }
        })
    }
    function approveDraft() {
        appState.data.forEach((result,index) =>{
            if(appState.data[index].isChecked==true){
                setAppState({loading: true, pageStatus: appState.pageStatus, disabled: true, data: appState.data, campaign: appState.campaign});
                let bodyRequest = []
                const user_id = appState.data[index].id
                let values = {
                    user_ids: [user_id]
                }
                bodyRequest.push(values)
                setApproveDraftKontenApi(id, values)
                .then((repos) => {
                    const data = repos.message;
                    if(repos.status == "Successful") {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        setTimeout(function () {
                            window.location.assign(`/campaign/${id}/approvement?Publish%20Konten`)
                        }, 3000);
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                    }
                    setAppState({ loading: false,pageStatus: appState.pageStatus, total: appState.total, current_page: appState.current_page, data: appState.data, campaign: appState.campaign});
                });
            }
        })
    }
    function publishKonten() {
        appState.data.forEach((result,index) =>{
            if(appState.data[index].isChecked==true){
                setAppState({loading: true, pageStatus: appState.pageStatus, disabled: true, data: appState.data, campaign: appState.campaign});
                let bodyRequest = []
                const user_id = appState.data[index].id
                let values = {
                    user_id: [user_id]
                }
                bodyRequest.push(values)
                setPublishKonten(id, values)
                .then((repos) => {
                    const data = repos.message;
                    if(repos.status == "Successful") {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        setTimeout(function () {
                            window.location.reload()
                        }, 3000);
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                    }
                    setAppState({ loading: false,pageStatus: appState.pageStatus, total: appState.total, current_page: appState.current_page, data: appState.data, campaign: appState.campaign});
                });
            }
        })
    }
    const onChangeStatus = async (event) => {
        const pageStatus = event
        const search = "";
        setSearch(search);
        setPotentialUser('');
        setAppState({ loading: true, pageStatus: event, status: appState.status, campaign: appState.campaign});
        const fetchData = async () => {
            let params = {
                status: event,
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
            }
            getUserCampaignApi(id, params)
                .then(result => {
                    setAppState({ loading: false, pageStatus: event, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, campaign: appState.campaign });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const onSubmit = (data) => alert(JSON.stringify(data));
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        setAppState({ loading: true, campaign: appState.campaign, pageStatus: appState.pageStatus });
        const fetchData = async () => {
            let params = {
                status: appState.pageStatus,
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                key: search
            }
            getUserCampaignApi(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.data.rows, total: result.data.count, per_page: limit, current_page: appState.current_page, campaign: appState.campaign, pageStatus: appState.pageStatus });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 5) {
            const limit = 5;
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, campaign: appState.campaign });
                const fetchData = async () => {
                    let status = appState.pageStatus == "undefined" ? "Waiting Approval" : appState.pageStatus
                    let params = {
                        status: status,
                        size: limit,
                        page: selectedPage,
                        orderby: orderby,
                        sort: sort,
                        key: search
                    }
                    getUserCampaignApi(id, params)
                        .then(result => {
                            setAppState({ loading: false, pageStatus: appState.pageStatus, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, campaign: appState.campaign });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        } else {
            const limit = e.target.value
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, campaign: appState.campaign });
                const fetchData = async () => {
                    let status = appState.pageStatus == "undefined" ? "Waiting Approval" : appState.pageStatus
                    let params = {
                        status: status,
                        size: limit,
                        page: selectedPage,
                        orderby: orderby,
                        sort: sort,
                        key: search
                    }
                    getUserCampaignApi(id, params)
                        .then(result => {
                            setAppState({ loading: false, pageStatus: appState.pageStatus, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, campaign: appState.campaign });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        }
    }
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true, campaign: appState.campaign });
        const fetchData = async () => {
            let status = appState.pageStatus == "undefined" ? "Waiting Approval" : appState.pageStatus
            let params = {
                status: status,
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                key: search
            }
            getUserCampaignApi(id, params)
                .then(result => {
                    setAppState({ loading: false, pageStatus: appState.pageStatus, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, campaign: appState.campaign });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    useEffect(() => {
        setAppState({ loading: true, pageStatus: appState.pageStatus});
        const fetchData = async () => {
            let status = appState.pageStatus == "undefined" ? "Waiting Approval" : appState.pageStatus
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort
            }
            const respDetailCampaign= await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            const respUserCampaign = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/${id}?status=${status}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            })
            setAppState({ loading: false, pageStatus: appState.pageStatus, campaign: respDetailCampaign.data.data, data: respUserCampaign.data.data.rows, total: respUserCampaign.data.data.count, per_page: limit, current_page: appState.current_page });
        };
        fetchData();
    }, [])

    const editFilter = (value) => {
        // var array = is_potential_ig
        // var index = array.indexOf(value)
        // if (index !== -1) {
        //     array.splice(index, 1)
        // }else{
        //     array.push(value)
        // }
        setPotentialUser(value)
        setAppState({ loading: true, pageStatus: appState.pageStatus, status: appState.status, campaign: appState.campaign});
        let params = {
            status: appState.pageStatus,
            size: limit,
            page: selectedPage,
            orderby: orderby,
            sort: sort,
            is_potential_ig : value
        }
        getUserCampaignApi(id, params)
        .then(result => {
            setAppState({ loading: false, pageStatus: appState.pageStatus, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, campaign: appState.campaign });
        }).catch(err => {
            console.log(err)
        })
    }

    const resetFilter = () => {
        setPotentialUser([])
        setAppState({ loading: true, pageStatus: appState.pageStatus, status: appState.status, campaign: appState.campaign});
        let params = {
            status: appState.pageStatus,
            size: limit,
            page: selectedPage,
            orderby: orderby,
            sort: sort,
            is_potential_ig : ''
        }
        getUserCampaignApi(id, params)
        .then(result => {
            setAppState({ loading: false, pageStatus: appState.pageStatus, data: result.data.rows, total: result.data.count, per_page: limit, current_page: selectedPage, campaign: appState.campaign });
        }).catch(err => {
            console.log(err)
        })
    }
    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    function justNumbers(string) {
        var numsStr = string.replace(/\D/g,"");
        return parseInt(numsStr);
    }
    const fetchData = async () => {
        setAppState({ loading: true});
        let status = appState.pageStatus == "undefined" ? "Waiting Approval" : appState.pageStatus
        let params = {
            size: limit,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            is_potential_ig: is_potential_ig
        }
        const respDetailCampaign= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const respUserCampaign = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/${id}?status=${status}`, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
            params
        })
        setAppState({ loading: false, pageStatus: appState.pageStatus, campaign: respDetailCampaign.data.data, data: respUserCampaign.data.data.rows, total: respUserCampaign.data.data.count, per_page: limit, current_page: appState.current_page });
    };
    useEffect(() => {
        fetchData();
    }, [sort, orderby]);
    // const MIN_VAL = 5;
    // const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
    return ( 
        <div id="campaign-wrapper">
            <div className="tab-campaign">
                {(() => {
                    if (appState.pageStatus == "undefined" || appState.pageStatus == "Waiting Approval"){
                        return <input defaultChecked="checked" id="tab1" type="radio" name="status"/>
                    } else if (appState.pageStatus == "Approve") {
                        return <input defaultChecked="checked" id="tab2" type="radio" name="status"/>
                    } else if (appState.pageStatus == "Send Product") {
                        return <input defaultChecked="checked" id="tab3" type="radio" name="status"/>
                    } else if (appState.pageStatus == "Draft Konten") {
                        return <input defaultChecked="checked" id="tab4" type="radio" name="status"/>
                    } else if (appState.pageStatus == "Publish Konten") {
                        return <input defaultChecked="checked" id="tab5" type="radio" name="status" />
                    }
                })()}
                <div className="tab-campaign">
                    <a href={`/campaign/${id}/approvement?Waiting Approval`}>
                        <label htmlFor="tab1" className="tab-campaign-item tab1">List User</label>
                    </a>
                    <a href={`/campaign/${id}/approvement?Approve`}>
                        <label htmlFor="tab2" className="tab-campaign-item tab2">Approve</label>
                    </a>
                    <a href={`/campaign/${id}/approvement?Send Product`}>
                        <label htmlFor="tab3" className="tab-campaign-item tab3">Kirim Product</label>
                    </a>
                    <a href={`/campaign/${id}/approvement?Draft Konten`}>
                        <label htmlFor="tab4" className="tab-campaign-item tab4">Draft Konten</label>
                    </a>
                    <a href={`/campaign/${id}/approvement?Publish Konten`}>
                        <label htmlFor="tab5" className="tab-campaign-item tab5">Publish Konten</label>
                    </a>
                </div>
                <section className="tab-container">
                    <div id="inner-campaign-wrapper">
                        <div className="main-dash">
                            
                            <div className="row justify-content-between align-items-center mb-4">
                                <div className="col-6">
                                {(() => {
                                    if (appState.data) {
                                    return <div className="row align-items-center">
                                        <div className="col-4">
                                            <img src={appState.campaign ? appState.campaign.thumbnail : "-"} className="img-fluid" />
                                        </div>
                                        <div className="col-8">
                                            <h4 className="font-medium">{appState.campaign.title}</h4>
                                            <div className="d-flex">
                                                <div className="grey font-medium mr-3">{new Date(appState.campaign.campaign_start_date).getDate()+ " " + monthNames[new Date(appState.campaign.campaign_start_date).getMonth()]+ " "+ new Date(appState.campaign.campaign_start_date).getFullYear()}</div>
                                                <div className="font-medium">{currencyFormat(appState.campaign.point)} Point</div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                })()}
                                </div>
                                <div className="col-3">
                                    <div className="d-flex align-items-center justify-content-end w-100 mb-3">
                                        {appState.data && appState.data.length> 0 ? appState.data.slice(0, 3).map((repo,index) => {
                                            return (
                                                <div className="img-group big" key={index}><img src={repo.photo} className="img-fluid img-circle" /></div>
                                            );
                                        }): <div className="img-group big"></div>
                                        }
                                        <div className="ig-text">{appState.total > 0 ? appState.total+" Orang" : ""}</div>
                                    </div>
                                </div>
                            </div>
                            {(() => {
                                if (appState.pageStatus == "undefined" || appState.pageStatus == "Waiting Approval") {
                                return <div>
                                    <h6 className="font-medium mb-3">Approval</h6>
                                    <div className="toolbar two">
                                        <div className="toolbar-left">
                                            <div className="toolbar-inner-left">
                                                <div className="toolicon-wrapper">
                                                    <div className="toolicon for-btn" onClick={approveUser}><img src="/img/ico-approve.svg" className="img-fluid" /></div>
                                                </div>
                                                <div className="toolicon-wrapper">
                                                    <div className="toolicon for-btn" onClick={declineUser} ><img src="/img/ico-decline.svg" className="img-fluid" /></div>
                                                </div>
                                                <CustomPillSelect data={staticInfluencer} title="Potential Influencer" onClick={(e) => editFilter(e)} onReset={(e) => resetFilter()} selected={is_potential_ig} width="300px" />
                                                <div className="toolicon-wrapper status export">
                                                    <ExcelFile
                                                        element={
                                                            <div className="toolicon status export">
                                                            <span>Export</span>
                                                            </div>
                                                        }
                                                        filename={"user-campaign-" + dateNow + "-" + timeNow}
                                                        >
                                                        <ExcelSheet data={appState.data} name="User Campaign">
                                                            <ExcelColumn label="Name" value={(col) => col.full_name}/>
                                                            <ExcelColumn label="Email" value={(col) => col.email}/>
                                                            <ExcelColumn label="Date of Birth" value={(col) => col.dob}/>
                                                            <ExcelColumn label="Total Point" value={(col) => currencyFormat(justNumbers(col.point))+" Point"}/>
                                                            <ExcelColumn label="Tanggal Join Campaign" value={(col) => new Date(col.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(col.campaign_users[0].created_at).getMonth()]+ " "+ new Date(col.campaign_users[0].created_at).getFullYear()}/>
                                                            <ExcelColumn label="Status" value={(col) => col.campaign_users[0].status}/>
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                </div>
                                                <div className="page-item">
                                                    <form className="page-input">
                                                        <div className="form-group mb-0">
                                                            <label htmlFor="page-count" className="sr-only">Page</label>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                value={limit}
                                                                allowNegative={false}
                                                                onChange={onChangeLimit}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </form>
                                                    <div className="text-page">/ Page</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolbar-right">
                                            <div className="toolbar-inner-right">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group search-input">
                                                        <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                        <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                                        <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                } else if (appState.pageStatus == "Approve") {
                                    return <div>
                                        <div className="toolbar two">
                                            <div className="toolbar-left">
                                                <div className="toolbar-inner-left">
                                                    <CustomPillSelect data={staticInfluencer} title="Potential Influencer" onClick={(e) => editFilter(e)} onReset={(e) => resetFilter()} selected={is_potential_ig} width="300px" />
                                                    <div className="toolicon-wrapper status export">
                                                        <ExcelFile
                                                            element={
                                                                <div className="toolicon status export">
                                                                <span>Export</span>
                                                                </div>
                                                            }
                                                            filename={"user-campaign-" + dateNow + "-" + timeNow}
                                                            >
                                                            <ExcelSheet data={appState.data} name="User Campaign">
                                                                <ExcelColumn label="Name" value={(col) => col.full_name}/>
                                                                <ExcelColumn label="Email" value={(col) => col.email}/>
                                                                <ExcelColumn label="Date of Birth" value={(col) => col.dob}/>
                                                                <ExcelColumn label="Total Point" value={(col) => currencyFormat(justNumbers(col.point))+" Point"}/>
                                                                <ExcelColumn label="Tanggal Join Campaign" value={(col) => new Date(col.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(col.campaign_users[0].created_at).getMonth()]+ " "+ new Date(col.campaign_users[0].created_at).getFullYear()}/>
                                                                <ExcelColumn label="Status" value={(col) => col.campaign_users[0].status}/>
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                    <div className="page-item">
                                                        <form className="page-input">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="page-count" className="sr-only">Page</label>
                                                                <NumberFormat
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    decimalScale={0}
                                                                    displayType="input"
                                                                    type="text"
                                                                    value={limit}
                                                                    allowNegative={false}
                                                                    onChange={onChangeLimit}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </form>
                                                        <div className="text-page">/ Page</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-right">
                                                <div className="toolbar-inner-right">
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="form-group search-input">
                                                            <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                            <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                                            <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } else if (appState.pageStatus == "Send Product") {
                                    return <div>
                                        <div className="toolbar two">
                                            <div className="toolbar-left">
                                                <div className="toolbar-inner-left">
                                                    <CustomPillSelect data={staticInfluencer} title="Potential Influencer" onClick={(e) => editFilter(e)} onReset={(e) => resetFilter()} selected={is_potential_ig} width="300px" />
                                                    <div className="toolicon-wrapper status export">
                                                    <ReactHTMLTableToExcel
                                                        id="test-table-xls-button"
                                                        className="toolicon status export"
                                                        table="table-to-xls"
                                                        filename={"user-campaign-" + dateNow + "-" + timeNow}
                                                        sheet="User Campaign"
                                                        buttonText="Export"/>
                                                    </div>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="form-group search-input">
                                                            <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                            <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                                            <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                        </div>
                                                    </form>
                                                    <div className="page-item limit-detail-group">
                                                        <form className="page-input">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="page-count" className="sr-only">Page</label>
                                                                <NumberFormat
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    decimalScale={0}
                                                                    displayType="input"
                                                                    type="text"
                                                                    value={limit}
                                                                    allowNegative={false}
                                                                    onChange={onChangeLimit}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </form>
                                                        <div className="text-page">/ Page</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-right">
                                                <div className="toolbar-inner-right">
                                                    <div>
                                                        <button onClick={sendProduct} className="btn btn-primary">Kirim Product</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } else if (appState.pageStatus == "Draft Konten") {
                                    return <div>
                                        <div className="toolbar two">
                                            <div className="toolbar-left">
                                                <div className="toolbar-inner-left">
                                                    <CustomPillSelect data={staticInfluencer} title="Potential Influencer" onClick={(e) => editFilter(e)} onReset={(e) => resetFilter()} selected={is_potential_ig} width="300px" />
                                                    <div className="toolicon-wrapper status export">
                                                        <ExcelFile
                                                            element={
                                                                <div className="toolicon status export">
                                                                <span>Export</span>
                                                                </div>
                                                            }
                                                            filename={"user-campaign-" + dateNow + "-" + timeNow}
                                                            >
                                                            <ExcelSheet data={appState.data} name="User Campaign">
                                                                <ExcelColumn label="Name" value={(col) => col.full_name}/>
                                                                <ExcelColumn label="Email" value={(col) => col.email}/>
                                                                <ExcelColumn label="Date of Birth" value={(col) => col.dob}/>
                                                                <ExcelColumn label="Total Point" value={(col) => currencyFormat(justNumbers(col.point))+" Point"}/>
                                                                <ExcelColumn label="Tanggal Join Campaign" value={(col) => new Date(col.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(col.campaign_users[0].created_at).getMonth()]+ " "+ new Date(col.campaign_users[0].created_at).getFullYear()}/>
                                                                <ExcelColumn label="Status" value={(col) => "List Draft Content"}/>
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="form-group search-input">
                                                            <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                            <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                                            <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                        </div>
                                                    </form>
                                                    <div className="page-item limit-detail-group">
                                                        <form className="page-input">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="page-count" className="sr-only">Page</label>
                                                                <NumberFormat
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    decimalScale={0}
                                                                    displayType="input"
                                                                    type="text"
                                                                    value={limit}
                                                                    allowNegative={false}
                                                                    onChange={onChangeLimit}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </form>
                                                        <div className="text-page">/ Page</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-right">
                                                <div className="toolbar-inner-right">
                                                    <div>
                                                        <button onClick={approveDraft} className="btn btn-primary">Approve Draft Content</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } else if (appState.pageStatus == "Publish Konten") {
                                    return <div>
                                        <div className="toolbar two">
                                            <div className="toolbar-left">
                                                <div className="toolbar-inner-left">
                                                    <CustomPillSelect data={staticInfluencer} title="Potential Influencer" onClick={(e) => editFilter(e)} onReset={(e) => resetFilter()} selected={is_potential_ig} width="300px" />
                                                    <div className="toolicon-wrapper status export">
                                                        <ExcelFile
                                                            element={
                                                                <div className="toolicon status export">
                                                                <span>Export</span>
                                                                </div>
                                                            }
                                                            filename={"user-campaign-" + dateNow + "-" + timeNow}
                                                            >
                                                            <ExcelSheet data={appState.data} name="User Campaign">
                                                                <ExcelColumn label="Name" value={(col) => col.full_name}/>
                                                                <ExcelColumn label="Email" value={(col) => col.email}/>
                                                                <ExcelColumn label="Date of Birth" value={(col) => col.dob}/>
                                                                <ExcelColumn label="Total Point" value={(col) => currencyFormat(justNumbers(col.point))+" Point"}/>
                                                                <ExcelColumn label="Tanggal Join Campaign" value={(col) => new Date(col.campaign_users[0].created_at).getDate()+ " " + monthNames[new Date(col.campaign_users[0].created_at).getMonth()]+ " "+ new Date(col.campaign_users[0].created_at).getFullYear()}/>
                                                                <ExcelColumn label="Status" value={(col) => "Detail Content"}/>
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="form-group search-input">
                                                            <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                            <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                                            <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                                        </div>
                                                    </form>
                                                    <div className="page-item limit-detail-group">
                                                        <form className="page-input">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="page-count" className="sr-only">Page</label>
                                                                <NumberFormat
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    decimalScale={0}
                                                                    displayType="input"
                                                                    type="text"
                                                                    value={limit}
                                                                    allowNegative={false}
                                                                    onChange={onChangeLimit}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </form>
                                                        <div className="text-page">/ Page</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-right">
                                                <div className="toolbar-inner-right">
                                                    <div>
                                                        <button onClick={publishKonten} className="btn btn-primary">Done</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            })()}
                        </div>
                        <ListLoading isLoading={appState.loading} link_id={id} pageStatus={appState.pageStatus} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} campaign={appState.campaign} total={appState.total} set_page={(e)=>set_page(e)} justNumbers={justNumbers} currencyFormat={currencyFormat} sort={sort} setSort={(e) => setSort(e)} orderby={orderby} setOrderby={(e) => setOrderby(e)}/>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default ApproveCampaign;
