import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routes from "./routes";
import DashboardComponent from "./component/dashboard.component";

function App() {
    return (
        <BrowserRouter>
            <DashboardComponent
                content={<Routes/>}
            />
        </BrowserRouter>
    );
}

export default App;
