import React from "react";
import {Button, Collapse, Table, Tag} from 'antd';
import {CaretRightOutlined} from '@ant-design/icons';
import {getRecommendationBySkinType} from "../../api/recommendation.api";
import {Link} from "react-router-dom";

const {Panel} = Collapse;

class ProductRecommendationComponent extends React.Component {
    constructor() {
        super();

        this.state = {
            panelList: [
                {
                    key: 'skin-type',
                    headerName: 'Skin Type',
                    productRecommendationPanel: []
                }, {
                    key: 'skin-tone',
                    headerName: 'Skin Tone',
                    productRecommendationPanel: []
                }, {
                    key: 'skin-undertone',
                    headerName: 'Skin Undertone',
                    productRecommendationPanel: []
                }, {
                    key: 'skin-concern',
                    headerName: 'Skin Concern',
                    productRecommendationPanel: []
                }
            ],
            columnSkinType: [
                {title: 'Skin Type', dataIndex: 'name', key: 'name'},
                {
                    title: 'Product Recommendation',
                    dataIndex: 'recomendation',
                    key: 'recommendation',
                    render: (recomendation) => recomendation.map(item => <Tag color="#0e528a" style={{width: 70, textAlign: 'center'}}
                                                                              key={item.product_id}>{item.product_name}</Tag>)
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => (
                        <Link to={() => this.linkTo(record)}>
                            <Button>Edit</Button>
                        </Link>
                    )
                }
            ], columns: [
                {dataIndex: 'name', key: 'name'},
            ], columnShadeList: [
                {title: 'Shade', key: 'shade'},
            ],
            skinConditionKey: 'skin-type'
        }
    }

    linkTo = (record) => {
        const {skinConditionKey} = this.state
        return `edit/${skinConditionKey}/${record.id}`
    }

    componentDidMount(): void {
        this.getRecommendationList('skin-type')
    }

    getRecommendationList = () => {
        const {panelList} = this.state
        Promise.all([
            getRecommendationBySkinType('skin-type'),
            getRecommendationBySkinType('skin-tone'),
            getRecommendationBySkinType('skin-undertone'),
            getRecommendationBySkinType('skin-concern')])
            .then(response => {
                panelList[0].productRecommendationPanel = response[0].data
                panelList[1].productRecommendationPanel = response[1].data
                panelList[2].productRecommendationPanel = response[2].data
                panelList[3].productRecommendationPanel = response[3].data
                this.setState({panelList})
            })
            .catch(err => {
                console.log(err)
            })
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return true
    }

    onChangePanel = (value) => {
        let skinConditionKey = value[value.length-1]
        this.setState({skinConditionKey})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {panelList, columnSkinType} = this.state
        return (
            <>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['skin-type']}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    onChange={this.onChangePanel}
                    className="site-collapse-custom-collapse">
                    {

                        panelList.map(item => (
                            <Panel header={item.headerName} key={item.key}>
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columnSkinType}
                                    dataSource={item.productRecommendationPanel}
                                    pagination={false}
                                    rowKey={'id'}
                                />
                            </Panel>
                        ))
                    }
                </Collapse>
            </>
        )
    }
}

export default ProductRecommendationComponent
