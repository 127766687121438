import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import InterestCategoryFormComponent from "./interest-category-form.component";
import {createInterestCategoryApi} from "../../api/interest-category.api";

class CreateInterestCategoryPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            loading: false
        }
    }

    onFinishCreateInterestCategory = (values, fileImage) => {
        if(fileImage.length < 1){
            alert('Anda belum mencantumkan image')
        } else {
            this.setState({loading: true})
            const form = new FormData();

            form.append('name', values.name)
            form.append('image', fileImage[0])

            createInterestCategoryApi(form)
                .then(result => {
                    alert("Interest category berhasil di upload")
                    this.setState({loading: false})
                    window.location.href = '/interest-category'
                }).catch(err => {
                console.log(err)
            })
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {loading} = this.state
        return (
            <>
                <PageHeaderComponent title={'Create Interest Category'} />
                <InterestCategoryFormComponent onFinish={this.onFinishCreateInterestCategory} labelCol={2} wrapperCol={10} loading={loading}/>
            </>
        )
    }
}

export default CreateInterestCategoryPage
