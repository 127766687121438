import React from "react";
import BannerListComponent from "./banner-list.component";
import PageHeaderComponent from "../../component/page-header.component";

class BannerListPage extends React.PureComponent {
    render() {
        return (
            <>
                <PageHeaderComponent title={'Banner List'}/>
                <BannerListComponent/>
            </>
        )
    }
}

export default BannerListPage
