import React from "react";
import InterestListComponent from "./interest-list.component";
import PageHeaderComponent from "../../component/page-header.component";

class InterestPage extends React.PureComponent {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <PageHeaderComponent title={'Interest List'}/>
                <InterestListComponent/>
            </>
        )
    }
}

export default InterestPage
