import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const registerUserApi = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/register`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const loginUserApi = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/login`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const forgotPasswordApi = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/forgot-password`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const changePasswordApi = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/update-password`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getAllUserApi = (params, bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user/search`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            },
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getCityApi = () => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/city`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const getUserListExportApi = (bodyRequest) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user/export`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const getDetailAdminApi = () => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const getUserDetailApi = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.user)
            }).catch(err => {
            reject(err)
        })
    }))
}

const getUserListBySearchEmailApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user/get-by-email`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.user)
            }).catch(err => {
            reject(err)
        })
    }))
}

const getUserListReferralIdByEmailApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user/get-by-email-referral`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    }))
}

const searchUserByEmailApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user/get-by-email`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.user)
            }).catch(err => {
            reject(err)
        })
    }))
}

export {
    registerUserApi,
    loginUserApi,
    forgotPasswordApi,
    changePasswordApi,
    getAllUserApi,
    getCityApi,
    getUserListExportApi,
    getDetailAdminApi,
    getUserDetailApi,
    getUserListBySearchEmailApi,
    getUserListReferralIdByEmailApi,
    searchUserByEmailApi
}
