import React from "react";
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";

const {Sider} = Layout;
const {SubMenu} = Menu;

class SideBarComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            listMenu: [
                {
                    key: 'users',
                    title: 'User',
                    subMenu: [
                        {
                            key: '/users/',
                            params: '',
                            label: 'User List'
                        }, {
                            key: '/tracking/',
                            params: 'tracking',
                            label: 'Tracking User'
                        }
                    ]
                }, {
                    key: 'banners',
                    title: 'Banner',
                    subMenu: [
                        {
                            key: '/banners/create',
                            params: 'create',
                            label: 'Create Banner'
                        }, {
                            key: '/banners/',
                            params: '',
                            label: 'Banner List'
                        }, {
                            key: '/banners/refer',
                            params: 'refer',
                            label: 'Refer A Friend '
                        }
                    ]
                }, {
                    key: 'interest-category',
                    title: 'Interest Category',
                    subMenu: [
                        {
                            key: '/interest-category/create',
                            params: 'create',
                            label: 'Create Interest Category'
                        }, {
                            key: '/interest-category/',
                            params: '',
                            label: 'Interest Category List'
                        }
                    ]
                }, {
                    key: 'interest',
                    title: 'Interest',
                    subMenu: [
                        {
                            key: '/interest/create',
                            params: 'create',
                            label: 'Create Interest'
                        }, {
                            key: '/interest/',
                            params: '',
                            label: 'Interest List'
                        }, {
                            key: '/interest/recommendation',
                            params: 'recommendation',
                            label: 'Interest Recommendation'
                        }
                    ]
                }, {
                    key: 'recommendation',
                    title: 'Recommendation',
                    subMenu: [
                        {
                            key: '/recommendation/create',
                            params: 'create',
                            label: 'Create Skin Condition'
                        }, {
                            key: '/recommendation/',
                            params: '',
                            label: 'Beauty Profile'
                        }, {
                            key: '/recommendation/product-recommendation',
                            params: 'product-recommendation',
                            label: 'Product Recommendation'
                        }
                    ]
                }, {
                    key: 'point',
                    title: 'Point',
                    subMenu: [
                       {
                            key: '/point/',
                            params: '',
                            label: 'Point Setting'
                        }
                    ]
                }, {
                    key: 'level',
                    title: 'User Level',
                    subMenu: [
                        {
                            key: '/level/create',
                            params: 'create',
                            label: 'Create Level'
                        }, {
                            key: '/level/',
                            params: '',
                            label: 'Level Setting'
                        }
                    ]
                }, {
                    key: 'reward',
                    title: 'Reward',
                    subMenu: [
                        {
                            key: '/reward/create',
                            params: 'create',
                            label: 'Create Reward'
                        }, {
                            key: '/reward/',
                            params: '',
                            label: 'Reward List'
                        }, {
                            key: '/reward/redeem-reward',
                            params: 'redeem-reward',
                            label: 'Redeem Reward'
                        }
                    ]
                }, {
                    key: 'notification',
                    title: 'Notification',
                    subMenu: [
                        {
                            key: '/notification/create',
                            params: 'create',
                            label: 'Create Notification'
                        }, {
                            key: '/notification/',
                            params: '',
                            label: 'Notification List'
                        }
                    ]
                }, {
                    key: 'admin',
                    title: 'Admin',
                    subMenu: [
                        {
                            key: '/admin/change-password',
                            params: 'change-password',
                            label: 'Change Password'
                        }, {
                            key: '/admin/register',
                            params: 'register',
                            label: 'Register User'
                        },
                    ]
                },
            ],
            path: '/users/'
        }
    }

    componentDidMount() {
        const path = window.location.pathname
        this.setState({path})
    }

    onClickMenu = (item) => {
        this.setState({path: item.key})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {listMenu, path} = this.state
        const pathRaw = window.location.pathname.split('/')
        const pathName = pathRaw[1]
        return (
            <Sider style={{backgroundColor: 'white'}}>
                <Menu mode="inline" defaultOpenKeys={[pathName]} selectedKeys={[path]} onClick={this.onClickMenu}>
                    {listMenu.map((menu) => (
                        <SubMenu key={menu.key} title={menu.title} multiple={true}>
                            {menu.subMenu.map((submenu, index) => (
                                <Menu.Item key={submenu.key}>
                                    <Link to={'/' + menu.key + '/' + submenu.params}>
                                        {submenu.label}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    ))}
                    <li className="ant-menu-submenu ant-menu-submenu-inline" role="menuitem"><div className="ant-menu-submenu-title"><a href="/mission-registered" className="link-menu-old">Mission Registered</a></div></li>
                </Menu>
            </Sider>
        )
    }
}

export default SideBarComponent
