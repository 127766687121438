import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import List from './List';
import Loading from '../../component/Loading';
import { getUserApprovedApi } from "../../api/goddess-gang.api";
import {useHistory, useLocation} from 'react-router';

import {cloudFunctionBaseUrl} from "../../ui-util/general-variable";


const token = localStorage.getItem('TOKEN_AUTH');
function GoddessGang() {
    const firstMount = useRef(true)
    const ListLoading                 = Loading(List);
    const [limit, setLimit]           = useState(5);
    const [city, setCity]             = useState(null);
    const [interest, setInterest]     = useState(null);
    const [socmed, setSocmed]         = useState(null);
    const [page, setPage]         = useState(null);
    const [sort, setSort] = useState("desc");
    const [orderby, setOrderby] = useState("full_name");
    const [ranges, setRanges]         = useState({
        ig_followers_min: 0,
        ig_followers_max: '',
        ig_er_min: 0,
        ig_er_max: '',
        ig_reach_min: 0,
        ig_reach_min: '',
        tiktok_followers_min: 0,
        tiktok_followers_max: '',
        tiktok_er_min: 0,
        tiktok_er_max: '',
        tiktok_reach_min: 0,
        tiktok_reach_min: '',
    })
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        group: null,
        campaign: null,
        sosmed: null,
        interest: null,
        city: null,
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1
    });
    const fetchData = async () => {
        setAppState({ loading: true});
        let params = {
            size: limit,
            page: page,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ranges.ig_followers_min,
            ig_followers_max: ranges.ig_followers_max,
            ig_er_min: ranges.ig_er_min,
            ig_er_max: ranges.ig_er_max,
            ig_reach_min: ranges.ig_reach_min,
            ig_reach_max: ranges.ig_reach_max,
            tiktok_followers_min: ranges.tiktok_followers_min,
            tiktok_followers_max: ranges.tiktok_followers_max,
            tiktok_er_min: ranges.tiktok_er_min,
            tiktok_er_max: ranges.tiktok_er_max,
            tiktok_reach_min: ranges.tiktok_reach_min,
            tiktok_reach_max: ranges.tiktok_reach_max,
            city_id: city,
            interest_id: interest,
            sosmed_id: socmed,
        }
        const respUser= await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=approve`, {
            params,
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        const respGroup = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/group`, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        const respCampaign = await axios.get(
        `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/approvement`, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': token
            },
        })
        const respSosmed = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/social-media/all`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const respInterest = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/interest`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const respCity = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/city`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const cityTemp = respCity.data.cities.map(data => ({"value":data.id,"text":`${data.type} ${data.name}`}))
        const interestTemp = respInterest.data.result.map(data => ({"value":data.id,"text":data.name}))
        const socmedTemp = respSosmed.data.data.map(data => ({"value":data.id,"text":data.name}))
        setAppState({ loading: false, data: respUser.data.users.rows, total: respUser.data.users.count, per_page: limit, current_page: appState.current_page, group: respGroup.data.data.rows, campaign: respCampaign.data.data.rows, sosmed: socmedTemp, interest: interestTemp, city: cityTemp  });
    };
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true, group: appState.group, campaign: appState.campaign, sosmed: appState.sosmed, city : appState.city, interest: appState.interest});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                ig_followers_min: ranges.ig_followers_min,
                ig_followers_max: ranges.ig_followers_max,
                ig_er_min: ranges.ig_er_min,
                ig_er_max: ranges.ig_er_max,
                ig_reach_min: ranges.ig_reach_min,
                ig_reach_max: ranges.ig_reach_max,
                tiktok_followers_min: ranges.tiktok_followers_min,
                tiktok_followers_max: ranges.tiktok_followers_max,
                tiktok_er_min: ranges.tiktok_er_min,
                tiktok_er_max: ranges.tiktok_er_max,
                tiktok_reach_min: ranges.tiktok_reach_min,
                tiktok_reach_max: ranges.tiktok_reach_max
            }
            getUserApprovedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: selectedPage, group: appState.group, campaign: appState.campaign, sosmed: appState.sosmed, city : appState.city, interest: appState.interest});
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }

    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
            return 
        }
        set_page(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges])

    // useEffect(() => {
    //     setAppState({ loading: true});
    //     fetchData();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [city, interest, socmed]);

    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sosmed: socmed,
                interest: interest,
                ig_followers_min: ranges.ig_followers_min,
                ig_followers_max: ranges.ig_followers_max,
                ig_er_min: ranges.ig_er_min,
                ig_er_max: ranges.ig_er_max,
                ig_reach_min: ranges.ig_reach_min,
                ig_reach_max: ranges.ig_reach_max,
                tiktok_followers_min: ranges.tiktok_followers_min,
                tiktok_followers_max: ranges.tiktok_followers_max,
                tiktok_er_min: ranges.tiktok_er_min,
                tiktok_er_max: ranges.tiktok_er_max,
                tiktok_reach_min: ranges.tiktok_reach_min,
                tiktok_reach_max: ranges.tiktok_reach_max,
                city: city,
                sort: sort
            }
            const respUser= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=approve`, {
                params,
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respGroup = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respCampaign = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/ready-to-invite`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respSosmed = await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/social-media/all`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            const respInterest = await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/interest-affable`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            const respCity = await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/city`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
                })
            const cityTemp = respCity.data.cities.map(data => ({"value":data.id,"text":`${data.type} ${data.name}`}))
            const interestTemp = respInterest.data.result.map(data => ({"value":data.id,"text":data.name}))
            const socmedTemp = respSosmed.data.data.map(data => ({"value":data.id,"text":data.name}))
            setAppState({ loading: false, data: respUser.data.users.rows, total: respUser.data.users.count, per_page: limit, current_page: appState.current_page, group: respGroup.data.data.rows, campaign: respCampaign.data.data.rows, sosmed: socmedTemp, interest: interestTemp, city: cityTemp  });
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
        
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    return ( 
        <div id="main-content">
            <ListLoading isLoading={appState.loading} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} group={appState.group} campaign={appState.campaign} total={appState.total} set_page={(e)=>set_page(e)} formatFollower={formatFollower} ranges={ranges} setRanges={setRanges} fetchData={fetchData} cityData={appState.city} sosmedData={appState.sosmed} interestData={appState.interest} city={city} sosmed={socmed} interest={interest} sort={sort} setSort={setSort} orderby={orderby} setOrderby={setOrderby}/>
        </div>
    );
}


export default GoddessGang;
