import React from "react";
import {Col, Image, List, Row} from "antd";
import {formatDateToReadable} from "../../ui-util/general-variable";

class NotificationDetailComponent extends React.PureComponent{

    constructor() {
        super();

        this.state = {
            notificationDetailItem: [
                {key: 'title', name: 'Title'},
                {key: 'description', name: 'Description'},
                {key: 'specifiedFor', name: 'Specified For'},
                {key: 'createdAt', name: 'Date'},
                {key: 'created_at', name: 'Join Date'},
                {key: 'image', name: 'Image'},
                {key: 'receiverId', name: 'Receiver Id'},
            ],
        }
    }

    renderItemValue = (item) => {
        const {notificationDetail} = this.props
        switch (item.key) {
            case 'createdAt':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{formatDateToReadable(notificationDetail[item.key])}</p>
                        </Col>
                    </Row>
                )
            case 'image':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <Image
                                src={notificationDetail[item.key]}
                                width={100}
                            />
                        </Col>
                    </Row>
                )
            case 'receiverId':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <List
                                itemLayout="horizontal"
                                dataSource={notificationDetail[item.key]}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={item}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                )
            default:
                if (!!notificationDetail[item.key]) {
                    return (
                        <Row key={item.key} gutter={16}>
                            <Col className={'gutter-row'} span={10}>
                                <p>{item.name}</p>
                            </Col>
                            <Col className={'gutter-row'} span={14}>
                                <p>{notificationDetail[item.key]}</p>
                            </Col>
                        </Row>
                    )
                } else {
                    return null
                }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {notificationDetailItem} = this.state
        return (
            <Row gutter={16}>
                <Col span={24}>
                    {notificationDetailItem.map(item => this.renderItemValue(item))}
                </Col>
            </Row>
        )
    }
}

export default NotificationDetailComponent
