import React from "react";
import {Button, Modal} from "antd";

class StandardAlertModal extends React.PureComponent{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {modalVisibility, changeVisibility, title, bodyMessage, onOkButtonHandler, skinConditionKey, loading} = this.props
        return (
            <Modal
                visible={modalVisibility}
                title={title}
                onCancel={() => changeVisibility(skinConditionKey,false)}
                onOk={() => onOkButtonHandler()}
                footer={[
                    <Button key="back" onClick={() => changeVisibility(skinConditionKey,false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={() => onOkButtonHandler()}>
                        Ok
                    </Button>,
                ]}
            >
                <p>{bodyMessage}</p>
            </Modal>
        )
    }
}

export default StandardAlertModal
