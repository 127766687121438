import React from 'react'
import RegisterFormComponent from "./register-form.component";

class RegisterPage extends React.PureComponent {
    render() {
        return (
            <div className="App">
                {/*<div className="auth-inner">*/}
                    <RegisterFormComponent/>
                {/*</div>*/}
            </div>
        )
    }
}

export default RegisterPage
