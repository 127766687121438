import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import List from './List';
import Loading from '../../../../component/Loading';
import { getGroupUserApi } from "../../../../api/group.api";
import {useHistory, useLocation} from 'react-router';
import {cloudFunctionBaseUrl} from "../../../../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');
function DetailGoddessGangGroup({ match }) {
    const firstMount = useRef(true)
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [search, setSearch]         = useState('');
    const [limit, setLimit]           = useState(5);
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('created_at');
    const [allUser, setAllUser]       = useState([]);
    const [ranges, setRanges]         = useState({
        ig_followers_min: 0,
        ig_followers_max: '',
        ig_er_min: 0,
        ig_er_max: '',
        ig_reach_min: 0,
        ig_reach_min: '',
        tiktok_followers_min: 0,
        tiktok_followers_max: '',
        tiktok_er_min: 0,
        tiktok_er_max: '',
        tiktok_reach_min: 0,
        tiktok_reach_min: '',
    })
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        group: null,
        campaign: null,
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1
    });
    const { params: { id }, } = match;
    function onChangeSearch() {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                ig_followers: ranges.ig_followers,
                ig_er: ranges.ig_er,
                ig_react: ranges.ig_react,
                tiktok_followers: ranges.tiktok_followers,
                tiktok_er: ranges.tiktok_er,
                tiktok_reach: ranges.tiktok_reach,
                key: search
            }
            getGroupUserApi(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, status: appState.status, campaign: appState.campaign, group: appState.group });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                ig_followers: ranges.ig_followers,
                ig_er: ranges.ig_er,
                ig_react: ranges.ig_react,
                tiktok_followers: ranges.tiktok_followers,
                tiktok_er: ranges.tiktok_er,
                tiktok_reach: ranges.tiktok_reach,
                key: search
            }
            getGroupUserApi(id, params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: selectedPage, status: appState.status, campaign: appState.campaign, group: appState.group });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const fetchData = async () => {
        setAppState({ loading: true});
        let params = {
            size: limit,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ranges.ig_followers_min,
            ig_followers_max: ranges.ig_followers_max,
            ig_er_min: ranges.ig_er_min,
            ig_er_max: ranges.ig_er_max,
            ig_reach_min: ranges.ig_reach_min,
            ig_reach_max: ranges.ig_reach_max,
            tiktok_followers_min: ranges.tiktok_followers_min,
            tiktok_followers_max: ranges.tiktok_followers_max,
            tiktok_er_min: ranges.tiktok_er_min,
            tiktok_er_max: ranges.tiktok_er_max,
            tiktok_reach_min: ranges.tiktok_reach_min,
            tiktok_reach_max: ranges.tiktok_reach_max,
            key: search
        }
        getGroupUserApi(id, params)
        .then(result => {
            setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page });
        }).catch(err => {
            console.log(err)
        })
    };
    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
            return 
        }
        set_page(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges])
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
            }
            const respDetailGroup= await axios.get(
                `${cloudFunctionBaseUrl()}/api/v2/admin/group/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'Authorization': token
                    },
            })
            const respUserGroup = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/user/${id}`, {
                params,
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            delete params.size
            const respAllUserGroup = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/group/user/${id}`, {
                params,
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            const respCampaign = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/approvement`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
            setAllUser(respAllUserGroup.data.users.rows);
            setAppState({ loading: false, group: respDetailGroup.data.data, data: respUserGroup.data.users.rows, total: respUserGroup.data.users.count, per_page: limit, current_page: appState.current_page, campaign: respCampaign.data.data.rows  });
        };
        fetchData();
    }, []);
     // Configuration    
     const COUNT_FORMATS =
     [
         { // 0 - 999
             letter: '',
             limit: 1e3
         },
         { // 1,000 - 999,999
             letter: 'K',
             limit: 1e6
         },
         { // 1,000,000 - 999,999,999
             letter: 'M',
             limit: 1e9
         },
         { // 1,000,000,000 - 999,999,999,999
             letter: 'B',
             limit: 1e12
         },
         { // 1,000,000,000,000 - 999,999,999,999,999
             letter: 'T',
             limit: 1e15
         }
     ];
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    return ( 
        <div id="main-content">     
            <ListLoading isLoading={appState.loading} currencyFormat={currencyFormat} group_id={id} campaign={appState.campaign} group={appState.group} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} total={appState.total} set_page={(e)=>set_page(e)} ranges={ranges} setRanges={setRanges} search={search} setSearch={setSearch} onChangeSearch={onChangeSearch} formatFollower={formatFollower} allUser={allUser} limit={limit} orderby={orderby} ranges={ranges} groupId={id} monthNames={monthNames} orderby={orderby} sort={sort}/>
        </div>
    );
}


export default DetailGoddessGangGroup;
