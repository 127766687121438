import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import { deleteUserGroupApi, getGroupUserApi, setSendEmailApi } from "../../../../api/group.api";
import { Controller, useForm } from "react-hook-form";
import { Popover } from 'antd';
import ReactPaginate from "react-paginate";
import ReactExport from "react-data-export";
import { Slider } from "antd";
import SortArrow from "../../../../component/Common/sort-arrow";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const formatSliderValue = (str) => {
  if (typeof str !== "string") return [undefined, undefined];
  const [a, b] = str.split("-");
  if (Number.isNaN(parseInt(a, 10))) return [undefined, undefined];
  if (Number.isNaN(parseInt(b, 10))) return [undefined, undefined];
  return [a, b];
};

const List = (props) => {
  const {
    data,
    total,
    current_page,
    per_page,
    campaign,
    group,
    group_id,
    formatFollower,
    allUser,
    ranges,
    groupId,
    monthNames,
    currencyFormat
  } = props;
  const [search, setSearch]         = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isEmail, setIsEmail] = useState([]);
  const [user_id, setUser_id] = useState("");
  const [ig_followers_min, setIgFollowersMin]            = useState(0);
  const [ig_followers_max, setIgFollowersMax]            = useState('');
  const [ig_er_min, setIgErMin]                          = useState(0);
  const [ig_er_max, setIgErMax]                          = useState('');
  const [ig_reach_min, setIgReachMin]                    = useState(0);
  const [ig_reach_max, setIgReachMax]                    = useState('');
  const [tiktok_followers_min, setTiktokFollowersMin]    = useState(0);
  const [tiktok_followers_max, setTiktokFollowersMax]    = useState('');
  const [tiktok_er_min, setTiktokErMin]                  = useState(0);
  const [tiktok_er_max, setTiktokErMax]                  = useState('');
  const [tiktok_reach_min, setTiktokReachMin]            = useState(0);
  const [tiktok_reach_max, setTiktokReachMax]            = useState('');
  const [sort, setSort]                                  = useState('desc');
  const [orderby, setOrderby]                            = useState('created_at');
  const [filterSosmedOpen, setFilterSosmedOpen] = useState(false);
  let userData = data;
  const [appState, setAppState] = useState({
    dataState: data,
    campaigns: campaign,
    group: group,
    showCampaign: false,
    current_page: current_page,
    total: total,
    per_page: per_page,
    disabled: false,
  });
  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ mode: "all" });
  let today = new Date();
  let dateNow = today
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })
    .replace(/[^\d,.-]/g, "-");
  let timeNow =
  today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
  function updateSorting (value) {
    setSort(value.sort)
    setOrderby(value.orderBy)
    let params = {
        size: appState.per_page,
        orderby: value.orderBy,
        sort: value.sort,
        ig_followers_min: ig_followers_min,
        ig_followers_max: ig_followers_max,
        ig_er_min: ig_er_min,
        ig_er_max: ig_er_max,
        ig_reach_min: ig_reach_min,
        ig_reach_max: ig_reach_max,
        tiktok_followers_min: tiktok_followers_min,
        tiktok_followers_max: tiktok_followers_max,
        tiktok_er_min: tiktok_er_min,
        tiktok_er_max: tiktok_er_max,
        tiktok_reach_min: tiktok_reach_min,
        tiktok_reach_max: tiktok_reach_max,
        key: search
    }
    getGroupUserApi(group_id, params)
    .then(result => {
        userData = result.users.rows;
        setAppState({dataState: userData,
          total: result.users.count,
          per_page: appState.per_page,
          current_page: appState.current_page})
    }).catch(err => {
        console.log(err)
    })
    setIsCheckAll(false)
  }
  function FilterRange(){
      setAppState({ loading: true});
      setFilterSosmedOpen(false);
      const fetchData = async () => {
          let params = {
              size: appState.per_page,
              orderby: orderby,
              sort: sort,
              ig_followers_min: ig_followers_min,
              ig_followers_max: ig_followers_max,
              ig_er_min: ig_er_min,
              ig_er_max: ig_er_max,
              ig_reach_min: ig_reach_min,
              ig_reach_max: ig_reach_max,
              tiktok_followers_min: tiktok_followers_min,
              tiktok_followers_max: tiktok_followers_max,
              tiktok_er_min: tiktok_er_min,
              tiktok_er_max: tiktok_er_max,
              tiktok_reach_min: tiktok_reach_min,
              tiktok_reach_max: tiktok_reach_max,
              key: search
          }
          getGroupUserApi(group_id, params)
              .then(result => {
                  userData = result.users.rows;
                  setAppState({dataState: userData,
                    total: result.users.count,
                    per_page: appState.per_page,
                    current_page: appState.current_page})
              }).catch(err => {
                  console.log(err)
              })
          };
          setIsCheckAll(false)
      fetchData();
  }
  function resetIg() {
    const ig_followers_min = 0
    setIgFollowersMin(ig_followers_min)
    const ig_followers_max = ''
    setIgFollowersMax(ig_followers_max)
    const ig_er_min = 0
    setIgErMin(ig_er_min)
    const ig_er_max = ''
    setIgErMax(ig_er_max)
    const ig_reach_min = 0
    setIgReachMin(ig_reach_min)
    const ig_reach_max = ''
    setIgReachMax(ig_reach_max)
    const fetchData = async () => {
        let params = {
            size: appState.per_page,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: search
        }
        getGroupUserApi(group_id, params)
            .then(result => {
              userData = result.users.rows;
                setAppState({ loading: false, dataState: userData, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page });
            }).catch(err => {
                console.log(err)
            })
        };
    fetchData()
  }
  function resetTiktok() {
    const tiktok_followers_min = 0
    setTiktokFollowersMin(tiktok_followers_min)
    const tiktok_followers_max = ''
    setTiktokFollowersMax(tiktok_followers_max)
    const tiktok_er_min = 0
    setTiktokErMin(tiktok_er_min)
    const tiktok_er_max = ''
    setTiktokErMax(tiktok_er_max)
    const tiktok_reach_min = 0
    setTiktokReachMin(tiktok_reach_min)
    const tiktok_reach_max = ''
    setTiktokReachMax(tiktok_reach_max)
    const fetchData = async () => {
        let params = {
            size: appState.per_page,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: search
        }
        getGroupUserApi(group_id, params)
            .then(result => {
                userData = result.users.rows;
                setAppState({ loading: false, dataState: userData, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page });
            }).catch(err => {
                console.log(err)
            })
        };
    fetchData()
  }
  const onSubmit = (data) => alert(JSON.stringify(data));
  const changeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  function onChangeSearch() {
    setAppState({ loading: true, current_page: appState.current_page, per_page: appState.per_page });
    setIsCheckAll(false)
    const fetchData = async () => {
        let params = {
            size: appState.per_page,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: search
        }
        getGroupUserApi(groupId, params)
          .then(result => {
            userData = result.users.rows;
            setAppState({ loading: false, dataState: userData, total: result.users.count, per_page: appState.per_page, current_page: appState.current_page });
          }).catch(err => {
              console.log(err)
          })
        };
      fetchData();
  }
  function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 5) {
          const limit = 5;
          setTimeout(function () {
              setAppState({ dataState: appState.dataState, loading: true, current_page: 1, per_page: limit, group: appState.group});
              setIsCheckAll(false)
                  const fetchData = async () => {
                  let params = {
                      size: limit,
                      page: current_page,
                      orderby: orderby,
                      sort: sort,
                      ig_followers_min: ig_followers_min,
                      ig_followers_max: ig_followers_max,
                      ig_er_min: ig_er_min,
                      ig_er_max: ig_er_max,
                      ig_reach_min: ig_reach_min,
                      ig_reach_max: ig_reach_max,
                      tiktok_followers_min: tiktok_followers_min,
                      tiktok_followers_max: tiktok_followers_max,
                      tiktok_er_min: tiktok_er_min,
                      tiktok_er_max: tiktok_er_max,
                      tiktok_reach_min: tiktok_reach_min,
                      tiktok_reach_max: tiktok_reach_max,
                      key: search
                  }
                  getGroupUserApi(groupId, params)
                  .then(result => {
                      userData = result.users.rows;
                      setAppState({ loading: false, per_page: limit, current_page: 1, dataState: userData, total: result.users.count, group: appState.group });
                  }).catch(err => {
                      console.log(err)
                  })
                  };
              fetchData();
          },  3000);
        } else {
            const limit = e.target.value;
            setTimeout(function () {
              setAppState({ dataState: appState.dataState, loading: true, current_page: 1, per_page: limit, group: appState.group});
              setIsCheckAll(false)
                  const fetchData = async () => {
                  let params = {
                      size: limit,
                      page: current_page,
                      orderby: orderby,
                      sort: sort,
                      ig_followers_min: ig_followers_min,
                      ig_followers_max: ig_followers_max,
                      ig_er_min: ig_er_min,
                      ig_er_max: ig_er_max,
                      ig_reach_min: ig_reach_min,
                      ig_reach_max: ig_reach_max,
                      tiktok_followers_min: tiktok_followers_min,
                      tiktok_followers_max: tiktok_followers_max,
                      tiktok_er_min: tiktok_er_min,
                      tiktok_er_max: tiktok_er_max,
                      tiktok_reach_min: tiktok_reach_min,
                      tiktok_reach_max: tiktok_reach_max,
                      key: search
                  }
                  getGroupUserApi(groupId, params)
                  .then(result => {
                      userData = result.users.rows;
                      setAppState({ loading: false, per_page: limit, current_page: 1, dataState: userData, total: result.users.count, group: appState.group });
                  }).catch(err => {
                      console.log(err)
                  })
                  };
              fetchData();
            },  3000);
        }
  };
  function setPage(e){
    const selectedPage = e
    setAppState({ loading: true, current_page: selectedPage, per_page: appState.per_page });
    setIsCheckAll(false)
    const fetchData = async () => {
        let params = {
            size: appState.per_page,
            page: selectedPage,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max,
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            key: search
        }
        getGroupUserApi(groupId, params)
            .then(result => {
              userData = result.users.rows;
              setAppState({ loading: false, dataState: userData, total: result.users.count, per_page: appState.per_page, current_page: selectedPage });
            }).catch(err => {
                console.log(err)
            })
        };
    fetchData();
}
  const changePage = (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  };
  const handleAllChecked = async (e) => {
    let isChecked = e.target.checked
    setIsCheckAll(!isCheckAll);
    let tempIsCheck = isCheck
    let tempIsEmail = isEmail
    if (isChecked) {
        userData.forEach(element => {
            let index = tempIsCheck.indexOf(element.id)
            if (index < 0) {
                tempIsCheck.push(element.id)
                tempIsEmail.push(element.email)
            }
        });
    } else {
        userData.forEach(element => {
            let index = tempIsCheck.indexOf(element.id)
            tempIsCheck.splice(index, 1)
            tempIsEmail.splice(index, 1)
        });
    }
    console.log(tempIsCheck);
    await setIsCheck(tempIsCheck);
    await setIsEmail(tempIsEmail);
  };
  const handleCheckChieldElement = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    let check = [...isCheck, id];
    if (!checked) {
      if(isCheckAll===true) {
          setIsCheckAll(!isCheckAll)
      }
      setIsCheck(isCheck.filter((item) => item !== id));
      check = isCheck.filter((item) => item !== id);
    }
  };
  const checkEmail = (e) => {
    const email = e.email
    setIsEmail([...isEmail, email]);
  };
  console.log(isEmail)
  function deleteUser() {
    setAppState({
      loading: true,
      disabled: true,
      campaigns: appState.campaigns,
      group: appState.group,
      total: appState.total,
      current_page: appState.current_page,
      dataState: userData,
    });
    swal({
      title: "Confirmation",
      text: "Are you sure delete user group ?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((isConfirm) => {
      if (!isConfirm) {
        return false;
      }
      setAppState({
        loading: true,
        disabled: true,
        campaigns: appState.campaigns,
        group: appState.group,
        total: appState.total,
        current_page: appState.current_page,
        dataState: userData,
      });
      let bodyRequest = [];
      const user_id = isCheck;
      let values = {
        group_id: group_id,
        user_ids: [user_id],
      };
      bodyRequest.push(values);
      deleteUserGroupApi(values).then((repos) => {
        const data = repos.message;
        if (repos.code == 201) {
          swal({
            title: "Done!",
            text: data,
            icon: "success",
            timer: 3000,
            button: false,
          });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          swal({
            title: "Error!",
            text: data,
            icon: "error",
            button: true,
          });
        }
        setAppState({
          loading: false,
          campaigns: appState.campaigns,
          group: appState.group,
          total: appState.total,
          current_page: appState.current_page,
          dataState: appState.dataState,
        });
      });
    });
  }
  function sendToEmail() {
      let listUser = []
    if (isEmail.length > 0) {
        listUser = isEmail
    } else {
        listUser = allUser.map((row) => row.email)
    }
    let userString = listUser.join(",")
    window.open(
        `mailto:?to=${userString}`
      );
      
  }
  function sendEmail(e) {
    setAppState({
      loading: true,
      campaigns: appState.campaigns,
      group: appState.group,
      total: appState.total,
      current_page: appState.current_page,
      dataState: userData,
    });
    let bodyRequest = [];
    const campaign_id = e;
    let values = {
      group_id: group_id,
      user_ids: [user_id],
      campaign_ids: [campaign_id],
    };
    bodyRequest.push(values);
    setSendEmailApi(values).then((repos) => {
      const data = repos.message;
      if (repos.code == 200) {
        swal({
          title: "Done!",
          text: data,
          icon: "success",
          timer: 3000,
          button: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal({
          title: "Error!",
          text: data,
          icon: "error",
          button: true,
        });
      }
      setAppState({
        loading: false,
        campaigns: appState.campaigns,
        group: appState.group,
        total: appState.total,
        current_page: appState.current_page,
        dataState: userData,
      });
    });
  }
  const page_count = appState.total / appState.per_page;
  // const MIN_VAL = 5;
  // const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
  return (
    <div>
      <div className="main-dash">
        {(() => {
          if (appState.group) {
            localStorage.setItem('group_id', appState.group.id)
            localStorage.setItem('group_name', appState.group.name)
            return (
              <div>
                <h4 className="font-medium mb-4">{appState.group.name}</h4>
                <div className="row mb-5">
                  <div className="col-3">
                    <h6 className="font-medium">
                      {appState.group.campaign_name}
                    </h6>
                    {(() => {
                      if (appState.group.created_at != null) {
                        return (
                          <div className="box-date">
                            <div className="fd">
                              <img
                                src="/img/ico-calendar.svg"
                                className="img-fluid red"
                              />
                            </div>
                            <div className="fd-date">{new Date(appState.group.created_at).getDate()+ " " + monthNames[new Date(appState.group.created_at).getMonth()]+ " "+ new Date(appState.group.created_at).getFullYear()}</div>
                          </div>
                        );
                      }
                    })()}
                    <div className="d-flex align-items-center mb-3">
                      {appState.group.group_users &&
                      appState.group.group_users.length > 0 ? (
                        appState.group.group_users
                          .slice(0, 3)
                          .map((item, index) => {
                            return (
                              <div className="img-group" key={index}>
                                {(() => {
                                  if (item.user != null) {
                                    return (
                                      <img
                                        src={item.user.photo}
                                        className="img-fluid img-circle"
                                      />
                                    );
                                  } else {
                                    return (
                                      <img
                                        src=""
                                        className="img-fluid img-circle"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                            );
                          })
                          ) : (
                            <div className="img-group"></div>
                            )}
                        <div className="ig-text">{appState.group.total_user==0||appState.group.total_user==null?"":currencyFormat(appState.group.total_user)+" Orang"}</div>
                    </div>
                  </div>
                  <div className="col-3">
                    <Link to="/goddess-gang" className="btn btn-primary">
                      <img src="/img/ico-add.svg" className="img-fluid white" />{" "}
                      Add Influencer
                    </Link>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">
                        <div className="mission-status">
                          <div className="font-medium">Total Missions</div>
                          <h5 className="font-medium red">
                            {appState.group.total_mission} Mission
                          </h5>
                          <div className="ms-icon">
                            <img
                              src="/img/ico-mission.svg"
                              className="img-fluid white"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mission-status">
                          <div className="font-medium">Total Ongoing</div>
                          <h5 className="font-medium red">
                            {appState.group.total_ongoing} Mission
                          </h5>
                          <div className="ms-icon">
                            <img
                              src="/img/ico-loading.svg"
                              className="img-fluid white"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mission-status">
                          <div className="font-medium">Total Done</div>
                          <h5 className="font-medium red">
                            {appState.group.total_done} Mission
                          </h5>
                          <div className="ms-icon">
                            <img
                              src="/img/ico-approve.svg"
                              className="img-fluid white"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })()}
        <div className="toolbar two">
          <div className="toolbar-left">
            <div className="toolbar-inner-left">
              <div className="toolicon-wrapper check-btn">
                <Popover
                  trigger="click"
                  placement="bottomLeft"
                  visible={filterSosmedOpen}
                  onVisibleChange={(visible) => {
                      setFilterSosmedOpen(visible);
                  }}
                  content={(
                      <div className="tool-wrapper" style={{ border: 'none', boxShadow: 'none', alignItems: 'flex-end', paddingLeft: 14 }}>
                        <div className="row">
                            <div className="col-6">
                                <div className="filter-head">
                                    <div className="font-medium">Instagram</div>
                                    <div>
                                        <button className="btn-reset-filter" onClick={resetIg}>Reset</button>
                                    </div>
                                </div>
                                <p>Followers</p>
                                <div className="row mb-4">
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={ig_followers_min}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setIgFollowersMin(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Min"
                                                className="form-control no-radius input-number"
                                            /> 
                                        </form>
                                    </div> - 
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={ig_followers_max}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setIgFollowersMax(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Max"
                                                className="form-control no-radius input-number"
                                            />   
                                        </form> 
                                    </div>
                                </div>
                                <p>ER</p>
                                <div className="row mb-4">
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={ig_er_min}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setIgErMin(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Min"
                                                className="form-control no-radius input-number"
                                            />   
                                        </form> 
                                    </div> - 
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={ig_er_max}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setIgErMax(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Max"
                                                className="form-control no-radius input-number"
                                            />   
                                        </form> 
                                    </div>
                                </div>
                                <p>Reach</p>
                                <div className="row mb-4">
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={ig_reach_min}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setIgReachMin(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Min"
                                                className="form-control no-radius input-number"
                                            />
                                        </form>    
                                    </div> - 
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={ig_reach_max}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setIgReachMax(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Max"
                                                className="form-control no-radius input-number"
                                            />
                                        </form>    
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="filter-head">
                                    <div className="font-medium">Tiktok</div>
                                    <div>
                                        <button className="btn-reset-filter" onClick={resetTiktok}>Reset</button>
                                    </div>
                                </div>
                                <p>Followers</p>
                                <div className="row mb-4">
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={tiktok_followers_min}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setTiktokFollowersMin(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Min"
                                                className="form-control no-radius input-number"
                                            />  
                                        </form>  
                                    </div> - 
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={tiktok_followers_max}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setTiktokFollowersMax(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Max"
                                                className="form-control no-radius input-number"
                                            />
                                        </form>    
                                    </div>
                                </div>
                                <p>ER</p>
                                <div className="row mb-4">
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={tiktok_er_min}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setTiktokErMin(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Min"
                                                className="form-control no-radius input-number"
                                            />    
                                        </form>
                                    </div> - 
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={tiktok_er_max}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setTiktokErMax(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Max"
                                                className="form-control no-radius input-number"
                                            />   
                                        </form> 
                                    </div>
                                </div>
                                <p>Reach</p>
                                <div className="row mb-4">
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={tiktok_reach_min}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setTiktokReachMin(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Min"
                                                className="form-control no-radius input-number"
                                            />
                                        </form>    
                                    </div> - 
                                    <div className="col-5">
                                        <form onSubmit={handleSubmit(FilterRange)}>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                value={tiktok_reach_max}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix=""
                                                decimalScale={0}
                                                displayType="input"
                                                type="text"
                                                allowNegative={false}
                                                onValueChange={
                                                    (value) => {
                                                        setTiktokReachMax(value.floatValue)
                                                    }
                                                }
                                                min="0"
                                                placeholder="Max"
                                                className="form-control no-radius input-number"
                                            />   
                                        </form> 
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    )}>
                    <label htmlFor="myid" className="toolicon filter" />
                  </Popover>
              </div>

              <div className="toolicon-wrapper status export">
                <ExcelFile
                  element={
                    <button className="toolicon status export">
                      <span>Export</span>
                    </button>
                  }
                  filename={"group-detail-list-" + dateNow + "-" + timeNow}
                >
                  <ExcelSheet data={userData} name="Group">
                    <ExcelColumn label="Name" value={(col) => col.full_name} />
                    <ExcelColumn label="Birth Date" value={(col) => col.dob} />
                    <ExcelColumn label="Email" value={(col) => col.email} />
                    <ExcelColumn
                      label="Register Season"
                      value={(col) => col.goddes_gang_session + "x"}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </div>
              <div className="toolicon-wrapper status ste">
                <button onClick={sendToEmail} className="toolicon status ste">
                  <span>Send Email</span>
                </button>
              </div>
              <div className="toolicon-wrapper status del">
                <button onClick={deleteUser} className="toolicon status del">
                  <span>Remove Selected</span>
                </button>
              </div>
              <div className="page-item limit-detail-group">
                  <form className="page-input">
                      <div className="form-group mb-0">
                          <label htmlFor="page-count" className="sr-only">Page</label>
                          <NumberFormat
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix=""
                            decimalScale={0}
                            displayType="input"
                            type="text"
                            value={appState.per_page}
                            allowNegative={false}
                            onChange={onChangeLimit}
                            className="form-control"
                          />
                      </div>
                  </form>
                  <div className="text-page">/ Page</div>
              </div>
            </div>
          </div>
          <div className="toolbar-right">
            <div className="toolbar-inner-right">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group search-input">
                  <label htmlFor="inputSearch" className="sr-only">
                    Search
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={changeSearch}
                    autoFocus
                  />
                  <button
                    className="btn btn-search"
                    onClick={handleSubmit(onChangeSearch)}
                  >
                    <img src="/img/ico-search.svg" alt="search" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end of toolbar*/}
      </div>
      <div className="content-wrapper">
      {(() => {
            if (userData){
            return <div>
                <div className="head-data-info">
                  <div className="tablerow-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="selectAll"
                        id="selectAll"
                        onChange={(e) => handleAllChecked(e)}
                        checked={isCheckAll}
                      />
                      <label className="custom-control-label" htmlFor="selectAll" />
                    </div>
                  </div>
                  <div className="tablerow-2">
                    User
                    <SortArrow
                        valueSort={sort}
                        valueOrderBy={"full_name"}
                        activeOrderBy={orderby}
                        onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-3">
                    <div className="d-flex align-items-end justify-content-center">
                      <div>
                        <img src="/img/insta-outline.svg" className="sosmed-icon" />
                      </div>
                      <div>Username</div>
                    </div>
                  </div>
                  <div className="tablerow-4">
                    Followers
                    <SortArrow
                        valueSort={sort}
                        valueOrderBy={"follower_instagram"}
                        activeOrderBy={orderby}
                        onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-5">ER
                    <SortArrow
                      valueSort={sort}
                      valueOrderBy={"er_instagram"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-6">Reach</div>
                  <div className="tablerow-7">
                    <div className="d-flex align-items-end justify-content-center">
                      <div>
                        <img src="/img/tiktik-outline.svg" className="sosmed-icon" />
                      </div>
                      <div>Username</div>
                    </div>
                  </div>
                  <div className="tablerow-8">Followers 
                  <SortArrow
                      valueSort={sort}
                      valueOrderBy={"follower_tiktok"}
                      activeOrderBy={orderby}
                      onClick={(e) => updateSorting(e)}
                  />
                  </div>
                  <div className="tablerow-9">
                    ER
                    <SortArrow
                        valueSort={sort}
                        valueOrderBy={"er_tiktok"}
                        activeOrderBy={orderby}
                        onClick={(e) => updateSorting(e)}
                    />
                  </div>
                  <div className="tablerow-10">Reach</div>
                  <div className="tablerow-11">Social Media</div>
                  <div className="tablerow-12">Register Season</div>
                  <div className="tablerow-13">Status</div>
                </div>
                {appState.dataState && appState.dataState.length > 0 ? appState.dataState.map((repo, index) => {
                  return (
                    <div className="data-items" key={index}>
                      <div className="di-01">
                        <div className="custom-control custom-checkbox" key={index}>
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={repo.id}
                            checked={isCheck.includes(repo.id)}
                            onChange={handleCheckChieldElement}
                            onClick={()=>checkEmail({email:repo.email})}
                          />
                          <label className="custom-control-label" htmlFor={repo.id} />
                        </div>
                      </div>
                      <div className="di-02">
                        <a
                          href={`/goddess-gang/${repo.id}/detail`}
                          className="link-user"
                        >
                          <div className="box-user">
                            <div className="img-part">
                              <img
                                src={repo.photo}
                                className="img-user img-fluid"
                                alt="user"
                              />
                            </div>
                            <div className="user-part">
                              <h5>
                                {repo.full_name}
                                {(() => {
                                  if (repo.is_potential == true) {
                                    return (
                                      <span className="tool-tip floating">
                                        <button className="btn tool-tip-icon">
                                          <img
                                            src="/img/ico-potential.svg"
                                            className="ico-potential"
                                          />
                                        </button>
                                        <div className="tool-tip-content right">
                                          <span>Influencer Potential</span>
                                        </div>
                                      </span>
                                    );
                                  } else {
                                    return <span></span>;
                                  }
                                })()}
                              </h5>
                              <p>{repo.dob}</p>
                              <p className="for-mail">{repo.email}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="di-03">
                        <p className="for-username">
                          {repo.username_instagram == null
                            ? "-"
                            : "@" + repo.username_instagram}
                        </p>
                      </div>
                      <div className="di-04">
                        {repo.follower_instagram == null
                          ? "-"
                          : formatFollower(repo.follower_instagram)}
                      </div>
                      <div className="di-05">
                        {repo.er_instagram == null
                          ? "-"
                          : formatFollower(repo.er_instagram)}
                      </div>
                      <div className="di-06">
                        {repo.reach_instagram == null
                          ? "-"
                          : formatFollower(repo.reach_instagram)}
                      </div>
                      <div className="di-07">
                        <p className="for-username">
                          {repo.username_tiktok == null
                            ? "-"
                            : "@" + repo.username_tiktok}
                        </p>
                      </div>
                      <div className="di-08">
                        {repo.follower_tiktok == null
                          ? "-"
                          : formatFollower(repo.follower_tiktok)}
                      </div>
                      <div className="di-09">
                        {repo.er_tiktok == null ? "-" : formatFollower(repo.er_tiktok)}
                      </div>
                      <div className="di-10">
                        {repo.reach_tiktok == null
                          ? "-"
                          : formatFollower(repo.reach_tiktok)}
                      </div>
                      <div className="di-11">
                        {repo.social_medias.map((item, index) => {
                          return (
                            <span key={index}>
                              {(() => {
                                if (item.user_social_medias.value != "") {
                                  return (
                                    <a
                                      href={item.user_social_medias.value}
                                      target="_blank"
                                      className="icon-sm"
                                    >
                                      <img src={item.logo} className="user-ico-sm" />
                                    </a>
                                  );
                                } else {
                                  return <span></span>;
                                }
                              })()}
                            </span>
                          );
                        })}
                      </div>
                      <div className="di-12">{repo.goddes_gang_session}X</div>
                      <div className="di-13">
                        <a href={`/goddess-gang/${repo.id}/detail`} className="gg-link">
                          Detail{" "}
                          <img src="/img/arrow-right.svg" className="gg-icon-arrow" />
                        </a>
                      </div>
                    </div>
                  );
                }): <p style={{ textAlign: "center", marginTop: 120, marginBottom: 120 }}>No data user detail group, sorry</p>
                }
                <div className="d-flex justify-content-center mt-5">
                  {(() => {
                    if (total < 5 || appState.total < 5) {
                      return (
                        <ReactPaginate
                          pageCount={1}
                          pageRangeDisplayed={4}
                          marginPagesDisplayed={1}
                          forcePage={appState.current_page - 1}
                          activeLinkClassName="active"
                          containerClassName="pagination"
                          previousLabel="&laquo;"
                          nextLabel="&raquo;"
                          onPageChange={changePage}
                        />
                      );
                    } else {
                      return (
                        <ReactPaginate
                          pageCount={Math.ceil(page_count)}
                          pageRangeDisplayed={4}
                          marginPagesDisplayed={1}
                          forcePage={appState.current_page - 1}
                          activeLinkClassName="active"
                          containerClassName="pagination"
                          previousLabel="&laquo;"
                          nextLabel="&raquo;"
                          onPageChange={changePage}
                        />
                      );
                    }
                  })()}
                </div>
            </div>
            } else {
              return <div className="content-wrapper">
                  <div className="credential pt-5">
                      <div className="credential-box-noborder">
                      <div className="load-2">
                          <p>Loading... Please wait</p>
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                      </div>
                      </div>
                  </div>
              </div>
              }
          })()}
      </div>
    </div>
  );
};

export default List;
