import React from "react";
import {Button, Form, Input, Row} from "antd";
import {UserOutlined} from '@ant-design/icons';
import {forgotPasswordApi} from "../../api/user.api";

class ForgotPasswordFormComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            loadings: false
        }
    }

    onFinishForgotPasswordForm = (values) => {
        this.setState({loadings: true})
        forgotPasswordApi(values)
            .then(result => {
                this.setState({loadings: false})
                alert('Email penggantian password sudah dikirim. Mohon periksa email anda secara berkala!')
                window.location.href = '/'
            }).catch(err => {
            console.log(err)
            this.setState({loadings: false})
            alert('Maaf terjadi kesalahan!')
        })
    }

    render() {
        const {loadings} = this.state
        return (
            <Form
                name="normal_login"
                className="login-form"
                onFinish={this.onFinishForgotPasswordForm}
            >
                <Form.Item
                    name={'email'}
                    rules={[{required: true, message: 'Input your email!'}]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder={'Email'}/>
                </Form.Item>
                <Form.Item style={{marginTop: 10}}>
                    <Row>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loadings} style={{marginRight: 10}}>
                            Submit
                        </Button>
                        <Button className="login-form-button" href={"/"}>
                            Login
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        );
    }
}

export default ForgotPasswordFormComponent
