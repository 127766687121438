import React, { useEffect, useState } from 'react';
import List from './List';
import Loading from '../../../component/Loading';
import { getDetailRedeemPointApi } from "../../../api/redeem-point.api";
import {useHistory, useLocation} from 'react-router';

function DetailRedeemPoint({ match }) {
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [limit, setLimit]           = useState(6);
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('id');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const [selectedPage, setPage]     = useState(1);
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        users: null,
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1
    });
    const { params: { id }, } = match;
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true, users: appState.users });
        const fetchData = async () => {
            let params = {
                status: "Approve",
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                user_id: id
            }
            getDetailRedeemPointApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.rows, users: result.user, total: result.count, per_page: limit, current_page: selectedPage });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                status: "Approve",
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                user_id: id
            }
            getDetailRedeemPointApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.rows, users: result.user, total: result.count, per_page: limit, current_page: appState.current_page });
                }).catch(err => {
                    console.log(err)
                })
            };
            fetchData();
        }, []);
        function currencyFormat(num) {
            return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        }
        function justNumbers(string) {
            var numsStr = string.replace(/\D/g,"");
            return parseInt(numsStr);
        }
        return ( 
            <div id="main-content">
            <div className="main-dash">
            {(() => {
                if (appState.users) {
                    var total_point = justNumbers(appState.users.total_point);
                return <div className="udr-head">
                    <div className="user-info">
                        <div className="d-flex align-items-center justify-content-between">
                            <div style={{width: '25%'}}><img src={appState.users.photo} className="img-fluid img-circle" /></div>
                            <div style={{width: '70%'}}>
                            <h2 className="font-medium">{appState.users.full_name}</h2>
                            <div className="d-flex justify-content-between">
                                <div style={{width: '49.5%'}}>
                                    <div className="mb-2">{appState.users.dob}</div>
                                    <div>{appState.users.phone}</div>
                                </div>
                                <div style={{width: '49.5%'}}>
                                    <div className="mb-2">{appState.users.email}</div>
                                    {(() => {
                                        if (appState.users.is_potential == 1) {
                                        return <div className="d-flex align-items-center">
                                            <div className="mr-2"><img src="/img/ico-potential.svg" className="img-fluid" width={15} style={{verticalAlign: 'text-top'}} /> </div>
                                            <div className="text-red font-medium">Potential Influencer</div>
                                        </div>
                                        } else {
                                           return <div className="d-flex align-items-center"></div> 
                                        }
                                    })()}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="bank-info">
                        <p className="font-medium">Detail Rekening</p>
                        {appState.users.user_banks.map((repo,index) => {
                        return (
                            <div className="box-rekening" key={index}>
                                <div style={{width: '33%', textAlign: 'center'}}>
                                    <div className="ft-med mb-2">Bank Tujuan</div>
                                    {(() => {
                                        if (repo.bank.logo != "") {
                                            return <img src={repo.bank.logo} className="img-fluid" width={80} />
                                        } else {
                                            return <div className="font-medium mb-2">{repo.bank.bank_name}</div>
                                        }
                                    })()}
                                </div>
                                <div style={{width: '33%', textAlign: 'center'}}>
                                    <div className="ft-med mb-2">Nomor Rekening</div>
                                    <div className="font-medium mb-2">{repo.account_number}</div>
                                </div>
                                <div style={{width: '33%', textAlign: 'center'}}>
                                    <div className="ft-med mb-2">Nama Pemilik</div>
                                    <div className="font-medium mb-2">{repo.account_name}</div>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                    <div className="dana-info">
                        <div className="d-flex justify-content-between">
                            <div style={{width: '49%'}}>
                                <p className="font-medium">Detail Reedem</p>
                                <div className="box-rp">
                                    <div className="font-medium">Total Point</div>
                                    <h4 className="font-medium">{currencyFormat(total_point)} Point</h4>
                                    <div className="for-ico-bottom">
                                    <span className="icon-content white" style={{fontSize: '30px'}} />
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '49%'}}>
                                <p className="font-medium">Dana yang di Reedem</p>
                                <div className="box-rp">
                                    <div className="font-medium">Total Dana</div>
                                    <h4 className="font-medium">Rp. {appState.users.total_dana_withdraw ? currencyFormat(appState.users.total_dana_withdraw): "0"}</h4>
                                    <div className="for-ico-bottom">
                                        <span className="icon-content white" style={{fontSize: '30px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                } else {
                return <div className="pt-4 pb-5">
                        <div className="credential-box-noborder">
                        <div className="load-2">
                            <p>Loading... Please wait</p>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        </div>
                    </div>
                    }
                })()}
                <ListLoading isLoading={appState.loading} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} total={appState.total} set_page={(e)=>set_page(e)} currencyFormat={currencyFormat}/> 
            </div>
        </div>
    );
}


export default DetailRedeemPoint;
