import React from "react";
import {Button, Table} from "antd";
import EditFormModal from "../../component/edit-form.modal";
import StandardAlertModal from "../../component/standard-alert.modal";
import LevelFormComponent from "../create-level/level-form.component";
import {deleteLevelApi, getLevelApi, updateLevelApi} from "../../api/level.api";

class UserLevelComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'Nama Level', dataIndex: 'name', key: 'userLevel'},
                {
                    title: 'Range Poin',
                    key: 'point',
                    render: (record) => `${!!record.minimum_point ? record.minimum_point : 0} - ${!!record.maximum_point ? record.maximum_point : 0}`
                }, {
                    title: 'Action', key: 'action',
                    render: (record) =>
                        <>
                            <Button htmlType="submit" style={{marginRight: 10}}
                                    onClick={() => this.changeVisibility('editUserLevelModalVisibility', true, record)}>Edit</Button>
                            <Button htmlType="submit"
                                    onClick={() => this.changeVisibility('deleteUserLevelModalVisibility', true, record)}>Delete</Button>
                        </>
                }
            ],
            editUserLevelModalVisibility: false,
            deleteUserLevelModalVisibility: false,
            levelList: [],
            levelDetail: {},
            loading: false,
            loadingDelete: false
        }
    }

    componentDidMount(): void {
        getLevelApi()
            .then(response => {
                this.setState({levelList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    changeVisibility = (key, value, levelDetail) => {
        this.setState({[key]: value, levelDetail})
    }

    onOkButtonHandler = () => {
        this.setState({loadingDelete: true})
        const {id} = this.state.levelDetail
        deleteLevelApi(id)
            .then(response => {
                this.setState({loadingDelete: false, deleteUserLevelModalVisibility: false})
                alert('Level berhasil diubah!')
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
            })
    }

    onFinishEditLevel = (values) => {
        this.setState({loading: true})
        const {id} = this.state.levelDetail
        updateLevelApi(id, values)
            .then(response => {
                this.setState({loading: false, editUserLevelModalVisibility: false})
                alert('Level berhasil diubah!')
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
            })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns, editUserLevelModalVisibility, levelDetail, deleteUserLevelModalVisibility, levelList, loading, loadingDelete} = this.state
        return (
            <>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={levelList}
                    pagination={false}
                    rowKey={'id'}
                />
                <EditFormModal
                    modalVisibility={editUserLevelModalVisibility}
                    modalVisibilityKey={"editUserLevelModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    title={`Edit Level`}
                    content={<LevelFormComponent loading={loading} onFinish={this.onFinishEditLevel} levelDetail={levelDetail} labelCol={6}/>}
                />
                <StandardAlertModal
                    loading={loadingDelete}
                    onOkButtonHandler={this.onOkButtonHandler}
                    skinConditionKey={"deleteUserLevelModalVisibility"}
                    changeVisibility={this.changeVisibility}
                    modalVisibility={deleteUserLevelModalVisibility}
                    title={`Delete User Level`}
                    bodyMessage={`Apakah anda yakin ingin menghapus level ini?`}
                />
            </>
        )
    }
}

export default UserLevelComponent
