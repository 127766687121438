import React, { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Popover } from 'antd';
import axios from 'axios';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import List from './List';
import Loading from '../../../component/Loading';
import { getUserRejectedApi, getUpdateUsersApi } from "../../../api/goddess-gang.api";
import {useHistory, useLocation} from 'react-router';
import { cloudFunctionBaseUrl } from '../../../ui-util/general-variable';
import CustomDropdown from '../../../component/Common/custom-dropdown';
import CustomAutoComplete from '../../../component/Common/custom-autocomplete';

const token = localStorage.getItem('TOKEN_AUTH');

function MissionRegisteredRejected() {
    const firstMount = useRef(true)
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [search, setSearch]         = useState('');
    const [ig_followers_min, setIgFollowersMin]            = useState(0);
    const [ig_followers_max, setIgFollowersMax]            = useState('');
    const [ig_er_min, setIgErMin]                          = useState(0);
    const [ig_er_max, setIgErMax]                          = useState('');
    const [ig_reach_min, setIgReachMin]                    = useState(0);
    const [ig_reach_max, setIgReachMax]                    = useState('');
    const [tiktok_followers_min, setTiktokFollowersMin]    = useState(0);
    const [tiktok_followers_max, setTiktokFollowersMax]    = useState('');
    const [tiktok_er_min, setTiktokErMin]                  = useState(0);
    const [tiktok_er_max, setTiktokErMax]                  = useState('');
    const [tiktok_reach_min, setTiktokReachMin]            = useState(0);
    const [tiktok_reach_max, setTiktokReachMax]            = useState('');
    const [limit, setLimit]           = useState(5);
    const [city, setCity]             = useState(null);
    const [interest, setInterest]     = useState(null);
    const [socmed, setSocmed]         = useState(null);
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('created_at');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const dateRangePickerRef          = useRef(null);
    const [filterSosmedOpen, setFilterSosmedOpen] = useState(false);
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        sosmed: null,
        interest: null,
        city: null,
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1
    });
    const {control, formState: {errors}, watch, register, setValue, getValues, handleSubmit, reset} = useForm({mode: "all"});
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    function onChangeDate (event,picker) {
        setAppState({loading: true});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                sosmed: socmed,
                interest: interest,
                city: city,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max, 
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getUserRejectedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function handleSocmed(value) {
        setSocmed(value)
    }
    function hanldeCity(value) {
        setCity(value)
    }
    function handleInterest(value) {
        setInterest(value)
    }
    function resetIg() {
        const ig_followers_min = 0
        setIgFollowersMin(ig_followers_min)
        const ig_followers_max = ''
        setIgFollowersMax(ig_followers_max)
        const ig_er_min = 0
        setIgErMin(ig_er_min)
        const ig_er_max = ''
        setIgErMax(ig_er_max)
        const ig_reach_min = 0
        setIgReachMin(ig_reach_min)
        const ig_reach_max = ''
        setIgReachMax(ig_reach_max)
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                city_id: city,
                sosmed_id: socmed,
                interest_id: interest,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max,
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getUserRejectedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, status: appState.status, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData()
    }
    function resetTiktok() {
        const tiktok_followers_min = 0
        setTiktokFollowersMin(tiktok_followers_min)
        const tiktok_followers_max = ''
        setTiktokFollowersMax(tiktok_followers_max)
        const tiktok_er_min = 0
        setTiktokErMin(tiktok_er_min)
        const tiktok_er_max = ''
        setTiktokErMax(tiktok_er_max)
        const tiktok_reach_min = 0
        setTiktokReachMin(tiktok_reach_min)
        const tiktok_reach_max = ''
        setTiktokReachMax(tiktok_reach_max)
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                city_id: city,
                sosmed_id: socmed,
                interest_id: interest,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max,
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getUserRejectedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, status: appState.status, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData()
    }
    const fetchData = async () => {
        setAppState({ loading: true});
        setFilterSosmedOpen(false);
        let params = {
            size: limit,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            city_id: city,
            sosmed_id: socmed,
            interest_id: interest,
            ig_followers_min: ig_followers_min,
            ig_followers_max: ig_followers_max,
            ig_er_min: ig_er_min,
            ig_er_max: ig_er_max,
            ig_reach_min: ig_reach_min,
            ig_reach_max: ig_reach_max, 
            tiktok_followers_min: tiktok_followers_min,
            tiktok_followers_max: tiktok_followers_max,
            tiktok_er_min: tiktok_er_min,
            tiktok_er_max: tiktok_er_max,
            tiktok_reach_min: tiktok_reach_min,
            tiktok_reach_max: tiktok_reach_max,
            start_date: startDate,
            end_date: endDate,
            key: search
        }
        getUserRejectedApi(params)
            .then(result => {
                setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, status: appState.status, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
            }).catch(err => {
                console.log(err)
            })
        const respUser= await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/goddes-gang/user?status=reject`, {
                params,
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const respSosmed = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/social-media/all`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const respInterest = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/interest-affable`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const respCity = await axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/city`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            })
        const cityTemp = respCity.data.cities.map(data => ({"value":data.id,"text":`${data.type} ${data.name}`}))
        const interestTemp = respInterest.data.result.map(data => ({"value":data.id,"text":data.name}))
        const socmedTemp = respSosmed.data.data.map(data => ({"value":data.id,"text":data.name}))
        setAppState({ loading: false, data: respUser.data.users.rows, total: respUser.data.users.count, per_page: limit, current_page: appState.current_page, status: appState.status, sosmed: socmedTemp, interest: interestTemp, city: cityTemp  });
    };
    const handleCancel = (event, picker) => {
        setAppState({ loading: true});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max, 
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getUserRejectedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    const onSubmit = (data) => alert(JSON.stringify(data));
    const changeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    function onChangeSearch() {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max, 
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getUserRejectedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function set_page(e){
        const selectedPage = e
        setAppState({ loading: true, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
        const fetchData = async () => {
            let params = {
                size: limit,
                page: selectedPage,
                orderby: orderby,
                sort: sort,
                city_id: city,
                sosmed_id: socmed,
                interest_id: interest,
                ig_followers_min: ig_followers_min,
                ig_followers_max: ig_followers_max,
                ig_er_min: ig_er_min,
                ig_er_max: ig_er_max,
                ig_reach_min: ig_reach_min,
                ig_reach_max: ig_reach_max, 
                tiktok_followers_min: tiktok_followers_min,
                tiktok_followers_max: tiktok_followers_max,
                tiktok_er_min: tiktok_er_min,
                tiktok_er_max: tiktok_er_max,
                tiktok_reach_min: tiktok_reach_min,
                tiktok_reach_max: tiktok_reach_max,
                start_date: startDate,
                end_date: endDate,
                key: search
            }
            getUserRejectedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: selectedPage, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }
    function onChangeLimit(e){
        const per_page = e.target.value
        if (per_page < 5) {
            const limit = 5;
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        orderby: orderby,
                        sort: sort,
                        city_id: city,
                        sosmed_id: socmed,
                        interest_id: interest,
                        ig_followers_min: ig_followers_min,
                        ig_followers_max: ig_followers_max,
                        ig_er_min: ig_er_min,
                        ig_er_max: ig_er_max,
                        ig_reach_min: ig_reach_min,
                        ig_reach_max: ig_reach_max, 
                        tiktok_followers_min: tiktok_followers_min,
                        tiktok_followers_max: tiktok_followers_max,
                        tiktok_er_min: tiktok_er_min,
                        tiktok_er_max: tiktok_er_max,
                        tiktok_reach_min: tiktok_reach_min,
                        tiktok_reach_max: tiktok_reach_max,
                        start_date: startDate,
                        end_date: endDate,
                        key: search
                    }
                    getUserRejectedApi(params)
                        .then(result => {
                            setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        } else {
            const limit = e.target.value
            setTimeout(function () {
                setLimit(limit);
                setAppState({ loading: true, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                const fetchData = async () => {
                    let params = {
                        size: limit,
                        page: appState.current_page,
                        orderby: orderby,
                        sort: sort,
                        city_id: city,
                        sosmed_id: socmed,
                        interest_id: interest,
                        ig_followers_min: ig_followers_min,
                        ig_followers_max: ig_followers_max,
                        ig_er_min: ig_er_min,
                        ig_er_max: ig_er_max,
                        ig_reach_min: ig_reach_min,
                        ig_reach_max: ig_reach_max, 
                        tiktok_followers_min: tiktok_followers_min,
                        tiktok_followers_max: tiktok_followers_max,
                        tiktok_er_min: tiktok_er_min,
                        tiktok_er_max: tiktok_er_max,
                        tiktok_reach_min: tiktok_reach_min,
                        tiktok_reach_max: tiktok_reach_max,
                        start_date: startDate,
                        end_date: endDate,
                        key: search
                    }
                    getUserRejectedApi(params)
                        .then(result => {
                            setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
                        }).catch(err => {
                            console.log(err)
                        })
                    };
                fetchData();
            },  3000);
        }
    }
    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
            return 
        }
        set_page(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setAppState({ loading: true});
        fetchData();
        }, []);
    useEffect(() => {
        setAppState({ loading: true});
        fetchData();
        }, [sort, orderby, city, interest, socmed]);

    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
        
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    
    const formatSliderValue = (str) => {
        if (typeof str !== 'string') return [undefined, undefined];
        const [a, b] = str.split('-');
        if (Number.isNaN(parseInt(a, 10))) return [undefined, undefined];
        if (Number.isNaN(parseInt(b, 10))) return [undefined, undefined];
        return [a, b]
    }
    function UpdateUsers() {
        setAppState({status:appState.status, disabled: true, data: appState.data});
        getUpdateUsersApi()
        .then((repos) => {
            const data = repos.message;
            if(repos.status == "Successful") {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload()
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
            }
            setAppState({ loading: false,status:appState.status, total: appState.total, current_page: appState.current_page, data: appState.data, sosmed: appState.sosmed, city : appState.city, interest: appState.interest });
        });  
    }
    // const MIN_VAL = 5;
    // const withValueLimit = ({ floatValue }) => floatValue >= MIN_VAL;
    return ( 
        <div id="main-content">
            {(() => {
            if (appState.city){
            return <div>
            <div className="main-dash">
                <div className="toolbar">
                    <div className="toolbar-left">
                        <div className="toolbar-inner-left">
                            <div className="toolicon-wrapper status sm">
                                <div>
                                    <button onClick={UpdateUsers} disabled={appState.disabled} className="btn btn-primary btn-update-users">{appState.disabled ? "Loading..." : "Update Users"}</button>
                                </div>
                            </div>
                            <div className="page-item">
                                <form className="page-input">
                                    <div className="form-group mb-0">
                                        <label htmlFor="page-count" className="sr-only">Page</label>
                                        <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            decimalScale={0}
                                            displayType="input"
                                            type="text"
                                            value={limit}
                                            allowNegative={false}
                                            onChange={onChangeLimit}
                                            className="form-control"
                                        />
                                    </div>
                                </form>
                                <div className="text-page">/ Page</div>
                            </div>
                        </div>
                    </div>
                    <div className="toolbar-right">
                        <div className="toolbar-inner-right">
                            <div className="toolicon-wrapper check-btn">
                                <Popover
                                        trigger="click"
                                        placement="bottomLeft"
                                        visible={filterSosmedOpen}
                                        onVisibleChange={(visible) => {
                                            setFilterSosmedOpen(visible);
                                        }}
                                        content={(
                                            <div className="tool-wrapper" style={{ border: 'none', boxShadow: 'none', alignItems: 'flex-end', paddingLeft: 14 }}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filter-head">
                                                    <div className="font-medium">Instagram</div>
                                                    <div>
                                                        <button className="btn-reset-filter" onClick={resetIg}>Reset</button>
                                                    </div>
                                                </div>
                                                <p>Followers</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_followers_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgFollowersMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            /> 
                                                        </form>
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_followers_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgFollowersMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                                <p>ER</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_er_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgErMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_er_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgErMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                                <p>Reach</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_reach_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgReachMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={ig_reach_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setIgReachMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="filter-head">
                                                    <div className="font-medium">Tiktok</div>
                                                    <div>
                                                        <button className="btn-reset-filter" onClick={resetTiktok}>Reset</button>
                                                    </div>
                                                </div>
                                                <p>Followers</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_followers_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokFollowersMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />  
                                                        </form>  
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_followers_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokFollowersMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div>
                                                </div>
                                                <p>ER</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_er_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokErMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />    
                                                        </form>
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_er_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokErMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                                <p>Reach</p>
                                                <div className="row mb-4">
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_reach_min}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokReachMin(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Min"
                                                                className="form-control no-radius input-number"
                                                            />
                                                        </form>    
                                                    </div> - 
                                                    <div className="col-5">
                                                        <form onSubmit={handleSubmit(fetchData)}>
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={tiktok_reach_max}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix=""
                                                                decimalScale={0}
                                                                displayType="input"
                                                                type="text"
                                                                allowNegative={false}
                                                                onValueChange={
                                                                    (value) => {
                                                                        setTiktokReachMax(value.floatValue)
                                                                    }
                                                                }
                                                                min="0"
                                                                placeholder="Max"
                                                                className="form-control no-radius input-number"
                                                            />   
                                                        </form> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}>
                                    <label htmlFor="myid" className="toolicon filter" />
                                </Popover>
                            </div>
                            <div className="tool-daterange">
                                <DateRangePicker
                                    ref={dateRangePickerRef}
                                    initialSettings={{
                                        autoUpdateInput: false,
                                        locale: {
                                        cancelLabel: 'Clear',
                                        },
                                        maxDate : new Date().toLocaleDateString("en-US"),
                                        startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
                                        endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                                    }}
                                    autoUpdateInput={true}
                                    startDate={moment(startDate, 'YYYY-MM-DD')}
                                    endDate={moment(endDate, 'YYYY-MM-DD')}
                                    locale={{ format: "YYYY-MM-DD" }} 
                                    onCancel={handleCancel}
                                    onApply={onChangeDate}>
                                    <button className="daterange">
                                        <input type="text" className="form-control" value={labelDate} onChange={onChangeDate} placeholder="Start Date - End Date"/>
                                    </button>
                                </DateRangePicker>
                            </div>    
                            <CustomDropdown title="All Sosmed" icon="sosmed" data={appState.sosmed} onClick={(e) => handleSocmed(e)} active={socmed} />
                            <CustomAutoComplete title="All Interest" icon="interest" data={appState.interest} onClick={(e) => handleInterest(e)} active={interest} />
                            <CustomAutoComplete title="All City" icon="city" data={appState.city} onClick={(e) => hanldeCity(e)} active={city} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group search-input">
                                    <label htmlFor="inputSearch" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="inputSearch" placeholder="Search" value={search} onChange={changeSearch} />
                                    <button className="btn btn-search" onClick={handleSubmit(onChangeSearch)}><img src="/img/ico-search.svg" alt="search" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>{/* end of toolbar*/}
            </div>
            <ListLoading isLoading={appState.loading} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} total={appState.total} formatFollower={formatFollower} set_page={(e)=>set_page(e)} sort={sort} setSort={(e) => setSort(e)} orderby={orderby} setOrderby={(e) => setOrderby(e)} />
            </div>
            } else {
            return <div className="content-wrapper">
                <div className="credential pt-5">
                    <div className="credential-box-noborder">
                    <div className="load-2">
                        <p>Loading... Please wait</p>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    </div>
                </div>
            </div>
            }
        })()}
        </div>
    );
}


export default MissionRegisteredRejected;
