import React from "react";
import CreateBannerComponent from "./create-banner.component";
import PageHeaderComponent from "../../component/page-header.component";

class CreateBannerPage extends React.PureComponent {
    render() {
        return (
            <>
                <PageHeaderComponent title={'Create Banner'}/>
                <CreateBannerComponent />
                </>
            )
    }
}

export default CreateBannerPage
