import {Empty, message, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {getQuestionerResultApi} from '../../api/goddess-gang.api';

const QuestionerAnswers = ({userId, onHide}) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    useEffect(() => {
        if (!userId) return;
        getQuestionerResultApi(userId)
            .then(data => {
                setData(data)
                setLoading(false)
            }).catch((err) => {
                message.error(err.message)
                setLoading(false)
            })
    }, [userId])

    return (
        <Modal show onHide={onHide}>
            <div className="p-4">
                <div className="text-right">
                    <button type="button" className="close" onClick={() => onHide()}>
                        <img src="/img/ico-close.svg" alt="close" />
                    </button>
                </div>
                <div className="p-4">
                    <h2 className="main-title mb-3" style={{color: '#953232'}}>
                    Submitted Data
                    </h2>
                    {loading
                    ? (
                        <div className="d-flex justify-content-center p-4">
                            <Spin />
                        </div>
                    )
                    : data && (
                        <>
                            <div
                                className="px-4 d-flex justify-content-between align-items-center"
                                style={{
                                    height: 50,
                                    border: '1px solid #56932F',
                                    background: '#FAFFF7',
                                    color: '#56932F',
                                    marginBottom: 32,
                                }}
                            >
                                <div style={{fontSize: 14}}>
                                Correct Answers
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className="mr-1 font-weight-bold" style={{fontSize: 44}}>{data.correct_answer}</span>
                                    <span style={{color: '#707070'}}>/ {data.total_answer}</span>
                                </div>
                            </div>
                            {data.data.length > 0 ? data.data.map((item, index) => (
                                <div className="create-questioner-form mb-5">
                                    <div className="d-flex">
                                        <div className="question-number flex-shrink-0">
                                            {index + 1}
                                        </div>
                                        <div className="flex-grow-1 question-field">
                                            <h5 className="read-only-title mb-3">
                                                {item.question}
                                            </h5>
                                            {!!item.image && (
                                                <div
                                                    style={{
                                                        maxWidth: 406,
                                                        marginRight: 16,
                                                    }}
                                                >
                                                    <img 
                                                        src={item.image}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        alt=""
                                                        />
                                                </div>
                                            )}
                                            <div style={{color: '#989898', fontSize: 14}}>
                                            Answer :
                                            </div>
                                            {item.answer.type === 'text' && (
                                                <p>
                                                    {item.answer.label}
                                                </p>
                                            )}
                                            {item.answer.type === 'image' && (
                                                <img
                                                    src={item.answer.label}
                                                    style={{width: 50, height: 50, objectFit: 'contain', marginBottom: 16}}
                                                    alt="option"
                                                    />
                                            )}
                                            {item.answer.is_correct == 1 ? (
                                                <div style={{color: '#56932F'}} className="d-flex align-items-center">
                                                    <img src="/img/ico-check-green.svg" width={24} height={24} className="mr-2" alt=""/>
                                                Correct
                                                </div>
                                            ) : (
                                                <div style={{color: '#953232'}} className="d-flex align-items-center">
                                                    <img src="/img/ico-x-red.svg" width={24} height={24} className="mr-2" alt=""/>
                                                Incorrect
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )) : (
                                <Empty />
                            )}
                            </>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default QuestionerAnswers;
