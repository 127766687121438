import React from "react";
import {Button, DatePicker, Form, Image, Input, InputNumber, Row, Select, Switch, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {isEqual} from "../../ui-util/general-variable";
import {getLevelApi} from "../../api/level.api";
import moment from "moment";
import {getGroupListApi} from "../../api/group.api";
import {DebounceSelect} from "../../component/debounce-select.component";
import {searchUserByEmailApi} from "../../api/user.api";

const {TextArea} = Input;
const {Option} = Select;

class RewardFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            rewardForm: [
                {
                    label: 'Point',
                    name: 'point_cost',
                    type: 'point',
                    required: true,
                    messageAlert: 'Please input point!',
                    placeholder: '1'
                }, {
                    label: 'Name',
                    name: 'name',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input name!',
                    placeholder: 'Cth: Free Produk'
                }, {
                    label: 'Detail',
                    name: 'description',
                    type: 'textarea',
                    required: true,
                    messageAlert: 'Please input detail!',
                    placeholder: 'Cth: Dapatkan 100 poin untuk menggunakan reward ini'
                }, {
                    label: 'Level',
                    name: 'level_id',
                    type: 'select',
                    messageAlert: 'Please select level!',
                    placeholder: 'Pilih level user'
                },
                {
                    label: 'Email',
                    name: 'email',
                    type: 'select-multiple',
                    placeholder: 'Masukkan email!'
                },
                {
                    label: 'Group',
                    name: 'group_name',
                    type: 'select',
                    placeholder: 'Masukkan nama group!'
                }, {
                    label: 'Valid Until',
                    name: 'valid_until',
                    type: 'datepicker',
                    required: true,
                    messageAlert: 'Please add valid date!',
                }, {
                    label: 'Image',
                    name: 'image',
                    type: 'image',
                    required: true,
                    messageAlert: 'Please upload image!'
                }, {
                    label: 'Publish',
                    name: 'is_publish',
                    type: 'toggle',
                }
            ],
            fileImageList: [],
            imagePath: '',
            rewardDetail: {},
            showImage: false,
            levelList: [],
            groupList: [],
            fetchingUserList: false,
            userList: [],
            selectedEmail: [],
            selectValue: false,
            selectKey: ''
        }
    }

    componentDidMount(): void {
        let {rewardDetail} = this.props
        Promise.all([getLevelApi(), getGroupListApi()])
            .then(response => {
                this.setState({levelList: response[0].result, groupList: response[1].results})
            })
            .catch(err => {
                console.log(err)
            })
        if (!!rewardDetail) {
            this.setState({showImage: true})
            rewardDetail.valid_until = moment(rewardDetail.valid_until)
        }

        this.formRef.current.setFieldsValue(rewardDetail)
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {rewardDetail} = this.props
        if (!isEqual(this.props.rewardDetail, prevProps.rewardDetail)) {
            rewardDetail.valid_until = moment(rewardDetail.valid_until)
            this.formRef.current.setFieldsValue(rewardDetail)
        }
    }

    searchUser = (value) => {
        searchUserByEmailApi({email: value})
            .then((response) => response.json())
            .then(response =>
                response.map((user) => ({
                    label: `${user.email}`,
                    value: user.email,
                })),
            );
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {rewardForm, fileImageList, showImage, levelList, groupList, selectValue, selectKey, selectedEmail} = this.state
        const {loading, rewardDetail, onFinish, labelCol, switchValue, onChange} = this.props

        return (
            <>
                <Form ref={this.formRef} onFinish={(value) => onFinish(value, fileImageList, selectedEmail)} labelCol={{span: labelCol}}
                      wrapperCol={{span: 20}}>
                    {
                        rewardForm.map((rewardItem, index) => {
                            switch (rewardItem.type) {
                                case 'image':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <Upload
                                                fileList={fileImageList}
                                                onRemove={this.removeButtonHandler}
                                                beforeUpload={this.beforeUploadImage}
                                            >
                                                {
                                                    showImage &&
                                                    <Image
                                                        src={rewardDetail.image}
                                                        width={100}
                                                    />
                                                }
                                                <Row
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginTop: 10
                                                    }}>
                                                    <Button icon={<UploadOutlined/>}>
                                                        Upload
                                                    </Button>
                                                    <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                                </Row>
                                            </Upload>
                                        </Form.Item>

                                    )
                                case 'textarea':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <TextArea placeholder={rewardItem.placeholder}/>
                                        </Form.Item>
                                    )
                                case 'select':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder={rewardItem.placeholder}
                                                disabled={selectedEmail.length > 0 || (rewardItem.name !== selectKey && selectValue)}
                                                onChange={(value) => {
                                                    if (!!value) {
                                                        this.setState({selectValue: true, selectKey: rewardItem.name})
                                                    } else {
                                                        this.setState({selectValue: false})
                                                    }
                                                }}
                                            >
                                                {
                                                    rewardItem.name === 'level_id' &&
                                                    levelList.map((item, index) => (
                                                        <Option value={item.id}
                                                                key={index.toString()}>{item.name}</Option>
                                                    ))
                                                }
                                                {
                                                    rewardItem.name === 'group_name' &&
                                                    groupList.map((item, index) => (
                                                        <Option value={item.name}
                                                                key={index.toString()}>{item.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                case 'select-multiple':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <DebounceSelect
                                                disabled={selectValue}
                                                value={selectedEmail}
                                                onChange={(newValue) => {
                                                    this.setState({selectedEmail: newValue})
                                                }}
                                            />
                                        </Form.Item>
                                    )
                                case 'point':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <InputNumber min={1} placeholder={rewardItem.placeholder}/>
                                        </Form.Item>
                                    )
                                case 'datepicker':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <DatePicker style={{width: 373}} />
                                        </Form.Item>
                                    )
                                case 'toggle':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <Switch checked={switchValue} onChange={(value) => onChange(value)}/>
                                        </Form.Item>
                                    )
                                default:
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={rewardItem.label}
                                            name={rewardItem.name}
                                            rules={[{
                                                type: rewardItem.rulesType,
                                                required: rewardItem.required,
                                                message: rewardItem.messageAlert,
                                            }]}
                                        >
                                            <Input placeholder={rewardItem.placeholder}/>
                                        </Form.Item>
                                    )
                            }
                        })
                    }
                    <Form.Item
                        wrapperCol={{
                            span: 8,
                            offset: 4,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default RewardFormComponent
