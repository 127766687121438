import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const createAndUpdateRecommendationApi = (skinConditionKey, skinTypeId, bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/${skinConditionKey}/${skinTypeId}/recomendation/`,
            {recomendation: bodyRequest},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getRecommendationBySkinType = (skinConditionKey) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/${skinConditionKey}/recomendation`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getDetailRecommendationApi = (skinConditionKey, skinTypeId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/${skinConditionKey}/${skinTypeId}/recomendation`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {createAndUpdateRecommendationApi, getRecommendationBySkinType, getDetailRecommendationApi}
