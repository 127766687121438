import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getBannerListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banners`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const createBannerApi = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banners/create`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const updateBannerApi = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banners/update/${id}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const deleteBannerApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banners/delete/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getBannerDetailApi = (bannerId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/banners/${bannerId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {
    getBannerListApi,
    createBannerApi,
    updateBannerApi,
    deleteBannerApi,
    getBannerDetailApi
}
