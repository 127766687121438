import React from "react";
import {Modal} from "antd";

class EditFormModal extends React.PureComponent{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {modalVisibility, changeVisibility, content, title, modalVisibilityKey} = this.props
        return (
            <Modal
                visible={modalVisibility}
                title={title}
                onCancel={() => changeVisibility(modalVisibilityKey,false, {})}
                footer={null}
            >
                {content}
            </Modal>
        )
    }
}

export default EditFormModal
