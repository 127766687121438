import {formatDateToReadable} from "./general-variable";

const setUserData = (rows) => {
    let userList = []
    rows.forEach((row) => {
        let userDetail = {}
        userDetail = {
            id: row.id,
            name: row.full_name,
            email: row.email,
            birthDate: !!row.birth_date ? formatDateToReadable(row.birth_date) : '',
            gender: row.gender,
            joinDate: formatDateToReadable(row.createdAt),
            phone: !!row.phone ? row.phone : '',
            referal_id: row.referal_id,
            address: !!row.address ? `${row.address}, ${row.address_city.name}, ${row.address_city.province.name}` : '',
            level: !!row.level ? row.level.name : '',
            skinConcern: !!row.skin_concern ? row.skin_concern.name : '',
            skinType: !!row.skin_type ? row.skin_type.name : '',
            skinUndertone: !!row.skin_undertone ? row.skin_undertone.name : '',
            skinTone: !!row.skin_tone ? row.skin_tone.name : '',
        }

        if(row.social_medias.length > 0){
            row.social_medias.forEach(socialMedia => {
                userDetail[socialMedia.name] = socialMedia.user_social_medias.value
            })
        }
        if(row.interests.length > 0){
            row.interests.forEach((interest) => {
                if(!!interest.interest_category){
                    userDetail[interest.interest_category.name] = interest.name
                }
            })
        }
        userList.push(userDetail)
    })

    return userList
}

export {setUserData}
