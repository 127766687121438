import React from "react";
import {Button, Form, Image, Input, Row, Select, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {getInterestCategoryApi} from "../../api/interest-category.api";
import {isEqual} from "../../ui-util/general-variable";

const {Option} = Select;

class InterestFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            fileImageList: [],
            categoryList: []
        }
    }

    componentDidMount(): void {
        this.getInterestCategoryList()
        const {interestDetail} = this.props
        this.formRef.current.setFieldsValue(interestDetail)
    }

    getInterestCategoryList = () => {
        getInterestCategoryApi()
            .then(response => {
                this.setState({categoryList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage]});
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {interestDetail} = this.props
        if (!isEqual(this.props.interestDetail, prevProps.interestDetail)) {
            this.formRef.current.setFieldsValue(interestDetail)
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {fileImageList, categoryList} = this.state
        const {onFinish, interestDetail, labelCol, loading} = this.props
        return (
            <Form ref={this.formRef} onFinish={(value) => onFinish(value, fileImageList)} labelCol={{span: labelCol}}
                  wrapperCol={{span: 10}}>
                {!interestDetail && <Form.Item
                    key={'interestCategoryId'}
                    label={'Category'}
                    name={'interestCategoryId'}
                    rules={[{
                        required: true,
                        message: 'Please select category!',
                    }]}
                >
                    <Select
                        placeholder="Pilih kategori"
                        style={{width: '100%'}}
                    >
                        {
                            categoryList.map(item => <Option key={item.id}>{item.name}</Option>)
                        }
                    </Select>
                </Form.Item>}
                <Form.Item
                    key='name'
                    label='Name'
                    name='name'
                    rules={[{
                        required: true,
                        message: 'Please input name',
                    }]}
                >
                    <Input placeholder={'Cth: Dewy'}/>
                </Form.Item>
                <Form.Item
                    key='image'
                    label='Image'
                    name='image'
                    rules={[{
                        required: true,
                        message: 'Please input image',
                    }]}
                >
                    <Upload
                        fileList={fileImageList}
                        onRemove={this.removeButtonHandler}
                        beforeUpload={this.beforeUploadImage}
                    >
                        {
                            !!interestDetail &&
                            <Image
                                src={interestDetail.image}
                                width={100}
                            />
                        }
                        <Row style={{marginTop: 10}}>
                            <Button style={{marginRight: 10}} icon={<UploadOutlined/>}>
                                Upload
                            </Button>
                            <p>Ukuran gambar maksimal 2MB</p>
                        </Row>
                    </Upload>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default InterestFormComponent
