import React, { useEffect, useState, useRef, useCallback } from 'react';
import List from './List';
import Loading from '../../../../component/Loading';
import { getUserApprovedApi } from "../../../../api/goddess-gang.api";
import {useHistory, useLocation} from 'react-router';


function CreateGoddessGangGroup() {
    const firstMount = useRef(true)
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [name, setName]             = useState('');
    const [search, setSearch]         = useState('');
    const [limit, setLimit]           = useState(5);
    const [sort, setSort]             = useState('desc');
    const [orderby, setOrderby]       = useState('created_at');
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        disabled: false,
        total: null,
        per_page: 5,
        current_page: 1,
    });
    const [ranges, setRanges]         = useState({
        ig_followers_min: 0,
        ig_followers_max: '',
        ig_er_min: 0,
        ig_er_max: '',
        ig_reach_min: 0,
        ig_reach_min: '',
        tiktok_followers_min: 0,
        tiktok_followers_max: '',
        tiktok_er_min: 0,
        tiktok_er_max: '',
        tiktok_reach_min: 0,
        tiktok_reach_min: '',
    })
    const fetchData = async () => {
        setAppState({ loading: true});
        let params = {
            size: limit,
            page: appState.current_page,
            orderby: orderby,
            sort: sort,
            ig_followers_min: ranges.ig_followers_min,
            ig_followers_max: ranges.ig_followers_max,
            ig_er_min: ranges.ig_er_min,
            ig_er_max: ranges.ig_er_max,
            ig_reach_min: ranges.ig_reach_min,
            ig_reach_max: ranges.ig_reach_max,
            tiktok_followers_min: ranges.tiktok_followers_min,
            tiktok_followers_max: ranges.tiktok_followers_max,
            tiktok_er_min: ranges.tiktok_er_min,
            tiktok_er_max: ranges.tiktok_er_max,
            tiktok_reach_min: ranges.tiktok_reach_min,
            tiktok_reach_max: ranges.tiktok_reach_max,
            key: search
        }
        getUserApprovedApi(params)
        .then(result => {
            setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page });
        }).catch(err => {
            console.log(err)
        })
    };
    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
            return 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges])
    useEffect(() => {
        setAppState({ loading: true});
        const fetchData = async () => {
            let params = {
                size: limit,
                page: appState.current_page,
                orderby: orderby,
                sort: sort
            }
            getUserApprovedApi(params)
                .then(result => {
                    setAppState({ loading: false, data: result.users.rows, total: result.users.count, per_page: limit, current_page: appState.current_page });
                }).catch(err => {
                    console.log(err)
                })
            };
        fetchData();
    }, []);
    // Configuration    
    const COUNT_FORMATS =
    [
        { // 0 - 999
            letter: '',
            limit: 1e3
        },
        { // 1,000 - 999,999
            letter: 'K',
            limit: 1e6
        },
        { // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9
        },
        { // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12
        },
        { // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15
        }
    ];
        
    // Format Method:
    function formatFollower(value)
    {
        const format = COUNT_FORMATS.find(format => (value < format.limit));

        value = (1000 * value / format.limit);
        value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

        return (value + format.letter);
    }
    return ( 
        <div id="main-content">
            <ListLoading isLoading={appState.loading} current_page={appState.current_page} per_page={appState.per_page} data={appState.data} total={appState.total} formatFollower={formatFollower} name={name} setName={setName} limit={limit} sort={sort} setSort={(e) => setSort(e)} orderby={orderby} setOrderby={(e) => setOrderby(e)}/>
        </div>
    );
}


export default CreateGoddessGangGroup;
