import React from "react";
import {CaretRightOutlined} from "@ant-design/icons";
import {Button, Collapse, Table, Tag} from "antd";
import {getInterestRecommendationApi} from "../../api/interest.api";
import {Link} from "react-router-dom";

const {Panel} = Collapse;

class InterestRecommendationComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'User Interest', dataIndex: 'name', key: 'name'},
                {
                    title: 'Product Recommendation',
                    dataIndex: 'recomendation',
                    key: 'recommendation',
                    render: (recomendation) => recomendation.map(item => <Tag color="#0e528a" style={{width: 70, textAlign: 'center'}}
                                                                              key={item.product_id}>{item.product_name}</Tag>)
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => (
                        <Link to={`recommendation/${record.id}`}>
                            <Button>Edit</Button>
                        </Link>
                    )
                }
            ],
            interestRecommendation : []
        }
    }

    componentDidMount() {
        getInterestRecommendationApi()
            .then(response => {
                this.setState({interestRecommendation: response.data})
            })
            .catch(err => console.log(err))
    }

    render() {
        const {interestRecommendation, columns} = this.state
        return (
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                onChange={this.onChangePanel}
                className="site-collapse-custom-collapse">
                {

                    interestRecommendation.map(item => (
                        <Panel header={item.name} key={item.id}>
                            <Table
                                className="components-table-demo-nested"
                                columns={columns}
                                dataSource={item.interests}
                                pagination={false}
                                rowKey={'id'}
                            />
                        </Panel>
                    ))
                }
            </Collapse>
        )
    }
}

export default InterestRecommendationComponent