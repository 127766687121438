import React from "react";
import PointFormComponent from "./point-form.component";
import {createPointApi} from "../../api/point.api";

class CreatePointComponent extends React.PureComponent{

    constructor() {
        super();

        this.state = {
            loading: false
        }
    }

    onFinishCreatePoint = (values) => {
        this.setState({loading: true})
        createPointApi(values)
            .then(result => {
                this.setState({loading: false})
                alert('Point berhasil dibuat!')
                window.location.href = '/point'
            }).catch(err => {
                console.log(err)
        })
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {loading} = this.state
        return (<PointFormComponent onFinish={this.onFinishCreatePoint} loading={loading} labelCol={2}/>)
    }
}

export default CreatePointComponent
