import React from "react";
import {Button, Form, Input, Select} from "antd";
import {getLevelApi} from "../../api/level.api";
import {isEqual} from "../../ui-util/general-variable";

const {TextArea} = Input;
const {Option} = Select;

class PointFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            pointSettingForm: [
                {
                    label: 'Name',
                    name: 'name',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input point name!',
                    placeholder: 'Cth: Welcome Point'
                }, {
                    label: 'Type',
                    name: 'type',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input point name!',
                    placeholder: 'Cth: WELCOME_PONT'
                }, {
                    label: 'Code',
                    name: 'code',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input code!',
                    placeholder: 'Cth: LD01'
                }, {
                    label: 'Point',
                    name: 'point',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input point!',
                    placeholder: 'Cth: 100'
                }, {
                    label: 'Level',
                    name: 'level_id',
                    type: 'select',
                    required: true,
                    messageAlert: 'Please input level!',
                    placeholder: 'Pilih level user'
                }, {
                    label: 'Description',
                    name: 'description',
                    type: 'textArea',
                    required: false,
                    placeholder: 'Masukkan deskripsi'
                },
            ],
            levelList: []
        }
    }

    componentDidMount(): void {
        const {pointDetail} = this.props
        this.formRef.current.setFieldsValue(pointDetail)
        this.getLevelData()
    }

    getLevelData = () => {
        getLevelApi()
            .then(response => {
                this.setState({levelList: response.result})
            })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {pointDetail} = this.props
        if (!isEqual(this.props.pointDetail, prevProps.pointDetail)) {
            this.formRef.current.setFieldsValue(pointDetail)
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {pointSettingForm, levelList} = this.state
        const {onFinish, loading, labelCol, slug} = this.props
        return (
            <>
                <Form onFinish={onFinish} labelCol={{span: labelCol}} ref={this.formRef}
                      wrapperCol={{span: 12}}>
                        {
                            pointSettingForm.map((item, index) => {
                                switch (item.type) {
                                    case 'textArea':
                                        return (
                                            <Form.Item
                                                key={index.toString()}
                                                label={item.label}
                                                name={item.name}
                                                rules={[{
                                                    required: item.required,
                                                    message: item.messageAlert,
                                                }]}
                                            >
                                                <TextArea placeholder={item.placeholder}/>
                                            </Form.Item>
                                        )
                                    case 'select':
                                        return (
                                            <Form.Item
                                                key={index.toString()}
                                                label={item.label}
                                                name={item.name}
                                                rules={[{
                                                    required: item.required,
                                                    message: item.messageAlert,
                                                }]}
                                            >
                                                <Select
                                                    style={{width: '100%'}}
                                                    placeholder={item.placeholder}
                                                >
                                                    {
                                                        levelList.map((item) => (
                                                            <Option value={item.id}
                                                                    key={item.id}>{item.name}</Option>
                                                        ))}
                                                </Select>

                                            </Form.Item>
                                        )
                                    default:
                                        return (
                                            <Form.Item
                                                key={index.toString()}
                                                label={item.label}
                                                name={item.name}
                                                rules={[{
                                                    required: item.required,
                                                    message: item.messageAlert,
                                                }]}
                                            >
                                                <Input disabled={slug === 'edit' && item.name === 'type' ? true : false} placeholder={item.placeholder}/>
                                            </Form.Item>
                                        )
                                }
                            })
                        }
                    <Form.Item
                        wrapperCol={{
                            span:4,
                            offset: 4,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default PointFormComponent
